<script>
import {UseDark} from "@vueuse/components";
import loginBox from "./loginBox";

export default {
    name: 'GSApps',
    components: {
        loginBox,
        UseDark
    },
    props: {
        view: Object,
        platformName: String,
    },
    setup() {
    },
    computed: {
        mainApps() {
            return this.appsLink.filter(x => x.cat === 'main')
        },
        insideApps() {
            return this.appsLink.filter(x => x.cat === 'inside')
        },
        otherApps() {
            return this.appsLink.filter(x => x.cat === 'other')
        },
    },
    data() {
        return {
            appsHover: null,
            appsLink: [
                {
                    title: '碳排盤查',
                    cat: 'main',
                    url: 'https://esg.greenshepherd.com.tw/?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/esg.png',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_esg.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_esg.svg',
                },
                {
                    title: '虛擬電廠',
                    cat: 'main',
                    url: 'https://vpp.greenshepherd.com.tw/?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/ai.png',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_ai.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_ai.svg',
                },
                {
                    title: '能源交易',
                    cat: 'main',
                    url: 'https://market.greenshepherd.com.tw/?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/vpp.png',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_vpp.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_vpp.svg',
                },
                {
                    title: '案件流程',
                    cat: 'inside',
                    url: 'https://www.greenshepherd.com.tw/afu?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/afu.png',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu.svg',
                },
                {
                    title: '維運派工',
                    cat: 'inside',
                    url: 'https://om.greenshepherd.com.tw/?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/om.png',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_om.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_om.svg',
                },
                {
                    title: '電力交易',
                    cat: 'inside',
                    url: 'https://trade.greenshepherd.com.tw/?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/trade.png',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_trade.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_trade.svg',
                },
                {
                    title: '人資管理',
                    cat: 'inside',
                    url: 'https://hr.greenshepherd.com.tw/?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/hr.png',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
                {
                    title: '阿福測試區',
                    cat: 'inside',
                    url: 'https://afu.greenshepherd.com.tw/?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/test.png?r=1',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
                {
                    title: '文件簽核',
                    cat: 'inside',
                    url: 'https://sign.greenshepherd.com.tw/?r=' + Math.random(),
                    img: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/test.png?r=1',
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
            ],
            messageList: [],
            message: '',
            showLogin: false,
            showApps: false,
            showUser: false,
        }
    },
    methods: {
        updateShowApps(newVal) {
            this.$emit('update:showApps', newVal);
        },
        logout() {
            this.api.get('/api/sso/logout').then(() => {
                this.$emit('update:userLogout', Object.freeze({
                    ...{},
                    roles: [],
                    isLogin: false,
                }));
            })
        },
        accountManager() {
            location.href = 'https://account.greenshepherd.com.tw/?r=' + Math.random()
        },
        handleMaskClick() {
            this.showLogin = false
        },
        handleLoginSuccess() {
            this.showLogin = false
            this.$router.push('/user/memberPage')
        },
    },
}
</script>

<template>
    <div class="appsArea">
        <div class="apps">
            <!-- <a @click.stop.prevent="showLogin = true" class="btnUserLogin">登入</a>-->
            <template v-if="view.isLogin">
                <a @click="showUser = true">
                    <div class="userIcon" :style="{'backgroundImage': view.currentUser.avatar}"></div>
                </a>
            </template>
            <template v-else>
                <a @click.stop.prevent="showLogin = true" class="btnUserLogin">登入</a>
            </template>
            <a href="#" @click.stop.prevent="showApps = true" ><Icon type="ios-apps"/></a>
            <Notification icon="md-notifications" placement="bottom-end" :style="{ fontSize: '35px !important' }">
                <NotificationTab title="通知" name="message" :count="messageList.length">
                    <NotificationItem
                        v-for="(item, index) in messageList"
                        :key="index"
                        :title="item.title"
                        :icon="item.icon"
                        :icon-color="item.iconColor"
                        :time="item.time"
                        :read="item.read"
                    />
                </NotificationTab>
            </Notification>
        </div>
        <div class="appsMask" v-show="showApps || showUser" @click="showApps = false; showUser = false"></div>
        <div class="userBox" v-show="showUser">
            <div class="userInfo">
                <div class="userIconBig" :style="{'backgroundImage': view.currentUser.avatar}"></div>
                <h1>{{ view.currentUser.user_name }}，你好！</h1>
                <router-link class="serviceBtn" :to="'/user/memberPage'">會員中心</router-link>
            </div>
            <div class="userButtons" v-if="view.isLogin" @click="view.intoDebug">
                <a class="accountBtn" href="#" @click.prevent.stop="accountManager">帳號管理</a>
                <a class="logoutBtn" href="#" @click.prevent.stop="logout"><Icon type="md-exit" />登出</a>
            </div>

            <div class="userDarkMode" v-if="view.isLogin">
                <span class="darkMode">
                    深色模式
                </span>
                <UseDark v-slot="{ isDark, toggleDark }">
                    <div @click="toggleDark()" class="darkModeArea">
                        <Icon v-show="isDark" type="ios-moon"/>
                        <Icon v-show="!isDark" type="ios-sunny"/>
                    </div>
                </UseDark>
            </div>
        </div>
        <div class="appsBox" v-show="showApps" :class="{'small': !view.isLogin || insideApps.length === 0}">
            <ul>
                <li v-for="app in mainApps" :key="app.title" @click="location.href = app.url" @mouseover="appsHover = app.title"
                    @mouseleave="appsHover = ''"><a :href="app.url">
                    <img :src="appsHover === app.title ? app.svg : app.svg2"/><br/>{{ app.title }}
                </a></li>
            </ul>
            <ul v-if="view.isLogin">
                <li v-for="app in insideApps" :key="app.title" @click="location.href = app.url" @mouseover="appsHover = app.title"
                    @mouseleave="appsHover = ''"><a :href="app.url">
                    <img :src="appsHover === app.title ? app.svg : app.svg2"/><br/>{{ app.title }}
                </a></li>
            </ul>
        </div>
        <loginBox :platformName="platformName" :view="view" v-if="showLogin" @maskClick="handleMaskClick" @loginSuccess="handleLoginSuccess"  />
    </div>
</template>
<style lang="stylus">
.apps
    position: absolute
    font-size: 38px
    line-height: 60px
    cursor: pointer
    top: 14px
    right: 0
    color: #fff
    float: right
    margin: 0

    .ivu-notifications
        float: right
        margin: -6px 5px

        .ivu-badge .ivu-icon-md-notifications
            font-size: 32px !important

            &:hover
                color: #f3993a !important

    a
        margin-right: 5px
        float: right
        width: 50px !important
        height: 50px !important
        display: inline-block
        line-height: 50px
        color: #ffffff !important;

        &:hover
            color: #f3993a !important

        .userIcon
            margin-top: 4px
            background-color #fff
            border: solid 4px #eee
            border-radius: 50%
            width: 42px !important
            height: 42px !important

            &:hover
                border: solid 3px #f3993a

    .btnUserLogin
        float: right
        font-size: 14px
        font-weight: 600
        padding: 10px 15px
        width: 75px !important
        height: 30px !important
        line-height: 10px
        background: #f08200
        color: #fff
        border: none
        margin: 10px 15px 10px 0
        display: inline-block
        text-align: center
        border-radius: 5px

        &:hover
            background: #ffffff
            color: #f08200

.appsMask
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0, 0, 0, 0)
    z-index: 999999

.appsArea
    position: relative

.userBox
    user-select: none
    position: absolute
    top: 72px
    right: 12px
    height: 420px
    width: 325px
    padding 0;
    background: #99999966
    border: solid 12px #aaaaaa05
    border-radius: 6px
    z-index: 9999999
    overflow-y: auto
    overflow-x: hidden
    box-shadow: 0 0 10px #00000033
    &::-webkit-scrollbar
        width: 2px
        height: 8px
        background-color: transparent

    &::-webkit-scrollbar-thumb
        background: #ffffff33
        &:hover
            background-color: var(--focus-color)

    &>div
        border-radius: 8px
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        margin: 5px 15px 5px 5px
        background: var(--apps-bg-color);
        color: #bbb;

    .userInfo
        padding: 25px 15px;
        h1
            margin-top: 25px;
        .userIconBig
            border-radius: 50%;
            border: solid 5px #fff;
            background: #dedede;
            width: 100px;
            height: 100px;
        .serviceBtn
            border: solid;
            padding: 10px 25px;
            border-radius: 24px;
            margin-top: 10px;

    .userButtons
        height: 42px
        line-height: 40px
        border: solid 1px #aaaaaa05
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin: 5px 15px 5px 5px;
        background: var(--apps-bg-color);
        color: #fff;
        padding: 0 20px;

        .accountBtn,
        .logoutBtn
            border-radius: 8px 0 0 8px
            font-size: 16px
            margin-right: 2px
            overflow: hidden
            color: #ffffff

        .logoutBtn
            border-radius: 0 8px 8px 0
            text-align: right


    .userDarkMode
        border: solid 1px #aaaaaa05
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin: 5px 15px 5px 5px;
        background: var(--apps-bg-color);
        color: #fff;
        padding: 0 20px;

        .darkModeArea
            cursor: pointer
            float: right
            margin: 10px 10px
            border: 1px solid #ffffff
            background-color: #dddddd
            width: 42px
            height: 23px
            border-radius: 30px

            .ivu-icon
                float: left
                font-size: 16px
                margin: 2px 3px
                background: #fff
                border-radius: 30px
                color: #000

                &.ivu-icon-ios-moon
                    color: #ffffff
                    background: #000000
                    margin-left: 19px
                    margin-right: 1px
.appsBox
    user-select: none
    position: absolute
    top: 72px
    right: 52px
    height: 320px
    width: 325px
    background: #99999966
    border: solid 12px #aaaaaa05
    border-radius: 6px
    z-index: 9999999
    overflow-y: auto
    overflow-x: hidden

    &.small
        height 126px;

    &::-webkit-scrollbar
        width: 2px
        height: 8px
        background-color: transparent

    &::-webkit-scrollbar-thumb
        background: #ffffff33
        &:hover
            background-color: var(--focus-color)

    .noLogin, ul
        width: 300px
        background: var(--apps-bg-color)
        border-radius: 8px
        display: flex
        flex-wrap: wrap
        margin-bottom: 12px
        margin-right: 5px
        color: #ffffff

    li
        padding: 5px
        list-style-type: none
        cursor: pointer
        width: 100px
        height: 90px
        overflow: hidden

        img
            height: 50px
            border: none
            max-width: 100%

        &:hover a
            color: #96C800

        a
            color: #bbbbbb
            font-size: 16px
            line-height: 25px
            display: block

    .accounts
        width: 300px
        color: #bbbbbb
        text-align: right
        line-height: 51px
        margin-right: 0
        background: var(--apps-bg-color)
        border-radius: 5px
        padding: 0
        height: 50px

        a:hover
            color: #96C800 !important

        .darkMode
            color: #bbbbbb
            text-align: left
            display: inline-block
            float: left
            margin-left: 15px
            height: 55px
            width: 110px
            overflow: hidden



</style>