<template>
    <div class="sso-check">
        <!-- 可以根據需要添加一些 UI 元素 -->
    </div>
</template>

<script>
import axios from 'axios';
import UserSession from './UserSession';
import { useUserStore } from './UserStore';

export default {
    name: 'SSOCheck',
    props: {
        showApps: {
            type: Boolean,
            default: false
        },
    },
    emits: ['userSessionReady', 'update:ssoCheck'],
    setup() {
        const userStore = useUserStore();
        return { userStore };
    },
    data() {
        return {
            localUserSession: new UserSession(),
        };
    },
    mounted() {
        this.emitUserSession();
        this.ssoCheck();
    },
    methods: {
        async ssoCheck() {
            try {
                const serviceUrl = this.getServiceUrl(location.hostname);
                const response = await axios.get(`/api/sso/check?indialog=true&service=${encodeURI(serviceUrl)}`);
                const {data} = response;

                this.localUserSession.updateLoginStatus(data.isLogin);
                this.localUserSession.updateSsoStatus(data.url, data.url !== '');
                this.processUserRoles(data.data);

                if (this.isDevelopmentEnvironment()) {
                    this.addDevelopmentRoles();
                }

                // 更新 Pinia store
                this.userStore.setUserSession(this.localUserSession);

                this.$emit('update:ssoCheck', {
                    userData: this.localUserSession.currentUser,
                    roles: this.localUserSession.roleList,
                    isLogin: this.localUserSession.isLogin,
                });
            } catch (error) {
                console.error('SSO 檢查失敗:', error);
                throw error;
            }
        },
        emitUserSession() {
            const userSessionProxy = new Proxy(this.localUserSession, {
                get: (target, prop) => {
                    if (typeof target[prop] === 'function') {
                        return target[prop].bind(target);
                    }
                    return target[prop];
                }
            });
            this.$emit('userSessionReady', userSessionProxy);
        },
        getServiceUrl(hostname) {
            if (hostname === 'localhost' || hostname === '127.0.0.1') {
                let url = `${location.protocol}//${hostname}:${location.port}`;
                return url.endsWith(':') ? url.slice(0, -1) : url;
            }
            return hostname.replace('.greenshepherd.com.tw', '');
        },
        processUserRoles(userData) {
            if (!userData) return;
            try {
                const roles = userData.user_roles ? userData.user_roles.split(',') : [];
                this.localUserSession.setRoles(roles);
                this.localUserSession.updateUser(userData);
            } catch (err) {
                console.error('處理用戶角色時出錯:', err);
                this.localUserSession.setRoles([]);
            }
        },
        isDevelopmentEnvironment() {
            return ['localhost', '127.0.0.1'].includes(location.hostname);
        },
        addDevelopmentRoles() {
            const devRoles = ['admin', 'hr', 'dev'];
            this.localUserSession.setRoles([...new Set([...this.localUserSession.roleList, ...devRoles])]);
        },
    },
};
</script>

<style scoped>
.sso-check {
    /* 可以根據需要添加樣式 */
}
</style>