import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        userSession: null,
    }),

    getters: {
        ssoUrl: (state) => state.userSession ? state.userSession.ssoUrl : '',
        isLogin: (state) => state.userSession ? state.userSession.isLogin : false,
        roleList: (state) => state.userSession ? state.userSession.roleList : [],
        hasRole: (state) => (role) => state.userSession ? state.userSession.hasRole(role) : false,
        checkPermission: (state) => (permissions) => state.userSession ? state.userSession.checkPermission(permissions) : false,
    },

    actions: {
        setUserSession(session) {
            this.userSession = session;
        },
        clearUserSession() {
            this.userSession = null;
        },
    },
});