export {bankCodes,bankBranchCodes}
let bankCodes =
  [
    {"code": "004", "name": "臺灣銀行",shortcut:"臺銀"},
    {"code": "005", "name": "臺灣土地銀行",shortcut:"土銀"},
    {"code": "006", "name": "合作金庫商業銀行",shortcut:"合庫商銀"},
    {"code": "007", "name": "第一商業銀行",shortcut:"一銀"},
    {"code": "008", "name": "華南商業銀行",shortcut:"華銀"},
    {"code": "009", "name": "彰化商業銀行",shortcut: "彰銀"},
    {"code": "011", "name": "上海商業儲蓄銀行",shortcut: "上銀"},
    {"code": "012", "name": "台北富邦商業銀行",shortcut: "北富銀"},
    {"code": "013", "name": "國泰世華商業銀行",shortcut: "國世銀"},
    {"code": "016", "name": "高雄銀行",shortcut: "高銀"},
    {"code": "017", "name": "兆豐國際商業銀行",shortcut: "兆豐銀"},
    {"code": "018", "name": "全國農業金庫",shortcut: "農業金庫"},
    {"code": "020", "name": "日商瑞穗銀行台北分行",shortcut: "瑞穗銀行"},
    {"code": "021", "name": "花旗（台灣）商業銀行",shortcut: "花旗台銀"},
    {"code": "022", "name": "美國銀行台北分行",shortcut: "美銀台北"},
    {"code": "023", "name": "泰國盤谷銀行台北分行",shortcut: "盤谷台北"},
    {"code": "025", "name": "首都銀行",shortcut: "首都台北"},
    {"code": "029", "name": "新加坡商大華銀行台北分行",shortcut: "大華銀台北"},
    {"code": "030", "name": "美商道富銀行台北分行",shortcut: "道富銀行"},
    {"code": "037", "name": "法商法國興業銀行台北分行",shortcut: "法興台北"},
    {"code": "039", "name": "澳盛（台灣）商業銀行",shortcut: "澳盛台灣"},
    {"code": "048", "name": "台灣工業銀行",shortcut: "台灣工銀"},
    {"code": "050", "name": "臺灣中小企業銀行",shortcut: "臺企"},
    {"code": "052", "name": "渣打國際商業銀行",shortcut: "渣商銀"},
    {"code": "053", "name": "台中商業銀行",shortcut: "台中銀"},
    {"code": "054", "name": "京城商業銀行",shortcut: "京城銀行"},
    {"code": "060", "name": "兆豐票券金融股份有限公司",shortcut: "兆豐票券"},
    {"code": "061", "name": "中華票券",shortcut: "華票"},
    {"code": "062", "name": "國際票券",shortcut: "國票"},
    {"code": "066", "name": "萬通票券金融股份有限公司",shortcut: " 萬票"},
    {"code": "072", "name": "德商德意志銀行台北分行",shortcut: "德銀台北"},
    {"code": "075", "name": "香港商東亞銀行台北分行",shortcut: "東亞銀行"},
    {"code": "076", "name": "美商摩根大通銀行台北分行",shortcut: "摩根大通銀"},
    {"code": "081", "name": "匯豐（台灣）商業銀行",shortcut: "匯豐台灣"},
    {"code": "082", "name": "法國巴黎銀行台北分行",shortcut: "巴黎銀行"},
    {"code": "085", "name": "新加坡商新加坡華僑銀行台北分行",shortcut: "新僑銀行"},
    {"code": "086", "name": "法商東方匯理銀行台北分行",shortcut: "東方匯理"},
    {"code": "092", "name": "瑞士商瑞士銀行台北分行",shortcut: "瑞士銀行"},
    {"code": "093", "name": "荷商安智銀行台北分行",shortcut: "安智銀行"},
    {"code": "098", "name": "日商三菱東京日聯銀行台北分行",shortcut: "三菱日聯"},
    {"code": "101", "name": "瑞興商業銀行",shortcut: "瑞興銀行"},
    {"code": "102", "name": "華泰商業銀行",shortcut: "華泰銀行"},
    {"code": "103", "name": "臺灣新光商業銀行",shortcut: "新光銀行"},
    {"code": "108", "name": "陽信商業銀行",shortcut: "陽信銀行"},
    {"code": "118", "name": "板信商業銀行",shortcut: "板信銀行"},
    {"code": "147", "name": "三信商業銀行",shortcut: "三信銀行"},
    {"code": "321", "name": "日商三井住友銀行台北分行",shortcut: "三井住友"},
    {"code": "372", "name": "大慶票券金融股份有限公司",shortcut: "大慶票券"},
    {"code": "380", "name": "大陸商中國銀行臺北分行",shortcut: "中銀臺北"},
    {"code": "381", "name": "大陸商交通銀行臺北分行",shortcut: "交銀臺北"},
    {"code": "382", "name": "大陸商中國建設銀行臺北分行",shortcut: "建行臺北"},
    {"code": "700", "name": "中華郵政",shortcut: "中華郵政"},
    {"code": "803", "name": "聯邦商業銀行",shortcut: "聯邦銀行"},
    {"code": "805", "name": "遠東國際商業銀行",shortcut: "遠東銀行"},
    {"code": "806", "name": "元大商業銀行",shortcut: "元大銀行"},
    {"code": "807", "name": "永豐商業銀行",shortcut: "永豐銀行"},
    {"code": "808", "name": "玉山商業銀行",shortcut: "玉山銀行"},
    {"code": "809", "name": "凱基商業銀行",shortcut: "凱基銀行"},
    {"code": "810", "name": "星展（台灣）商業銀行",shortcut: "星展台灣"},
    {"code": "812", "name": "台新國際商業銀行",shortcut: "台新銀行"},
    {"code": "814", "name": "大眾商業銀行",shortcut: "大眾銀行"},
    {"code": "815", "name": "日盛國際商業銀行",shortcut: "日盛銀行"},
    {"code": "816", "name": "安泰商業銀行",shortcut: "安泰銀行"},
    {"code": "822", "name": "中國信託商業銀行",shortcut: "中信銀行"},

    {"code": "104", "name": "台北市第五信用合作社",shortcut: "北五"},
    {"code": "114", "name": "基隆第一信用合作社",shortcut: "基一"},
    {"code": "115", "name": "基隆市第二信用合作社",shortcut: "基二"},
    {"code": "119", "name": "淡水第一信用合作社",shortcut: "淡一"},
    {"code": "120", "name": "新北市淡水信用合作社",shortcut: "淡信"},
    {"code": "124", "name": "宜蘭信用合作社",shortcut: "宜信"},
    {"code": "127", "name": "桃園信用合作社",shortcut: "桃信"},
    {"code": "130", "name": "新竹第一信用合作社",shortcut: "竹一"},
    {"code": "132", "name": "新竹第三信用合作社",shortcut: "竹三"},
    {"code": "146", "name": "台中市第二信用合作社",shortcut: "中二"},
    {"code": "158", "name": "彰化第一信用合作社",shortcut: "彰一"},
    {"code": "161", "name": "彰化第五信用合作社",shortcut: "彰五"},
    {"code": "162", "name": "彰化第六信用合作社",shortcut: "彰六"},
    {"code": "163", "name": "彰化第十信用合作社",shortcut: "彰十"},
    {"code": "165", "name": "彰化縣鹿港信用合作社",shortcut: "鹿信"},
    {"code": "178", "name": "嘉義市第三信用合作社",shortcut: "嘉三"},
    {"code": "188", "name": "台南第三信用合作社",shortcut: "南三"},
    {"code": "204", "name": "高雄市第三信用合作社",shortcut: "高三"},
    {"code": "215", "name": "花蓮第一信用合作社",shortcut: "花一"},
    {"code": "216", "name": "花蓮第二信用合作社",shortcut: "花二"},
    {"code": "222", "name": "澎湖縣第一信用合作社",shortcut: "澎一"},
    {"code": "223", "name": "澎湖第二信用合作社",shortcut: "澎二"},
    {"code": "224", "name": "金門縣信用合作社",shortcut: "金門"},

    {"code": "503", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "504", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "505", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "506", "name": "聯資中心所屬會員",shortcut: "聯資中心"},
    {"code": "507", "name": "聯資中心所屬會員",shortcut: "聯資中心"},
    {"code": "512", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "515", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "517", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "518", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "520", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "521", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "523", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "524", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "525", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "526", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "954(漁會)", "name": "財團法人農漁會中區資訊中心",shortcut: "中農中心"},

    {"code": "603", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "605", "name": "高雄市高雄地區農會",shortcut: "高區農"},
    {"code": "606", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "607", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "608", "name": "聯資中心所屬會員",shortcut: "聯資中心"},
    {"code": "609", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "610", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "611", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "612", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "613", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "614", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "616", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "617", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "618", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "619", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "620", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "621", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "622", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "623", "name": "北農中心所屬會員",shortcut: "北農中心"},
    {"code": "624", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "625", "name": "臺中市臺中地區農會",shortcut: "中區農"},
    {"code": "627", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "901", "name": "板農中心所屬會員",shortcut: "板農中心"},
    {"code": "903", "name": "新北市汐止區農會",shortcut: "汐農"},
    {"code": "904", "name": "板農中心所屬會員",shortcut: "板農中心"},
    {"code": "912", "name": "板農中心所屬會員",shortcut: "板農中心"},
    {"code": "916", "name": "板農中心所屬會員",shortcut: "板農中心"},
    {"code": "922", "name": "臺南市臺南地區農會",shortcut: "南區農"},
    {"code": "928", "name": "板農中心所屬會員",shortcut: "板農中心"},
    {"code": "952", "name": "南農中心所屬會員",shortcut: "南農中心"},
    {"code": "954(農會)", "name": "財團法人農漁會中區資訊中心",shortcut: "中農中心"},
  ]
let bankBranchCodes= [
    {
        "parentCode": "101",
        "code": "1010011",
        "name": "營業部",
        "shortcut": "瑞興營業"
    },
    {
        "parentCode": "101",
        "code": "1010020",
        "name": "民生分行",
        "shortcut": "瑞興民生"
    },
    {
        "parentCode": "101",
        "code": "1010039",
        "name": "建成分行",
        "shortcut": "瑞興建成"
    },
    {
        "parentCode": "101",
        "code": "1010048",
        "name": "大橋分行",
        "shortcut": "瑞興大橋"
    },
    {
        "parentCode": "101",
        "code": "1010057",
        "name": "南京東路分行",
        "shortcut": "瑞興南京東"
    },
    {
        "parentCode": "101",
        "code": "1010066",
        "name": "昆明分行",
        "shortcut": "瑞興昆明"
    },
    {
        "parentCode": "101",
        "code": "1010075",
        "name": "長安分行",
        "shortcut": "瑞興長安"
    },
    {
        "parentCode": "101",
        "code": "1010084",
        "name": "永吉分行",
        "shortcut": "瑞興永吉"
    },
    {
        "parentCode": "101",
        "code": "1010093",
        "name": "和平東路分行",
        "shortcut": "瑞興和平東"
    },
    {
        "parentCode": "101",
        "code": "1010109",
        "name": "石牌分行",
        "shortcut": "瑞興石牌"
    },
    {
        "parentCode": "101",
        "code": "1010118",
        "name": "內湖分行",
        "shortcut": "瑞興內湖"
    },
    {
        "parentCode": "101",
        "code": "1010127",
        "name": "成功簡易型分行",
        "shortcut": "瑞興成功簡"
    },
    {
        "parentCode": "101",
        "code": "1010136",
        "name": "古亭分行",
        "shortcut": "瑞興古亭"
    },
    {
        "parentCode": "101",
        "code": "1010145",
        "name": "景美分行",
        "shortcut": "瑞興景美"
    },
    {
        "parentCode": "101",
        "code": "1010154",
        "name": "南港分行",
        "shortcut": "瑞興南港"
    },
    {
        "parentCode": "101",
        "code": "1010163",
        "name": "信義簡易型分行",
        "shortcut": "瑞興信義簡"
    },
    {
        "parentCode": "101",
        "code": "1010172",
        "name": "萬華分行",
        "shortcut": "瑞興萬華"
    },
    {
        "parentCode": "101",
        "code": "1010181",
        "name": "士林分行",
        "shortcut": "瑞興士林"
    },
    {
        "parentCode": "101",
        "code": "1010190",
        "name": "松山簡易型分行",
        "shortcut": "瑞興松山簡"
    },
    {
        "parentCode": "101",
        "code": "1010206",
        "name": "城內分行",
        "shortcut": "瑞興城內"
    },
    {
        "parentCode": "101",
        "code": "1010215",
        "name": "桃園分行",
        "shortcut": "瑞興桃園"
    },
    {
        "parentCode": "101",
        "code": "1010224",
        "name": "中和分行",
        "shortcut": "瑞興中和"
    },
    {
        "parentCode": "102",
        "code": "1020010",
        "name": "營業部",
        "shortcut": "華泰銀營"
    },
    {
        "parentCode": "102",
        "code": "1020029",
        "name": "迪化街分行",
        "shortcut": "華泰銀迪化"
    },
    {
        "parentCode": "102",
        "code": "1020038",
        "name": "建成分行",
        "shortcut": "華泰銀建成"
    },
    {
        "parentCode": "102",
        "code": "1020047",
        "name": "大同分行",
        "shortcut": "華泰銀大同"
    },
    {
        "parentCode": "102",
        "code": "1020056",
        "name": "中山分行",
        "shortcut": "華泰銀中山"
    },
    {
        "parentCode": "102",
        "code": "1020065",
        "name": "大安分行",
        "shortcut": "華泰銀大安"
    },
    {
        "parentCode": "102",
        "code": "1020074",
        "name": "松山分行",
        "shortcut": "華泰銀松山"
    },
    {
        "parentCode": "102",
        "code": "1020083",
        "name": "古亭分行",
        "shortcut": "華泰銀古亭"
    },
    {
        "parentCode": "102",
        "code": "1020092",
        "name": "士林分行",
        "shortcut": "華泰銀士林"
    },
    {
        "parentCode": "102",
        "code": "1020108",
        "name": "內湖分行",
        "shortcut": "華泰銀內湖"
    },
    {
        "parentCode": "102",
        "code": "1020117",
        "name": "信義分行",
        "shortcut": "華泰銀信義"
    },
    {
        "parentCode": "102",
        "code": "1020126",
        "name": "永吉分行",
        "shortcut": "華泰銀永吉"
    },
    {
        "parentCode": "102",
        "code": "1020135",
        "name": "和平分行",
        "shortcut": "華泰銀和平"
    },
    {
        "parentCode": "102",
        "code": "1020144",
        "name": "光復分行",
        "shortcut": "華泰銀光復"
    },
    {
        "parentCode": "102",
        "code": "1020153",
        "name": "文山簡易型分行",
        "shortcut": "華泰銀文山"
    },
    {
        "parentCode": "102",
        "code": "1020171",
        "name": "石牌分行",
        "shortcut": "華泰銀石牌"
    },
    {
        "parentCode": "102",
        "code": "1020180",
        "name": "萬華分行",
        "shortcut": "華泰銀萬華"
    },
    {
        "parentCode": "102",
        "code": "1020199",
        "name": "桃園分行",
        "shortcut": "華泰銀桃園"
    },
    {
        "parentCode": "102",
        "code": "1020205",
        "name": "松德分行",
        "shortcut": "華泰銀松德"
    },
    {
        "parentCode": "102",
        "code": "1020214",
        "name": "新莊分行",
        "shortcut": "華泰銀新莊"
    },
    {
        "parentCode": "102",
        "code": "1020223",
        "name": "中和分行",
        "shortcut": "華泰銀中和"
    },
    {
        "parentCode": "102",
        "code": "1020232",
        "name": "板橋分行",
        "shortcut": "華泰銀板橋"
    },
    {
        "parentCode": "102",
        "code": "1020241",
        "name": "南京東路分行",
        "shortcut": "華泰銀南京"
    },
    {
        "parentCode": "102",
        "code": "1020250",
        "name": "敦化分行",
        "shortcut": "華泰銀敦化"
    },
    {
        "parentCode": "102",
        "code": "1020269",
        "name": "新店分行",
        "shortcut": "華泰銀新店"
    },
    {
        "parentCode": "102",
        "code": "1020278",
        "name": "中壢分行",
        "shortcut": "華泰銀中壢"
    },
    {
        "parentCode": "102",
        "code": "1020287",
        "name": "高雄分行",
        "shortcut": "華泰銀高雄"
    },
    {
        "parentCode": "102",
        "code": "1020296",
        "name": "三重分行",
        "shortcut": "華泰銀三重"
    },
    {
        "parentCode": "102",
        "code": "1020302",
        "name": "大直分行",
        "shortcut": "華泰銀大直"
    },
    {
        "parentCode": "102",
        "code": "1020348",
        "name": "台南分行",
        "shortcut": "華泰台南"
    },
    {
        "parentCode": "102",
        "code": "1020357",
        "name": "北高雄分行",
        "shortcut": "華泰銀北高"
    },
    {
        "parentCode": "102",
        "code": "1020366",
        "name": "台中分行",
        "shortcut": "華泰銀台中"
    },
    {
        "parentCode": "102",
        "code": "1020375",
        "name": "彰化分行",
        "shortcut": "華泰銀彰化"
    },
    {
        "parentCode": "103",
        "code": "1030019",
        "name": "中正分行",
        "shortcut": "新光中正"
    },
    {
        "parentCode": "103",
        "code": "1030028",
        "name": "東台北分行",
        "shortcut": "新光東台北"
    },
    {
        "parentCode": "103",
        "code": "1030037",
        "name": "龍山分行",
        "shortcut": "新光龍山"
    },
    {
        "parentCode": "103",
        "code": "1030046",
        "name": "西園分行",
        "shortcut": "新光西園"
    },
    {
        "parentCode": "103",
        "code": "1030055",
        "name": "西門分行",
        "shortcut": "新光西門"
    },
    {
        "parentCode": "103",
        "code": "1030064",
        "name": "大同分行",
        "shortcut": "新光大同"
    },
    {
        "parentCode": "103",
        "code": "1030073",
        "name": "復興分行",
        "shortcut": "新光復興"
    },
    {
        "parentCode": "103",
        "code": "1030082",
        "name": "忠孝簡易型分行",
        "shortcut": "新光忠孝簡"
    },
    {
        "parentCode": "103",
        "code": "1030091",
        "name": "五常分行",
        "shortcut": "新光五常"
    },
    {
        "parentCode": "103",
        "code": "1030107",
        "name": "城北分行",
        "shortcut": "新光城北"
    },
    {
        "parentCode": "103",
        "code": "1030116",
        "name": "城內分行",
        "shortcut": "新光城內"
    },
    {
        "parentCode": "103",
        "code": "1030125",
        "name": "新湖分行",
        "shortcut": "新光新湖"
    },
    {
        "parentCode": "103",
        "code": "1030134",
        "name": "松安分行",
        "shortcut": "新光松安"
    },
    {
        "parentCode": "103",
        "code": "1030143",
        "name": "慶城分行",
        "shortcut": "新光慶城"
    },
    {
        "parentCode": "103",
        "code": "1030152",
        "name": "內湖分行",
        "shortcut": "新光內湖"
    },
    {
        "parentCode": "103",
        "code": "1030161",
        "name": "世貿分行",
        "shortcut": "新光世貿"
    },
    {
        "parentCode": "103",
        "code": "1030170",
        "name": "松山分行",
        "shortcut": "新光松山"
    },
    {
        "parentCode": "103",
        "code": "1030189",
        "name": "南港分行",
        "shortcut": "新光南港"
    },
    {
        "parentCode": "103",
        "code": "1030198",
        "name": "林森北路分行",
        "shortcut": "新光林森北"
    },
    {
        "parentCode": "103",
        "code": "1030204",
        "name": "大安簡易型分行",
        "shortcut": "新光大安簡"
    },
    {
        "parentCode": "103",
        "code": "1030213",
        "name": "新竹分行",
        "shortcut": "新光新竹"
    },
    {
        "parentCode": "103",
        "code": "1030222",
        "name": "中壢分行",
        "shortcut": "新光中壢"
    },
    {
        "parentCode": "103",
        "code": "1030231",
        "name": "桃園分行",
        "shortcut": "新光桃園"
    },
    {
        "parentCode": "103",
        "code": "1030240",
        "name": "東三重分行",
        "shortcut": "新光東三重"
    },
    {
        "parentCode": "103",
        "code": "1030259",
        "name": "竹北分行",
        "shortcut": "新光竹北"
    },
    {
        "parentCode": "103",
        "code": "1030268",
        "name": "連城路分行",
        "shortcut": "新光連城路"
    },
    {
        "parentCode": "103",
        "code": "1030277",
        "name": "竹科分行",
        "shortcut": "新光竹科"
    },
    {
        "parentCode": "103",
        "code": "1030286",
        "name": "土城分行",
        "shortcut": "新光土城"
    },
    {
        "parentCode": "103",
        "code": "1030295",
        "name": "蘆洲分行",
        "shortcut": "新光蘆洲"
    },
    {
        "parentCode": "103",
        "code": "1030301",
        "name": "建成分行",
        "shortcut": "新光建成"
    },
    {
        "parentCode": "103",
        "code": "1030310",
        "name": "北三重分行",
        "shortcut": "新光北三重"
    },
    {
        "parentCode": "103",
        "code": "1030329",
        "name": "永和分行",
        "shortcut": "新光永和"
    },
    {
        "parentCode": "103",
        "code": "1030338",
        "name": "新埔分行",
        "shortcut": "新光新埔"
    },
    {
        "parentCode": "103",
        "code": "1030347",
        "name": "台中分行",
        "shortcut": "新光台中"
    },
    {
        "parentCode": "103",
        "code": "1030356",
        "name": "中港分行",
        "shortcut": "新光中港"
    },
    {
        "parentCode": "103",
        "code": "1030365",
        "name": "左營華夏路分行",
        "shortcut": "新光左營華"
    },
    {
        "parentCode": "103",
        "code": "1030374",
        "name": "大甲分行",
        "shortcut": "新光大甲"
    },
    {
        "parentCode": "103",
        "code": "1030383",
        "name": "大墩分行",
        "shortcut": "新光大墩"
    },
    {
        "parentCode": "103",
        "code": "1030392",
        "name": "員林分行",
        "shortcut": "新光員林"
    },
    {
        "parentCode": "103",
        "code": "1030408",
        "name": "南屯分行",
        "shortcut": "新光南屯"
    },
    {
        "parentCode": "103",
        "code": "1030417",
        "name": "東台南分行",
        "shortcut": "新光東台南"
    },
    {
        "parentCode": "103",
        "code": "1030426",
        "name": "大里分行",
        "shortcut": "新光大里"
    },
    {
        "parentCode": "103",
        "code": "1030435",
        "name": "松竹分行",
        "shortcut": "新光松竹"
    },
    {
        "parentCode": "103",
        "code": "1030444",
        "name": "彰化分行",
        "shortcut": "新光彰化"
    },
    {
        "parentCode": "103",
        "code": "1030453",
        "name": "樹林分行",
        "shortcut": "新光樹林"
    },
    {
        "parentCode": "103",
        "code": "1030462",
        "name": "後埔分行",
        "shortcut": "新光後埔"
    },
    {
        "parentCode": "103",
        "code": "1030471",
        "name": "古亭分行",
        "shortcut": "新光古亭"
    },
    {
        "parentCode": "103",
        "code": "1030480",
        "name": "士林分行",
        "shortcut": "新光士林"
    },
    {
        "parentCode": "103",
        "code": "1030499",
        "name": "營業部",
        "shortcut": "新光營業部"
    },
    {
        "parentCode": "103",
        "code": "1030505",
        "name": "丹鳳分行",
        "shortcut": "新光丹鳳"
    },
    {
        "parentCode": "103",
        "code": "1030514",
        "name": "北投復興崗分行",
        "shortcut": "新光北投復"
    },
    {
        "parentCode": "103",
        "code": "1030523",
        "name": "新生南路分行",
        "shortcut": "新光新生南"
    },
    {
        "parentCode": "103",
        "code": "1030541",
        "name": "新營分行",
        "shortcut": "新光新營"
    },
    {
        "parentCode": "103",
        "code": "1030550",
        "name": "天母分行",
        "shortcut": "新光天母"
    },
    {
        "parentCode": "103",
        "code": "1030569",
        "name": "新店分行",
        "shortcut": "新光新店"
    },
    {
        "parentCode": "103",
        "code": "1030578",
        "name": "大直分行",
        "shortcut": "新光大直"
    },
    {
        "parentCode": "103",
        "code": "1030587",
        "name": "興隆簡易型分行",
        "shortcut": "新光興隆簡"
    },
    {
        "parentCode": "103",
        "code": "1030596",
        "name": "八德分行",
        "shortcut": "新光八德"
    },
    {
        "parentCode": "103",
        "code": "1030611",
        "name": "長安分行",
        "shortcut": "新光長安"
    },
    {
        "parentCode": "103",
        "code": "1030620",
        "name": "嘉義分行",
        "shortcut": "新光嘉義"
    },
    {
        "parentCode": "103",
        "code": "1030648",
        "name": "鳳山分行",
        "shortcut": "新光鳳山"
    },
    {
        "parentCode": "103",
        "code": "1030657",
        "name": "台南分行",
        "shortcut": "新光台南"
    },
    {
        "parentCode": "103",
        "code": "1030666",
        "name": "北嘉義簡易型分行",
        "shortcut": "新光北嘉簡"
    },
    {
        "parentCode": "103",
        "code": "1030675",
        "name": "斗六分行",
        "shortcut": "新光斗六"
    },
    {
        "parentCode": "103",
        "code": "1030684",
        "name": "竹南分行",
        "shortcut": "新光竹南"
    },
    {
        "parentCode": "103",
        "code": "1030693",
        "name": "花蓮分行",
        "shortcut": "新光花蓮"
    },
    {
        "parentCode": "103",
        "code": "1030709",
        "name": "基隆分行",
        "shortcut": "新光基隆"
    },
    {
        "parentCode": "103",
        "code": "1030718",
        "name": "宜蘭分行",
        "shortcut": "新光宜蘭"
    },
    {
        "parentCode": "103",
        "code": "1030727",
        "name": "三峽分行",
        "shortcut": "新光三峽"
    },
    {
        "parentCode": "103",
        "code": "1030736",
        "name": "路竹簡易型分行",
        "shortcut": "新光路竹簡"
    },
    {
        "parentCode": "103",
        "code": "1030745",
        "name": "高雄分行",
        "shortcut": "新光高雄"
    },
    {
        "parentCode": "103",
        "code": "1030754",
        "name": "新莊分行",
        "shortcut": "新光新莊"
    },
    {
        "parentCode": "103",
        "code": "1030763",
        "name": "江子翠分行",
        "shortcut": "新光江子翠"
    },
    {
        "parentCode": "103",
        "code": "1030772",
        "name": "林口分行",
        "shortcut": "新光林口"
    },
    {
        "parentCode": "103",
        "code": "1030781",
        "name": "彌陀簡易型分行",
        "shortcut": "新光彌陀簡"
    },
    {
        "parentCode": "103",
        "code": "1030790",
        "name": "岡山分行",
        "shortcut": "新光岡山"
    },
    {
        "parentCode": "103",
        "code": "1030806",
        "name": "北高雄分行",
        "shortcut": "新光北高雄"
    },
    {
        "parentCode": "103",
        "code": "1030824",
        "name": "小港分行",
        "shortcut": "新光小港"
    },
    {
        "parentCode": "103",
        "code": "1030833",
        "name": "中華分行",
        "shortcut": "新光中華"
    },
    {
        "parentCode": "103",
        "code": "1030842",
        "name": "承德分行",
        "shortcut": "新光承德"
    },
    {
        "parentCode": "103",
        "code": "1030851",
        "name": "敦南分行",
        "shortcut": "新光敦南"
    },
    {
        "parentCode": "103",
        "code": "1030860",
        "name": "中和分行",
        "shortcut": "新光中和"
    },
    {
        "parentCode": "103",
        "code": "1030879",
        "name": "南台中分行",
        "shortcut": "新光南台中"
    },
    {
        "parentCode": "103",
        "code": "1030888",
        "name": "水湳分行",
        "shortcut": "新光水湳"
    },
    {
        "parentCode": "103",
        "code": "1030897",
        "name": "北屯分行",
        "shortcut": "新光北屯"
    },
    {
        "parentCode": "103",
        "code": "1030903",
        "name": "西屯分行",
        "shortcut": "新光西屯"
    },
    {
        "parentCode": "103",
        "code": "1030912",
        "name": "向上分行",
        "shortcut": "新光向上"
    },
    {
        "parentCode": "103",
        "code": "1030921",
        "name": "十甲分行",
        "shortcut": "新光十甲"
    },
    {
        "parentCode": "103",
        "code": "1030930",
        "name": "豐原分行",
        "shortcut": "新光豐原"
    },
    {
        "parentCode": "103",
        "code": "1030949",
        "name": "永安分行",
        "shortcut": "新光永安"
    },
    {
        "parentCode": "103",
        "code": "1030958",
        "name": "屏東分行",
        "shortcut": "新光屏東"
    },
    {
        "parentCode": "103",
        "code": "1030967",
        "name": "東園分行",
        "shortcut": "新光東園"
    },
    {
        "parentCode": "103",
        "code": "1030976",
        "name": "萬丹分行",
        "shortcut": "新光萬丹"
    },
    {
        "parentCode": "103",
        "code": "1030985",
        "name": "七賢分行",
        "shortcut": "新光七賢"
    },
    {
        "parentCode": "103",
        "code": "1030994",
        "name": "南崁分行",
        "shortcut": "新光南崁"
    },
    {
        "parentCode": "103",
        "code": "1031003",
        "name": "汐止分行",
        "shortcut": "新光汐止"
    },
    {
        "parentCode": "103",
        "code": "1031012",
        "name": "桃北分行",
        "shortcut": "新光桃北"
    },
    {
        "parentCode": "103",
        "code": "1031021",
        "name": "東新竹分行",
        "shortcut": "新光東新竹"
    },
    {
        "parentCode": "103",
        "code": "1031030",
        "name": "九如分行",
        "shortcut": "新光九如"
    },
    {
        "parentCode": "103",
        "code": "1031049",
        "name": "永康分行",
        "shortcut": "新光永康"
    },
    {
        "parentCode": "103",
        "code": "1031058",
        "name": "壢新分行",
        "shortcut": "新光壢新"
    },
    {
        "parentCode": "103",
        "code": "1031067",
        "name": "沙鹿分行",
        "shortcut": "新光沙鹿"
    },
    {
        "parentCode": "103",
        "code": "1031076",
        "name": "大雅分行",
        "shortcut": "新光大雅"
    },
    {
        "parentCode": "103",
        "code": "1031094",
        "name": "草屯分行",
        "shortcut": "新光草屯"
    },
    {
        "parentCode": "103",
        "code": "1031100",
        "name": "南東分行",
        "shortcut": "新光南東"
    },
    {
        "parentCode": "104",
        "code": "1040018",
        "name": "營業部",
        "shortcut": "北五營"
    },
    {
        "parentCode": "104",
        "code": "1040027",
        "name": "吉林分社",
        "shortcut": "北五吉林"
    },
    {
        "parentCode": "104",
        "code": "1040036",
        "name": "大同分社",
        "shortcut": "北五大同"
    },
    {
        "parentCode": "104",
        "code": "1040045",
        "name": "中山分社",
        "shortcut": "北五中山"
    },
    {
        "parentCode": "104",
        "code": "1040054",
        "name": "松山分社",
        "shortcut": "北五松山"
    },
    {
        "parentCode": "104",
        "code": "1040063",
        "name": "大安分社",
        "shortcut": "北五大安"
    },
    {
        "parentCode": "104",
        "code": "1040072",
        "name": "石牌分社",
        "shortcut": "北五石牌"
    },
    {
        "parentCode": "104",
        "code": "1040081",
        "name": "南港分社",
        "shortcut": "北五南港"
    },
    {
        "parentCode": "104",
        "code": "1040090",
        "name": "文山分社",
        "shortcut": "北五文山"
    },
    {
        "parentCode": "104",
        "code": "1040106",
        "name": "中正分社",
        "shortcut": "北五中正"
    },
    {
        "parentCode": "104",
        "code": "1040115",
        "name": "北投分社",
        "shortcut": "北五北投"
    },
    {
        "parentCode": "104",
        "code": "1040124",
        "name": "內湖分社",
        "shortcut": "北五內湖"
    },
    {
        "parentCode": "104",
        "code": "1040133",
        "name": "天母分社",
        "shortcut": "北五天母"
    },
    {
        "parentCode": "108",
        "code": "1080014",
        "name": "營業部",
        "shortcut": "陽信營"
    },
    {
        "parentCode": "108",
        "code": "1080023",
        "name": "石牌分行",
        "shortcut": "陽信石牌"
    },
    {
        "parentCode": "108",
        "code": "1080032",
        "name": "北投分行",
        "shortcut": "陽信北投"
    },
    {
        "parentCode": "108",
        "code": "1080041",
        "name": "社子分行",
        "shortcut": "陽信社子"
    },
    {
        "parentCode": "108",
        "code": "1080050",
        "name": "士林分行",
        "shortcut": "陽信士林"
    },
    {
        "parentCode": "108",
        "code": "1080069",
        "name": "大屯分行",
        "shortcut": "陽信大屯"
    },
    {
        "parentCode": "108",
        "code": "1080078",
        "name": "劍潭分行",
        "shortcut": "陽信劍潭"
    },
    {
        "parentCode": "108",
        "code": "1080087",
        "name": "蘭雅分行",
        "shortcut": "陽信蘭雅"
    },
    {
        "parentCode": "108",
        "code": "1080096",
        "name": "天母分行",
        "shortcut": "陽信天母"
    },
    {
        "parentCode": "108",
        "code": "1080102",
        "name": "社中分行",
        "shortcut": "陽信社中"
    },
    {
        "parentCode": "108",
        "code": "1080111",
        "name": "吉林分行",
        "shortcut": "陽信吉林"
    },
    {
        "parentCode": "108",
        "code": "1080120",
        "name": "成功分行",
        "shortcut": "陽信成功"
    },
    {
        "parentCode": "108",
        "code": "1080139",
        "name": "民生分行",
        "shortcut": "陽信民生"
    },
    {
        "parentCode": "108",
        "code": "1080148",
        "name": "延吉分行",
        "shortcut": "陽信延吉"
    },
    {
        "parentCode": "108",
        "code": "1080157",
        "name": "木柵分行",
        "shortcut": "陽信木柵"
    },
    {
        "parentCode": "108",
        "code": "1080166",
        "name": "龍江分行",
        "shortcut": "陽信龍江"
    },
    {
        "parentCode": "108",
        "code": "1080175",
        "name": "南京分行",
        "shortcut": "陽信南京"
    },
    {
        "parentCode": "108",
        "code": "1080184",
        "name": "景美分行",
        "shortcut": "陽信景美"
    },
    {
        "parentCode": "108",
        "code": "1080193",
        "name": "中興分行",
        "shortcut": "陽信中興"
    },
    {
        "parentCode": "108",
        "code": "1080209",
        "name": "信義分行",
        "shortcut": "陽信信義"
    },
    {
        "parentCode": "108",
        "code": "1080218",
        "name": "中和分行",
        "shortcut": "陽信中和"
    },
    {
        "parentCode": "108",
        "code": "1080227",
        "name": "永和分行",
        "shortcut": "陽信永和"
    },
    {
        "parentCode": "108",
        "code": "1080236",
        "name": "蘆洲分行",
        "shortcut": "陽信蘆洲"
    },
    {
        "parentCode": "108",
        "code": "1080245",
        "name": "板橋分行",
        "shortcut": "陽信板橋"
    },
    {
        "parentCode": "108",
        "code": "1080263",
        "name": "新和簡易型分行",
        "shortcut": "陽信新和簡"
    },
    {
        "parentCode": "108",
        "code": "1080272",
        "name": "溪洲分行",
        "shortcut": "陽信溪洲"
    },
    {
        "parentCode": "108",
        "code": "1080281",
        "name": "泰山分行",
        "shortcut": "陽信泰山"
    },
    {
        "parentCode": "108",
        "code": "1080290",
        "name": "新莊分行",
        "shortcut": "陽信新莊"
    },
    {
        "parentCode": "108",
        "code": "1080306",
        "name": "古亭分行",
        "shortcut": "陽信古亭"
    },
    {
        "parentCode": "108",
        "code": "1080315",
        "name": "三重分行",
        "shortcut": "陽信三重"
    },
    {
        "parentCode": "108",
        "code": "1080324",
        "name": "大業簡易型分行",
        "shortcut": "陽信大業簡"
    },
    {
        "parentCode": "108",
        "code": "1080333",
        "name": "雙和分行",
        "shortcut": "陽信雙和"
    },
    {
        "parentCode": "108",
        "code": "1080342",
        "name": "復興分行",
        "shortcut": "陽信復興"
    },
    {
        "parentCode": "108",
        "code": "1080351",
        "name": "大安分行",
        "shortcut": "陽信大安"
    },
    {
        "parentCode": "108",
        "code": "1080360",
        "name": "桃園分行",
        "shortcut": "陽信桃園"
    },
    {
        "parentCode": "108",
        "code": "1080379",
        "name": "新店分行",
        "shortcut": "陽信新店"
    },
    {
        "parentCode": "108",
        "code": "1080397",
        "name": "新福簡易型分行",
        "shortcut": "陽信新福簡"
    },
    {
        "parentCode": "108",
        "code": "1080403",
        "name": "新竹分行",
        "shortcut": "陽信新竹"
    },
    {
        "parentCode": "108",
        "code": "1080412",
        "name": "員林分行",
        "shortcut": "陽信員林"
    },
    {
        "parentCode": "108",
        "code": "1080421",
        "name": "花蓮分行",
        "shortcut": "陽信花蓮"
    },
    {
        "parentCode": "108",
        "code": "1080430",
        "name": "精武分行",
        "shortcut": "陽信精武"
    },
    {
        "parentCode": "108",
        "code": "1080449",
        "name": "社頭簡易型分行",
        "shortcut": "陽信社頭簡"
    },
    {
        "parentCode": "108",
        "code": "1080458",
        "name": "屏東分行",
        "shortcut": "陽信屏東"
    },
    {
        "parentCode": "108",
        "code": "1080467",
        "name": "中正簡易型分行",
        "shortcut": "陽信中正簡"
    },
    {
        "parentCode": "108",
        "code": "1080476",
        "name": "高雄分行",
        "shortcut": "陽信高雄"
    },
    {
        "parentCode": "108",
        "code": "1080485",
        "name": "大同分行",
        "shortcut": "陽信大同"
    },
    {
        "parentCode": "108",
        "code": "1080494",
        "name": "北屯分行",
        "shortcut": "陽信北屯"
    },
    {
        "parentCode": "108",
        "code": "1080500",
        "name": "嘉義分行",
        "shortcut": "陽信嘉義"
    },
    {
        "parentCode": "108",
        "code": "1080519",
        "name": "新埔分行",
        "shortcut": "陽信新埔"
    },
    {
        "parentCode": "108",
        "code": "1080528",
        "name": "台南分行",
        "shortcut": "陽信台南"
    },
    {
        "parentCode": "108",
        "code": "1080555",
        "name": "東寧簡易型分行",
        "shortcut": "陽信東寧簡"
    },
    {
        "parentCode": "108",
        "code": "1080564",
        "name": "中華分行",
        "shortcut": "陽信中華"
    },
    {
        "parentCode": "108",
        "code": "1080573",
        "name": "健康分行",
        "shortcut": "陽信健康"
    },
    {
        "parentCode": "108",
        "code": "1080582",
        "name": "台中分行",
        "shortcut": "陽信台中"
    },
    {
        "parentCode": "108",
        "code": "1080607",
        "name": "西華分行",
        "shortcut": "陽信西華"
    },
    {
        "parentCode": "108",
        "code": "1080616",
        "name": "安順分行",
        "shortcut": "陽信安順"
    },
    {
        "parentCode": "108",
        "code": "1080634",
        "name": "左營分行",
        "shortcut": "陽信左營"
    },
    {
        "parentCode": "108",
        "code": "1080643",
        "name": "向上分行",
        "shortcut": "陽信向上"
    },
    {
        "parentCode": "108",
        "code": "1080652",
        "name": "內湖分行",
        "shortcut": "陽信內湖"
    },
    {
        "parentCode": "108",
        "code": "1080661",
        "name": "中壢分行",
        "shortcut": "陽信中壢"
    },
    {
        "parentCode": "108",
        "code": "1080670",
        "name": "五股分行",
        "shortcut": "陽信五股"
    },
    {
        "parentCode": "108",
        "code": "1080689",
        "name": "林森分行",
        "shortcut": "陽信林森"
    },
    {
        "parentCode": "108",
        "code": "1080698",
        "name": "立文分行",
        "shortcut": "陽信立文"
    },
    {
        "parentCode": "108",
        "code": "1080704",
        "name": "新興分行",
        "shortcut": "陽信新興"
    },
    {
        "parentCode": "108",
        "code": "1080713",
        "name": "青年分行",
        "shortcut": "陽信青年"
    },
    {
        "parentCode": "108",
        "code": "1080722",
        "name": "三鳳分行",
        "shortcut": "陽信三鳳"
    },
    {
        "parentCode": "108",
        "code": "1080731",
        "name": "四維分行",
        "shortcut": "陽信四維"
    },
    {
        "parentCode": "108",
        "code": "1080740",
        "name": "大公分行",
        "shortcut": "陽信大公"
    },
    {
        "parentCode": "108",
        "code": "1080759",
        "name": "大順分行",
        "shortcut": "陽信大順"
    },
    {
        "parentCode": "108",
        "code": "1080768",
        "name": "海光分行",
        "shortcut": "陽信海光"
    },
    {
        "parentCode": "108",
        "code": "1080777",
        "name": "前鎮分行",
        "shortcut": "陽信前鎮"
    },
    {
        "parentCode": "108",
        "code": "1080786",
        "name": "平等分行",
        "shortcut": "陽信平等"
    },
    {
        "parentCode": "108",
        "code": "1080795",
        "name": "基隆分行",
        "shortcut": "陽信基隆"
    },
    {
        "parentCode": "108",
        "code": "1080801",
        "name": "小港分行",
        "shortcut": "陽信小港"
    },
    {
        "parentCode": "108",
        "code": "1080810",
        "name": "右昌分行",
        "shortcut": "陽信右昌"
    },
    {
        "parentCode": "108",
        "code": "1080829",
        "name": "建國分行",
        "shortcut": "陽信建國"
    },
    {
        "parentCode": "108",
        "code": "1080838",
        "name": "五甲分行",
        "shortcut": "陽信五甲"
    },
    {
        "parentCode": "108",
        "code": "1080847",
        "name": "鼎力分行",
        "shortcut": "陽信鼎力"
    },
    {
        "parentCode": "108",
        "code": "1080856",
        "name": "楠梓分行",
        "shortcut": "陽信楠梓"
    },
    {
        "parentCode": "108",
        "code": "1080865",
        "name": "旗山分行",
        "shortcut": "陽信旗山"
    },
    {
        "parentCode": "108",
        "code": "1080874",
        "name": "土城分行",
        "shortcut": "陽信土城"
    },
    {
        "parentCode": "108",
        "code": "1080883",
        "name": "美濃簡易型分行",
        "shortcut": "陽信美濃簡"
    },
    {
        "parentCode": "108",
        "code": "1080917",
        "name": "林園簡易型分行",
        "shortcut": "陽信林園簡"
    },
    {
        "parentCode": "108",
        "code": "1080926",
        "name": "岡山分行",
        "shortcut": "陽信岡山"
    },
    {
        "parentCode": "108",
        "code": "1080935",
        "name": "仁德分行",
        "shortcut": "陽信仁德"
    },
    {
        "parentCode": "108",
        "code": "1080944",
        "name": "東桃園分行",
        "shortcut": "陽信東桃園"
    },
    {
        "parentCode": "108",
        "code": "1080953",
        "name": "永康簡易型分行",
        "shortcut": "陽信永康簡"
    },
    {
        "parentCode": "108",
        "code": "1080971",
        "name": "南港分行",
        "shortcut": "陽信南港"
    },
    {
        "parentCode": "108",
        "code": "1080980",
        "name": "里港簡易型分行",
        "shortcut": "陽信里港簡"
    },
    {
        "parentCode": "108",
        "code": "1080999",
        "name": "大里分行",
        "shortcut": "陽信大里"
    },
    {
        "parentCode": "108",
        "code": "1081017",
        "name": "萬華分行",
        "shortcut": "陽信萬華"
    },
    {
        "parentCode": "108",
        "code": "1081026",
        "name": "臺北分行",
        "shortcut": "陽信臺北"
    },
    {
        "parentCode": "108",
        "code": "1081035",
        "name": "長安分行",
        "shortcut": "陽信長安"
    },
    {
        "parentCode": "108",
        "code": "1081053",
        "name": "羅東分行",
        "shortcut": "陽信羅東"
    },
    {
        "parentCode": "108",
        "code": "1081062",
        "name": "竹北分行",
        "shortcut": "陽信竹北"
    },
    {
        "parentCode": "108",
        "code": "1081071",
        "name": "重新分行",
        "shortcut": "陽信重新"
    },
    {
        "parentCode": "108",
        "code": "1081080",
        "name": "彰化分行",
        "shortcut": "陽信彰化"
    },
    {
        "parentCode": "108",
        "code": "1081336",
        "name": "苗栗分行",
        "shortcut": "陽信苗栗"
    },
    {
        "parentCode": "108",
        "code": "1081354",
        "name": "龍井分行",
        "shortcut": "陽信龍井"
    },
    {
        "parentCode": "108",
        "code": "1081363",
        "name": "雲林分行",
        "shortcut": "陽信雲林"
    },
    {
        "parentCode": "108",
        "code": "1081372",
        "name": "南投分行",
        "shortcut": "陽信南投"
    },
    {
        "parentCode": "108",
        "code": "1081503",
        "name": "臺東分行",
        "shortcut": "陽信臺東"
    },
    {
        "parentCode": "108",
        "code": "1081512",
        "name": "宜蘭分行",
        "shortcut": "陽信宜蘭"
    },
    {
        "parentCode": "114",
        "code": "1140015",
        "name": "營業部",
        "shortcut": "基一營"
    },
    {
        "parentCode": "114",
        "code": "1140024",
        "name": "仁一路分社",
        "shortcut": "基一仁一路"
    },
    {
        "parentCode": "114",
        "code": "1140033",
        "name": "愛三路分社",
        "shortcut": "基一愛三路"
    },
    {
        "parentCode": "114",
        "code": "1140042",
        "name": "忠二路分社",
        "shortcut": "基一忠二路"
    },
    {
        "parentCode": "114",
        "code": "1140051",
        "name": "安一路分社",
        "shortcut": "基一安一路"
    },
    {
        "parentCode": "114",
        "code": "1140060",
        "name": "信二路分社",
        "shortcut": "基一信二路"
    },
    {
        "parentCode": "114",
        "code": "1140079",
        "name": "中華路分社",
        "shortcut": "基一中華路"
    },
    {
        "parentCode": "114",
        "code": "1140088",
        "name": "八斗子分社",
        "shortcut": "基一八斗子"
    },
    {
        "parentCode": "114",
        "code": "1140097",
        "name": "南興路分社",
        "shortcut": "基一南興路"
    },
    {
        "parentCode": "114",
        "code": "1140103",
        "name": "大武崙分社",
        "shortcut": "基一大武崙"
    },
    {
        "parentCode": "114",
        "code": "1140112",
        "name": "碇內分社",
        "shortcut": "基一碇內"
    },
    {
        "parentCode": "114",
        "code": "1140121",
        "name": "仁二路分社",
        "shortcut": "基一仁二路"
    },
    {
        "parentCode": "114",
        "code": "1140130",
        "name": "百福分社",
        "shortcut": "基一百福"
    },
    {
        "parentCode": "114",
        "code": "1140149",
        "name": "松山分社",
        "shortcut": "基一松山"
    },
    {
        "parentCode": "114",
        "code": "1140158",
        "name": "永春分社",
        "shortcut": "基一永春"
    },
    {
        "parentCode": "115",
        "code": "1150014",
        "name": "營業部",
        "shortcut": "基二營"
    },
    {
        "parentCode": "115",
        "code": "1150023",
        "name": "廟口分社",
        "shortcut": "基二廟口"
    },
    {
        "parentCode": "115",
        "code": "1150032",
        "name": "中正區分社",
        "shortcut": "基二中正"
    },
    {
        "parentCode": "115",
        "code": "1150041",
        "name": "新店分社（元大證券收付處）",
        "shortcut": "基二新店"
    },
    {
        "parentCode": "115",
        "code": "1150050",
        "name": "七堵分社",
        "shortcut": "基二七堵"
    },
    {
        "parentCode": "115",
        "code": "1150069",
        "name": "安樂分社",
        "shortcut": "基二安樂"
    },
    {
        "parentCode": "115",
        "code": "1150078",
        "name": "信義分社",
        "shortcut": "基二信義"
    },
    {
        "parentCode": "115",
        "code": "1150087",
        "name": "港東簡易型分社",
        "shortcut": "基二港東簡"
    },
    {
        "parentCode": "115",
        "code": "1150096",
        "name": "暖暖分社",
        "shortcut": "基二暖暖"
    },
    {
        "parentCode": "115",
        "code": "1150102",
        "name": "安和分社",
        "shortcut": "基二安和"
    },
    {
        "parentCode": "115",
        "code": "1150111",
        "name": "臺北分社",
        "shortcut": "基二臺北"
    },
    {
        "parentCode": "115",
        "code": "1150120",
        "name": "百福分社",
        "shortcut": "基二百福"
    },
    {
        "parentCode": "115",
        "code": "1150139",
        "name": "基金簡易型分社",
        "shortcut": "基二基金簡"
    },
    {
        "parentCode": "115",
        "code": "1150148",
        "name": "新豐簡易型分社",
        "shortcut": "基二新豐簡"
    },
    {
        "parentCode": "115",
        "code": "1150157",
        "name": "復興簡易型分社",
        "shortcut": "基二復興簡"
    },
    {
        "parentCode": "118",
        "code": "1180011",
        "name": "板橋分行",
        "shortcut": "板信板橋"
    },
    {
        "parentCode": "118",
        "code": "1180020",
        "name": "後埔分行",
        "shortcut": "板信後埔"
    },
    {
        "parentCode": "118",
        "code": "1180039",
        "name": "永和分行",
        "shortcut": "板信永和"
    },
    {
        "parentCode": "118",
        "code": "1180048",
        "name": "埔墘分行",
        "shortcut": "板信埔墘"
    },
    {
        "parentCode": "118",
        "code": "1180057",
        "name": "華江分行",
        "shortcut": "板信華江"
    },
    {
        "parentCode": "118",
        "code": "1180066",
        "name": "民族分行",
        "shortcut": "板信民族"
    },
    {
        "parentCode": "118",
        "code": "1180075",
        "name": "中和分行",
        "shortcut": "板信中和"
    },
    {
        "parentCode": "118",
        "code": "1180084",
        "name": "土城分行",
        "shortcut": "板信土城"
    },
    {
        "parentCode": "118",
        "code": "1180093",
        "name": "文化分行",
        "shortcut": "板信文化"
    },
    {
        "parentCode": "118",
        "code": "1180109",
        "name": "大觀分行",
        "shortcut": "板信大觀"
    },
    {
        "parentCode": "118",
        "code": "1180118",
        "name": "興南分行",
        "shortcut": "板信興南"
    },
    {
        "parentCode": "118",
        "code": "1180145",
        "name": "小港分行",
        "shortcut": "板信小港"
    },
    {
        "parentCode": "118",
        "code": "1180163",
        "name": "新興分行",
        "shortcut": "板信新興"
    },
    {
        "parentCode": "118",
        "code": "1180181",
        "name": "前鎮分行",
        "shortcut": "板信前鎮"
    },
    {
        "parentCode": "118",
        "code": "1180206",
        "name": "陽明分行",
        "shortcut": "板信陽明"
    },
    {
        "parentCode": "118",
        "code": "1180215",
        "name": "高新莊分行",
        "shortcut": "板信高新莊"
    },
    {
        "parentCode": "118",
        "code": "1180260",
        "name": "松江分行",
        "shortcut": "板信松江"
    },
    {
        "parentCode": "118",
        "code": "1180279",
        "name": "信義分行",
        "shortcut": "板信信義"
    },
    {
        "parentCode": "118",
        "code": "1180288",
        "name": "新莊分行",
        "shortcut": "板信新莊"
    },
    {
        "parentCode": "118",
        "code": "1180297",
        "name": "秀朗分行",
        "shortcut": "板信秀朗"
    },
    {
        "parentCode": "118",
        "code": "1180303",
        "name": "桃園分行",
        "shortcut": "板信桃園"
    },
    {
        "parentCode": "118",
        "code": "1180312",
        "name": "三重分行",
        "shortcut": "板信三重"
    },
    {
        "parentCode": "118",
        "code": "1180330",
        "name": "員山分行",
        "shortcut": "板信員山"
    },
    {
        "parentCode": "118",
        "code": "1180349",
        "name": "桃鶯分行",
        "shortcut": "板信桃鶯"
    },
    {
        "parentCode": "118",
        "code": "1180358",
        "name": "民生分行",
        "shortcut": "板信民生"
    },
    {
        "parentCode": "118",
        "code": "1180367",
        "name": "龍岡分行",
        "shortcut": "板信龍岡"
    },
    {
        "parentCode": "118",
        "code": "1180385",
        "name": "樹林分行",
        "shortcut": "板信樹林"
    },
    {
        "parentCode": "118",
        "code": "1180394",
        "name": "金城分行",
        "shortcut": "板信金城"
    },
    {
        "parentCode": "118",
        "code": "1180400",
        "name": "新竹分行",
        "shortcut": "板信新竹"
    },
    {
        "parentCode": "118",
        "code": "1180419",
        "name": "八德分行",
        "shortcut": "板信八德"
    },
    {
        "parentCode": "118",
        "code": "1180428",
        "name": "台中分行",
        "shortcut": "板信台中"
    },
    {
        "parentCode": "118",
        "code": "1180437",
        "name": "台南分行",
        "shortcut": "板信台南"
    },
    {
        "parentCode": "118",
        "code": "1180446",
        "name": "新店分行",
        "shortcut": "板信新店"
    },
    {
        "parentCode": "118",
        "code": "1180455",
        "name": "內湖分行",
        "shortcut": "板信內湖"
    },
    {
        "parentCode": "118",
        "code": "1180482",
        "name": "中正分行",
        "shortcut": "板信中正"
    },
    {
        "parentCode": "118",
        "code": "1180507",
        "name": "嘉義分行",
        "shortcut": "板信嘉義"
    },
    {
        "parentCode": "118",
        "code": "1180561",
        "name": "軍輝分行",
        "shortcut": "板信軍輝"
    },
    {
        "parentCode": "118",
        "code": "1180598",
        "name": "蘆洲分行",
        "shortcut": "板信蘆洲"
    },
    {
        "parentCode": "118",
        "code": "1180604",
        "name": "羅東分行",
        "shortcut": "板信羅東"
    },
    {
        "parentCode": "118",
        "code": "1180613",
        "name": "北台中分行",
        "shortcut": "板信北台中"
    },
    {
        "parentCode": "118",
        "code": "1180622",
        "name": "成功分行",
        "shortcut": "板信成功"
    },
    {
        "parentCode": "118",
        "code": "1180631",
        "name": "福和分行",
        "shortcut": "板信福和"
    },
    {
        "parentCode": "118",
        "code": "1180659",
        "name": "營業部",
        "shortcut": "板信營"
    },
    {
        "parentCode": "118",
        "code": "1180668",
        "name": "瑞光分行",
        "shortcut": "板信瑞光"
    },
    {
        "parentCode": "118",
        "code": "1180677",
        "name": "丹鳳分行",
        "shortcut": "板信丹鳳"
    },
    {
        "parentCode": "118",
        "code": "1180686",
        "name": "北新分行",
        "shortcut": "板信北新"
    },
    {
        "parentCode": "118",
        "code": "1180695",
        "name": "艋舺分行",
        "shortcut": "板信艋舺"
    },
    {
        "parentCode": "118",
        "code": "1180701",
        "name": "興隆分行",
        "shortcut": "板信興隆"
    },
    {
        "parentCode": "118",
        "code": "1180710",
        "name": "雙園分行",
        "shortcut": "板信雙園"
    },
    {
        "parentCode": "118",
        "code": "1180729",
        "name": "西門分行",
        "shortcut": "板信西門"
    },
    {
        "parentCode": "118",
        "code": "1180738",
        "name": "古亭分行",
        "shortcut": "板信古亭"
    },
    {
        "parentCode": "118",
        "code": "1180747",
        "name": "安東分行",
        "shortcut": "板信安東"
    },
    {
        "parentCode": "118",
        "code": "1180756",
        "name": "松山分行",
        "shortcut": "板信松山"
    },
    {
        "parentCode": "118",
        "code": "1180765",
        "name": "士林分行",
        "shortcut": "板信士林"
    },
    {
        "parentCode": "118",
        "code": "1180774",
        "name": "東湖分行",
        "shortcut": "板信東湖"
    },
    {
        "parentCode": "118",
        "code": "1180783",
        "name": "安和分行",
        "shortcut": "板信安和"
    },
    {
        "parentCode": "118",
        "code": "1180792",
        "name": "光復分行",
        "shortcut": "板信光復"
    },
    {
        "parentCode": "118",
        "code": "1180808",
        "name": "民權分行",
        "shortcut": "板信民權"
    },
    {
        "parentCode": "118",
        "code": "1180817",
        "name": "重慶分行",
        "shortcut": "板信重慶"
    },
    {
        "parentCode": "118",
        "code": "1180826",
        "name": "木柵分行",
        "shortcut": "板信木柵"
    },
    {
        "parentCode": "118",
        "code": "1180835",
        "name": "環南分行",
        "shortcut": "板信環南"
    },
    {
        "parentCode": "118",
        "code": "1180844",
        "name": "大直分行",
        "shortcut": "板信大直"
    },
    {
        "parentCode": "118",
        "code": "1180853",
        "name": "萬大分行",
        "shortcut": "板信萬大"
    },
    {
        "parentCode": "118",
        "code": "1180862",
        "name": "永春分行",
        "shortcut": "板信永春"
    },
    {
        "parentCode": "119",
        "code": "1190010",
        "name": "營業部",
        "shortcut": "淡一營"
    },
    {
        "parentCode": "119",
        "code": "1190029",
        "name": "三重分社",
        "shortcut": "淡一三重"
    },
    {
        "parentCode": "119",
        "code": "1190038",
        "name": "三芝分社",
        "shortcut": "淡一三芝"
    },
    {
        "parentCode": "119",
        "code": "1190047",
        "name": "八里分社",
        "shortcut": "淡一八里"
    },
    {
        "parentCode": "119",
        "code": "1190056",
        "name": "石門分社",
        "shortcut": "淡一石門"
    },
    {
        "parentCode": "119",
        "code": "1190065",
        "name": "竹圍分社",
        "shortcut": "淡一竹圍"
    },
    {
        "parentCode": "119",
        "code": "1190074",
        "name": "水碓分社",
        "shortcut": "淡一水碓"
    },
    {
        "parentCode": "119",
        "code": "1190083",
        "name": "英專分社",
        "shortcut": "淡一英專"
    },
    {
        "parentCode": "119",
        "code": "1190092",
        "name": "新市分社",
        "shortcut": "淡一新市"
    },
    {
        "parentCode": "119",
        "code": "1190108",
        "name": "龍形分社",
        "shortcut": "淡一龍形"
    },
    {
        "parentCode": "119",
        "code": "1190117",
        "name": "金山分社",
        "shortcut": "淡一金山"
    },
    {
        "parentCode": "119",
        "code": "1190126",
        "name": "萬里分社",
        "shortcut": "淡一萬里"
    },
    {
        "parentCode": "119",
        "code": "1190135",
        "name": "板橋分社",
        "shortcut": "淡一板橋"
    },
    {
        "parentCode": "119",
        "code": "1190153",
        "name": "林口分社",
        "shortcut": "淡一林口"
    },
    {
        "parentCode": "119",
        "code": "1190162",
        "name": "中和分社",
        "shortcut": "淡一中和"
    },
    {
        "parentCode": "120",
        "code": "1200016",
        "name": "營業部",
        "shortcut": "淡信營"
    },
    {
        "parentCode": "120",
        "code": "1200025",
        "name": "水碓分社",
        "shortcut": "淡信水碓"
    },
    {
        "parentCode": "120",
        "code": "1200034",
        "name": "竹圍分社",
        "shortcut": "淡信竹圍"
    },
    {
        "parentCode": "120",
        "code": "1200043",
        "name": "英專分社",
        "shortcut": "淡信英專"
    },
    {
        "parentCode": "120",
        "code": "1200052",
        "name": "義山分社",
        "shortcut": "淡信義山"
    },
    {
        "parentCode": "120",
        "code": "1200061",
        "name": "三芝分社",
        "shortcut": "淡信三芝"
    },
    {
        "parentCode": "120",
        "code": "1200070",
        "name": "八里分社",
        "shortcut": "淡信八里"
    },
    {
        "parentCode": "120",
        "code": "1200089",
        "name": "成州分社",
        "shortcut": "淡信成州"
    },
    {
        "parentCode": "120",
        "code": "1200098",
        "name": "五股分社",
        "shortcut": "淡信五股"
    },
    {
        "parentCode": "120",
        "code": "1200104",
        "name": "萬金分社",
        "shortcut": "淡信萬金"
    },
    {
        "parentCode": "120",
        "code": "1200113",
        "name": "新莊分社",
        "shortcut": "淡信新莊"
    },
    {
        "parentCode": "124",
        "code": "1240012",
        "name": "營業部",
        "shortcut": "宜信營"
    },
    {
        "parentCode": "124",
        "code": "1240021",
        "name": "復興分社",
        "shortcut": "宜信復興"
    },
    {
        "parentCode": "124",
        "code": "1240030",
        "name": "東門分社",
        "shortcut": "宜信東門"
    },
    {
        "parentCode": "124",
        "code": "1240058",
        "name": "頭城分社",
        "shortcut": "宜信頭城"
    },
    {
        "parentCode": "124",
        "code": "1240067",
        "name": "礁溪分社",
        "shortcut": "宜信礁溪"
    },
    {
        "parentCode": "124",
        "code": "1240076",
        "name": "冬山分社",
        "shortcut": "宜信冬山"
    },
    {
        "parentCode": "124",
        "code": "1240085",
        "name": "三星分社",
        "shortcut": "宜信三星"
    },
    {
        "parentCode": "127",
        "code": "1270019",
        "name": "營業部",
        "shortcut": "桃信營業"
    },
    {
        "parentCode": "127",
        "code": "1270028",
        "name": "大林分社",
        "shortcut": "桃信大林"
    },
    {
        "parentCode": "127",
        "code": "1270037",
        "name": "永興分社",
        "shortcut": "桃信永興"
    },
    {
        "parentCode": "127",
        "code": "1270046",
        "name": "南華分社",
        "shortcut": "桃信南華"
    },
    {
        "parentCode": "127",
        "code": "1270055",
        "name": "介壽分社",
        "shortcut": "桃信介壽"
    },
    {
        "parentCode": "127",
        "code": "1270064",
        "name": "中山分社",
        "shortcut": "桃信中山"
    },
    {
        "parentCode": "127",
        "code": "1270073",
        "name": "永安分社",
        "shortcut": "桃信永安"
    },
    {
        "parentCode": "127",
        "code": "1270082",
        "name": "會稽分社",
        "shortcut": "桃信會稽"
    },
    {
        "parentCode": "127",
        "code": "1270091",
        "name": "莊敬分社",
        "shortcut": "桃信莊敬"
    },
    {
        "parentCode": "127",
        "code": "1270107",
        "name": "大湳分社",
        "shortcut": "桃信大湳"
    },
    {
        "parentCode": "127",
        "code": "1270116",
        "name": "南崁分社",
        "shortcut": "桃信南崁"
    },
    {
        "parentCode": "130",
        "code": "1300013",
        "name": "營業部",
        "shortcut": "竹一營"
    },
    {
        "parentCode": "130",
        "code": "1300022",
        "name": "城中分社",
        "shortcut": "竹一城中"
    },
    {
        "parentCode": "130",
        "code": "1300031",
        "name": "武昌分社",
        "shortcut": "竹一武昌"
    },
    {
        "parentCode": "130",
        "code": "1300040",
        "name": "新社分社",
        "shortcut": "竹一新社"
    },
    {
        "parentCode": "130",
        "code": "1300059",
        "name": "竹北分社",
        "shortcut": "竹一竹北"
    },
    {
        "parentCode": "130",
        "code": "1300068",
        "name": "三民分社",
        "shortcut": "竹一三民"
    },
    {
        "parentCode": "130",
        "code": "1300077",
        "name": "香山分社",
        "shortcut": "竹一香山"
    },
    {
        "parentCode": "130",
        "code": "1300086",
        "name": "東南分社",
        "shortcut": "竹一東南"
    },
    {
        "parentCode": "130",
        "code": "1300095",
        "name": "光復分社",
        "shortcut": "竹一光復"
    },
    {
        "parentCode": "130",
        "code": "1300101",
        "name": "南寮分社",
        "shortcut": "竹一南寮"
    },
    {
        "parentCode": "130",
        "code": "1300110",
        "name": "南香山分社",
        "shortcut": "竹一南香山"
    },
    {
        "parentCode": "130",
        "code": "1300129",
        "name": "武陵分社",
        "shortcut": "竹一武陵"
    },
    {
        "parentCode": "130",
        "code": "1300138",
        "name": "延平分社",
        "shortcut": "竹一延平"
    },
    {
        "parentCode": "130",
        "code": "1300147",
        "name": "建功分社",
        "shortcut": "竹一建功"
    },
    {
        "parentCode": "130",
        "code": "1300156",
        "name": "芎林分社",
        "shortcut": "竹一芎林"
    },
    {
        "parentCode": "130",
        "code": "1300165",
        "name": "寶山分社",
        "shortcut": "竹一寶山"
    },
    {
        "parentCode": "132",
        "code": "1320011",
        "name": "市中分社",
        "shortcut": "竹三市中"
    },
    {
        "parentCode": "132",
        "code": "1320020",
        "name": "民生分社",
        "shortcut": "竹三民生"
    },
    {
        "parentCode": "132",
        "code": "1320039",
        "name": "營業部",
        "shortcut": "竹三營"
    },
    {
        "parentCode": "132",
        "code": "1320048",
        "name": "中正分社",
        "shortcut": "竹三中正"
    },
    {
        "parentCode": "132",
        "code": "1320057",
        "name": "東南分社",
        "shortcut": "竹三東南"
    },
    {
        "parentCode": "132",
        "code": "1320066",
        "name": "西門分社",
        "shortcut": "竹三西門"
    },
    {
        "parentCode": "132",
        "code": "1320075",
        "name": "東光分社",
        "shortcut": "竹三東光"
    },
    {
        "parentCode": "132",
        "code": "1320084",
        "name": "香山分社",
        "shortcut": "竹三香山"
    },
    {
        "parentCode": "132",
        "code": "1320093",
        "name": "竹南分社",
        "shortcut": "竹三竹南"
    },
    {
        "parentCode": "132",
        "code": "1320109",
        "name": "延平分社",
        "shortcut": "竹三延平"
    },
    {
        "parentCode": "132",
        "code": "1320118",
        "name": "光華分社",
        "shortcut": "竹三光華"
    },
    {
        "parentCode": "132",
        "code": "1320127",
        "name": "新豐分社",
        "shortcut": "竹三新豐"
    },
    {
        "parentCode": "132",
        "code": "1320136",
        "name": "竹北分社",
        "shortcut": "竹三竹北"
    },
    {
        "parentCode": "132",
        "code": "1320145",
        "name": "六家簡易型分社",
        "shortcut": "竹三六家簡"
    },
    {
        "parentCode": "146",
        "code": "1460014",
        "name": "營業部",
        "shortcut": "中二營"
    },
    {
        "parentCode": "146",
        "code": "1460023",
        "name": "文昌分社",
        "shortcut": "中二文昌"
    },
    {
        "parentCode": "146",
        "code": "1460032",
        "name": "南屯分社",
        "shortcut": "中二南屯"
    },
    {
        "parentCode": "146",
        "code": "1460041",
        "name": "五權分社",
        "shortcut": "中二五權"
    },
    {
        "parentCode": "146",
        "code": "1460050",
        "name": "東南分社",
        "shortcut": "中二東南"
    },
    {
        "parentCode": "146",
        "code": "1460069",
        "name": "大智分社",
        "shortcut": "中二大智"
    },
    {
        "parentCode": "146",
        "code": "1460078",
        "name": "港路分社",
        "shortcut": "中二港路"
    },
    {
        "parentCode": "146",
        "code": "1460087",
        "name": "精進分社",
        "shortcut": "中二精進"
    },
    {
        "parentCode": "146",
        "code": "1460096",
        "name": "中興分社",
        "shortcut": "中二中興"
    },
    {
        "parentCode": "146",
        "code": "1460102",
        "name": "水湳分社",
        "shortcut": "中二水湳"
    },
    {
        "parentCode": "146",
        "code": "1460111",
        "name": "中和分社",
        "shortcut": "中二中和"
    },
    {
        "parentCode": "146",
        "code": "1460120",
        "name": "松竹分社",
        "shortcut": "中二松竹"
    },
    {
        "parentCode": "146",
        "code": "1460139",
        "name": "向上分社",
        "shortcut": "中二向上"
    },
    {
        "parentCode": "146",
        "code": "1460148",
        "name": "大雅分社",
        "shortcut": "中二大雅"
    },
    {
        "parentCode": "146",
        "code": "1460157",
        "name": "西屯分社",
        "shortcut": "中二西屯"
    },
    {
        "parentCode": "146",
        "code": "1460166",
        "name": "太平分社",
        "shortcut": "中二太平"
    },
    {
        "parentCode": "146",
        "code": "1460175",
        "name": "大里分社",
        "shortcut": "中二大里"
    },
    {
        "parentCode": "146",
        "code": "1460184",
        "name": "潭子分社",
        "shortcut": "中二潭子"
    },
    {
        "parentCode": "146",
        "code": "1460193",
        "name": "文山分社",
        "shortcut": "中二文山"
    },
    {
        "parentCode": "147",
        "code": "1470013",
        "name": "台中分行",
        "shortcut": "三信銀台中"
    },
    {
        "parentCode": "147",
        "code": "1470022",
        "name": "營業部",
        "shortcut": "三信銀營"
    },
    {
        "parentCode": "147",
        "code": "1470040",
        "name": "成功分行",
        "shortcut": "三信銀成功"
    },
    {
        "parentCode": "147",
        "code": "1470059",
        "name": "西屯分行",
        "shortcut": "三信銀西屯"
    },
    {
        "parentCode": "147",
        "code": "1470068",
        "name": "國光分行",
        "shortcut": "三信銀國光"
    },
    {
        "parentCode": "147",
        "code": "1470077",
        "name": "大智分行",
        "shortcut": "三信銀大智"
    },
    {
        "parentCode": "147",
        "code": "1470086",
        "name": "林森分行",
        "shortcut": "三信銀林森"
    },
    {
        "parentCode": "147",
        "code": "1470095",
        "name": "南門分行",
        "shortcut": "三信銀南門"
    },
    {
        "parentCode": "147",
        "code": "1470101",
        "name": "進化分行",
        "shortcut": "三信銀進化"
    },
    {
        "parentCode": "147",
        "code": "1470110",
        "name": "南屯分行",
        "shortcut": "三信銀南屯"
    },
    {
        "parentCode": "147",
        "code": "1470138",
        "name": "北屯分行",
        "shortcut": "三信銀北屯"
    },
    {
        "parentCode": "147",
        "code": "1470147",
        "name": "豐原分行",
        "shortcut": "三信銀豐原"
    },
    {
        "parentCode": "147",
        "code": "1470156",
        "name": "彰化分行",
        "shortcut": "三信銀彰化"
    },
    {
        "parentCode": "147",
        "code": "1470165",
        "name": "員林分行",
        "shortcut": "三信銀員林"
    },
    {
        "parentCode": "147",
        "code": "1470183",
        "name": "台北分行",
        "shortcut": "三信銀台北"
    },
    {
        "parentCode": "147",
        "code": "1470192",
        "name": "桃園分行",
        "shortcut": "三信銀桃園"
    },
    {
        "parentCode": "147",
        "code": "1470208",
        "name": "板橋分行",
        "shortcut": "三信銀板橋"
    },
    {
        "parentCode": "147",
        "code": "1470253",
        "name": "豐信分行",
        "shortcut": "三信銀豐信"
    },
    {
        "parentCode": "147",
        "code": "1470262",
        "name": "中山分行",
        "shortcut": "三信銀中山"
    },
    {
        "parentCode": "147",
        "code": "1470271",
        "name": "豐東分行（客戶移撥豐原分行服務）",
        "shortcut": "三信銀豐東"
    },
    {
        "parentCode": "147",
        "code": "1470299",
        "name": "台南分行",
        "shortcut": "三信銀台南"
    },
    {
        "parentCode": "147",
        "code": "1470305",
        "name": "高雄分行",
        "shortcut": "三信銀高雄"
    },
    {
        "parentCode": "147",
        "code": "1470314",
        "name": "新莊分行",
        "shortcut": "三信銀新莊"
    },
    {
        "parentCode": "147",
        "code": "1470323",
        "name": "新竹分行",
        "shortcut": "三信銀新竹"
    },
    {
        "parentCode": "147",
        "code": "1470332",
        "name": "鳳山分行",
        "shortcut": "三信銀鳳山"
    },
    {
        "parentCode": "147",
        "code": "1470341",
        "name": "大雅分行",
        "shortcut": "三信銀大雅"
    },
    {
        "parentCode": "147",
        "code": "1470350",
        "name": "橋頭分行",
        "shortcut": "三信銀橋頭"
    },
    {
        "parentCode": "147",
        "code": "1470369",
        "name": "大肚分行",
        "shortcut": "三信銀大肚"
    },
    {
        "parentCode": "158",
        "code": "1580019",
        "name": "營業部",
        "shortcut": "彰一營"
    },
    {
        "parentCode": "158",
        "code": "1580028",
        "name": "中華分社",
        "shortcut": "彰一中華"
    },
    {
        "parentCode": "158",
        "code": "1580037",
        "name": "曉陽分社",
        "shortcut": "彰一曉陽"
    },
    {
        "parentCode": "158",
        "code": "1580046",
        "name": "彰美分社",
        "shortcut": "彰一彰美"
    },
    {
        "parentCode": "158",
        "code": "1580055",
        "name": "花壇分社",
        "shortcut": "彰一花壇"
    },
    {
        "parentCode": "158",
        "code": "1580064",
        "name": "大竹分社",
        "shortcut": "彰一大竹"
    },
    {
        "parentCode": "158",
        "code": "1580073",
        "name": "和美分社",
        "shortcut": "彰一和美"
    },
    {
        "parentCode": "158",
        "code": "1580082",
        "name": "中正分社",
        "shortcut": "彰一中正"
    },
    {
        "parentCode": "158",
        "code": "1580091",
        "name": "員林分社",
        "shortcut": "彰一員林"
    },
    {
        "parentCode": "161",
        "code": "1610013",
        "name": "營業部",
        "shortcut": "彰五營"
    },
    {
        "parentCode": "161",
        "code": "1610022",
        "name": "華山分社",
        "shortcut": "彰五華山"
    },
    {
        "parentCode": "161",
        "code": "1610031",
        "name": "彰南路分社",
        "shortcut": "彰五彰南路"
    },
    {
        "parentCode": "161",
        "code": "1610040",
        "name": "東芳分社",
        "shortcut": "彰五東芳"
    },
    {
        "parentCode": "161",
        "code": "1610059",
        "name": "旭光分社",
        "shortcut": "彰五旭光"
    },
    {
        "parentCode": "161",
        "code": "1610068",
        "name": "大里分社",
        "shortcut": "彰五大里"
    },
    {
        "parentCode": "161",
        "code": "1610077",
        "name": "埔心分社",
        "shortcut": "彰五埔心"
    },
    {
        "parentCode": "161",
        "code": "1610086",
        "name": "田中分社",
        "shortcut": "彰五田中"
    },
    {
        "parentCode": "162",
        "code": "1620012",
        "name": "車路口分社",
        "shortcut": "彰六車路口"
    },
    {
        "parentCode": "162",
        "code": "1620021",
        "name": "營業部",
        "shortcut": "彰六營"
    },
    {
        "parentCode": "162",
        "code": "1620030",
        "name": "觀音亭口分社",
        "shortcut": "彰六觀音亭"
    },
    {
        "parentCode": "162",
        "code": "1620049",
        "name": "華陽分社",
        "shortcut": "彰六華陽"
    },
    {
        "parentCode": "162",
        "code": "1620058",
        "name": "和美分社",
        "shortcut": "彰六和美"
    },
    {
        "parentCode": "162",
        "code": "1620067",
        "name": "花壇分社",
        "shortcut": "彰六花壇"
    },
    {
        "parentCode": "162",
        "code": "1620076",
        "name": "曉陽分社",
        "shortcut": "彰六曉陽"
    },
    {
        "parentCode": "162",
        "code": "1620085",
        "name": "伸港分社",
        "shortcut": "彰六伸港"
    },
    {
        "parentCode": "162",
        "code": "1620094",
        "name": "秀水分社",
        "shortcut": "彰六秀水"
    },
    {
        "parentCode": "162",
        "code": "1620100",
        "name": "福興分社",
        "shortcut": "彰六福興"
    },
    {
        "parentCode": "162",
        "code": "1620119",
        "name": "永靖分社",
        "shortcut": "彰六永靖"
    },
    {
        "parentCode": "162",
        "code": "1620128",
        "name": "埔心分社",
        "shortcut": "彰六埔心"
    },
    {
        "parentCode": "163",
        "code": "1630011",
        "name": "營業部",
        "shortcut": "彰十營業"
    },
    {
        "parentCode": "163",
        "code": "1630020",
        "name": "華山分社",
        "shortcut": "彰十華山"
    },
    {
        "parentCode": "163",
        "code": "1630039",
        "name": "三民分社",
        "shortcut": "彰十三民"
    },
    {
        "parentCode": "163",
        "code": "1630048",
        "name": "中華分社",
        "shortcut": "彰十中華"
    },
    {
        "parentCode": "163",
        "code": "1630057",
        "name": "大竹分社",
        "shortcut": "彰十大竹"
    },
    {
        "parentCode": "163",
        "code": "1630066",
        "name": "和美分社",
        "shortcut": "彰十和美"
    },
    {
        "parentCode": "163",
        "code": "1630075",
        "name": "大埔分社",
        "shortcut": "彰十大埔"
    },
    {
        "parentCode": "163",
        "code": "1630084",
        "name": "埔鹽分社",
        "shortcut": "彰十埔鹽"
    },
    {
        "parentCode": "163",
        "code": "1630093",
        "name": "溪湖分社",
        "shortcut": "彰十溪湖"
    },
    {
        "parentCode": "163",
        "code": "1630109",
        "name": "伸港分社",
        "shortcut": "彰十伸港"
    },
    {
        "parentCode": "165",
        "code": "1650019",
        "name": "營業部",
        "shortcut": "鹿信營"
    },
    {
        "parentCode": "165",
        "code": "1650028",
        "name": "彰鹿分社",
        "shortcut": "鹿信彰鹿"
    },
    {
        "parentCode": "165",
        "code": "1650037",
        "name": "頂番分社",
        "shortcut": "鹿信頂番"
    },
    {
        "parentCode": "165",
        "code": "1650046",
        "name": "埔鹽分社",
        "shortcut": "鹿信埔鹽"
    },
    {
        "parentCode": "165",
        "code": "1650055",
        "name": "彰濱分社",
        "shortcut": "鹿信彰濱"
    },
    {
        "parentCode": "165",
        "code": "1650064",
        "name": "管嶼分社",
        "shortcut": "鹿信管嶼"
    },
    {
        "parentCode": "165",
        "code": "1650073",
        "name": "王功分社",
        "shortcut": "鹿信王功"
    },
    {
        "parentCode": "165",
        "code": "1650082",
        "name": "草港分社",
        "shortcut": "鹿信草港"
    },
    {
        "parentCode": "165",
        "code": "1650091",
        "name": "溪湖分社",
        "shortcut": "鹿信溪湖"
    },
    {
        "parentCode": "178",
        "code": "1780013",
        "name": "營業部",
        "shortcut": "嘉三營"
    },
    {
        "parentCode": "178",
        "code": "1780022",
        "name": "中埔分社",
        "shortcut": "嘉三中埔"
    },
    {
        "parentCode": "178",
        "code": "1780031",
        "name": "新榮分社",
        "shortcut": "嘉三新榮"
    },
    {
        "parentCode": "178",
        "code": "1780040",
        "name": "北興分社",
        "shortcut": "嘉三北興"
    },
    {
        "parentCode": "178",
        "code": "1780059",
        "name": "忠孝分社",
        "shortcut": "嘉三忠孝"
    },
    {
        "parentCode": "178",
        "code": "1780068",
        "name": "興嘉分社",
        "shortcut": "嘉三興嘉"
    },
    {
        "parentCode": "178",
        "code": "1780077",
        "name": "新南分社",
        "shortcut": "嘉三新南"
    },
    {
        "parentCode": "178",
        "code": "1780086",
        "name": "新興分社",
        "shortcut": "嘉三新興"
    },
    {
        "parentCode": "178",
        "code": "1780095",
        "name": "美源分社",
        "shortcut": "嘉三美源"
    },
    {
        "parentCode": "178",
        "code": "1780101",
        "name": "新生分社",
        "shortcut": "嘉三新生"
    },
    {
        "parentCode": "178",
        "code": "1780110",
        "name": "民生分社",
        "shortcut": "嘉三民生"
    },
    {
        "parentCode": "188",
        "code": "1880010",
        "name": "營業部",
        "shortcut": "南三營"
    },
    {
        "parentCode": "188",
        "code": "1880029",
        "name": "東門分社",
        "shortcut": "南三東門"
    },
    {
        "parentCode": "188",
        "code": "1880038",
        "name": "西門分社",
        "shortcut": "南三西門"
    },
    {
        "parentCode": "188",
        "code": "1880047",
        "name": "安平分社",
        "shortcut": "南三安平"
    },
    {
        "parentCode": "188",
        "code": "1880056",
        "name": "成功分社",
        "shortcut": "南三成功"
    },
    {
        "parentCode": "188",
        "code": "1880065",
        "name": "大同分社",
        "shortcut": "南三大同"
    },
    {
        "parentCode": "188",
        "code": "1880074",
        "name": "文賢分社",
        "shortcut": "南三文賢"
    },
    {
        "parentCode": "188",
        "code": "1880083",
        "name": "金華分社",
        "shortcut": "南三金華"
    },
    {
        "parentCode": "188",
        "code": "1880092",
        "name": "小東分社",
        "shortcut": "南三小東"
    },
    {
        "parentCode": "188",
        "code": "1880108",
        "name": "開元分社",
        "shortcut": "南三開元"
    },
    {
        "parentCode": "188",
        "code": "1880117",
        "name": "中華分社",
        "shortcut": "南三中華"
    },
    {
        "parentCode": "188",
        "code": "1880126",
        "name": "大灣分社",
        "shortcut": "南三大灣"
    },
    {
        "parentCode": "188",
        "code": "1880135",
        "name": "安中分社",
        "shortcut": "南三安中"
    },
    {
        "parentCode": "204",
        "code": "2040011",
        "name": "營業部",
        "shortcut": "高三營"
    },
    {
        "parentCode": "204",
        "code": "2040020",
        "name": "八德分社",
        "shortcut": "高三八德"
    },
    {
        "parentCode": "204",
        "code": "2040039",
        "name": "苓雅分社",
        "shortcut": "高三苓雅"
    },
    {
        "parentCode": "204",
        "code": "2040048",
        "name": "新興分社",
        "shortcut": "高三新興"
    },
    {
        "parentCode": "204",
        "code": "2040057",
        "name": "三民分社",
        "shortcut": "高三三民"
    },
    {
        "parentCode": "204",
        "code": "2040066",
        "name": "鼓山分社",
        "shortcut": "高三鼓山"
    },
    {
        "parentCode": "204",
        "code": "2040075",
        "name": "臨海分社",
        "shortcut": "高三臨海"
    },
    {
        "parentCode": "204",
        "code": "2040084",
        "name": "前鎮分社",
        "shortcut": "高三前鎮"
    },
    {
        "parentCode": "204",
        "code": "2040093",
        "name": "前金分社",
        "shortcut": "高三前金"
    },
    {
        "parentCode": "204",
        "code": "2040109",
        "name": "左營分社",
        "shortcut": "高三左營"
    },
    {
        "parentCode": "204",
        "code": "2040118",
        "name": "青年分社",
        "shortcut": "高三青年"
    },
    {
        "parentCode": "204",
        "code": "2040127",
        "name": "楠梓分社",
        "shortcut": "高三楠梓"
    },
    {
        "parentCode": "204",
        "code": "2040136",
        "name": "三多分社",
        "shortcut": "高三三多"
    },
    {
        "parentCode": "204",
        "code": "2040145",
        "name": "灣子分社",
        "shortcut": "高三灣子"
    },
    {
        "parentCode": "204",
        "code": "2040154",
        "name": "天祥分社",
        "shortcut": "高三天祥"
    },
    {
        "parentCode": "204",
        "code": "2040163",
        "name": "陽明分社",
        "shortcut": "高三陽明"
    },
    {
        "parentCode": "204",
        "code": "2040172",
        "name": "武廟分社",
        "shortcut": "高三武廟"
    },
    {
        "parentCode": "204",
        "code": "2040181",
        "name": "瑞祥分社",
        "shortcut": "高三瑞祥"
    },
    {
        "parentCode": "204",
        "code": "2040190",
        "name": "右昌分社",
        "shortcut": "高三右昌"
    },
    {
        "parentCode": "204",
        "code": "2040206",
        "name": "一心分社",
        "shortcut": "高三一心"
    },
    {
        "parentCode": "215",
        "code": "2150017",
        "name": "復興分社（光隆，日盛證券收付處）",
        "shortcut": "花一復興"
    },
    {
        "parentCode": "215",
        "code": "2150026",
        "name": "自由分社",
        "shortcut": "花一自由"
    },
    {
        "parentCode": "215",
        "code": "2150035",
        "name": "中華分社",
        "shortcut": "花一中華"
    },
    {
        "parentCode": "215",
        "code": "2150044",
        "name": "總社〈營業部〉",
        "shortcut": "花一總"
    },
    {
        "parentCode": "215",
        "code": "2150053",
        "name": "美崙分社",
        "shortcut": "花一美崙"
    },
    {
        "parentCode": "215",
        "code": "2150062",
        "name": "信義簡易型分社",
        "shortcut": "花一信義"
    },
    {
        "parentCode": "215",
        "code": "2150071",
        "name": "自強分社",
        "shortcut": "花一自強"
    },
    {
        "parentCode": "215",
        "code": "2150080",
        "name": "國光簡易型分社",
        "shortcut": "花一國光簡"
    },
    {
        "parentCode": "215",
        "code": "2150099",
        "name": "鳳山分社",
        "shortcut": "花一鳳山"
    },
    {
        "parentCode": "215",
        "code": "2150105",
        "name": "建國簡易型分社",
        "shortcut": "花一建國簡"
    },
    {
        "parentCode": "215",
        "code": "2150114",
        "name": "永康分社",
        "shortcut": "花一永康"
    },
    {
        "parentCode": "215",
        "code": "2150123",
        "name": "慶豐簡易型分社",
        "shortcut": "花一慶豐簡"
    },
    {
        "parentCode": "215",
        "code": "2150132",
        "name": "台東分社",
        "shortcut": "花一台東"
    },
    {
        "parentCode": "215",
        "code": "2150141",
        "name": "仁武分社",
        "shortcut": "花一仁武"
    },
    {
        "parentCode": "215",
        "code": "2150150",
        "name": "橋頭分社",
        "shortcut": "花一橋頭"
    },
    {
        "parentCode": "216",
        "code": "2160016",
        "name": "營業部",
        "shortcut": "花二營"
    },
    {
        "parentCode": "216",
        "code": "2160025",
        "name": "中山分社",
        "shortcut": "花二中山"
    },
    {
        "parentCode": "216",
        "code": "2160034",
        "name": "主里分社",
        "shortcut": "花二主里"
    },
    {
        "parentCode": "216",
        "code": "2160043",
        "name": "美崙分社",
        "shortcut": "花二美崙"
    },
    {
        "parentCode": "216",
        "code": "2160052",
        "name": "中正分社",
        "shortcut": "花二中正"
    },
    {
        "parentCode": "216",
        "code": "2160061",
        "name": "富國分社",
        "shortcut": "花二富國"
    },
    {
        "parentCode": "216",
        "code": "2160070",
        "name": "田蒲分社",
        "shortcut": "花二田蒲"
    },
    {
        "parentCode": "216",
        "code": "2160089",
        "name": "建國分社",
        "shortcut": "花二建國"
    },
    {
        "parentCode": "216",
        "code": "2160098",
        "name": "壽豐分社",
        "shortcut": "花二壽豐"
    },
    {
        "parentCode": "216",
        "code": "2160104",
        "name": "玉里分社",
        "shortcut": "花二玉里"
    },
    {
        "parentCode": "216",
        "code": "2160122",
        "name": "台東分社",
        "shortcut": "花二台東"
    },
    {
        "parentCode": "216",
        "code": "2160131",
        "name": "大里分社",
        "shortcut": "花二大里"
    },
    {
        "parentCode": "216",
        "code": "2160140",
        "name": "太平分社",
        "shortcut": "花二太平"
    },
    {
        "parentCode": "216",
        "code": "2160159",
        "name": "大雅分社",
        "shortcut": "花二大雅"
    },
    {
        "parentCode": "216",
        "code": "2160168",
        "name": "烏日分社",
        "shortcut": "花二烏日"
    },
    {
        "parentCode": "216",
        "code": "2160177",
        "name": "公益分社",
        "shortcut": "花二公益"
    },
    {
        "parentCode": "216",
        "code": "2160186",
        "name": "西屯分社",
        "shortcut": "花二西屯"
    },
    {
        "parentCode": "222",
        "code": "2220017",
        "name": "營業部",
        "shortcut": "澎一營"
    },
    {
        "parentCode": "222",
        "code": "2220026",
        "name": "民權分社",
        "shortcut": "澎一民權"
    },
    {
        "parentCode": "222",
        "code": "2220035",
        "name": "光復分社",
        "shortcut": "澎一光復"
    },
    {
        "parentCode": "222",
        "code": "2220044",
        "name": "澎南分社",
        "shortcut": "澎一澎南"
    },
    {
        "parentCode": "222",
        "code": "2220053",
        "name": "赤崁分社",
        "shortcut": "澎一赤崁"
    },
    {
        "parentCode": "222",
        "code": "2220062",
        "name": "西嶼分社",
        "shortcut": "澎一西嶼"
    },
    {
        "parentCode": "222",
        "code": "2220080",
        "name": "湖西分社",
        "shortcut": "澎一湖西"
    },
    {
        "parentCode": "222",
        "code": "2220099",
        "name": "港都分社",
        "shortcut": "澎一港都"
    },
    {
        "parentCode": "222",
        "code": "2220105",
        "name": "朝陽分社",
        "shortcut": "澎一朝陽"
    },
    {
        "parentCode": "223",
        "code": "2230016",
        "name": "營業部",
        "shortcut": "澎二營"
    },
    {
        "parentCode": "223",
        "code": "2230025",
        "name": "民生分社",
        "shortcut": "澎二民生"
    },
    {
        "parentCode": "223",
        "code": "2230034",
        "name": "陽明分社",
        "shortcut": "澎二陽明"
    },
    {
        "parentCode": "223",
        "code": "2230043",
        "name": "澎南分社",
        "shortcut": "澎二澎南"
    },
    {
        "parentCode": "223",
        "code": "2230052",
        "name": "白沙分社",
        "shortcut": "澎二白沙"
    },
    {
        "parentCode": "223",
        "code": "2230061",
        "name": "西嶼分社",
        "shortcut": "澎二西嶼"
    },
    {
        "parentCode": "223",
        "code": "2230070",
        "name": "湖西分社",
        "shortcut": "澎二湖西"
    },
    {
        "parentCode": "223",
        "code": "2230089",
        "name": "漁港分社",
        "shortcut": "澎二漁港"
    },
    {
        "parentCode": "223",
        "code": "2230098",
        "name": "中興分社",
        "shortcut": "澎二中興"
    },
    {
        "parentCode": "224",
        "code": "2240015",
        "name": "營業部",
        "shortcut": "金門營"
    },
    {
        "parentCode": "224",
        "code": "2240024",
        "name": "金沙分社",
        "shortcut": "金門金沙"
    },
    {
        "parentCode": "224",
        "code": "2240033",
        "name": "金湖分社",
        "shortcut": "金門金湖"
    },
    {
        "parentCode": "224",
        "code": "2240042",
        "name": "烈嶼分社",
        "shortcut": "金門烈嶼"
    },
    {
        "parentCode": "321",
        "code": "3210019",
        "name": "台北分行",
        "shortcut": "三井住友北"
    },
    {
        "parentCode": "372",
        "code": "3720015",
        "name": "總公司",
        "shortcut": "大慶票總"
    },
    {
        "parentCode": "380",
        "code": "3800016",
        "name": "臺北分行",
        "shortcut": "中銀臺北"
    },
    {
        "parentCode": "381",
        "code": "3810888",
        "name": "臺北分行",
        "shortcut": "交銀臺北"
    },
    {
        "parentCode": "382",
        "code": "3820018",
        "name": "臺北分行",
        "shortcut": "建行臺北"
    },
    {
        "parentCode": "503",
        "code": "5030019",
        "name": "基隆區漁會",
        "shortcut": "基漁"
    },
    {
        "parentCode": "504",
        "code": "5040010",
        "name": "新北市瑞芳區漁會",
        "shortcut": "瑞漁本會"
    },
    {
        "parentCode": "504",
        "code": "5040021",
        "name": "深澳分部",
        "shortcut": "瑞漁深澳"
    },
    {
        "parentCode": "504",
        "code": "5040032",
        "name": "新北市萬里區漁會",
        "shortcut": "萬里區漁會"
    },
    {
        "parentCode": "504",
        "code": "5040054",
        "name": "萬里分部",
        "shortcut": "萬里漁萬里"
    },
    {
        "parentCode": "505",
        "code": "5050011",
        "name": "宜蘭縣頭城區漁會",
        "shortcut": "頭漁本會"
    },
    {
        "parentCode": "505",
        "code": "5050022",
        "name": "宜蘭縣蘇澳區漁會",
        "shortcut": "蘇漁本會"
    },
    {
        "parentCode": "505",
        "code": "5050033",
        "name": "大溪分部",
        "shortcut": "頭漁大溪"
    },
    {
        "parentCode": "505",
        "code": "5050066",
        "name": "壯圍分部",
        "shortcut": "頭漁壯圍"
    },
    {
        "parentCode": "506",
        "code": "5060012",
        "name": "桃園區漁會",
        "shortcut": "桃漁本會"
    },
    {
        "parentCode": "507",
        "code": "5070013",
        "name": "新竹區漁會",
        "shortcut": "竹漁本會"
    },
    {
        "parentCode": "512",
        "code": "5120011",
        "name": "雲林區漁會",
        "shortcut": "雲林漁本會"
    },
    {
        "parentCode": "512",
        "code": "5120022",
        "name": "台西分部",
        "shortcut": "雲林漁台西"
    },
    {
        "parentCode": "512",
        "code": "5120033",
        "name": "口湖分部",
        "shortcut": "雲林漁口湖"
    },
    {
        "parentCode": "512",
        "code": "5120044",
        "name": "麥寮分部",
        "shortcut": "雲林漁麥寮"
    },
    {
        "parentCode": "512",
        "code": "5120055",
        "name": "三條崙分部",
        "shortcut": "雲林漁三條"
    },
    {
        "parentCode": "515",
        "code": "5150014",
        "name": "嘉義區漁會",
        "shortcut": "嘉義漁本會"
    },
    {
        "parentCode": "515",
        "code": "5150025",
        "name": "新塭分部",
        "shortcut": "嘉義漁新塭"
    },
    {
        "parentCode": "515",
        "code": "5150036",
        "name": "義竹分部",
        "shortcut": "嘉義漁義竹"
    },
    {
        "parentCode": "515",
        "code": "5150047",
        "name": "過溝分部",
        "shortcut": "嘉義漁過溝"
    },
    {
        "parentCode": "515",
        "code": "5150058",
        "name": "東石分部",
        "shortcut": "嘉義漁東石"
    },
    {
        "parentCode": "515",
        "code": "5150069",
        "name": "過路分部",
        "shortcut": "嘉義漁過路"
    },
    {
        "parentCode": "515",
        "code": "5150070",
        "name": "副瀨分部",
        "shortcut": "嘉義漁副瀨"
    },
    {
        "parentCode": "515",
        "code": "5150081",
        "name": "景山分部",
        "shortcut": "嘉義漁景山"
    },
    {
        "parentCode": "517",
        "code": "5170016",
        "name": "南市區漁會",
        "shortcut": "南市漁本會"
    },
    {
        "parentCode": "517",
        "code": "5170027",
        "name": "四草分部",
        "shortcut": "南市漁四草"
    },
    {
        "parentCode": "517",
        "code": "5170038",
        "name": "安南分部",
        "shortcut": "南市漁安南"
    },
    {
        "parentCode": "518",
        "code": "5180017",
        "name": "南縣區漁會",
        "shortcut": "南縣漁本會"
    },
    {
        "parentCode": "518",
        "code": "5180028",
        "name": "七股分部",
        "shortcut": "南縣漁七股"
    },
    {
        "parentCode": "518",
        "code": "5180039",
        "name": "北門分部",
        "shortcut": "南縣漁北門"
    },
    {
        "parentCode": "518",
        "code": "5180040",
        "name": "將軍港分部",
        "shortcut": "南縣漁將軍"
    },
    {
        "parentCode": "518",
        "code": "5180051",
        "name": "青山港分部",
        "shortcut": "南縣漁青山"
    },
    {
        "parentCode": "518",
        "code": "5180073",
        "name": "永吉分部",
        "shortcut": "南縣漁永吉"
    },
    {
        "parentCode": "520",
        "code": "5200012",
        "name": "高雄區漁會信用部",
        "shortcut": "高漁會信用"
    },
    {
        "parentCode": "520",
        "code": "5200023",
        "name": "小港區漁會信用部",
        "shortcut": "港漁信用"
    },
    {
        "parentCode": "520",
        "code": "5200034",
        "name": "旗津分部",
        "shortcut": "高漁旗津"
    },
    {
        "parentCode": "520",
        "code": "5200056",
        "name": "援中港分部",
        "shortcut": "高漁援中港"
    },
    {
        "parentCode": "521",
        "code": "5210024",
        "name": "興達港區漁會",
        "shortcut": "興達漁本會"
    },
    {
        "parentCode": "521",
        "code": "5210035",
        "name": "下茄萣分部",
        "shortcut": "興達漁下茄"
    },
    {
        "parentCode": "521",
        "code": "5210068",
        "name": "林園區漁會",
        "shortcut": "林漁本會"
    },
    {
        "parentCode": "521",
        "code": "5210079",
        "name": "彌陀區漁會",
        "shortcut": "彌漁本會"
    },
    {
        "parentCode": "521",
        "code": "5210091",
        "name": "永安區漁會",
        "shortcut": "永漁本會"
    },
    {
        "parentCode": "521",
        "code": "5210105",
        "name": "舊港分部",
        "shortcut": "彌漁舊港"
    },
    {
        "parentCode": "521",
        "code": "5210116",
        "name": "梓官區漁會",
        "shortcut": "梓官漁本會"
    },
    {
        "parentCode": "521",
        "code": "5210149",
        "name": "南寮分部",
        "shortcut": "彌漁南寮"
    },
    {
        "parentCode": "523",
        "code": "5230015",
        "name": "琉球區漁會",
        "shortcut": "琉漁本會"
    },
    {
        "parentCode": "523",
        "code": "5230037",
        "name": "東港區漁會",
        "shortcut": "東漁本會"
    },
    {
        "parentCode": "523",
        "code": "5230048",
        "name": "林邊區漁會",
        "shortcut": "林邊漁本會"
    },
    {
        "parentCode": "523",
        "code": "5230059",
        "name": "塭豐分部",
        "shortcut": "林邊漁塭豐"
    },
    {
        "parentCode": "523",
        "code": "5230082",
        "name": "崎峰分部",
        "shortcut": "林邊漁崎峰"
    },
    {
        "parentCode": "523",
        "code": "5230093",
        "name": "水利分部",
        "shortcut": "林邊漁水利"
    },
    {
        "parentCode": "523",
        "code": "5230118",
        "name": "光復分部",
        "shortcut": "東漁光復"
    },
    {
        "parentCode": "523",
        "code": "5230129",
        "name": "新園分部",
        "shortcut": "東漁新園"
    },
    {
        "parentCode": "523",
        "code": "5230130",
        "name": "枋寮區漁會",
        "shortcut": "枋寮漁本會"
    },
    {
        "parentCode": "524",
        "code": "5240016",
        "name": "新港區漁會",
        "shortcut": "新漁本會"
    },
    {
        "parentCode": "525",
        "code": "5250017",
        "name": "澎湖區漁會",
        "shortcut": "澎湖漁本會"
    },
    {
        "parentCode": "525",
        "code": "5250028",
        "name": "西嶼分部",
        "shortcut": "澎湖漁西嶼"
    },
    {
        "parentCode": "525",
        "code": "5250039",
        "name": "白沙分部",
        "shortcut": "澎湖漁白沙"
    },
    {
        "parentCode": "536",
        "code": "5260018",
        "name": "金門區漁會",
        "shortcut": "金門漁本會"
    },
    {
        "parentCode": "603",
        "code": "6030016",
        "name": "基隆市農會",
        "shortcut": "基隆農會"
    },
    {
        "parentCode": "603",
        "code": "6030027",
        "name": "安樂分會",
        "shortcut": "基隆安樂"
    },
    {
        "parentCode": "603",
        "code": "6030038",
        "name": "百福分會",
        "shortcut": "基隆百福"
    },
    {
        "parentCode": "603",
        "code": "6030049",
        "name": "愛六路分部",
        "shortcut": "基隆愛六路"
    },
    {
        "parentCode": "605",
        "code": "6050018",
        "name": "信用部",
        "shortcut": "高區農信"
    },
    {
        "parentCode": "605",
        "code": "6050029",
        "name": "三民分部",
        "shortcut": "高區農三民"
    },
    {
        "parentCode": "605",
        "code": "6050030",
        "name": "鼓山分部",
        "shortcut": "高區農鼓山"
    },
    {
        "parentCode": "605",
        "code": "6050041",
        "name": "新興分部",
        "shortcut": "高區農新興"
    },
    {
        "parentCode": "605",
        "code": "6050052",
        "name": "鼎力分部",
        "shortcut": "高區農鼎力"
    },
    {
        "parentCode": "605",
        "code": "6050063",
        "name": "苓雅分部",
        "shortcut": "高區農苓雅"
    },
    {
        "parentCode": "605",
        "code": "6050074",
        "name": "前鎮分部",
        "shortcut": "高區農前鎮"
    },
    {
        "parentCode": "605",
        "code": "6050085",
        "name": "楠梓分部",
        "shortcut": "高區農楠梓"
    },
    {
        "parentCode": "605",
        "code": "6050096",
        "name": "右昌分部",
        "shortcut": "高區農右昌"
    },
    {
        "parentCode": "605",
        "code": "6050100",
        "name": "後勁分部",
        "shortcut": "高區農後勁"
    },
    {
        "parentCode": "605",
        "code": "6050111",
        "name": "左營分部",
        "shortcut": "高區農左營"
    },
    {
        "parentCode": "605",
        "code": "6050122",
        "name": "營北分部",
        "shortcut": "高區農營北"
    },
    {
        "parentCode": "605",
        "code": "6050133",
        "name": "新庄分部",
        "shortcut": "高區農新庄"
    },
    {
        "parentCode": "606",
        "code": "6060019",
        "name": "三重區農會",
        "shortcut": "重農"
    },
    {
        "parentCode": "606",
        "code": "6060031",
        "name": "淡水區農會",
        "shortcut": "淡農"
    },
    {
        "parentCode": "606",
        "code": "6060042",
        "name": "樹林區農會",
        "shortcut": "樹農本會"
    },
    {
        "parentCode": "606",
        "code": "6060053",
        "name": "鶯歌區農會",
        "shortcut": "鶯農"
    },
    {
        "parentCode": "606",
        "code": "6060064",
        "name": "三峽區農會",
        "shortcut": "三峽本會"
    },
    {
        "parentCode": "606",
        "code": "6060097",
        "name": "土城區農會",
        "shortcut": "土城農會"
    },
    {
        "parentCode": "606",
        "code": "6060101",
        "name": "蘆洲區農會",
        "shortcut": "蘆農"
    },
    {
        "parentCode": "606",
        "code": "6060112",
        "name": "五股區農會",
        "shortcut": "五股區農會"
    },
    {
        "parentCode": "606",
        "code": "6060123",
        "name": "林口區農會",
        "shortcut": "林口區農會"
    },
    {
        "parentCode": "606",
        "code": "6060134",
        "name": "泰山區農會",
        "shortcut": "泰農"
    },
    {
        "parentCode": "606",
        "code": "6060145",
        "name": "坪林區農會",
        "shortcut": "坪林本會"
    },
    {
        "parentCode": "606",
        "code": "6060156",
        "name": "八里區農會",
        "shortcut": "八里農會"
    },
    {
        "parentCode": "606",
        "code": "6060167",
        "name": "金山地區農會",
        "shortcut": "金農"
    },
    {
        "parentCode": "606",
        "code": "6060178",
        "name": "瑞芳地區農會",
        "shortcut": "瑞芳本會"
    },
    {
        "parentCode": "606",
        "code": "6060189",
        "name": "新店地區農會",
        "shortcut": "店農本會"
    },
    {
        "parentCode": "606",
        "code": "6060190",
        "name": "中和地區農會",
        "shortcut": "和農本會"
    },
    {
        "parentCode": "606",
        "code": "6060204",
        "name": "深坑區農會",
        "shortcut": "深坑本會"
    },
    {
        "parentCode": "606",
        "code": "6060237",
        "name": "三多分部",
        "shortcut": "樹農三多"
    },
    {
        "parentCode": "606",
        "code": "6060248",
        "name": "山佳分部",
        "shortcut": "樹農山佳"
    },
    {
        "parentCode": "606",
        "code": "6060259",
        "name": "柑園分部",
        "shortcut": "樹農柑園"
    },
    {
        "parentCode": "606",
        "code": "6060260",
        "name": "成福分部",
        "shortcut": "三峽農成福"
    },
    {
        "parentCode": "606",
        "code": "6060318",
        "name": "清水分部",
        "shortcut": "土城農清水"
    },
    {
        "parentCode": "606",
        "code": "6060329",
        "name": "興珍分部",
        "shortcut": "五股興珍"
    },
    {
        "parentCode": "606",
        "code": "6060330",
        "name": "成州分部",
        "shortcut": "五股成州"
    },
    {
        "parentCode": "606",
        "code": "6060341",
        "name": "南勢分部",
        "shortcut": "林口南勢"
    },
    {
        "parentCode": "606",
        "code": "6060352",
        "name": "萬里分部",
        "shortcut": "金農萬里"
    },
    {
        "parentCode": "606",
        "code": "6060363",
        "name": "德安分部",
        "shortcut": "店農德安"
    },
    {
        "parentCode": "606",
        "code": "6060374",
        "name": "九份分部",
        "shortcut": "瑞芳九份"
    },
    {
        "parentCode": "606",
        "code": "6060385",
        "name": "四腳亭分部",
        "shortcut": "瑞芳四腳亭"
    },
    {
        "parentCode": "606",
        "code": "6060396",
        "name": "雙溪辦事處",
        "shortcut": "瑞芳雙溪"
    },
    {
        "parentCode": "606",
        "code": "6060400",
        "name": "貢寮辦事處",
        "shortcut": "瑞芳貢寮"
    },
    {
        "parentCode": "606",
        "code": "6060411",
        "name": "澳底分部",
        "shortcut": "瑞芳澳底"
    },
    {
        "parentCode": "606",
        "code": "6060422",
        "name": "中正分部",
        "shortcut": "店農中正"
    },
    {
        "parentCode": "606",
        "code": "6060433",
        "name": "烏來分部",
        "shortcut": "店農烏來"
    },
    {
        "parentCode": "606",
        "code": "6060444",
        "name": "中正分部",
        "shortcut": "和農中正"
    },
    {
        "parentCode": "606",
        "code": "6060455",
        "name": "員山分部",
        "shortcut": "和農員山"
    },
    {
        "parentCode": "606",
        "code": "6060466",
        "name": "石碇區農會",
        "shortcut": "石碇農"
    },
    {
        "parentCode": "606",
        "code": "6060477",
        "name": "平溪區農會",
        "shortcut": "平溪本會"
    },
    {
        "parentCode": "606",
        "code": "6060499",
        "name": "石門區農會",
        "shortcut": "石門本會"
    },
    {
        "parentCode": "606",
        "code": "6060503",
        "name": "二橋分部",
        "shortcut": "鶯農二橋"
    },
    {
        "parentCode": "606",
        "code": "6060514",
        "name": "鳳鳴分部",
        "shortcut": "鶯農鳳鳴"
    },
    {
        "parentCode": "606",
        "code": "6060547",
        "name": "老梅分部",
        "shortcut": "石門老梅"
    },
    {
        "parentCode": "606",
        "code": "6060558",
        "name": "橫溪分部",
        "shortcut": "三峽農橫溪"
    },
    {
        "parentCode": "606",
        "code": "6060569",
        "name": "長安分部",
        "shortcut": "蘆農長安"
    },
    {
        "parentCode": "606",
        "code": "6060570",
        "name": "智光分部",
        "shortcut": "和農智光"
    },
    {
        "parentCode": "606",
        "code": "6060581",
        "name": "連城分部",
        "shortcut": "和農連城"
    },
    {
        "parentCode": "606",
        "code": "6060592",
        "name": "民享分部",
        "shortcut": "和農民享"
    },
    {
        "parentCode": "606",
        "code": "6060606",
        "name": "溪美分部",
        "shortcut": "重農溪美"
    },
    {
        "parentCode": "606",
        "code": "6060651",
        "name": "貴子分部",
        "shortcut": "泰農貴子"
    },
    {
        "parentCode": "606",
        "code": "6060662",
        "name": "明志分部",
        "shortcut": "泰農明志"
    },
    {
        "parentCode": "606",
        "code": "6060673",
        "name": "大埔分部",
        "shortcut": "三峽農大埔"
    },
    {
        "parentCode": "606",
        "code": "6060684",
        "name": "頂埔分部",
        "shortcut": "土城農頂埔"
    },
    {
        "parentCode": "606",
        "code": "6060695",
        "name": "貨饒分部",
        "shortcut": "土城農貨饒"
    },
    {
        "parentCode": "606",
        "code": "6060709",
        "name": "德泰分部",
        "shortcut": "五股德泰"
    },
    {
        "parentCode": "606",
        "code": "6060710",
        "name": "力行分部",
        "shortcut": "重農力行"
    },
    {
        "parentCode": "606",
        "code": "6060721",
        "name": "三民分部",
        "shortcut": "重農三民"
    },
    {
        "parentCode": "606",
        "code": "6060732",
        "name": "錦和分部",
        "shortcut": "和農錦和"
    },
    {
        "parentCode": "606",
        "code": "6060743",
        "name": "仁愛分部",
        "shortcut": "和農仁愛"
    },
    {
        "parentCode": "606",
        "code": "6060754",
        "name": "保安分部",
        "shortcut": "樹農保安"
    },
    {
        "parentCode": "606",
        "code": "6060765",
        "name": "埤頭分部",
        "shortcut": "八里農埤頭"
    },
    {
        "parentCode": "606",
        "code": "6060798",
        "name": "中興分部",
        "shortcut": "淡農中興"
    },
    {
        "parentCode": "606",
        "code": "6060802",
        "name": "竹圍分部",
        "shortcut": "淡農竹圍"
    },
    {
        "parentCode": "606",
        "code": "6060813",
        "name": "水碓分部",
        "shortcut": "淡農水碓"
    },
    {
        "parentCode": "606",
        "code": "6060824",
        "name": "龍形分部",
        "shortcut": "八里農龍形"
    },
    {
        "parentCode": "606",
        "code": "6060835",
        "name": "成功分部",
        "shortcut": "蘆農成功"
    },
    {
        "parentCode": "606",
        "code": "6060846",
        "name": "北新庄分部",
        "shortcut": "芝農北新庄"
    },
    {
        "parentCode": "606",
        "code": "6060857",
        "name": "野柳分部",
        "shortcut": "金農野柳"
    },
    {
        "parentCode": "606",
        "code": "6060868",
        "name": "龍興分部",
        "shortcut": "瑞芳龍興"
    },
    {
        "parentCode": "606",
        "code": "6060891",
        "name": "峰廷分部",
        "shortcut": "土城農峰廷"
    },
    {
        "parentCode": "606",
        "code": "6060905",
        "name": "廣福分部",
        "shortcut": "土城農廣福"
    },
    {
        "parentCode": "606",
        "code": "6060916",
        "name": "光復分部",
        "shortcut": "重農光復"
    },
    {
        "parentCode": "606",
        "code": "6060927",
        "name": "貴和分部",
        "shortcut": "泰農貴和"
    },
    {
        "parentCode": "606",
        "code": "6060938",
        "name": "成功分部",
        "shortcut": "重農成功"
    },
    {
        "parentCode": "606",
        "code": "6060950",
        "name": "五福分部",
        "shortcut": "五股五福"
    },
    {
        "parentCode": "606",
        "code": "6060961",
        "name": "福隆辦事處",
        "shortcut": "瑞芳福隆"
    },
    {
        "parentCode": "606",
        "code": "6061005",
        "name": "泰林分部",
        "shortcut": "泰農泰林"
    },
    {
        "parentCode": "606",
        "code": "6061016",
        "name": "泰友分部",
        "shortcut": "泰農泰友"
    },
    {
        "parentCode": "606",
        "code": "6061027",
        "name": "東山分部",
        "shortcut": "樹農東山"
    },
    {
        "parentCode": "606",
        "code": "6061038",
        "name": "金寮分部",
        "shortcut": "樹農金寮"
    },
    {
        "parentCode": "606",
        "code": "6061061",
        "name": "福德分部",
        "shortcut": "淡農福德"
    },
    {
        "parentCode": "606",
        "code": "6061072",
        "name": "長榮分部",
        "shortcut": "蘆農長榮"
    },
    {
        "parentCode": "606",
        "code": "6061083",
        "name": "德音分部",
        "shortcut": "五股德音"
    },
    {
        "parentCode": "606",
        "code": "6061094",
        "name": "成德分部",
        "shortcut": "五股成德"
    },
    {
        "parentCode": "606",
        "code": "6061108",
        "name": "新和分部",
        "shortcut": "店農新和"
    },
    {
        "parentCode": "606",
        "code": "6061119",
        "name": "慈福分部",
        "shortcut": "重農慈福"
    },
    {
        "parentCode": "606",
        "code": "6061120",
        "name": "圳安分部",
        "shortcut": "樹農圳安"
    },
    {
        "parentCode": "606",
        "code": "6061131",
        "name": "北大分部",
        "shortcut": "三峽農北大"
    },
    {
        "parentCode": "606",
        "code": "6061175",
        "name": "成功分部",
        "shortcut": "五股成功"
    },
    {
        "parentCode": "606",
        "code": "6061186",
        "name": "大學城分部",
        "shortcut": "樹農大學城"
    },
    {
        "parentCode": "606",
        "code": "6061197",
        "name": "永昌分部",
        "shortcut": "鶯農永昌"
    },
    {
        "parentCode": "606",
        "code": "6061201",
        "name": "萬順辦事處",
        "shortcut": "深坑萬順"
    },
    {
        "parentCode": "606",
        "code": "6061212",
        "name": "永豐分部",
        "shortcut": "土城農永豐"
    },
    {
        "parentCode": "606",
        "code": "6061223",
        "name": "竹林分部",
        "shortcut": "和農竹林"
    },
    {
        "parentCode": "606",
        "code": "6061245",
        "name": "青潭辦事處",
        "shortcut": "店農青潭"
    },
    {
        "parentCode": "606",
        "code": "6061289",
        "name": "興南分部",
        "shortcut": "和農興南"
    },
    {
        "parentCode": "606",
        "code": "6061290",
        "name": "三芝區農會",
        "shortcut": "芝農本會"
    },
    {
        "parentCode": "606",
        "code": "6061315",
        "name": "大同分部",
        "shortcut": "樹農大同"
    },
    {
        "parentCode": "606",
        "code": "6061326",
        "name": "安康分部",
        "shortcut": "店農安康"
    },
    {
        "parentCode": "606",
        "code": "6061337",
        "name": "復興分部",
        "shortcut": "樹農復興"
    },
    {
        "parentCode": "606",
        "code": "6061348",
        "name": "義山分部",
        "shortcut": "淡農義山"
    },
    {
        "parentCode": "606",
        "code": "6061359",
        "name": "民生分部",
        "shortcut": "三峽農民生"
    },
    {
        "parentCode": "606",
        "code": "6061360",
        "name": "新興分部",
        "shortcut": "淡農新興"
    },
    {
        "parentCode": "607",
        "code": "6070010",
        "name": "宜蘭市農會",
        "shortcut": "宜農"
    },
    {
        "parentCode": "607",
        "code": "6070021",
        "name": "頭城鎮農會",
        "shortcut": "頭城本會"
    },
    {
        "parentCode": "607",
        "code": "6070032",
        "name": "羅東鎮農會",
        "shortcut": "羅農本會"
    },
    {
        "parentCode": "607",
        "code": "6070054",
        "name": "壯圍鄉農會",
        "shortcut": "壯圍本會"
    },
    {
        "parentCode": "607",
        "code": "6070065",
        "name": "員山鄉農會",
        "shortcut": "員山本會"
    },
    {
        "parentCode": "607",
        "code": "6070076",
        "name": "五結鄉農會",
        "shortcut": "五結本會"
    },
    {
        "parentCode": "607",
        "code": "6070098",
        "name": "蘇澳地區農會",
        "shortcut": "蘇澳本會"
    },
    {
        "parentCode": "607",
        "code": "6070102",
        "name": "三星地區農會",
        "shortcut": "三星本會"
    },
    {
        "parentCode": "607",
        "code": "6070113",
        "name": "大溪分部",
        "shortcut": "頭城大溪"
    },
    {
        "parentCode": "607",
        "code": "6070135",
        "name": "古亭辦事處",
        "shortcut": "壯圍古亭辦"
    },
    {
        "parentCode": "607",
        "code": "6070146",
        "name": "同樂分部",
        "shortcut": "員山同樂"
    },
    {
        "parentCode": "607",
        "code": "6070157",
        "name": "利澤分部",
        "shortcut": "五結利澤"
    },
    {
        "parentCode": "607",
        "code": "6070168",
        "name": "二結分部",
        "shortcut": "五結二結"
    },
    {
        "parentCode": "607",
        "code": "6070179",
        "name": "四結分部",
        "shortcut": "五結四結"
    },
    {
        "parentCode": "607",
        "code": "6070205",
        "name": "南方澳分部",
        "shortcut": "蘇澳南方澳"
    },
    {
        "parentCode": "607",
        "code": "6070216",
        "name": "南澳分部",
        "shortcut": "蘇澳南澳"
    },
    {
        "parentCode": "607",
        "code": "6070227",
        "name": "新馬分部",
        "shortcut": "蘇澳新馬"
    },
    {
        "parentCode": "607",
        "code": "6070238",
        "name": "新城分部",
        "shortcut": "蘇澳新城"
    },
    {
        "parentCode": "607",
        "code": "6070249",
        "name": "大洲辦事處",
        "shortcut": "三星大洲辦"
    },
    {
        "parentCode": "607",
        "code": "6070250",
        "name": "福山辦事處",
        "shortcut": "三星福山辦"
    },
    {
        "parentCode": "607",
        "code": "6070283",
        "name": "民權辦事處",
        "shortcut": "羅農民權"
    },
    {
        "parentCode": "607",
        "code": "6070308",
        "name": "神農分部",
        "shortcut": "宜農神農"
    },
    {
        "parentCode": "607",
        "code": "6070319",
        "name": "南門辦事處",
        "shortcut": "羅農南門"
    },
    {
        "parentCode": "607",
        "code": "6070331",
        "name": "深溝分部",
        "shortcut": "員山深溝"
    },
    {
        "parentCode": "607",
        "code": "6070342",
        "name": "金面分部",
        "shortcut": "頭城金面"
    },
    {
        "parentCode": "607",
        "code": "6070353",
        "name": "大隱辦事處",
        "shortcut": "三星大隱辦"
    },
    {
        "parentCode": "607",
        "code": "6070364",
        "name": "北門辦事處",
        "shortcut": "羅農北門"
    },
    {
        "parentCode": "607",
        "code": "6070375",
        "name": "中正路辦事處",
        "shortcut": "羅農中正路"
    },
    {
        "parentCode": "607",
        "code": "6070386",
        "name": "南門分部",
        "shortcut": "宜農南門"
    },
    {
        "parentCode": "607",
        "code": "6070412",
        "name": "新店分部",
        "shortcut": "宜農新店"
    },
    {
        "parentCode": "607",
        "code": "6070423",
        "name": "龍德分部",
        "shortcut": "蘇澳龍德"
    },
    {
        "parentCode": "607",
        "code": "6070434",
        "name": "大同辦事處",
        "shortcut": "三星大同辦"
    },
    {
        "parentCode": "607",
        "code": "6070445",
        "name": "大湖分部",
        "shortcut": "員山大湖"
    },
    {
        "parentCode": "607",
        "code": "6070467",
        "name": "東門辦事處",
        "shortcut": "羅農東門"
    },
    {
        "parentCode": "607",
        "code": "6070478",
        "name": "竹安分部",
        "shortcut": "頭城竹安"
    },
    {
        "parentCode": "607",
        "code": "6070515",
        "name": "延平分部",
        "shortcut": "宜農延平"
    },
    {
        "parentCode": "607",
        "code": "6070526",
        "name": "美城分部",
        "shortcut": "壯圍美城"
    },
    {
        "parentCode": "608",
        "code": "6080011",
        "name": "桃園區農會",
        "shortcut": "桃農本會"
    },
    {
        "parentCode": "608",
        "code": "6080033",
        "name": "大溪區農會",
        "shortcut": "大溪本會"
    },
    {
        "parentCode": "608",
        "code": "6080044",
        "name": "楊梅區農會",
        "shortcut": "楊梅本會"
    },
    {
        "parentCode": "608",
        "code": "6080055",
        "name": "大園區農會",
        "shortcut": "大園本會"
    },
    {
        "parentCode": "608",
        "code": "6080066",
        "name": "蘆竹區農會",
        "shortcut": "蘆竹本會"
    },
    {
        "parentCode": "608",
        "code": "6080077",
        "name": "龜山區農會",
        "shortcut": "龜山本會"
    },
    {
        "parentCode": "608",
        "code": "6080088",
        "name": "八德區農會",
        "shortcut": "八德本會"
    },
    {
        "parentCode": "608",
        "code": "6080099",
        "name": "北勢分部",
        "shortcut": "平鎮北勢"
    },
    {
        "parentCode": "608",
        "code": "6080103",
        "name": "新屋區農會",
        "shortcut": "新屋本會"
    },
    {
        "parentCode": "608",
        "code": "6080114",
        "name": "龍潭區農會",
        "shortcut": "龍潭本會"
    },
    {
        "parentCode": "608",
        "code": "6080136",
        "name": "復興區農會",
        "shortcut": "復興本會"
    },
    {
        "parentCode": "608",
        "code": "6080147",
        "name": "樂善分部",
        "shortcut": "龜山樂善"
    },
    {
        "parentCode": "608",
        "code": "6080158",
        "name": "一心分部",
        "shortcut": "大溪一心"
    },
    {
        "parentCode": "608",
        "code": "6080169",
        "name": "中新分部",
        "shortcut": "大溪中新"
    },
    {
        "parentCode": "608",
        "code": "6080170",
        "name": "新峰分部",
        "shortcut": "大溪新峰"
    },
    {
        "parentCode": "608",
        "code": "6080181",
        "name": "埔頂分部",
        "shortcut": "大溪埔頂"
    },
    {
        "parentCode": "608",
        "code": "6080192",
        "name": "老街分部",
        "shortcut": "楊梅老街"
    },
    {
        "parentCode": "608",
        "code": "6080206",
        "name": "埔心分部",
        "shortcut": "楊梅埔心"
    },
    {
        "parentCode": "608",
        "code": "6080217",
        "name": "富岡分部",
        "shortcut": "楊梅富岡"
    },
    {
        "parentCode": "608",
        "code": "6080228",
        "name": "埔心分部",
        "shortcut": "大園埔心"
    },
    {
        "parentCode": "608",
        "code": "6080239",
        "name": "竹圍分部",
        "shortcut": "大園竹圍"
    },
    {
        "parentCode": "608",
        "code": "6080240",
        "name": "三和分部",
        "shortcut": "大園三和"
    },
    {
        "parentCode": "608",
        "code": "6080251",
        "name": "新興分部",
        "shortcut": "蘆竹新興"
    },
    {
        "parentCode": "608",
        "code": "6080262",
        "name": "山腳分部",
        "shortcut": "蘆竹山腳"
    },
    {
        "parentCode": "608",
        "code": "6080273",
        "name": "宏竹分部",
        "shortcut": "蘆竹宏竹"
    },
    {
        "parentCode": "608",
        "code": "6080284",
        "name": "大竹分部",
        "shortcut": "蘆竹大竹"
    },
    {
        "parentCode": "608",
        "code": "6080295",
        "name": "大崗分部",
        "shortcut": "龜山大崗"
    },
    {
        "parentCode": "608",
        "code": "6080309",
        "name": "大湳分部",
        "shortcut": "八德大湳"
    },
    {
        "parentCode": "608",
        "code": "6080310",
        "name": "永安分部",
        "shortcut": "新屋永安"
    },
    {
        "parentCode": "608",
        "code": "6080321",
        "name": "大坡分部",
        "shortcut": "新屋大坡"
    },
    {
        "parentCode": "608",
        "code": "6080365",
        "name": "山峰分部",
        "shortcut": "平鎮山峰"
    },
    {
        "parentCode": "608",
        "code": "6080376",
        "name": "山頂分部",
        "shortcut": "龜山山頂"
    },
    {
        "parentCode": "608",
        "code": "6080387",
        "name": "員林分部",
        "shortcut": "大溪員林"
    },
    {
        "parentCode": "608",
        "code": "6080398",
        "name": "外社分部",
        "shortcut": "蘆竹外社"
    },
    {
        "parentCode": "608",
        "code": "6080402",
        "name": "蘆竹分部",
        "shortcut": "蘆竹蘆竹"
    },
    {
        "parentCode": "608",
        "code": "6080413",
        "name": "龍岡分部",
        "shortcut": "平鎮龍岡"
    },
    {
        "parentCode": "608",
        "code": "6080424",
        "name": "宋屋分部",
        "shortcut": "平鎮宋屋"
    },
    {
        "parentCode": "608",
        "code": "6080435",
        "name": "僑愛分部",
        "shortcut": "大溪僑愛"
    },
    {
        "parentCode": "608",
        "code": "6080446",
        "name": "瑞豐分部",
        "shortcut": "八德瑞豐"
    },
    {
        "parentCode": "608",
        "code": "6080457",
        "name": "大福分部",
        "shortcut": "八德大福"
    },
    {
        "parentCode": "608",
        "code": "6080468",
        "name": "大林分部",
        "shortcut": "桃農大林"
    },
    {
        "parentCode": "608",
        "code": "6080479",
        "name": "會稽分部",
        "shortcut": "桃農會稽"
    },
    {
        "parentCode": "608",
        "code": "6080480",
        "name": "中興分部",
        "shortcut": "龍潭中興"
    },
    {
        "parentCode": "608",
        "code": "6080491",
        "name": "烏林分部",
        "shortcut": "龍潭烏林"
    },
    {
        "parentCode": "608",
        "code": "6080505",
        "name": "中福分部",
        "shortcut": "蘆竹中福"
    },
    {
        "parentCode": "608",
        "code": "6080516",
        "name": "五福分部",
        "shortcut": "蘆竹五福"
    },
    {
        "parentCode": "608",
        "code": "6080527",
        "name": "中路分部",
        "shortcut": "桃農中路"
    },
    {
        "parentCode": "608",
        "code": "6080538",
        "name": "慈文分部",
        "shortcut": "桃農慈文"
    },
    {
        "parentCode": "608",
        "code": "6080549",
        "name": "茄苳分部",
        "shortcut": "八德茄苳"
    },
    {
        "parentCode": "608",
        "code": "6080550",
        "name": "桃鶯分部",
        "shortcut": "八德桃鶯"
    },
    {
        "parentCode": "608",
        "code": "6080561",
        "name": "和平分部",
        "shortcut": "大園和平"
    },
    {
        "parentCode": "608",
        "code": "6080572",
        "name": "龍壽分部",
        "shortcut": "龜山龍壽"
    },
    {
        "parentCode": "608",
        "code": "6080583",
        "name": "南上分部",
        "shortcut": "龜山南上"
    },
    {
        "parentCode": "608",
        "code": "6080594",
        "name": "高榮分部",
        "shortcut": "新屋高榮"
    },
    {
        "parentCode": "608",
        "code": "6080619",
        "name": "三民分部",
        "shortcut": "復興三民"
    },
    {
        "parentCode": "608",
        "code": "6080620",
        "name": "公西分部",
        "shortcut": "龜山公西"
    },
    {
        "parentCode": "608",
        "code": "6080631",
        "name": "埔子分部",
        "shortcut": "桃農埔子"
    },
    {
        "parentCode": "608",
        "code": "6080642",
        "name": "龍山分部",
        "shortcut": "桃農龍山"
    },
    {
        "parentCode": "608",
        "code": "6080653",
        "name": "觀音區農會",
        "shortcut": "觀音本會"
    },
    {
        "parentCode": "608",
        "code": "6080664",
        "name": "大有分部",
        "shortcut": "桃農大有"
    },
    {
        "parentCode": "608",
        "code": "6080686",
        "name": "平鎮區農會",
        "shortcut": "平鎮本會"
    },
    {
        "parentCode": "608",
        "code": "6080697",
        "name": "大平分部",
        "shortcut": "楊梅大平"
    },
    {
        "parentCode": "608",
        "code": "6080712",
        "name": "崎頂分部",
        "shortcut": "大溪崎頂"
    },
    {
        "parentCode": "608",
        "code": "6080723",
        "name": "嶺頂分部",
        "shortcut": "龜山嶺頂"
    },
    {
        "parentCode": "608",
        "code": "6080745",
        "name": "五權分部",
        "shortcut": "大園五權"
    },
    {
        "parentCode": "608",
        "code": "6080756",
        "name": "潮音分部",
        "shortcut": "大園潮音"
    },
    {
        "parentCode": "608",
        "code": "6080767",
        "name": "金陵分部",
        "shortcut": "平鎮金陵"
    },
    {
        "parentCode": "608",
        "code": "6080778",
        "name": "高平分部",
        "shortcut": "龍潭高平"
    },
    {
        "parentCode": "608",
        "code": "6080790",
        "name": "八德分部",
        "shortcut": "龍潭八德"
    },
    {
        "parentCode": "608",
        "code": "6080804",
        "name": "山福分部",
        "shortcut": "龜山山福"
    },
    {
        "parentCode": "608",
        "code": "6080826",
        "name": "南興分部",
        "shortcut": "大溪南興"
    },
    {
        "parentCode": "608",
        "code": "6080837",
        "name": "霄裡分部",
        "shortcut": "八德霄裡"
    },
    {
        "parentCode": "608",
        "code": "6080848",
        "name": "舊路分部",
        "shortcut": "龜山舊路"
    },
    {
        "parentCode": "608",
        "code": "6080859",
        "name": "海湖分部",
        "shortcut": "蘆竹海湖"
    },
    {
        "parentCode": "609",
        "code": "6090089",
        "name": "中華民國農會中壢辦事處信用部",
        "shortcut": "中華民國信"
    },
    {
        "parentCode": "609",
        "code": "6090090",
        "name": "大崙分部",
        "shortcut": "中華民國崙"
    },
    {
        "parentCode": "610",
        "code": "6100016",
        "name": "關西鎮農會",
        "shortcut": "關西本會"
    },
    {
        "parentCode": "610",
        "code": "6100027",
        "name": "新埔鎮農會",
        "shortcut": "新埔本會"
    },
    {
        "parentCode": "610",
        "code": "6100038",
        "name": "竹北市農會",
        "shortcut": "竹北本會"
    },
    {
        "parentCode": "610",
        "code": "6100050",
        "name": "湖口鄉農會",
        "shortcut": "湖口農會"
    },
    {
        "parentCode": "610",
        "code": "6100061",
        "name": "新竹市農會信用部",
        "shortcut": "竹市農"
    },
    {
        "parentCode": "610",
        "code": "6100072",
        "name": "芎林鄉農會",
        "shortcut": "芎林農"
    },
    {
        "parentCode": "610",
        "code": "6100083",
        "name": "寶山鄉農會",
        "shortcut": "寶山農"
    },
    {
        "parentCode": "610",
        "code": "6100094",
        "name": "峨眉鄉農會",
        "shortcut": "峨眉農"
    },
    {
        "parentCode": "610",
        "code": "6100108",
        "name": "北埔鄉農會",
        "shortcut": "北埔農"
    },
    {
        "parentCode": "610",
        "code": "6100119",
        "name": "竹東地區農會",
        "shortcut": "竹東本會"
    },
    {
        "parentCode": "610",
        "code": "6100120",
        "name": "橫山地區農會",
        "shortcut": "橫山本會"
    },
    {
        "parentCode": "610",
        "code": "6100131",
        "name": "石光分部",
        "shortcut": "關西石光"
    },
    {
        "parentCode": "610",
        "code": "6100142",
        "name": "錦山分部",
        "shortcut": "關西錦山"
    },
    {
        "parentCode": "610",
        "code": "6100153",
        "name": "六家分部",
        "shortcut": "竹北六家"
    },
    {
        "parentCode": "610",
        "code": "6100186",
        "name": "三姓橋分部",
        "shortcut": "竹市農三姓"
    },
    {
        "parentCode": "610",
        "code": "6100197",
        "name": "秀湖分部",
        "shortcut": "芎林秀湖"
    },
    {
        "parentCode": "610",
        "code": "6100201",
        "name": "新城分部",
        "shortcut": "寶山農新城"
    },
    {
        "parentCode": "610",
        "code": "6100212",
        "name": "寶山分部",
        "shortcut": "寶山農寶山"
    },
    {
        "parentCode": "610",
        "code": "6100223",
        "name": "竹中分部",
        "shortcut": "竹東竹中"
    },
    {
        "parentCode": "610",
        "code": "6100234",
        "name": "二重辦事處",
        "shortcut": "竹東二重"
    },
    {
        "parentCode": "610",
        "code": "6100256",
        "name": "五峰辦事處",
        "shortcut": "竹東五峰"
    },
    {
        "parentCode": "610",
        "code": "6100267",
        "name": "橫山分部",
        "shortcut": "橫山橫山"
    },
    {
        "parentCode": "610",
        "code": "6100289",
        "name": "尖石分部",
        "shortcut": "橫山尖石"
    },
    {
        "parentCode": "610",
        "code": "6100290",
        "name": "豐田分部",
        "shortcut": "竹北豐田"
    },
    {
        "parentCode": "610",
        "code": "6100304",
        "name": "鳳岡分部",
        "shortcut": "竹北鳳岡"
    },
    {
        "parentCode": "610",
        "code": "6100315",
        "name": "樹林頭分部",
        "shortcut": "竹市農樹林"
    },
    {
        "parentCode": "610",
        "code": "6100326",
        "name": "石潭分部",
        "shortcut": "芎林石潭"
    },
    {
        "parentCode": "610",
        "code": "6100337",
        "name": "光復分部",
        "shortcut": "竹市農光復"
    },
    {
        "parentCode": "610",
        "code": "6100348",
        "name": "中興辦事處",
        "shortcut": "湖口中興"
    },
    {
        "parentCode": "610",
        "code": "6100359",
        "name": "內湖分部",
        "shortcut": "竹市農內湖"
    },
    {
        "parentCode": "610",
        "code": "6100360",
        "name": "民權分部",
        "shortcut": "竹市農民權"
    },
    {
        "parentCode": "610",
        "code": "6100371",
        "name": "富興分部",
        "shortcut": "峨眉農富興"
    },
    {
        "parentCode": "610",
        "code": "6100382",
        "name": "東寧辦事處",
        "shortcut": "竹東東寧"
    },
    {
        "parentCode": "610",
        "code": "6100393",
        "name": "照門分部",
        "shortcut": "新埔照門"
    },
    {
        "parentCode": "610",
        "code": "6100429",
        "name": "新興分部",
        "shortcut": "竹市農新興"
    },
    {
        "parentCode": "610",
        "code": "6100430",
        "name": "高鐵分部",
        "shortcut": "竹北高鐵"
    },
    {
        "parentCode": "610",
        "code": "6100441",
        "name": "虎林分部",
        "shortcut": "竹市農虎林"
    },
    {
        "parentCode": "610",
        "code": "6100452",
        "name": "光華分部",
        "shortcut": "竹市農光華"
    },
    {
        "parentCode": "610",
        "code": "6100463",
        "name": "新豐鄉農會",
        "shortcut": "新豐農會"
    },
    {
        "parentCode": "610",
        "code": "6100474",
        "name": "客家樓分部",
        "shortcut": "湖口客家樓"
    },
    {
        "parentCode": "610",
        "code": "6100485",
        "name": "上山分部",
        "shortcut": "芎林上山"
    },
    {
        "parentCode": "611",
        "code": "6110062",
        "name": "後龍鎮農會",
        "shortcut": "後龍本會"
    },
    {
        "parentCode": "611",
        "code": "6110501",
        "name": "新港分部",
        "shortcut": "後龍新港"
    },
    {
        "parentCode": "612",
        "code": "6121107",
        "name": "台中市豐原區農會",
        "shortcut": "豐原農會"
    },
    {
        "parentCode": "612",
        "code": "6121118",
        "name": "神岡區農會",
        "shortcut": "神岡農會"
    },
    {
        "parentCode": "613",
        "code": "6130075",
        "name": "名間鄉農會",
        "shortcut": "名間本會"
    },
    {
        "parentCode": "613",
        "code": "6130260",
        "name": "新街分部",
        "shortcut": "名間新街"
    },
    {
        "parentCode": "613",
        "code": "6130271",
        "name": "大庄分部",
        "shortcut": "名間大庄"
    },
    {
        "parentCode": "613",
        "code": "6130282",
        "name": "赤水分部",
        "shortcut": "名間赤水"
    },
    {
        "parentCode": "613",
        "code": "6130293",
        "name": "松柏嶺分部",
        "shortcut": "名間松柏嶺"
    },
    {
        "parentCode": "613",
        "code": "6130466",
        "name": "新光分部",
        "shortcut": "名間新光"
    },
    {
        "parentCode": "614",
        "code": "6140043",
        "name": "員林市農會",
        "shortcut": "員林本會"
    },
    {
        "parentCode": "614",
        "code": "6140087",
        "name": "二林鎮農會",
        "shortcut": "二林農會"
    },
    {
        "parentCode": "614",
        "code": "6140124",
        "name": "秀水鄉農會",
        "shortcut": "秀水本會"
    },
    {
        "parentCode": "614",
        "code": "6140179",
        "name": "埔心鄉農會",
        "shortcut": "埔心本會"
    },
    {
        "parentCode": "614",
        "code": "6140180",
        "name": "永靖鄉農會",
        "shortcut": "永靖本會"
    },
    {
        "parentCode": "614",
        "code": "6140227",
        "name": "埤頭鄉農會",
        "shortcut": "埤頭本會"
    },
    {
        "parentCode": "614",
        "code": "6140261",
        "name": "竹塘鄉農會",
        "shortcut": "竹塘本會"
    },
    {
        "parentCode": "614",
        "code": "6140320",
        "name": "福安分部",
        "shortcut": "秀水福安"
    },
    {
        "parentCode": "614",
        "code": "6140401",
        "name": "路口厝分部",
        "shortcut": "埤頭路口厝"
    },
    {
        "parentCode": "614",
        "code": "6140412",
        "name": "埤頭分部",
        "shortcut": "埤頭埤頭"
    },
    {
        "parentCode": "614",
        "code": "6140423",
        "name": "芙朝分部",
        "shortcut": "埤頭芙朝"
    },
    {
        "parentCode": "614",
        "code": "6140478",
        "name": "馬鳴分部",
        "shortcut": "秀水馬鳴"
    },
    {
        "parentCode": "614",
        "code": "6140515",
        "name": "金興分部",
        "shortcut": "秀水金興"
    },
    {
        "parentCode": "614",
        "code": "6140526",
        "name": "東山分部",
        "shortcut": "員林東山"
    },
    {
        "parentCode": "614",
        "code": "6140559",
        "name": "舊館分部",
        "shortcut": "埔心舊館"
    },
    {
        "parentCode": "614",
        "code": "6140593",
        "name": "明聖分部",
        "shortcut": "埔心明聖"
    },
    {
        "parentCode": "614",
        "code": "6140696",
        "name": "永安分部",
        "shortcut": "永靖永安"
    },
    {
        "parentCode": "614",
        "code": "6140700",
        "name": "百果山分部",
        "shortcut": "員林百果山"
    },
    {
        "parentCode": "614",
        "code": "6140711",
        "name": "原斗分部",
        "shortcut": "二林原斗"
    },
    {
        "parentCode": "614",
        "code": "6140733",
        "name": "五福分部",
        "shortcut": "永靖五福"
    },
    {
        "parentCode": "614",
        "code": "6140799",
        "name": "挖仔分部",
        "shortcut": "二林挖仔"
    },
    {
        "parentCode": "614",
        "code": "6140858",
        "name": "中山分部",
        "shortcut": "二林中山"
    },
    {
        "parentCode": "614",
        "code": "6140870",
        "name": "同安分部",
        "shortcut": "永靖同安"
    },
    {
        "parentCode": "614",
        "code": "6141017",
        "name": "長安分部",
        "shortcut": "竹塘長安"
    },
    {
        "parentCode": "614",
        "code": "6141028",
        "name": "永興分部",
        "shortcut": "永靖永興"
    },
    {
        "parentCode": "614",
        "code": "6141291",
        "name": "萬興分部",
        "shortcut": "二林萬興"
    },
    {
        "parentCode": "614",
        "code": "6141327",
        "name": "芬園鄉農會",
        "shortcut": "芬園農會"
    },
    {
        "parentCode": "614",
        "code": "6141338",
        "name": "芳苑鄉農會",
        "shortcut": "芳苑農會"
    },
    {
        "parentCode": "616",
        "code": "6160012",
        "name": "斗六市農會",
        "shortcut": "斗六本會"
    },
    {
        "parentCode": "616",
        "code": "6160023",
        "name": "虎尾鎮農會",
        "shortcut": "虎尾農"
    },
    {
        "parentCode": "616",
        "code": "6160034",
        "name": "西螺鎮農會",
        "shortcut": "西螺本會"
    },
    {
        "parentCode": "616",
        "code": "6160056",
        "name": "斗南鎮農會",
        "shortcut": "斗南鎮農會"
    },
    {
        "parentCode": "616",
        "code": "6160078",
        "name": "古坑鄉農會",
        "shortcut": "古坑本會"
    },
    {
        "parentCode": "616",
        "code": "6160089",
        "name": "大埤鄉農會",
        "shortcut": "大埤本會"
    },
    {
        "parentCode": "616",
        "code": "6160090",
        "name": "莿桐鄉農會",
        "shortcut": "莿桐本會"
    },
    {
        "parentCode": "616",
        "code": "6160115",
        "name": "二崙鄉農會",
        "shortcut": "二崙本會"
    },
    {
        "parentCode": "616",
        "code": "6160126",
        "name": "崙背鄉農會",
        "shortcut": "崙背本會"
    },
    {
        "parentCode": "616",
        "code": "6160137",
        "name": "台西鄉農會",
        "shortcut": "台西本會"
    },
    {
        "parentCode": "616",
        "code": "6160148",
        "name": "褒忠鄉農會",
        "shortcut": "褒忠本會"
    },
    {
        "parentCode": "616",
        "code": "6160160",
        "name": "四湖鄉農會",
        "shortcut": "四湖本會"
    },
    {
        "parentCode": "616",
        "code": "6160171",
        "name": "口湖鄉農會",
        "shortcut": "口湖本會"
    },
    {
        "parentCode": "616",
        "code": "6160218",
        "name": "石龜溪分部",
        "shortcut": "斗南石龜溪"
    },
    {
        "parentCode": "616",
        "code": "6160230",
        "name": "永光分部",
        "shortcut": "古坑永光"
    },
    {
        "parentCode": "616",
        "code": "6160241",
        "name": "東和分部",
        "shortcut": "古坑東和"
    },
    {
        "parentCode": "616",
        "code": "6160252",
        "name": "怡然分部",
        "shortcut": "大埤怡然"
    },
    {
        "parentCode": "616",
        "code": "6160263",
        "name": "饒平分部",
        "shortcut": "莿桐饒平"
    },
    {
        "parentCode": "616",
        "code": "6160274",
        "name": "油車分部",
        "shortcut": "二崙油車"
    },
    {
        "parentCode": "616",
        "code": "6160285",
        "name": "永定分部",
        "shortcut": "二崙永定"
    },
    {
        "parentCode": "616",
        "code": "6160296",
        "name": "崙豐分部",
        "shortcut": "台西崙豐"
    },
    {
        "parentCode": "616",
        "code": "6160300",
        "name": "飛沙分部",
        "shortcut": "四湖飛沙"
    },
    {
        "parentCode": "616",
        "code": "6160311",
        "name": "宜梧分部",
        "shortcut": "口湖宜梧"
    },
    {
        "parentCode": "616",
        "code": "6160322",
        "name": "金湖分部",
        "shortcut": "口湖金湖"
    },
    {
        "parentCode": "616",
        "code": "6160333",
        "name": "下崙分部",
        "shortcut": "口湖下崙"
    },
    {
        "parentCode": "616",
        "code": "6160403",
        "name": "埤源分部",
        "shortcut": "西螺埤源"
    },
    {
        "parentCode": "616",
        "code": "6160414",
        "name": "德興分部",
        "shortcut": "虎尾農德興"
    },
    {
        "parentCode": "616",
        "code": "6160436",
        "name": "六合分部",
        "shortcut": "莿桐六合"
    },
    {
        "parentCode": "616",
        "code": "6160447",
        "name": "溪底分部",
        "shortcut": "四湖溪底"
    },
    {
        "parentCode": "616",
        "code": "6160458",
        "name": "西埤分部",
        "shortcut": "斗南西埤"
    },
    {
        "parentCode": "616",
        "code": "6160469",
        "name": "新光分部",
        "shortcut": "斗南新光"
    },
    {
        "parentCode": "616",
        "code": "6160492",
        "name": "石榴分部",
        "shortcut": "斗六石榴"
    },
    {
        "parentCode": "616",
        "code": "6160506",
        "name": "大屯分部",
        "shortcut": "虎尾農大屯"
    },
    {
        "parentCode": "616",
        "code": "6160517",
        "name": "惠來分部",
        "shortcut": "虎尾農惠來"
    },
    {
        "parentCode": "616",
        "code": "6160528",
        "name": "九隆分部",
        "shortcut": "西螺九隆"
    },
    {
        "parentCode": "616",
        "code": "6160539",
        "name": "和心分部",
        "shortcut": "西螺和心"
    },
    {
        "parentCode": "616",
        "code": "6160540",
        "name": "建興分部",
        "shortcut": "西螺建興"
    },
    {
        "parentCode": "616",
        "code": "6160562",
        "name": "嘉興分部",
        "shortcut": "大埤嘉興"
    },
    {
        "parentCode": "616",
        "code": "6160573",
        "name": "林厝分部",
        "shortcut": "四湖林厝"
    },
    {
        "parentCode": "616",
        "code": "6160595",
        "name": "鎮南分部",
        "shortcut": "斗六鎮南"
    },
    {
        "parentCode": "616",
        "code": "6160609",
        "name": "光興分部",
        "shortcut": "斗六光興"
    },
    {
        "parentCode": "616",
        "code": "6160610",
        "name": "頂溪分部",
        "shortcut": "虎尾農頂溪"
    },
    {
        "parentCode": "616",
        "code": "6160621",
        "name": "中山分部",
        "shortcut": "西螺中山"
    },
    {
        "parentCode": "616",
        "code": "6160643",
        "name": "牛厝分部",
        "shortcut": "台西牛厝"
    },
    {
        "parentCode": "616",
        "code": "6160665",
        "name": "蚊港分部",
        "shortcut": "台西蚊港"
    },
    {
        "parentCode": "616",
        "code": "6160698",
        "name": "箔子分部",
        "shortcut": "四湖箔子"
    },
    {
        "parentCode": "616",
        "code": "6160702",
        "name": "明昌分部",
        "shortcut": "斗南明昌"
    },
    {
        "parentCode": "616",
        "code": "6160713",
        "name": "豐榮分部",
        "shortcut": "崙背豐榮"
    },
    {
        "parentCode": "616",
        "code": "6160735",
        "name": "西岐分部",
        "shortcut": "斗南西岐"
    },
    {
        "parentCode": "616",
        "code": "6160746",
        "name": "成功分部",
        "shortcut": "斗六成功"
    },
    {
        "parentCode": "616",
        "code": "6160757",
        "name": "虎溪分部",
        "shortcut": "斗六虎溪"
    },
    {
        "parentCode": "616",
        "code": "6160779",
        "name": "立仁分部",
        "shortcut": "虎尾農立仁"
    },
    {
        "parentCode": "617",
        "code": "6170013",
        "name": "吳鳳分部",
        "shortcut": "嘉市吳鳳"
    },
    {
        "parentCode": "617",
        "code": "6170024",
        "name": "朴子市農會",
        "shortcut": "朴子本會"
    },
    {
        "parentCode": "617",
        "code": "6170035",
        "name": "布袋鎮農會",
        "shortcut": "布袋本會"
    },
    {
        "parentCode": "617",
        "code": "6170046",
        "name": "大林鎮農會",
        "shortcut": "大林本會"
    },
    {
        "parentCode": "617",
        "code": "6170057",
        "name": "民雄鄉農會",
        "shortcut": "民雄本會"
    },
    {
        "parentCode": "617",
        "code": "6170068",
        "name": "溪口鄉農會",
        "shortcut": "溪口本會"
    },
    {
        "parentCode": "617",
        "code": "6170079",
        "name": "東石鄉農會",
        "shortcut": "東石本會"
    },
    {
        "parentCode": "617",
        "code": "6170080",
        "name": "義竹鄉農會",
        "shortcut": "義竹本會"
    },
    {
        "parentCode": "617",
        "code": "6170091",
        "name": "鹿草鄉農會",
        "shortcut": "鹿草本會"
    },
    {
        "parentCode": "617",
        "code": "6170105",
        "name": "太保市農會",
        "shortcut": "太保本會"
    },
    {
        "parentCode": "617",
        "code": "6170116",
        "name": "水上鄉農會",
        "shortcut": "水上本會"
    },
    {
        "parentCode": "617",
        "code": "6170138",
        "name": "番路鄉農會",
        "shortcut": "番路本會"
    },
    {
        "parentCode": "617",
        "code": "6170149",
        "name": "竹崎地區農會",
        "shortcut": "竹崎本會"
    },
    {
        "parentCode": "617",
        "code": "6170150",
        "name": "梅山鄉農會",
        "shortcut": "梅山本會"
    },
    {
        "parentCode": "617",
        "code": "6170172",
        "name": "蒜頭分部",
        "shortcut": "六腳蒜頭"
    },
    {
        "parentCode": "617",
        "code": "6170194",
        "name": "福全分部",
        "shortcut": "嘉市福全"
    },
    {
        "parentCode": "617",
        "code": "6170208",
        "name": "北興分部",
        "shortcut": "嘉市北興"
    },
    {
        "parentCode": "617",
        "code": "6170219",
        "name": "開元分部",
        "shortcut": "朴子開元"
    },
    {
        "parentCode": "617",
        "code": "6170220",
        "name": "文化分部",
        "shortcut": "朴子文化"
    },
    {
        "parentCode": "617",
        "code": "6170231",
        "name": "松梅分部",
        "shortcut": "朴子松梅"
    },
    {
        "parentCode": "617",
        "code": "6170242",
        "name": "過溝分部",
        "shortcut": "布袋過溝"
    },
    {
        "parentCode": "617",
        "code": "6170253",
        "name": "永安分部",
        "shortcut": "布袋永安"
    },
    {
        "parentCode": "617",
        "code": "6170264",
        "name": "吉林分部",
        "shortcut": "大林吉林"
    },
    {
        "parentCode": "617",
        "code": "6170275",
        "name": "溝背分部",
        "shortcut": "大林溝背"
    },
    {
        "parentCode": "617",
        "code": "6170286",
        "name": "大美分部",
        "shortcut": "大林大美"
    },
    {
        "parentCode": "617",
        "code": "6170297",
        "name": "排路分部",
        "shortcut": "大林排路"
    },
    {
        "parentCode": "617",
        "code": "6170301",
        "name": "北斗分部",
        "shortcut": "民雄北斗"
    },
    {
        "parentCode": "617",
        "code": "6170312",
        "name": "山中分部",
        "shortcut": "民雄山中"
    },
    {
        "parentCode": "617",
        "code": "6170323",
        "name": "菁埔分部",
        "shortcut": "民雄菁埔"
    },
    {
        "parentCode": "617",
        "code": "6170334",
        "name": "柴林分部",
        "shortcut": "溪口柴林"
    },
    {
        "parentCode": "617",
        "code": "6170345",
        "name": "崙尾分部",
        "shortcut": "溪口崙尾"
    },
    {
        "parentCode": "617",
        "code": "6170356",
        "name": "東石分部",
        "shortcut": "東石東石"
    },
    {
        "parentCode": "617",
        "code": "6170367",
        "name": "副瀨分部",
        "shortcut": "東石農副瀨"
    },
    {
        "parentCode": "617",
        "code": "6170378",
        "name": "下楫分部",
        "shortcut": "東石農下楫"
    },
    {
        "parentCode": "617",
        "code": "6170389",
        "name": "西崙分部",
        "shortcut": "東石農西崙"
    },
    {
        "parentCode": "617",
        "code": "6170390",
        "name": "過路分部",
        "shortcut": "義竹過路"
    },
    {
        "parentCode": "617",
        "code": "6170404",
        "name": "光榮分部",
        "shortcut": "義竹光榮"
    },
    {
        "parentCode": "617",
        "code": "6170415",
        "name": "下潭分部",
        "shortcut": "鹿草下潭"
    },
    {
        "parentCode": "617",
        "code": "6170426",
        "name": "後堀分部",
        "shortcut": "鹿草後堀"
    },
    {
        "parentCode": "617",
        "code": "6170437",
        "name": "後潭分部",
        "shortcut": "太保後潭"
    },
    {
        "parentCode": "617",
        "code": "6170448",
        "name": "新埤分部",
        "shortcut": "太保新埤"
    },
    {
        "parentCode": "617",
        "code": "6170459",
        "name": "南新分部",
        "shortcut": "太保南新"
    },
    {
        "parentCode": "617",
        "code": "6170460",
        "name": "成功分部",
        "shortcut": "水上成功"
    },
    {
        "parentCode": "617",
        "code": "6170471",
        "name": "南靖分部",
        "shortcut": "水上南靖"
    },
    {
        "parentCode": "617",
        "code": "6170482",
        "name": "大崙分部",
        "shortcut": "水上大崙"
    },
    {
        "parentCode": "617",
        "code": "6170493",
        "name": "忠和分部",
        "shortcut": "水上忠和"
    },
    {
        "parentCode": "617",
        "code": "6170507",
        "name": "北回分部",
        "shortcut": "水上北回"
    },
    {
        "parentCode": "617",
        "code": "6170518",
        "name": "柳林分部",
        "shortcut": "水上柳林"
    },
    {
        "parentCode": "617",
        "code": "6170563",
        "name": "觸口分部",
        "shortcut": "番路觸口"
    },
    {
        "parentCode": "617",
        "code": "6170574",
        "name": "內埔分部",
        "shortcut": "竹崎內埔"
    },
    {
        "parentCode": "617",
        "code": "6170585",
        "name": "灣橋分部",
        "shortcut": "竹崎灣橋"
    },
    {
        "parentCode": "617",
        "code": "6170596",
        "name": "東義分部",
        "shortcut": "竹崎東義"
    },
    {
        "parentCode": "617",
        "code": "6170600",
        "name": "瑞里分部",
        "shortcut": "梅山瑞里"
    },
    {
        "parentCode": "617",
        "code": "6170611",
        "name": "太平分部",
        "shortcut": "梅山太平"
    },
    {
        "parentCode": "617",
        "code": "6170622",
        "name": "太和分部",
        "shortcut": "梅山太和"
    },
    {
        "parentCode": "617",
        "code": "6170633",
        "name": "月眉分部",
        "shortcut": "新港月眉"
    },
    {
        "parentCode": "617",
        "code": "6170644",
        "name": "大潭分部",
        "shortcut": "新港大潭"
    },
    {
        "parentCode": "617",
        "code": "6170655",
        "name": "崙子分部",
        "shortcut": "新港崙子"
    },
    {
        "parentCode": "617",
        "code": "6170666",
        "name": "港尾寮分部",
        "shortcut": "六腳港尾"
    },
    {
        "parentCode": "617",
        "code": "6170677",
        "name": "六腳分部",
        "shortcut": "六腳六腳"
    },
    {
        "parentCode": "617",
        "code": "6170688",
        "name": "灣內分部",
        "shortcut": "六腳灣內"
    },
    {
        "parentCode": "617",
        "code": "6170699",
        "name": "大埔分部",
        "shortcut": "竹崎大埔"
    },
    {
        "parentCode": "617",
        "code": "6170703",
        "name": "更寮分部",
        "shortcut": "六腳更寮"
    },
    {
        "parentCode": "617",
        "code": "6170714",
        "name": "秀林分部",
        "shortcut": "民雄秀林"
    },
    {
        "parentCode": "617",
        "code": "6170725",
        "name": "民生分部",
        "shortcut": "水上民生"
    },
    {
        "parentCode": "617",
        "code": "6170758",
        "name": "嘉太分部",
        "shortcut": "太保嘉太"
    },
    {
        "parentCode": "617",
        "code": "6170769",
        "name": "新厝分部",
        "shortcut": "布袋新厝"
    },
    {
        "parentCode": "617",
        "code": "6170781",
        "name": "中林分部",
        "shortcut": "大林中林"
    },
    {
        "parentCode": "617",
        "code": "6170806",
        "name": "六腳鄉農會",
        "shortcut": "六腳本會"
    },
    {
        "parentCode": "617",
        "code": "6170817",
        "name": "新港鄉農會",
        "shortcut": "新港本會"
    },
    {
        "parentCode": "617",
        "code": "6170862",
        "name": "嘉義市農會",
        "shortcut": "嘉市本會"
    },
    {
        "parentCode": "617",
        "code": "6171261",
        "name": "安和分部",
        "shortcut": "新港安和"
    },
    {
        "parentCode": "617",
        "code": "6171272",
        "name": "宣信分部",
        "shortcut": "嘉市宣信"
    },
    {
        "parentCode": "617",
        "code": "6171283",
        "name": "民生分部",
        "shortcut": "嘉市民生"
    },
    {
        "parentCode": "617",
        "code": "6171320",
        "name": "隙頂分部",
        "shortcut": "番路隙頂"
    },
    {
        "parentCode": "617",
        "code": "6171331",
        "name": "重寮分部",
        "shortcut": "鹿草重寮"
    },
    {
        "parentCode": "617",
        "code": "6171353",
        "name": "大慷榔分部",
        "shortcut": "朴子大慷榔"
    },
    {
        "parentCode": "617",
        "code": "6171364",
        "name": "雙溪口分部",
        "shortcut": "朴子雙溪口"
    },
    {
        "parentCode": "617",
        "code": "6171375",
        "name": "新塭分部",
        "shortcut": "布袋新塭"
    },
    {
        "parentCode": "617",
        "code": "6171386",
        "name": "柳溝分部",
        "shortcut": "溪口柳溝"
    },
    {
        "parentCode": "617",
        "code": "6171397",
        "name": "新店分部",
        "shortcut": "義竹新店"
    },
    {
        "parentCode": "617",
        "code": "6171401",
        "name": "松山分部",
        "shortcut": "鹿草松山"
    },
    {
        "parentCode": "617",
        "code": "6171412",
        "name": "安東分部",
        "shortcut": "太保安東"
    },
    {
        "parentCode": "617",
        "code": "6171423",
        "name": "石棹分部",
        "shortcut": "竹崎石棹"
    },
    {
        "parentCode": "617",
        "code": "6171434",
        "name": "蘇崙分部",
        "shortcut": "六腳蘇崙"
    },
    {
        "parentCode": "617",
        "code": "6171467",
        "name": "中坑分部",
        "shortcut": "大林中坑"
    },
    {
        "parentCode": "617",
        "code": "6171478",
        "name": "三興分部",
        "shortcut": "民雄三興"
    },
    {
        "parentCode": "617",
        "code": "6171489",
        "name": "興南分部",
        "shortcut": "民雄興南"
    },
    {
        "parentCode": "617",
        "code": "6171490",
        "name": "鹿滿分部",
        "shortcut": "竹崎鹿滿"
    },
    {
        "parentCode": "618",
        "code": "6180025",
        "name": "新營區農會",
        "shortcut": "新營本會"
    },
    {
        "parentCode": "618",
        "code": "6180036",
        "name": "鹽水區農會",
        "shortcut": "鹽水本會"
    },
    {
        "parentCode": "618",
        "code": "6180069",
        "name": "佳里區農會",
        "shortcut": "佳里本會"
    },
    {
        "parentCode": "618",
        "code": "6180081",
        "name": "善化區農會",
        "shortcut": "善化本會"
    },
    {
        "parentCode": "618",
        "code": "6180139",
        "name": "六甲區農會",
        "shortcut": "六甲本會"
    },
    {
        "parentCode": "618",
        "code": "6180162",
        "name": "西港區農會",
        "shortcut": "西港本會"
    },
    {
        "parentCode": "618",
        "code": "6180184",
        "name": "將軍區農會",
        "shortcut": "將軍本會"
    },
    {
        "parentCode": "618",
        "code": "6180195",
        "name": "北門區農會",
        "shortcut": "北門本會"
    },
    {
        "parentCode": "618",
        "code": "6180243",
        "name": "玉井區農會",
        "shortcut": "玉井農"
    },
    {
        "parentCode": "618",
        "code": "6180298",
        "name": "歸仁區農會",
        "shortcut": "歸仁農本會"
    },
    {
        "parentCode": "618",
        "code": "6180324",
        "name": "永康區農會",
        "shortcut": "永康本會"
    },
    {
        "parentCode": "618",
        "code": "6180335",
        "name": "太子宮分部",
        "shortcut": "新營太子宮"
    },
    {
        "parentCode": "618",
        "code": "6180346",
        "name": "延平分部",
        "shortcut": "新營延平"
    },
    {
        "parentCode": "618",
        "code": "6180357",
        "name": "歡雅分部",
        "shortcut": "鹽水歡雅"
    },
    {
        "parentCode": "618",
        "code": "6180368",
        "name": "圣頭港分部",
        "shortcut": "鹽水圣頭港"
    },
    {
        "parentCode": "618",
        "code": "6180416",
        "name": "佳興分部",
        "shortcut": "佳里農佳興"
    },
    {
        "parentCode": "618",
        "code": "6180427",
        "name": "子龍分部",
        "shortcut": "佳里農子龍"
    },
    {
        "parentCode": "618",
        "code": "6180438",
        "name": "塭內分部",
        "shortcut": "佳里農塭內"
    },
    {
        "parentCode": "618",
        "code": "6180449",
        "name": "延平分部",
        "shortcut": "佳里農延平"
    },
    {
        "parentCode": "618",
        "code": "6180450",
        "name": "東關里分部",
        "shortcut": "善化東關里"
    },
    {
        "parentCode": "618",
        "code": "6180461",
        "name": "六分寮分部",
        "shortcut": "善化六分寮"
    },
    {
        "parentCode": "618",
        "code": "6180612",
        "name": "林鳳營分部",
        "shortcut": "六甲林鳳營"
    },
    {
        "parentCode": "618",
        "code": "6180689",
        "name": "後營分部",
        "shortcut": "西港後營"
    },
    {
        "parentCode": "618",
        "code": "6180715",
        "name": "竹林分部",
        "shortcut": "西港竹林"
    },
    {
        "parentCode": "618",
        "code": "6180771",
        "name": "苓仔寮分部",
        "shortcut": "將軍苓仔寮"
    },
    {
        "parentCode": "618",
        "code": "6180782",
        "name": "將軍分部",
        "shortcut": "將軍將軍"
    },
    {
        "parentCode": "618",
        "code": "6180793",
        "name": "馬沙溝分部",
        "shortcut": "將軍馬沙溝"
    },
    {
        "parentCode": "618",
        "code": "6180807",
        "name": "青鯤鯓分部",
        "shortcut": "將軍青鯤鯓"
    },
    {
        "parentCode": "618",
        "code": "6180818",
        "name": "溪底寮分部",
        "shortcut": "北門溪底寮"
    },
    {
        "parentCode": "618",
        "code": "6180829",
        "name": "蚵寮分部",
        "shortcut": "北門蚵寮"
    },
    {
        "parentCode": "618",
        "code": "6180966",
        "name": "大潭武東分部",
        "shortcut": "歸仁農大潭"
    },
    {
        "parentCode": "618",
        "code": "6180977",
        "name": "大廟分部",
        "shortcut": "歸仁農大廟"
    },
    {
        "parentCode": "618",
        "code": "6180988",
        "name": "新市場分部",
        "shortcut": "歸仁農新市"
    },
    {
        "parentCode": "618",
        "code": "6181011",
        "name": "大灣分部",
        "shortcut": "永康大灣"
    },
    {
        "parentCode": "618",
        "code": "6181066",
        "name": "三民分部",
        "shortcut": "新營三民"
    },
    {
        "parentCode": "618",
        "code": "6181158",
        "name": "新生分部",
        "shortcut": "佳里農新生"
    },
    {
        "parentCode": "618",
        "code": "6181181",
        "name": "鹽行分部",
        "shortcut": "永康鹽行"
    },
    {
        "parentCode": "618",
        "code": "6181206",
        "name": "三慈分部",
        "shortcut": "北門三慈"
    },
    {
        "parentCode": "618",
        "code": "6181217",
        "name": "中正分部",
        "shortcut": "新營中正"
    },
    {
        "parentCode": "618",
        "code": "6181239",
        "name": "歸南分部",
        "shortcut": "歸仁農歸南"
    },
    {
        "parentCode": "618",
        "code": "6181240",
        "name": "中華分部",
        "shortcut": "永康中華"
    },
    {
        "parentCode": "618",
        "code": "6181262",
        "name": "崑山分部",
        "shortcut": "永康崑山"
    },
    {
        "parentCode": "618",
        "code": "6181343",
        "name": "龍潭分部",
        "shortcut": "永康龍潭"
    },
    {
        "parentCode": "618",
        "code": "6181365",
        "name": "茄拔分部",
        "shortcut": "善化茄拔"
    },
    {
        "parentCode": "618",
        "code": "6181402",
        "name": "楠西區農會",
        "shortcut": "楠西農會"
    },
    {
        "parentCode": "619",
        "code": "6190015",
        "name": "鳳山區農會",
        "shortcut": "鳳農"
    },
    {
        "parentCode": "619",
        "code": "6190026",
        "name": "岡山區農會",
        "shortcut": "岡山本會"
    },
    {
        "parentCode": "619",
        "code": "6190037",
        "name": "旗山區農會",
        "shortcut": "旗農本會"
    },
    {
        "parentCode": "619",
        "code": "6190048",
        "name": "美濃區農會",
        "shortcut": "美濃農本會"
    },
    {
        "parentCode": "619",
        "code": "6190059",
        "name": "橋頭區農會",
        "shortcut": "橋頭本會"
    },
    {
        "parentCode": "619",
        "code": "6190060",
        "name": "燕巢區農會",
        "shortcut": "燕巢本會"
    },
    {
        "parentCode": "619",
        "code": "6190071",
        "name": "田寮區農會",
        "shortcut": "田寮本會"
    },
    {
        "parentCode": "619",
        "code": "6190082",
        "name": "阿蓮區農會",
        "shortcut": "阿蓮本會"
    },
    {
        "parentCode": "619",
        "code": "6190093",
        "name": "路竹區農會",
        "shortcut": "路竹本會"
    },
    {
        "parentCode": "619",
        "code": "6190107",
        "name": "湖內區農會",
        "shortcut": "湖內本會"
    },
    {
        "parentCode": "619",
        "code": "6190118",
        "name": "茄萣區農會",
        "shortcut": "茄萣本會"
    },
    {
        "parentCode": "619",
        "code": "6190129",
        "name": "彌陀區農會",
        "shortcut": "彌陀本會"
    },
    {
        "parentCode": "619",
        "code": "6190130",
        "name": "永安區農會",
        "shortcut": "永安本會"
    },
    {
        "parentCode": "619",
        "code": "6190141",
        "name": "梓官區農會",
        "shortcut": "梓官本會"
    },
    {
        "parentCode": "619",
        "code": "6190185",
        "name": "林園區農會",
        "shortcut": "林園本會"
    },
    {
        "parentCode": "619",
        "code": "6190196",
        "name": "大寮區農會",
        "shortcut": "大寮本會"
    },
    {
        "parentCode": "619",
        "code": "6190211",
        "name": "仁武區農會",
        "shortcut": "仁武本會"
    },
    {
        "parentCode": "619",
        "code": "6190222",
        "name": "大社區農會",
        "shortcut": "大社本會"
    },
    {
        "parentCode": "619",
        "code": "6190244",
        "name": "杉林區農會",
        "shortcut": "杉林本會"
    },
    {
        "parentCode": "619",
        "code": "6190255",
        "name": "甲仙地區農會",
        "shortcut": "甲仙本會"
    },
    {
        "parentCode": "619",
        "code": "6190266",
        "name": "五甲分部",
        "shortcut": "鳳農五甲"
    },
    {
        "parentCode": "619",
        "code": "6190277",
        "name": "市場分部",
        "shortcut": "岡山市場"
    },
    {
        "parentCode": "619",
        "code": "6190288",
        "name": "嘉興分部",
        "shortcut": "岡山嘉興"
    },
    {
        "parentCode": "619",
        "code": "6190299",
        "name": "仁壽分部",
        "shortcut": "岡山仁壽"
    },
    {
        "parentCode": "619",
        "code": "6190303",
        "name": "六龜區農會",
        "shortcut": "六龜農會"
    },
    {
        "parentCode": "619",
        "code": "6190314",
        "name": "旗尾分部",
        "shortcut": "旗農旗尾"
    },
    {
        "parentCode": "619",
        "code": "6190325",
        "name": "溪洲分部",
        "shortcut": "旗農溪洲"
    },
    {
        "parentCode": "619",
        "code": "6190336",
        "name": "南溪洲分部",
        "shortcut": "旗農南溪洲"
    },
    {
        "parentCode": "619",
        "code": "6190347",
        "name": "嶺口分部",
        "shortcut": "旗農嶺口"
    },
    {
        "parentCode": "619",
        "code": "6190358",
        "name": "中正分部",
        "shortcut": "旗農中正"
    },
    {
        "parentCode": "619",
        "code": "6190369",
        "name": "中壇分部",
        "shortcut": "美濃農中壇"
    },
    {
        "parentCode": "619",
        "code": "6190370",
        "name": "龍肚分部",
        "shortcut": "美濃農龍肚"
    },
    {
        "parentCode": "619",
        "code": "6190381",
        "name": "廣興分部",
        "shortcut": "美濃農廣興"
    },
    {
        "parentCode": "619",
        "code": "6190392",
        "name": "南隆分部",
        "shortcut": "美濃農南隆"
    },
    {
        "parentCode": "619",
        "code": "6190406",
        "name": "鳳雄分部",
        "shortcut": "燕巢鳳雄"
    },
    {
        "parentCode": "619",
        "code": "6190417",
        "name": "深水分部",
        "shortcut": "燕巢深水"
    },
    {
        "parentCode": "619",
        "code": "6190428",
        "name": "竹滬分部",
        "shortcut": "路竹竹滬"
    },
    {
        "parentCode": "619",
        "code": "6190439",
        "name": "一甲分部",
        "shortcut": "路竹一甲"
    },
    {
        "parentCode": "619",
        "code": "6190440",
        "name": "下坑分部",
        "shortcut": "路竹下坑"
    },
    {
        "parentCode": "619",
        "code": "6190451",
        "name": "三爺分部",
        "shortcut": "路竹三爺"
    },
    {
        "parentCode": "619",
        "code": "6190462",
        "name": "大湖分部",
        "shortcut": "湖內大湖"
    },
    {
        "parentCode": "619",
        "code": "6190473",
        "name": "赤崁分部",
        "shortcut": "梓官赤崁"
    },
    {
        "parentCode": "619",
        "code": "6190484",
        "name": "大舍分部",
        "shortcut": "梓官大舍"
    },
    {
        "parentCode": "619",
        "code": "6190495",
        "name": "梓官分部",
        "shortcut": "梓官梓官"
    },
    {
        "parentCode": "619",
        "code": "6190565",
        "name": "大寮分部",
        "shortcut": "大寮大寮"
    },
    {
        "parentCode": "619",
        "code": "6190576",
        "name": "中庄分部",
        "shortcut": "大寮中庄"
    },
    {
        "parentCode": "619",
        "code": "6190587",
        "name": "後庄分部",
        "shortcut": "大寮後庄"
    },
    {
        "parentCode": "619",
        "code": "6190598",
        "name": "昭明分部",
        "shortcut": "大寮昭明"
    },
    {
        "parentCode": "619",
        "code": "6190602",
        "name": "潮寮分部",
        "shortcut": "大寮潮寮"
    },
    {
        "parentCode": "619",
        "code": "6190624",
        "name": "中路分部",
        "shortcut": "阿蓮中路"
    },
    {
        "parentCode": "619",
        "code": "6190646",
        "name": "南安分部",
        "shortcut": "彌陀南安"
    },
    {
        "parentCode": "619",
        "code": "6190657",
        "name": "海埔分部",
        "shortcut": "湖內海埔"
    },
    {
        "parentCode": "619",
        "code": "6190679",
        "name": "保寧分部",
        "shortcut": "永安保寧"
    },
    {
        "parentCode": "619",
        "code": "6190680",
        "name": "安招分部",
        "shortcut": "燕巢安招"
    },
    {
        "parentCode": "619",
        "code": "6190716",
        "name": "橋頭分部",
        "shortcut": "橋頭橋頭"
    },
    {
        "parentCode": "619",
        "code": "6190727",
        "name": "灣內分部",
        "shortcut": "仁武灣內"
    },
    {
        "parentCode": "619",
        "code": "6190738",
        "name": "老爺分部",
        "shortcut": "鳳農老爺"
    },
    {
        "parentCode": "619",
        "code": "6190749",
        "name": "市場分部",
        "shortcut": "鳳農市場"
    },
    {
        "parentCode": "619",
        "code": "6190750",
        "name": "白砂崙分部",
        "shortcut": "茄萣白砂崙"
    },
    {
        "parentCode": "619",
        "code": "6190761",
        "name": "維新分部",
        "shortcut": "永安維新"
    },
    {
        "parentCode": "619",
        "code": "6190772",
        "name": "協榮分部",
        "shortcut": "岡山協榮"
    },
    {
        "parentCode": "619",
        "code": "6190783",
        "name": "壽齡分部",
        "shortcut": "彌陀壽齡"
    },
    {
        "parentCode": "619",
        "code": "6190794",
        "name": "新興分部",
        "shortcut": "田寮新興"
    },
    {
        "parentCode": "619",
        "code": "6190808",
        "name": "崇德分部",
        "shortcut": "田寮崇德"
    },
    {
        "parentCode": "619",
        "code": "6190819",
        "name": "桃源分部",
        "shortcut": "甲仙桃源"
    },
    {
        "parentCode": "619",
        "code": "6190831",
        "name": "中厝分部",
        "shortcut": "林園中厝"
    },
    {
        "parentCode": "619",
        "code": "6190853",
        "name": "太爺分部",
        "shortcut": "湖內太爺"
    },
    {
        "parentCode": "619",
        "code": "6190864",
        "name": "文山分部",
        "shortcut": "鳳農文山"
    },
    {
        "parentCode": "619",
        "code": "6190886",
        "name": "東區分部",
        "shortcut": "鳳農東區"
    },
    {
        "parentCode": "619",
        "code": "6190897",
        "name": "鎮北分部",
        "shortcut": "鳳農鎮北"
    },
    {
        "parentCode": "619",
        "code": "6190901",
        "name": "甲圍辦事處",
        "shortcut": "橋頭甲圍辦"
    },
    {
        "parentCode": "619",
        "code": "6190923",
        "name": "隆豐和分部",
        "shortcut": "橋頭隆豐和"
    },
    {
        "parentCode": "619",
        "code": "6190934",
        "name": "五林分部",
        "shortcut": "橋頭五林"
    },
    {
        "parentCode": "619",
        "code": "6190967",
        "name": "仁美分部",
        "shortcut": "鳥松仁美"
    },
    {
        "parentCode": "619",
        "code": "6190978",
        "name": "溝坪分部",
        "shortcut": "內門溝坪"
    },
    {
        "parentCode": "619",
        "code": "6190989",
        "name": "那瑪夏分部",
        "shortcut": "甲仙那瑪夏"
    },
    {
        "parentCode": "619",
        "code": "6190990",
        "name": "過埤分部",
        "shortcut": "鳳農過埤"
    },
    {
        "parentCode": "619",
        "code": "6191001",
        "name": "觀亭分部",
        "shortcut": "內門觀亭"
    },
    {
        "parentCode": "619",
        "code": "6191012",
        "name": "翁園分部",
        "shortcut": "大寮翁園"
    },
    {
        "parentCode": "619",
        "code": "6191045",
        "name": "八卦分部",
        "shortcut": "仁武八卦"
    },
    {
        "parentCode": "619",
        "code": "6191067",
        "name": "後紅分部",
        "shortcut": "岡山後紅"
    },
    {
        "parentCode": "619",
        "code": "6191078",
        "name": "復安分部",
        "shortcut": "阿蓮復安"
    },
    {
        "parentCode": "619",
        "code": "6191089",
        "name": "鳥松區農會",
        "shortcut": "鳥松本會"
    },
    {
        "parentCode": "619",
        "code": "6191090",
        "name": "澄清湖分部",
        "shortcut": "鳥松澄清湖"
    },
    {
        "parentCode": "619",
        "code": "6191104",
        "name": "大樹區農會",
        "shortcut": "大樹本會"
    },
    {
        "parentCode": "619",
        "code": "6191115",
        "name": "內門區農會",
        "shortcut": "內門農會"
    },
    {
        "parentCode": "620",
        "code": "6200031",
        "name": "東港鎮農會",
        "shortcut": "東港農信"
    },
    {
        "parentCode": "620",
        "code": "6200042",
        "name": "恆春鎮農會",
        "shortcut": "恆春本會"
    },
    {
        "parentCode": "620",
        "code": "6200075",
        "name": "麟洛鄉農會",
        "shortcut": "麟洛本會"
    },
    {
        "parentCode": "620",
        "code": "6200086",
        "name": "九如鄉農會",
        "shortcut": "九如農信"
    },
    {
        "parentCode": "620",
        "code": "6200112",
        "name": "里港鄉農會",
        "shortcut": "里港本會"
    },
    {
        "parentCode": "620",
        "code": "6200167",
        "name": "崁頂鄉農會",
        "shortcut": "崁頂農會"
    },
    {
        "parentCode": "620",
        "code": "6200189",
        "name": "南州地區農會",
        "shortcut": "南州本會"
    },
    {
        "parentCode": "620",
        "code": "6200204",
        "name": "琉球鄉農會",
        "shortcut": "琉球農"
    },
    {
        "parentCode": "620",
        "code": "6200215",
        "name": "滿州鄉農會",
        "shortcut": "滿州本會"
    },
    {
        "parentCode": "620",
        "code": "6200259",
        "name": "枋山地區農會",
        "shortcut": "枋山農"
    },
    {
        "parentCode": "620",
        "code": "6200330",
        "name": "土庫辦事處",
        "shortcut": "里港農土庫"
    },
    {
        "parentCode": "620",
        "code": "6200341",
        "name": "載興辦事處",
        "shortcut": "里港農載興"
    },
    {
        "parentCode": "620",
        "code": "6200547",
        "name": "墾丁分部",
        "shortcut": "恆春墾丁"
    },
    {
        "parentCode": "620",
        "code": "6200628",
        "name": "三廓分部",
        "shortcut": "里港農三廓"
    },
    {
        "parentCode": "620",
        "code": "6200709",
        "name": "力社分部",
        "shortcut": "崁頂農力社"
    },
    {
        "parentCode": "620",
        "code": "6200710",
        "name": "港東分部",
        "shortcut": "崁頂農港東"
    },
    {
        "parentCode": "620",
        "code": "6200721",
        "name": "下部分部",
        "shortcut": "東港農下部"
    },
    {
        "parentCode": "620",
        "code": "6200743",
        "name": "枋山分部",
        "shortcut": "枋山枋山"
    },
    {
        "parentCode": "620",
        "code": "6200754",
        "name": "加祿分部",
        "shortcut": "枋山加祿"
    },
    {
        "parentCode": "620",
        "code": "6200787",
        "name": "洽興分部",
        "shortcut": "九如洽興"
    },
    {
        "parentCode": "620",
        "code": "6200813",
        "name": "新埤分部",
        "shortcut": "南州新埤"
    },
    {
        "parentCode": "620",
        "code": "6200868",
        "name": "屏東市農會",
        "shortcut": "屏市農會"
    },
    {
        "parentCode": "620",
        "code": "6200879",
        "name": "車城地區農會",
        "shortcut": "車城農會"
    },
    {
        "parentCode": "620",
        "code": "6200880",
        "name": "屏東縣農會",
        "shortcut": "屏東縣農會"
    },
    {
        "parentCode": "620",
        "code": "6200891",
        "name": "枋寮地區農會",
        "shortcut": "枋寮農會"
    },
    {
        "parentCode": "620",
        "code": "6200905",
        "name": "竹田鄉農會",
        "shortcut": "竹田農會"
    },
    {
        "parentCode": "620",
        "code": "6200916",
        "name": "萬丹鄉農會",
        "shortcut": "萬丹農會"
    },
    {
        "parentCode": "620",
        "code": "6200927",
        "name": "長治鄉農會",
        "shortcut": "長治農會"
    },
    {
        "parentCode": "620",
        "code": "6200938",
        "name": "林邊鄉農會",
        "shortcut": "林邊本會"
    },
    {
        "parentCode": "620",
        "code": "6200949",
        "name": "佳冬鄉農會",
        "shortcut": "佳冬本會"
    },
    {
        "parentCode": "620",
        "code": "6200950",
        "name": "塔樓分部",
        "shortcut": "里港農塔樓"
    },
    {
        "parentCode": "620",
        "code": "6200961",
        "name": "高樹鄉農會",
        "shortcut": "高樹本會"
    },
    {
        "parentCode": "620",
        "code": "6200972",
        "name": "萬巒地區農會",
        "shortcut": "萬巒本會"
    },
    {
        "parentCode": "620",
        "code": "6200994",
        "name": "新園鄉農會",
        "shortcut": "新園本會"
    },
    {
        "parentCode": "621",
        "code": "6210021",
        "name": "吉安鄉農會",
        "shortcut": "吉安本會"
    },
    {
        "parentCode": "621",
        "code": "6210032",
        "name": "壽豐鄉農會",
        "shortcut": "壽豐本會"
    },
    {
        "parentCode": "621",
        "code": "6210043",
        "name": "富里鄉農會",
        "shortcut": "富里本會"
    },
    {
        "parentCode": "621",
        "code": "6210098",
        "name": "新秀地區農會",
        "shortcut": "新秀本會"
    },
    {
        "parentCode": "621",
        "code": "6210102",
        "name": "仁里分部",
        "shortcut": "吉安仁里"
    },
    {
        "parentCode": "621",
        "code": "6210124",
        "name": "志學分部",
        "shortcut": "壽豐志學"
    },
    {
        "parentCode": "621",
        "code": "6210135",
        "name": "東竹分部",
        "shortcut": "富里東竹"
    },
    {
        "parentCode": "621",
        "code": "6210146",
        "name": "東里分部",
        "shortcut": "富里東里"
    },
    {
        "parentCode": "621",
        "code": "6210191",
        "name": "新城分部",
        "shortcut": "新秀新城"
    },
    {
        "parentCode": "621",
        "code": "6210205",
        "name": "嘉里分部",
        "shortcut": "新秀嘉里"
    },
    {
        "parentCode": "621",
        "code": "6210216",
        "name": "秀林分部",
        "shortcut": "新秀秀林"
    },
    {
        "parentCode": "621",
        "code": "6210227",
        "name": "北昌分部",
        "shortcut": "吉安北昌"
    },
    {
        "parentCode": "621",
        "code": "6210238",
        "name": "宜昌分部",
        "shortcut": "吉安宜昌"
    },
    {
        "parentCode": "621",
        "code": "6210331",
        "name": "豐裡分部",
        "shortcut": "壽豐豐裡"
    },
    {
        "parentCode": "622",
        "code": "6220011",
        "name": "關山鎮農會",
        "shortcut": "關山本會"
    },
    {
        "parentCode": "622",
        "code": "6220022",
        "name": "成功鎮農會",
        "shortcut": "成功本會"
    },
    {
        "parentCode": "622",
        "code": "6220033",
        "name": "池上鄉農會",
        "shortcut": "池上本會"
    },
    {
        "parentCode": "622",
        "code": "6220044",
        "name": "東河分部",
        "shortcut": "東河東河"
    },
    {
        "parentCode": "622",
        "code": "6220055",
        "name": "長濱鄉農會",
        "shortcut": "長濱本會"
    },
    {
        "parentCode": "622",
        "code": "6220066",
        "name": "台東地區農會",
        "shortcut": "台東本會"
    },
    {
        "parentCode": "622",
        "code": "6220077",
        "name": "鹿野地區農會",
        "shortcut": "鹿野本會"
    },
    {
        "parentCode": "622",
        "code": "6220088",
        "name": "太麻里地區農會",
        "shortcut": "太麻里本會"
    },
    {
        "parentCode": "622",
        "code": "6220099",
        "name": "豐里分部",
        "shortcut": "台東豐里"
    },
    {
        "parentCode": "622",
        "code": "6220103",
        "name": "東河鄉農會",
        "shortcut": "東河本會"
    },
    {
        "parentCode": "622",
        "code": "6220114",
        "name": "泰源分部",
        "shortcut": "東河泰源"
    },
    {
        "parentCode": "622",
        "code": "6220125",
        "name": "台東分部",
        "shortcut": "台東台東"
    },
    {
        "parentCode": "622",
        "code": "6220136",
        "name": "知本分部",
        "shortcut": "台東知本"
    },
    {
        "parentCode": "622",
        "code": "6220147",
        "name": "綠島分部",
        "shortcut": "台東綠島"
    },
    {
        "parentCode": "622",
        "code": "6220158",
        "name": "初鹿分部",
        "shortcut": "台東初鹿"
    },
    {
        "parentCode": "622",
        "code": "6220169",
        "name": "瑞源分部",
        "shortcut": "鹿野瑞源"
    },
    {
        "parentCode": "622",
        "code": "6220170",
        "name": "美和分部",
        "shortcut": "太麻里美和"
    },
    {
        "parentCode": "622",
        "code": "6220181",
        "name": "金崙分部",
        "shortcut": "太麻里金崙"
    },
    {
        "parentCode": "622",
        "code": "6220192",
        "name": "大武分部",
        "shortcut": "太麻里大武"
    },
    {
        "parentCode": "622",
        "code": "6220206",
        "name": "忠孝分部",
        "shortcut": "成功忠孝"
    },
    {
        "parentCode": "622",
        "code": "6220217",
        "name": "太平分部",
        "shortcut": "台東太平"
    },
    {
        "parentCode": "622",
        "code": "6220228",
        "name": "蘭嶼分部",
        "shortcut": "台東蘭嶼"
    },
    {
        "parentCode": "622",
        "code": "6220239",
        "name": "海端分部",
        "shortcut": "關山海端"
    },
    {
        "parentCode": "622",
        "code": "6220240",
        "name": "瑞豐分部",
        "shortcut": "鹿野瑞豐"
    },
    {
        "parentCode": "622",
        "code": "6220251",
        "name": "信義分部",
        "shortcut": "成功信義"
    },
    {
        "parentCode": "622",
        "code": "6220284",
        "name": "新園分部",
        "shortcut": "台東新園"
    },
    {
        "parentCode": "623",
        "code": "6230012",
        "name": "北投區農會",
        "shortcut": "北投農本會"
    },
    {
        "parentCode": "623",
        "code": "6230023",
        "name": "士林區農會",
        "shortcut": "士農"
    },
    {
        "parentCode": "623",
        "code": "6230034",
        "name": "內湖區農會",
        "shortcut": "內湖農本會"
    },
    {
        "parentCode": "623",
        "code": "6230045",
        "name": "南港區農會",
        "shortcut": "南港農"
    },
    {
        "parentCode": "623",
        "code": "6230056",
        "name": "木柵區農會",
        "shortcut": "木柵農會"
    },
    {
        "parentCode": "623",
        "code": "6230067",
        "name": "景美區農會",
        "shortcut": "景美農"
    },
    {
        "parentCode": "623",
        "code": "6230078",
        "name": "石牌分部",
        "shortcut": "北投農石牌"
    },
    {
        "parentCode": "623",
        "code": "6230089",
        "name": "關渡分部",
        "shortcut": "北投農關渡"
    },
    {
        "parentCode": "623",
        "code": "6230090",
        "name": "社子分會",
        "shortcut": "士農社子"
    },
    {
        "parentCode": "623",
        "code": "6230104",
        "name": "潭美分部",
        "shortcut": "內湖農潭美"
    },
    {
        "parentCode": "623",
        "code": "6230115",
        "name": "中研分部",
        "shortcut": "南港中研"
    },
    {
        "parentCode": "623",
        "code": "6230126",
        "name": "立農分部",
        "shortcut": "北投農立農"
    },
    {
        "parentCode": "623",
        "code": "6230137",
        "name": "大屯分部",
        "shortcut": "北投農大屯"
    },
    {
        "parentCode": "623",
        "code": "6230148",
        "name": "文林分會",
        "shortcut": "士農文林"
    },
    {
        "parentCode": "623",
        "code": "6230159",
        "name": "天母分會",
        "shortcut": "士農天母"
    },
    {
        "parentCode": "623",
        "code": "6230171",
        "name": "東湖分部",
        "shortcut": "內湖農東湖"
    },
    {
        "parentCode": "623",
        "code": "6230182",
        "name": "西湖分部",
        "shortcut": "內湖農西湖"
    },
    {
        "parentCode": "623",
        "code": "6230207",
        "name": "陽明山分部",
        "shortcut": "士農陽明山"
    },
    {
        "parentCode": "623",
        "code": "6230218",
        "name": "後山埤分部",
        "shortcut": "南港後山埤"
    },
    {
        "parentCode": "623",
        "code": "6230229",
        "name": "延平分部",
        "shortcut": "士農延平"
    },
    {
        "parentCode": "623",
        "code": "6230241",
        "name": "福興分部",
        "shortcut": "北投農福興"
    },
    {
        "parentCode": "623",
        "code": "6230252",
        "name": "木新分部",
        "shortcut": "木柵木新"
    },
    {
        "parentCode": "623",
        "code": "6230274",
        "name": "民權分部",
        "shortcut": "內湖農民權"
    },
    {
        "parentCode": "624",
        "code": "6240013",
        "name": "澎湖縣農會信用部",
        "shortcut": "澎農信"
    },
    {
        "parentCode": "624",
        "code": "6240024",
        "name": "湖西分部",
        "shortcut": "澎農湖西"
    },
    {
        "parentCode": "624",
        "code": "6240035",
        "name": "西嶼分部",
        "shortcut": "澎農西嶼"
    },
    {
        "parentCode": "624",
        "code": "6240046",
        "name": "望安分部",
        "shortcut": "澎農望安"
    },
    {
        "parentCode": "624",
        "code": "6240057",
        "name": "白沙分部",
        "shortcut": "澎農白沙"
    },
    {
        "parentCode": "624",
        "code": "6240068",
        "name": "七美分部",
        "shortcut": "澎農七美"
    },
    {
        "parentCode": "625",
        "code": "6250014",
        "name": "臺中地區農會信用部",
        "shortcut": "中區農信"
    },
    {
        "parentCode": "625",
        "code": "6250025",
        "name": "西屯分部",
        "shortcut": "中區農西屯"
    },
    {
        "parentCode": "625",
        "code": "6250036",
        "name": "北屯分部",
        "shortcut": "中區農北屯"
    },
    {
        "parentCode": "625",
        "code": "6250047",
        "name": "南屯分部",
        "shortcut": "中區農南屯"
    },
    {
        "parentCode": "625",
        "code": "6250058",
        "name": "四民分部",
        "shortcut": "中區農四民"
    },
    {
        "parentCode": "625",
        "code": "6250069",
        "name": "西區分部",
        "shortcut": "中區農西區"
    },
    {
        "parentCode": "625",
        "code": "6250070",
        "name": "東區分部",
        "shortcut": "中區農東區"
    },
    {
        "parentCode": "625",
        "code": "6250092",
        "name": "南區分部",
        "shortcut": "中區農南區"
    },
    {
        "parentCode": "625",
        "code": "6250117",
        "name": "北區分部",
        "shortcut": "中區農北區"
    },
    {
        "parentCode": "625",
        "code": "6250139",
        "name": "軍功分部",
        "shortcut": "中區農軍功"
    },
    {
        "parentCode": "625",
        "code": "6250140",
        "name": "大坑分部",
        "shortcut": "中區農大坑"
    },
    {
        "parentCode": "627",
        "code": "6270016",
        "name": "連江縣農會",
        "shortcut": "連江縣農會"
    },
    {
        "parentCode": "700",
        "code": "7000010",
        "name": "郵政劃撥儲金",
        "shortcut": "郵政劃撥"
    },
    {
        "parentCode": "700",
        "code": "7000021",
        "name": "郵政存簿儲金",
        "shortcut": "郵政存簿"
    },
    {
        "parentCode": "803",
        "code": "8030010",
        "name": "台北分行",
        "shortcut": "聯邦台北"
    },
    {
        "parentCode": "803",
        "code": "8030021",
        "name": "營業部",
        "shortcut": "聯邦營"
    },
    {
        "parentCode": "803",
        "code": "8030032",
        "name": "桃園分行",
        "shortcut": "聯邦桃園"
    },
    {
        "parentCode": "803",
        "code": "8030043",
        "name": "台中分行",
        "shortcut": "聯邦台中"
    },
    {
        "parentCode": "803",
        "code": "8030054",
        "name": "南京東路分行",
        "shortcut": "聯邦南京"
    },
    {
        "parentCode": "803",
        "code": "8030065",
        "name": "中壢分行",
        "shortcut": "聯邦中壢"
    },
    {
        "parentCode": "803",
        "code": "8030076",
        "name": "三重分行",
        "shortcut": "聯邦三重"
    },
    {
        "parentCode": "803",
        "code": "8030098",
        "name": "東台北分行",
        "shortcut": "聯邦東台北"
    },
    {
        "parentCode": "803",
        "code": "8030102",
        "name": "高雄分行",
        "shortcut": "聯邦高雄"
    },
    {
        "parentCode": "803",
        "code": "8030113",
        "name": "板橋分行",
        "shortcut": "聯邦板橋"
    },
    {
        "parentCode": "803",
        "code": "8030124",
        "name": "台南分行",
        "shortcut": "聯邦台南"
    },
    {
        "parentCode": "803",
        "code": "8030135",
        "name": "員林分行",
        "shortcut": "聯邦員林"
    },
    {
        "parentCode": "803",
        "code": "8030157",
        "name": "仁愛分行",
        "shortcut": "聯邦仁愛"
    },
    {
        "parentCode": "803",
        "code": "8030168",
        "name": "南崁分行",
        "shortcut": "聯邦南崁"
    },
    {
        "parentCode": "803",
        "code": "8030179",
        "name": "新竹分行",
        "shortcut": "聯邦新竹"
    },
    {
        "parentCode": "803",
        "code": "8030180",
        "name": "蘆洲分行",
        "shortcut": "聯邦蘆洲"
    },
    {
        "parentCode": "803",
        "code": "8030205",
        "name": "九如分行",
        "shortcut": "聯邦九如"
    },
    {
        "parentCode": "803",
        "code": "8030216",
        "name": "雙和分行",
        "shortcut": "聯邦雙和"
    },
    {
        "parentCode": "803",
        "code": "8030227",
        "name": "迴龍分行",
        "shortcut": "聯邦迴龍"
    },
    {
        "parentCode": "803",
        "code": "8030238",
        "name": "嘉義分行",
        "shortcut": "聯邦嘉義"
    },
    {
        "parentCode": "803",
        "code": "8030249",
        "name": "公館分行",
        "shortcut": "聯邦公館"
    },
    {
        "parentCode": "803",
        "code": "8030250",
        "name": "內壢分行",
        "shortcut": "聯邦內壢"
    },
    {
        "parentCode": "803",
        "code": "8030261",
        "name": "忠孝分行",
        "shortcut": "聯邦忠孝"
    },
    {
        "parentCode": "803",
        "code": "8030272",
        "name": "新莊分行",
        "shortcut": "聯邦新莊"
    },
    {
        "parentCode": "803",
        "code": "8030283",
        "name": "鳳山分行",
        "shortcut": "聯邦鳳山"
    },
    {
        "parentCode": "803",
        "code": "8030294",
        "name": "桃鶯分行",
        "shortcut": "聯邦桃鶯"
    },
    {
        "parentCode": "803",
        "code": "8030308",
        "name": "龍潭分行",
        "shortcut": "聯邦龍潭"
    },
    {
        "parentCode": "803",
        "code": "8030319",
        "name": "新店分行",
        "shortcut": "聯邦新店"
    },
    {
        "parentCode": "803",
        "code": "8030320",
        "name": "大直分行",
        "shortcut": "聯邦大直"
    },
    {
        "parentCode": "803",
        "code": "8030331",
        "name": "中山簡易型分行",
        "shortcut": "聯邦中山簡"
    },
    {
        "parentCode": "803",
        "code": "8030342",
        "name": "文心分行",
        "shortcut": "聯邦文心"
    },
    {
        "parentCode": "803",
        "code": "8030353",
        "name": "健行分行",
        "shortcut": "聯邦健行"
    },
    {
        "parentCode": "803",
        "code": "8030364",
        "name": "中和分行",
        "shortcut": "聯邦中和"
    },
    {
        "parentCode": "803",
        "code": "8030375",
        "name": "內湖分行",
        "shortcut": "聯邦內湖"
    },
    {
        "parentCode": "803",
        "code": "8030386",
        "name": "大園分行",
        "shortcut": "聯邦大園"
    },
    {
        "parentCode": "803",
        "code": "8030397",
        "name": "永和分行",
        "shortcut": "聯邦永和"
    },
    {
        "parentCode": "803",
        "code": "8030401",
        "name": "中港簡易型分行",
        "shortcut": "聯邦中港簡"
    },
    {
        "parentCode": "803",
        "code": "8030412",
        "name": "通化簡易型分行",
        "shortcut": "聯邦通化簡"
    },
    {
        "parentCode": "803",
        "code": "8030423",
        "name": "士東分行",
        "shortcut": "聯邦士東"
    },
    {
        "parentCode": "803",
        "code": "8030434",
        "name": "微風簡易型分行",
        "shortcut": "聯邦微風簡"
    },
    {
        "parentCode": "803",
        "code": "8030445",
        "name": "長春分行",
        "shortcut": "聯邦長春"
    },
    {
        "parentCode": "803",
        "code": "8030456",
        "name": "松江分行",
        "shortcut": "聯邦松江"
    },
    {
        "parentCode": "803",
        "code": "8030467",
        "name": "大安分行",
        "shortcut": "聯邦大安"
    },
    {
        "parentCode": "803",
        "code": "8030478",
        "name": "永吉分行",
        "shortcut": "聯邦永吉"
    },
    {
        "parentCode": "803",
        "code": "8030489",
        "name": "文林分行",
        "shortcut": "聯邦文林"
    },
    {
        "parentCode": "803",
        "code": "8030490",
        "name": "東門分行",
        "shortcut": "聯邦東門"
    },
    {
        "parentCode": "803",
        "code": "8030504",
        "name": "敦化分行",
        "shortcut": "聯邦敦化"
    },
    {
        "parentCode": "803",
        "code": "8030515",
        "name": "西湖分行",
        "shortcut": "聯邦西湖"
    },
    {
        "parentCode": "803",
        "code": "8030526",
        "name": "北投簡易型分行",
        "shortcut": "聯邦北投簡"
    },
    {
        "parentCode": "803",
        "code": "8030537",
        "name": "北三重分行",
        "shortcut": "聯邦北三重"
    },
    {
        "parentCode": "803",
        "code": "8030548",
        "name": "後埔分行",
        "shortcut": "聯邦後埔"
    },
    {
        "parentCode": "803",
        "code": "8030559",
        "name": "北中和簡易型分行",
        "shortcut": "聯邦北中和"
    },
    {
        "parentCode": "803",
        "code": "8030560",
        "name": "富國分行",
        "shortcut": "聯邦富國"
    },
    {
        "parentCode": "803",
        "code": "8030571",
        "name": "樹林分行",
        "shortcut": "聯邦樹林"
    },
    {
        "parentCode": "803",
        "code": "8030582",
        "name": "汐止分行",
        "shortcut": "聯邦汐止"
    },
    {
        "parentCode": "803",
        "code": "8030593",
        "name": "北桃園分行",
        "shortcut": "聯邦北桃園"
    },
    {
        "parentCode": "803",
        "code": "8030607",
        "name": "北中壢分行",
        "shortcut": "聯邦北中壢"
    },
    {
        "parentCode": "803",
        "code": "8030618",
        "name": "北台中分行",
        "shortcut": "聯邦北台中"
    },
    {
        "parentCode": "803",
        "code": "8030629",
        "name": "民權分行",
        "shortcut": "聯邦民權"
    },
    {
        "parentCode": "803",
        "code": "8030630",
        "name": "西屯分行",
        "shortcut": "聯邦西屯"
    },
    {
        "parentCode": "803",
        "code": "8030641",
        "name": "興中分行",
        "shortcut": "聯邦興中"
    },
    {
        "parentCode": "803",
        "code": "8030652",
        "name": "北屯分行",
        "shortcut": "聯邦北屯"
    },
    {
        "parentCode": "803",
        "code": "8030663",
        "name": "南員林分行",
        "shortcut": "聯邦南員林"
    },
    {
        "parentCode": "803",
        "code": "8030674",
        "name": "東嘉義分行",
        "shortcut": "聯邦東嘉義"
    },
    {
        "parentCode": "803",
        "code": "8030685",
        "name": "府城分行",
        "shortcut": "聯邦府城"
    },
    {
        "parentCode": "803",
        "code": "8030696",
        "name": "豐原分行",
        "shortcut": "聯邦豐原"
    },
    {
        "parentCode": "803",
        "code": "8030700",
        "name": "富強分行",
        "shortcut": "聯邦富強"
    },
    {
        "parentCode": "803",
        "code": "8030711",
        "name": "開元分行",
        "shortcut": "聯邦開元"
    },
    {
        "parentCode": "803",
        "code": "8030722",
        "name": "南台南分行",
        "shortcut": "聯邦南台南"
    },
    {
        "parentCode": "803",
        "code": "8030733",
        "name": "苓雅分行",
        "shortcut": "聯邦苓雅"
    },
    {
        "parentCode": "803",
        "code": "8030744",
        "name": "北高雄分行",
        "shortcut": "聯邦北高雄"
    },
    {
        "parentCode": "803",
        "code": "8030755",
        "name": "三民分行",
        "shortcut": "聯邦三民"
    },
    {
        "parentCode": "803",
        "code": "8030766",
        "name": "五甲分行",
        "shortcut": "聯邦五甲"
    },
    {
        "parentCode": "803",
        "code": "8030777",
        "name": "屏東分行",
        "shortcut": "聯邦屏東"
    },
    {
        "parentCode": "803",
        "code": "8030788",
        "name": "和平分行",
        "shortcut": "聯邦和平"
    },
    {
        "parentCode": "803",
        "code": "8030799",
        "name": "永春分行",
        "shortcut": "聯邦永春"
    },
    {
        "parentCode": "803",
        "code": "8030803",
        "name": "田心分行",
        "shortcut": "聯邦田心"
    },
    {
        "parentCode": "803",
        "code": "8030814",
        "name": "安康分行",
        "shortcut": "聯邦安康"
    },
    {
        "parentCode": "803",
        "code": "8030825",
        "name": "大竹分行",
        "shortcut": "聯邦大竹"
    },
    {
        "parentCode": "803",
        "code": "8030836",
        "name": "龜山分行",
        "shortcut": "聯邦龜山"
    },
    {
        "parentCode": "803",
        "code": "8030847",
        "name": "林口分行",
        "shortcut": "聯邦林口"
    },
    {
        "parentCode": "803",
        "code": "8030858",
        "name": "五股分行",
        "shortcut": "聯邦五股"
    },
    {
        "parentCode": "803",
        "code": "8030869",
        "name": "高榮分行",
        "shortcut": "聯邦高榮"
    },
    {
        "parentCode": "803",
        "code": "8030870",
        "name": "大業分行",
        "shortcut": "聯邦大業"
    },
    {
        "parentCode": "803",
        "code": "8030881",
        "name": "蘆竹分行",
        "shortcut": "聯邦蘆竹"
    },
    {
        "parentCode": "803",
        "code": "8030892",
        "name": "土城分行",
        "shortcut": "聯邦土城"
    },
    {
        "parentCode": "803",
        "code": "8030906",
        "name": "南桃園分行",
        "shortcut": "聯邦南桃園"
    },
    {
        "parentCode": "803",
        "code": "8030917",
        "name": "集賢分行",
        "shortcut": "聯邦集賢"
    },
    {
        "parentCode": "803",
        "code": "8030928",
        "name": "三峽分行",
        "shortcut": "聯邦三峽"
    },
    {
        "parentCode": "803",
        "code": "8030939",
        "name": "東湖分行",
        "shortcut": "聯邦東湖"
    },
    {
        "parentCode": "805",
        "code": "8050012",
        "name": "營業部",
        "shortcut": "遠銀營業部"
    },
    {
        "parentCode": "805",
        "code": "8050023",
        "name": "台北襄陽分行",
        "shortcut": "遠銀襄陽"
    },
    {
        "parentCode": "805",
        "code": "8050034",
        "name": "台北逸仙分行",
        "shortcut": "遠銀逸仙"
    },
    {
        "parentCode": "805",
        "code": "8050045",
        "name": "桃園分行",
        "shortcut": "遠銀桃園"
    },
    {
        "parentCode": "805",
        "code": "8050056",
        "name": "台中公益分行",
        "shortcut": "遠銀公益"
    },
    {
        "parentCode": "805",
        "code": "8050067",
        "name": "台南分行",
        "shortcut": "遠銀台南"
    },
    {
        "parentCode": "805",
        "code": "8050078",
        "name": "高雄五福分行",
        "shortcut": "遠銀五福"
    },
    {
        "parentCode": "805",
        "code": "8050090",
        "name": "板橋南雅分行",
        "shortcut": "遠銀南雅"
    },
    {
        "parentCode": "805",
        "code": "8050104",
        "name": "新竹巨城分行",
        "shortcut": "遠銀巨城"
    },
    {
        "parentCode": "805",
        "code": "8050115",
        "name": "高雄中正分行",
        "shortcut": "遠銀中正"
    },
    {
        "parentCode": "805",
        "code": "8050148",
        "name": "三重分行",
        "shortcut": "遠銀三重"
    },
    {
        "parentCode": "805",
        "code": "8050159",
        "name": "台北松江分行",
        "shortcut": "遠銀松江"
    },
    {
        "parentCode": "805",
        "code": "8050160",
        "name": "永康分行",
        "shortcut": "遠銀永康"
    },
    {
        "parentCode": "805",
        "code": "8050171",
        "name": "中壢分行",
        "shortcut": "遠銀中壢"
    },
    {
        "parentCode": "805",
        "code": "8050193",
        "name": "永和分行",
        "shortcut": "遠銀永和"
    },
    {
        "parentCode": "805",
        "code": "8050207",
        "name": "台北東門分行",
        "shortcut": "遠銀東門"
    },
    {
        "parentCode": "805",
        "code": "8050218",
        "name": "高雄文化中心分行",
        "shortcut": "遠銀文中"
    },
    {
        "parentCode": "805",
        "code": "8050229",
        "name": "台中文心分行",
        "shortcut": "遠銀文心"
    },
    {
        "parentCode": "805",
        "code": "8050230",
        "name": "新竹科學園區分行",
        "shortcut": "遠銀科園"
    },
    {
        "parentCode": "805",
        "code": "8050241",
        "name": "桃園大興分行",
        "shortcut": "遠銀大興"
    },
    {
        "parentCode": "805",
        "code": "8050252",
        "name": "台北南門分行",
        "shortcut": "遠銀南門"
    },
    {
        "parentCode": "805",
        "code": "8050263",
        "name": "台北新莊分行",
        "shortcut": "遠銀新莊"
    },
    {
        "parentCode": "805",
        "code": "8050274",
        "name": "台北松山分行",
        "shortcut": "遠銀松山"
    },
    {
        "parentCode": "805",
        "code": "8050285",
        "name": "新店分行",
        "shortcut": "遠銀新店"
    },
    {
        "parentCode": "805",
        "code": "8050296",
        "name": "台北重慶分行",
        "shortcut": "遠銀重慶"
    },
    {
        "parentCode": "805",
        "code": "8050300",
        "name": "竹北分行",
        "shortcut": "遠銀竹北"
    },
    {
        "parentCode": "805",
        "code": "8050311",
        "name": "台北忠孝分行",
        "shortcut": "遠銀忠孝"
    },
    {
        "parentCode": "805",
        "code": "8050322",
        "name": "林口分行",
        "shortcut": "遠銀林口"
    },
    {
        "parentCode": "805",
        "code": "8050333",
        "name": "板橋文化分行",
        "shortcut": "遠銀文化"
    },
    {
        "parentCode": "805",
        "code": "8050344",
        "name": "台中自由分行",
        "shortcut": "遠銀自由"
    },
    {
        "parentCode": "805",
        "code": "8050355",
        "name": "新莊富國分行",
        "shortcut": "遠銀富國"
    },
    {
        "parentCode": "805",
        "code": "8050366",
        "name": "台北永吉分行",
        "shortcut": "遠銀永吉"
    },
    {
        "parentCode": "805",
        "code": "8050377",
        "name": "三重重陽簡易型分行",
        "shortcut": "遠銀重陽簡"
    },
    {
        "parentCode": "805",
        "code": "8050388",
        "name": "桃園大有分行",
        "shortcut": "遠銀大有"
    },
    {
        "parentCode": "805",
        "code": "8050399",
        "name": "台北金湖簡易型分行",
        "shortcut": "遠銀金湖簡"
    },
    {
        "parentCode": "805",
        "code": "8050403",
        "name": "台北農安簡易型分行",
        "shortcut": "遠銀農安簡"
    },
    {
        "parentCode": "805",
        "code": "8050414",
        "name": "台北中山分行",
        "shortcut": "遠銀中山"
    },
    {
        "parentCode": "805",
        "code": "8050425",
        "name": "台北古亭分行",
        "shortcut": "遠銀古亭"
    },
    {
        "parentCode": "805",
        "code": "8050436",
        "name": "台北信義分行",
        "shortcut": "遠銀信義"
    },
    {
        "parentCode": "805",
        "code": "8050458",
        "name": "蘆洲分行",
        "shortcut": "遠銀蘆洲"
    },
    {
        "parentCode": "805",
        "code": "8050469",
        "name": "中和分行",
        "shortcut": "遠銀中和"
    },
    {
        "parentCode": "805",
        "code": "8050470",
        "name": "板橋中正分行",
        "shortcut": "遠銀板中正"
    },
    {
        "parentCode": "805",
        "code": "8050481",
        "name": "新竹經國分行",
        "shortcut": "遠銀經國"
    },
    {
        "parentCode": "805",
        "code": "8050492",
        "name": "台中大雅分行",
        "shortcut": "遠銀大雅"
    },
    {
        "parentCode": "805",
        "code": "8050506",
        "name": "彰化分行",
        "shortcut": "遠銀彰化"
    },
    {
        "parentCode": "805",
        "code": "8050517",
        "name": "南投分行",
        "shortcut": "遠銀南投"
    },
    {
        "parentCode": "805",
        "code": "8050528",
        "name": "嘉義分行",
        "shortcut": "遠銀嘉義"
    },
    {
        "parentCode": "805",
        "code": "8050539",
        "name": "高雄苓雅分行",
        "shortcut": "遠銀苓雅"
    },
    {
        "parentCode": "805",
        "code": "8050540",
        "name": "鳳山分行",
        "shortcut": "遠銀鳳山"
    },
    {
        "parentCode": "805",
        "code": "8050562",
        "name": "大里分行",
        "shortcut": "遠銀大里"
    },
    {
        "parentCode": "805",
        "code": "8050573",
        "name": "崇德分行",
        "shortcut": "遠銀崇德"
    },
    {
        "parentCode": "805",
        "code": "8050584",
        "name": "板橋大遠百分行",
        "shortcut": "遠銀大遠百"
    },
    {
        "parentCode": "805",
        "code": "8050595",
        "name": "台北一０一分行",
        "shortcut": "遠銀一０一"
    },
    {
        "parentCode": "805",
        "code": "8050609",
        "name": "雙和分行",
        "shortcut": "遠銀雙和"
    },
    {
        "parentCode": "805",
        "code": "8050610",
        "name": "台北南京東路分行",
        "shortcut": "遠銀南京東"
    },
    {
        "parentCode": "805",
        "code": "8050621",
        "name": "台北大稻埕分行",
        "shortcut": "遠銀大稻埕"
    },
    {
        "parentCode": "806",
        "code": "8060013",
        "name": "台中分行",
        "shortcut": "元大台中"
    },
    {
        "parentCode": "806",
        "code": "8060024",
        "name": "文心分行",
        "shortcut": "元大文心"
    },
    {
        "parentCode": "806",
        "code": "8060035",
        "name": "台北分行",
        "shortcut": "元大台北"
    },
    {
        "parentCode": "806",
        "code": "8060046",
        "name": "沙鹿分行",
        "shortcut": "元大沙鹿"
    },
    {
        "parentCode": "806",
        "code": "8060057",
        "name": "豐原分行",
        "shortcut": "元大豐原"
    },
    {
        "parentCode": "806",
        "code": "8060068",
        "name": "彰化分行",
        "shortcut": "元大彰化"
    },
    {
        "parentCode": "806",
        "code": "8060079",
        "name": "員林分行",
        "shortcut": "元大員林"
    },
    {
        "parentCode": "806",
        "code": "8060080",
        "name": "三重分行",
        "shortcut": "元大三重"
    },
    {
        "parentCode": "806",
        "code": "8060091",
        "name": "平鎮分行",
        "shortcut": "元大平鎮"
    },
    {
        "parentCode": "806",
        "code": "8060105",
        "name": "草屯分行",
        "shortcut": "元大草屯"
    },
    {
        "parentCode": "806",
        "code": "8060116",
        "name": "永康分行",
        "shortcut": "元大永康"
    },
    {
        "parentCode": "806",
        "code": "8060150",
        "name": "景美分行",
        "shortcut": "元大景美"
    },
    {
        "parentCode": "806",
        "code": "8060161",
        "name": "新竹分行",
        "shortcut": "元大新竹"
    },
    {
        "parentCode": "806",
        "code": "8060172",
        "name": "鹿港分行",
        "shortcut": "元大鹿港"
    },
    {
        "parentCode": "806",
        "code": "8060183",
        "name": "斗信分行",
        "shortcut": "元大斗信"
    },
    {
        "parentCode": "806",
        "code": "8060194",
        "name": "嘉義分行",
        "shortcut": "元大嘉義"
    },
    {
        "parentCode": "806",
        "code": "8060219",
        "name": "南京東路分行",
        "shortcut": "元大南東"
    },
    {
        "parentCode": "806",
        "code": "8060220",
        "name": "永和分行",
        "shortcut": "元大永和"
    },
    {
        "parentCode": "806",
        "code": "8060231",
        "name": "復興分行",
        "shortcut": "元大復興"
    },
    {
        "parentCode": "806",
        "code": "8060242",
        "name": "大里分行",
        "shortcut": "元大大里"
    },
    {
        "parentCode": "806",
        "code": "8060253",
        "name": "博愛分行",
        "shortcut": "元大博愛"
    },
    {
        "parentCode": "806",
        "code": "8060264",
        "name": "苗栗分行",
        "shortcut": "元大苗栗"
    },
    {
        "parentCode": "806",
        "code": "8060275",
        "name": "崇德分行",
        "shortcut": "元大崇德"
    },
    {
        "parentCode": "806",
        "code": "8060286",
        "name": "北斗分行",
        "shortcut": "元大北斗"
    },
    {
        "parentCode": "806",
        "code": "8060297",
        "name": "佳里分行",
        "shortcut": "元大佳里"
    },
    {
        "parentCode": "806",
        "code": "8060301",
        "name": "大甲分行",
        "shortcut": "元大大甲"
    },
    {
        "parentCode": "806",
        "code": "8060312",
        "name": "虎尾分行",
        "shortcut": "元大虎尾"
    },
    {
        "parentCode": "806",
        "code": "8060334",
        "name": "士林分行",
        "shortcut": "元大士林"
    },
    {
        "parentCode": "806",
        "code": "8060345",
        "name": "南崁分行",
        "shortcut": "元大南崁"
    },
    {
        "parentCode": "806",
        "code": "8060367",
        "name": "古亭分行",
        "shortcut": "元大古亭"
    },
    {
        "parentCode": "806",
        "code": "8060378",
        "name": "新莊分行",
        "shortcut": "元大新莊"
    },
    {
        "parentCode": "806",
        "code": "8060389",
        "name": "中壢分行",
        "shortcut": "元大中壢"
    },
    {
        "parentCode": "806",
        "code": "8060404",
        "name": "三民分行",
        "shortcut": "元大三民"
    },
    {
        "parentCode": "806",
        "code": "8060415",
        "name": "城中分行",
        "shortcut": "元大城中"
    },
    {
        "parentCode": "806",
        "code": "8060426",
        "name": "屏東分行",
        "shortcut": "元大屏東"
    },
    {
        "parentCode": "806",
        "code": "8060448",
        "name": "斗南分行",
        "shortcut": "元大斗南"
    },
    {
        "parentCode": "806",
        "code": "8060482",
        "name": "板橋分行",
        "shortcut": "元大板橋"
    },
    {
        "parentCode": "806",
        "code": "8060493",
        "name": "桃園分行",
        "shortcut": "元大桃園"
    },
    {
        "parentCode": "806",
        "code": "8060518",
        "name": "鳳山分行",
        "shortcut": "元大鳳山"
    },
    {
        "parentCode": "806",
        "code": "8060529",
        "name": "東信分行",
        "shortcut": "元大東信"
    },
    {
        "parentCode": "806",
        "code": "8060585",
        "name": "內湖分行",
        "shortcut": "元大內湖"
    },
    {
        "parentCode": "806",
        "code": "8060596",
        "name": "羅東分行",
        "shortcut": "元大羅東"
    },
    {
        "parentCode": "806",
        "code": "8060600",
        "name": "金門分行",
        "shortcut": "元大金門"
    },
    {
        "parentCode": "806",
        "code": "8060611",
        "name": "台南分行",
        "shortcut": "元大台南"
    },
    {
        "parentCode": "806",
        "code": "8060622",
        "name": "林口分行",
        "shortcut": "元大林口"
    },
    {
        "parentCode": "806",
        "code": "8060633",
        "name": "竹北分行",
        "shortcut": "元大竹北"
    },
    {
        "parentCode": "806",
        "code": "8060688",
        "name": "府城分行",
        "shortcut": "元大府城"
    },
    {
        "parentCode": "806",
        "code": "8060699",
        "name": "府東分行",
        "shortcut": "元大府東"
    },
    {
        "parentCode": "806",
        "code": "8060806",
        "name": "開元分行",
        "shortcut": "元大開元"
    },
    {
        "parentCode": "806",
        "code": "8060828",
        "name": "安和分行",
        "shortcut": "元大安和"
    },
    {
        "parentCode": "806",
        "code": "8060839",
        "name": "中和分行",
        "shortcut": "元大中和"
    },
    {
        "parentCode": "806",
        "code": "8060840",
        "name": "松江分行",
        "shortcut": "元大松江"
    },
    {
        "parentCode": "806",
        "code": "8060851",
        "name": "竹科分行",
        "shortcut": "元大竹科"
    },
    {
        "parentCode": "806",
        "code": "8060862",
        "name": "天母分行",
        "shortcut": "元大天母"
    },
    {
        "parentCode": "806",
        "code": "8060873",
        "name": "蘆洲分行",
        "shortcut": "元大蘆洲"
    },
    {
        "parentCode": "806",
        "code": "8060884",
        "name": "信義分行",
        "shortcut": "元大信義"
    },
    {
        "parentCode": "806",
        "code": "8060895",
        "name": "中山北路分行",
        "shortcut": "元大中山北"
    },
    {
        "parentCode": "806",
        "code": "8060909",
        "name": "民生分行",
        "shortcut": "元大民生"
    },
    {
        "parentCode": "806",
        "code": "8060910",
        "name": "新店分行",
        "shortcut": "元大新店"
    },
    {
        "parentCode": "806",
        "code": "8060932",
        "name": "忠孝分行",
        "shortcut": "元大忠孝"
    },
    {
        "parentCode": "806",
        "code": "8060943",
        "name": "桃興分行",
        "shortcut": "元大桃興"
    },
    {
        "parentCode": "806",
        "code": "8060954",
        "name": "中港分行",
        "shortcut": "元大中港"
    },
    {
        "parentCode": "806",
        "code": "8060965",
        "name": "花蓮分行",
        "shortcut": "元大花蓮"
    },
    {
        "parentCode": "806",
        "code": "8060976",
        "name": "承德分行",
        "shortcut": "元大承德"
    },
    {
        "parentCode": "806",
        "code": "8060987",
        "name": "太平分行",
        "shortcut": "元大太平"
    },
    {
        "parentCode": "806",
        "code": "8060998",
        "name": "營業部",
        "shortcut": "元大營業部"
    },
    {
        "parentCode": "806",
        "code": "8061009",
        "name": "土城分行",
        "shortcut": "元大土城"
    },
    {
        "parentCode": "806",
        "code": "8061010",
        "name": "館前分行",
        "shortcut": "元大館前"
    },
    {
        "parentCode": "806",
        "code": "8061021",
        "name": "大同分行",
        "shortcut": "元大大同"
    },
    {
        "parentCode": "806",
        "code": "8061032",
        "name": "松山分行",
        "shortcut": "元大松山"
    },
    {
        "parentCode": "806",
        "code": "8061043",
        "name": "大安分行",
        "shortcut": "元大大安"
    },
    {
        "parentCode": "806",
        "code": "8061076",
        "name": "北三重分行",
        "shortcut": "元大北三重"
    },
    {
        "parentCode": "806",
        "code": "8061087",
        "name": "上新莊分行",
        "shortcut": "元大上新莊"
    },
    {
        "parentCode": "806",
        "code": "8061102",
        "name": "高雄分行",
        "shortcut": "元大高雄"
    },
    {
        "parentCode": "806",
        "code": "8061191",
        "name": "大統分行",
        "shortcut": "元大大統"
    },
    {
        "parentCode": "806",
        "code": "8061205",
        "name": "雙和分行",
        "shortcut": "元大雙和"
    },
    {
        "parentCode": "806",
        "code": "8061216",
        "name": "北投分行",
        "shortcut": "元大北投"
    },
    {
        "parentCode": "806",
        "code": "8061227",
        "name": "延平分行",
        "shortcut": "元大延平"
    },
    {
        "parentCode": "806",
        "code": "8061238",
        "name": "文德分行",
        "shortcut": "元大文德"
    },
    {
        "parentCode": "806",
        "code": "8061250",
        "name": "左營分行",
        "shortcut": "元大左營"
    },
    {
        "parentCode": "806",
        "code": "8061261",
        "name": "埔墘分行",
        "shortcut": "元大埔墘"
    },
    {
        "parentCode": "806",
        "code": "8061272",
        "name": "公館分行",
        "shortcut": "元大公館"
    },
    {
        "parentCode": "806",
        "code": "8061283",
        "name": "新店中正分行",
        "shortcut": "元大新店中"
    },
    {
        "parentCode": "806",
        "code": "8061294",
        "name": "和平分行",
        "shortcut": "元大和平"
    },
    {
        "parentCode": "806",
        "code": "8061308",
        "name": "西門分行",
        "shortcut": "元大西門"
    },
    {
        "parentCode": "806",
        "code": "8061319",
        "name": "樹林分行",
        "shortcut": "元大樹林"
    },
    {
        "parentCode": "806",
        "code": "8061320",
        "name": "光復分行",
        "shortcut": "元大光復"
    },
    {
        "parentCode": "806",
        "code": "8061331",
        "name": "南港分行",
        "shortcut": "元大南港"
    },
    {
        "parentCode": "807",
        "code": "8070014",
        "name": "世貿分行",
        "shortcut": "永豐世貿"
    },
    {
        "parentCode": "807",
        "code": "8070025",
        "name": "敦北分行",
        "shortcut": "永豐敦北"
    },
    {
        "parentCode": "807",
        "code": "8070036",
        "name": "台中分行",
        "shortcut": "永豐台中"
    },
    {
        "parentCode": "807",
        "code": "8070047",
        "name": "高雄分行",
        "shortcut": "永豐高雄"
    },
    {
        "parentCode": "807",
        "code": "8070058",
        "name": "忠孝分行",
        "shortcut": "永豐忠孝"
    },
    {
        "parentCode": "807",
        "code": "8070069",
        "name": "西松分行",
        "shortcut": "永豐西松"
    },
    {
        "parentCode": "807",
        "code": "8070070",
        "name": "板新分行",
        "shortcut": "永豐板新"
    },
    {
        "parentCode": "807",
        "code": "8070092",
        "name": "敦南分行",
        "shortcut": "永豐敦南"
    },
    {
        "parentCode": "807",
        "code": "8070106",
        "name": "新泰分行",
        "shortcut": "永豐新泰"
    },
    {
        "parentCode": "807",
        "code": "8070117",
        "name": "桃園分行",
        "shortcut": "永豐桃園"
    },
    {
        "parentCode": "807",
        "code": "8070128",
        "name": "北台中分行",
        "shortcut": "永豐北台中"
    },
    {
        "parentCode": "807",
        "code": "8070139",
        "name": "台南分行",
        "shortcut": "永豐台南"
    },
    {
        "parentCode": "807",
        "code": "8070151",
        "name": "松江分行",
        "shortcut": "永豐松江"
    },
    {
        "parentCode": "807",
        "code": "8070162",
        "name": "嘉義分行",
        "shortcut": "永豐嘉義"
    },
    {
        "parentCode": "807",
        "code": "8070173",
        "name": "三民分行",
        "shortcut": "永豐三民"
    },
    {
        "parentCode": "807",
        "code": "8070184",
        "name": "新竹分行",
        "shortcut": "永豐新竹"
    },
    {
        "parentCode": "807",
        "code": "8070195",
        "name": "三重分行",
        "shortcut": "永豐三重"
    },
    {
        "parentCode": "807",
        "code": "8070210",
        "name": "台北分行",
        "shortcut": "永豐台北"
    },
    {
        "parentCode": "807",
        "code": "8070221",
        "name": "彰化分行",
        "shortcut": "永豐彰化"
    },
    {
        "parentCode": "807",
        "code": "8070232",
        "name": "岡山分行",
        "shortcut": "永豐岡山"
    },
    {
        "parentCode": "807",
        "code": "8070243",
        "name": "中壢分行",
        "shortcut": "永豐中壢"
    },
    {
        "parentCode": "807",
        "code": "8070254",
        "name": "鳳山分行",
        "shortcut": "永豐鳳山"
    },
    {
        "parentCode": "807",
        "code": "8070265",
        "name": "中正簡易型分行",
        "shortcut": "永豐中正簡"
    },
    {
        "parentCode": "807",
        "code": "8070276",
        "name": "北新分行",
        "shortcut": "永豐北新"
    },
    {
        "parentCode": "807",
        "code": "8070287",
        "name": "屏東分行",
        "shortcut": "永豐屏東"
    },
    {
        "parentCode": "807",
        "code": "8070298",
        "name": "北桃園分行",
        "shortcut": "永豐北桃園"
    },
    {
        "parentCode": "807",
        "code": "8070302",
        "name": "學府分行",
        "shortcut": "永豐學府"
    },
    {
        "parentCode": "807",
        "code": "8070313",
        "name": "南高雄分行",
        "shortcut": "永豐南高雄"
    },
    {
        "parentCode": "807",
        "code": "8070324",
        "name": "光華分行",
        "shortcut": "永豐光華"
    },
    {
        "parentCode": "807",
        "code": "8070335",
        "name": "東門分行",
        "shortcut": "永豐東門"
    },
    {
        "parentCode": "807",
        "code": "8070346",
        "name": "天母分行",
        "shortcut": "永豐天母"
    },
    {
        "parentCode": "807",
        "code": "8070357",
        "name": "東板橋分行",
        "shortcut": "永豐東板橋"
    },
    {
        "parentCode": "807",
        "code": "8070368",
        "name": "東台南分行",
        "shortcut": "永豐東台南"
    },
    {
        "parentCode": "807",
        "code": "8070379",
        "name": "竹南分行",
        "shortcut": "永豐竹南"
    },
    {
        "parentCode": "807",
        "code": "8070380",
        "name": "南台中分行",
        "shortcut": "永豐南台中"
    },
    {
        "parentCode": "807",
        "code": "8070391",
        "name": "竹北自強簡易型分行",
        "shortcut": "永豐竹自強"
    },
    {
        "parentCode": "807",
        "code": "8070405",
        "name": "永康分行",
        "shortcut": "永豐永康"
    },
    {
        "parentCode": "807",
        "code": "8070416",
        "name": "中科分行",
        "shortcut": "永豐中科"
    },
    {
        "parentCode": "807",
        "code": "8070427",
        "name": "北高雄分行",
        "shortcut": "永豐北高雄"
    },
    {
        "parentCode": "807",
        "code": "8070438",
        "name": "金門分行",
        "shortcut": "永豐金門"
    },
    {
        "parentCode": "807",
        "code": "8070449",
        "name": "興大簡易型分行",
        "shortcut": "永豐興大簡"
    },
    {
        "parentCode": "807",
        "code": "8070450",
        "name": "中崙分行",
        "shortcut": "永豐中崙"
    },
    {
        "parentCode": "807",
        "code": "8070461",
        "name": "西湖分行",
        "shortcut": "永豐西湖"
    },
    {
        "parentCode": "807",
        "code": "8070472",
        "name": "豐原分行",
        "shortcut": "永豐豐原"
    },
    {
        "parentCode": "807",
        "code": "8070483",
        "name": "大里分行",
        "shortcut": "永豐大里"
    },
    {
        "parentCode": "807",
        "code": "8071011",
        "name": "忠孝東路分行",
        "shortcut": "永豐忠孝東"
    },
    {
        "parentCode": "807",
        "code": "8071033",
        "name": "建成分行",
        "shortcut": "永豐建成"
    },
    {
        "parentCode": "807",
        "code": "8071044",
        "name": "西門簡易型分行",
        "shortcut": "永豐西門簡"
    },
    {
        "parentCode": "807",
        "code": "8071055",
        "name": "萬華分行",
        "shortcut": "永豐萬華"
    },
    {
        "parentCode": "807",
        "code": "8071066",
        "name": "興隆分行",
        "shortcut": "永豐興隆"
    },
    {
        "parentCode": "807",
        "code": "8071077",
        "name": "南門分行",
        "shortcut": "永豐南門"
    },
    {
        "parentCode": "807",
        "code": "8071088",
        "name": "長安分行",
        "shortcut": "永豐長安"
    },
    {
        "parentCode": "807",
        "code": "8071099",
        "name": "延平分行",
        "shortcut": "永豐延平"
    },
    {
        "parentCode": "807",
        "code": "8071103",
        "name": "北投分行",
        "shortcut": "永豐北投"
    },
    {
        "parentCode": "807",
        "code": "8071114",
        "name": "松山分行",
        "shortcut": "永豐松山"
    },
    {
        "parentCode": "807",
        "code": "8071125",
        "name": "中山分行",
        "shortcut": "永豐中山"
    },
    {
        "parentCode": "807",
        "code": "8071136",
        "name": "士林分行",
        "shortcut": "永豐士林"
    },
    {
        "parentCode": "807",
        "code": "8071147",
        "name": "三和分行",
        "shortcut": "永豐三和"
    },
    {
        "parentCode": "807",
        "code": "8071158",
        "name": "雙園分行",
        "shortcut": "永豐雙園"
    },
    {
        "parentCode": "807",
        "code": "8071169",
        "name": "永和分行",
        "shortcut": "永豐永和"
    },
    {
        "parentCode": "807",
        "code": "8071170",
        "name": "板橋分行",
        "shortcut": "永豐板橋"
    },
    {
        "parentCode": "807",
        "code": "8071181",
        "name": "基隆分行",
        "shortcut": "永豐基隆"
    },
    {
        "parentCode": "807",
        "code": "8071192",
        "name": "羅東分行",
        "shortcut": "永豐羅東"
    },
    {
        "parentCode": "807",
        "code": "8071206",
        "name": "宜蘭分行",
        "shortcut": "永豐宜蘭"
    },
    {
        "parentCode": "807",
        "code": "8071217",
        "name": "營業部",
        "shortcut": "永豐營業部"
    },
    {
        "parentCode": "807",
        "code": "8071228",
        "name": "景美分行",
        "shortcut": "永豐景美"
    },
    {
        "parentCode": "807",
        "code": "8071239",
        "name": "新莊分行",
        "shortcut": "永豐新莊"
    },
    {
        "parentCode": "807",
        "code": "8071251",
        "name": "汐止分行",
        "shortcut": "永豐汐止"
    },
    {
        "parentCode": "807",
        "code": "8071262",
        "name": "城中分行",
        "shortcut": "永豐城中"
    },
    {
        "parentCode": "807",
        "code": "8071273",
        "name": "光復分行",
        "shortcut": "永豐光復"
    },
    {
        "parentCode": "807",
        "code": "8071284",
        "name": "正義分行",
        "shortcut": "永豐正義"
    },
    {
        "parentCode": "807",
        "code": "8071295",
        "name": "中和分行",
        "shortcut": "永豐中和"
    },
    {
        "parentCode": "807",
        "code": "8071310",
        "name": "士東分行",
        "shortcut": "永豐士東"
    },
    {
        "parentCode": "807",
        "code": "8071321",
        "name": "中港分行",
        "shortcut": "永豐中港"
    },
    {
        "parentCode": "807",
        "code": "8071332",
        "name": "蘆洲分行",
        "shortcut": "永豐蘆洲"
    },
    {
        "parentCode": "807",
        "code": "8071343",
        "name": "內湖分行",
        "shortcut": "永豐內湖"
    },
    {
        "parentCode": "807",
        "code": "8071354",
        "name": "社子分行",
        "shortcut": "永豐社子"
    },
    {
        "parentCode": "807",
        "code": "8071365",
        "name": "信義分行",
        "shortcut": "永豐信義"
    },
    {
        "parentCode": "807",
        "code": "8071376",
        "name": "永春分行",
        "shortcut": "永豐永春"
    },
    {
        "parentCode": "807",
        "code": "8071387",
        "name": "南三重分行",
        "shortcut": "永豐南三重"
    },
    {
        "parentCode": "807",
        "code": "8071398",
        "name": "積穗分行",
        "shortcut": "永豐積穗"
    },
    {
        "parentCode": "807",
        "code": "8071402",
        "name": "鶯歌分行",
        "shortcut": "永豐鶯歌"
    },
    {
        "parentCode": "807",
        "code": "8071413",
        "name": "新店分行",
        "shortcut": "永豐新店"
    },
    {
        "parentCode": "807",
        "code": "8071435",
        "name": "西盛分行",
        "shortcut": "永豐西盛"
    },
    {
        "parentCode": "807",
        "code": "8071446",
        "name": "板橋忠孝分行",
        "shortcut": "永豐板忠孝"
    },
    {
        "parentCode": "807",
        "code": "8071457",
        "name": "思源分行",
        "shortcut": "永豐思源"
    },
    {
        "parentCode": "807",
        "code": "8071468",
        "name": "土城分行",
        "shortcut": "永豐土城"
    },
    {
        "parentCode": "807",
        "code": "8071479",
        "name": "三興分行",
        "shortcut": "永豐三興"
    },
    {
        "parentCode": "807",
        "code": "8071480",
        "name": "龍江分行",
        "shortcut": "永豐龍江"
    },
    {
        "parentCode": "807",
        "code": "8071491",
        "name": "樹林分行",
        "shortcut": "永豐樹林"
    },
    {
        "parentCode": "807",
        "code": "8071505",
        "name": "五股分行",
        "shortcut": "永豐五股"
    },
    {
        "parentCode": "807",
        "code": "8071538",
        "name": "松德分行",
        "shortcut": "永豐松德"
    },
    {
        "parentCode": "807",
        "code": "8071549",
        "name": "東湖分行",
        "shortcut": "永豐東湖"
    },
    {
        "parentCode": "807",
        "code": "8071550",
        "name": "華江分行",
        "shortcut": "永豐華江"
    },
    {
        "parentCode": "807",
        "code": "8071561",
        "name": "北三重分行",
        "shortcut": "永豐北三重"
    },
    {
        "parentCode": "807",
        "code": "8071572",
        "name": "蘭雅分行",
        "shortcut": "永豐蘭雅"
    },
    {
        "parentCode": "807",
        "code": "8071583",
        "name": "德惠分行",
        "shortcut": "永豐德惠"
    },
    {
        "parentCode": "807",
        "code": "8071594",
        "name": "迴龍分行",
        "shortcut": "永豐迴龍"
    },
    {
        "parentCode": "807",
        "code": "8071608",
        "name": "仁愛分行",
        "shortcut": "永豐仁愛"
    },
    {
        "parentCode": "807",
        "code": "8071620",
        "name": "和平分行",
        "shortcut": "永豐和平"
    },
    {
        "parentCode": "807",
        "code": "8071642",
        "name": "重新分行",
        "shortcut": "永豐重新"
    },
    {
        "parentCode": "807",
        "code": "8071653",
        "name": "中興分行",
        "shortcut": "永豐中興"
    },
    {
        "parentCode": "807",
        "code": "8071664",
        "name": "海山分行",
        "shortcut": "永豐海山"
    },
    {
        "parentCode": "807",
        "code": "8071697",
        "name": "南蘆洲分行",
        "shortcut": "永豐南蘆洲"
    },
    {
        "parentCode": "807",
        "code": "8071701",
        "name": "江子翠分行",
        "shortcut": "永豐江子翠"
    },
    {
        "parentCode": "807",
        "code": "8071712",
        "name": "民安分行",
        "shortcut": "永豐民安"
    },
    {
        "parentCode": "807",
        "code": "8071723",
        "name": "苓雅分行",
        "shortcut": "永豐苓雅"
    },
    {
        "parentCode": "807",
        "code": "8071734",
        "name": "林口忠孝分行",
        "shortcut": "永豐林忠孝"
    },
    {
        "parentCode": "807",
        "code": "8071745",
        "name": "板橋民族分行",
        "shortcut": "永豐板民族"
    },
    {
        "parentCode": "807",
        "code": "8071756",
        "name": "泰山分行",
        "shortcut": "永豐泰山"
    },
    {
        "parentCode": "807",
        "code": "8071767",
        "name": "汀州分行",
        "shortcut": "永豐汀州"
    },
    {
        "parentCode": "807",
        "code": "8071778",
        "name": "南港分行",
        "shortcut": "永豐南港"
    },
    {
        "parentCode": "807",
        "code": "8071789",
        "name": "深坑分行",
        "shortcut": "永豐深坑"
    },
    {
        "parentCode": "807",
        "code": "8071790",
        "name": "南崁分行",
        "shortcut": "永豐南崁"
    },
    {
        "parentCode": "807",
        "code": "8071804",
        "name": "北台南分行",
        "shortcut": "永豐北台南"
    },
    {
        "parentCode": "807",
        "code": "8071815",
        "name": "西屯分行",
        "shortcut": "永豐西屯"
    },
    {
        "parentCode": "807",
        "code": "8071826",
        "name": "汐止樟樹分行",
        "shortcut": "永豐汐止樟"
    },
    {
        "parentCode": "807",
        "code": "8071837",
        "name": "濟南路分行",
        "shortcut": "永豐濟南路"
    },
    {
        "parentCode": "807",
        "code": "8071848",
        "name": "淡水簡易型分行",
        "shortcut": "永豐淡水簡"
    },
    {
        "parentCode": "807",
        "code": "8071859",
        "name": "南桃園分行",
        "shortcut": "永豐南桃園"
    },
    {
        "parentCode": "807",
        "code": "8071860",
        "name": "竹科分行",
        "shortcut": "永豐竹科"
    },
    {
        "parentCode": "807",
        "code": "8071871",
        "name": "員林分行",
        "shortcut": "永豐員林"
    },
    {
        "parentCode": "807",
        "code": "8071882",
        "name": "林口分行",
        "shortcut": "永豐林口"
    },
    {
        "parentCode": "807",
        "code": "8071893",
        "name": "內壢分行",
        "shortcut": "永豐內壢"
    },
    {
        "parentCode": "807",
        "code": "8071907",
        "name": "重慶北路分行",
        "shortcut": "永豐重慶北"
    },
    {
        "parentCode": "807",
        "code": "8071918",
        "name": "新湖分行",
        "shortcut": "永豐新湖"
    },
    {
        "parentCode": "807",
        "code": "8071929",
        "name": "大園分行",
        "shortcut": "永豐大園"
    },
    {
        "parentCode": "807",
        "code": "8071930",
        "name": "竹北光明分行",
        "shortcut": "永豐竹光明"
    },
    {
        "parentCode": "807",
        "code": "8071963",
        "name": "竹圍分行",
        "shortcut": "永豐竹圍"
    },
    {
        "parentCode": "808",
        "code": "8080015",
        "name": "營業部",
        "shortcut": "玉山營"
    },
    {
        "parentCode": "808",
        "code": "8080026",
        "name": "南京東路分行",
        "shortcut": "玉山南京東"
    },
    {
        "parentCode": "808",
        "code": "8080048",
        "name": "城東分行",
        "shortcut": "玉山城東"
    },
    {
        "parentCode": "808",
        "code": "8080059",
        "name": "新莊分行",
        "shortcut": "玉山新莊"
    },
    {
        "parentCode": "808",
        "code": "8080060",
        "name": "新竹分行",
        "shortcut": "玉山新竹"
    },
    {
        "parentCode": "808",
        "code": "8080071",
        "name": "嘉義分行",
        "shortcut": "玉山嘉義"
    },
    {
        "parentCode": "808",
        "code": "8080082",
        "name": "高雄分行",
        "shortcut": "玉山高雄"
    },
    {
        "parentCode": "808",
        "code": "8080118",
        "name": "基隆路分行",
        "shortcut": "玉山基隆路"
    },
    {
        "parentCode": "808",
        "code": "8080129",
        "name": "雙和分行",
        "shortcut": "玉山雙和"
    },
    {
        "parentCode": "808",
        "code": "8080130",
        "name": "中壢分行",
        "shortcut": "玉山中壢"
    },
    {
        "parentCode": "808",
        "code": "8080141",
        "name": "文心分行",
        "shortcut": "玉山文心"
    },
    {
        "parentCode": "808",
        "code": "8080152",
        "name": "台南分行",
        "shortcut": "玉山台南"
    },
    {
        "parentCode": "808",
        "code": "8080163",
        "name": "天母分行",
        "shortcut": "玉山天母"
    },
    {
        "parentCode": "808",
        "code": "8080174",
        "name": "埔墘分行",
        "shortcut": "玉山埔墘"
    },
    {
        "parentCode": "808",
        "code": "8080185",
        "name": "桃園分行",
        "shortcut": "玉山桃園"
    },
    {
        "parentCode": "808",
        "code": "8080196",
        "name": "前鎮分行",
        "shortcut": "玉山前鎮"
    },
    {
        "parentCode": "808",
        "code": "8080211",
        "name": "民生分行",
        "shortcut": "玉山民生"
    },
    {
        "parentCode": "808",
        "code": "8080222",
        "name": "大里分行",
        "shortcut": "玉山大里"
    },
    {
        "parentCode": "808",
        "code": "8080233",
        "name": "永康分行",
        "shortcut": "玉山永康"
    },
    {
        "parentCode": "808",
        "code": "8080244",
        "name": "鳳山分行",
        "shortcut": "玉山鳳山"
    },
    {
        "parentCode": "808",
        "code": "8080255",
        "name": "三重分行",
        "shortcut": "玉山三重"
    },
    {
        "parentCode": "808",
        "code": "8080266",
        "name": "土城分行",
        "shortcut": "玉山土城"
    },
    {
        "parentCode": "808",
        "code": "8080277",
        "name": "八德分行",
        "shortcut": "玉山八德"
    },
    {
        "parentCode": "808",
        "code": "8080288",
        "name": "大墩分行",
        "shortcut": "玉山大墩"
    },
    {
        "parentCode": "808",
        "code": "8080299",
        "name": "七賢分行",
        "shortcut": "玉山七賢"
    },
    {
        "parentCode": "808",
        "code": "8080303",
        "name": "復興分行",
        "shortcut": "玉山復興"
    },
    {
        "parentCode": "808",
        "code": "8080314",
        "name": "敦南分行",
        "shortcut": "玉山敦南"
    },
    {
        "parentCode": "808",
        "code": "8080325",
        "name": "重新分行",
        "shortcut": "玉山重新"
    },
    {
        "parentCode": "808",
        "code": "8080336",
        "name": "彰化分行",
        "shortcut": "玉山彰化"
    },
    {
        "parentCode": "808",
        "code": "8080347",
        "name": "北高雄分行",
        "shortcut": "玉山北高雄"
    },
    {
        "parentCode": "808",
        "code": "8080358",
        "name": "大雅分行",
        "shortcut": "玉山大雅"
    },
    {
        "parentCode": "808",
        "code": "8080369",
        "name": "楊梅分行",
        "shortcut": "玉山楊梅"
    },
    {
        "parentCode": "808",
        "code": "8080370",
        "name": "樹林分行",
        "shortcut": "玉山樹林"
    },
    {
        "parentCode": "808",
        "code": "8080381",
        "name": "豐原分行",
        "shortcut": "玉山豐原"
    },
    {
        "parentCode": "808",
        "code": "8080392",
        "name": "斗六分行",
        "shortcut": "玉山斗六"
    },
    {
        "parentCode": "808",
        "code": "8080406",
        "name": "長春分行",
        "shortcut": "玉山長春"
    },
    {
        "parentCode": "808",
        "code": "8080417",
        "name": "中山分行",
        "shortcut": "玉山中山"
    },
    {
        "parentCode": "808",
        "code": "8080428",
        "name": "佳里分行",
        "shortcut": "玉山佳里"
    },
    {
        "parentCode": "808",
        "code": "8080439",
        "name": "中和分行",
        "shortcut": "玉山中和"
    },
    {
        "parentCode": "808",
        "code": "8080440",
        "name": "竹南分行",
        "shortcut": "玉山竹南"
    },
    {
        "parentCode": "808",
        "code": "8080451",
        "name": "羅東分行",
        "shortcut": "玉山羅東"
    },
    {
        "parentCode": "808",
        "code": "8080462",
        "name": "內湖分行",
        "shortcut": "玉山內湖"
    },
    {
        "parentCode": "808",
        "code": "8080473",
        "name": "壢新分行",
        "shortcut": "玉山壢新"
    },
    {
        "parentCode": "808",
        "code": "8080484",
        "name": "板新分行",
        "shortcut": "玉山板新"
    },
    {
        "parentCode": "808",
        "code": "8080495",
        "name": "光華分行",
        "shortcut": "玉山光華"
    },
    {
        "parentCode": "808",
        "code": "8080509",
        "name": "東嘉義分行",
        "shortcut": "玉山東嘉義"
    },
    {
        "parentCode": "808",
        "code": "8080510",
        "name": "成功分行",
        "shortcut": "玉山成功"
    },
    {
        "parentCode": "808",
        "code": "8080521",
        "name": "永和分行",
        "shortcut": "玉山永和"
    },
    {
        "parentCode": "808",
        "code": "8080532",
        "name": "城中分行",
        "shortcut": "玉山城中"
    },
    {
        "parentCode": "808",
        "code": "8080543",
        "name": "五股分行",
        "shortcut": "玉山五股"
    },
    {
        "parentCode": "808",
        "code": "8080554",
        "name": "新店分行",
        "shortcut": "玉山新店"
    },
    {
        "parentCode": "808",
        "code": "8080565",
        "name": "松山分行",
        "shortcut": "玉山松山"
    },
    {
        "parentCode": "808",
        "code": "8080576",
        "name": "和平分行",
        "shortcut": "玉山和平"
    },
    {
        "parentCode": "808",
        "code": "8080587",
        "name": "新板特區分行",
        "shortcut": "玉山新板"
    },
    {
        "parentCode": "808",
        "code": "8080598",
        "name": "民權分行",
        "shortcut": "玉山民權"
    },
    {
        "parentCode": "808",
        "code": "8080602",
        "name": "泰山分行",
        "shortcut": "玉山泰山"
    },
    {
        "parentCode": "808",
        "code": "8080613",
        "name": "南崁分行",
        "shortcut": "玉山南崁"
    },
    {
        "parentCode": "808",
        "code": "8080624",
        "name": "大順分行",
        "shortcut": "玉山大順"
    },
    {
        "parentCode": "808",
        "code": "8080635",
        "name": "左營分行",
        "shortcut": "玉山左營"
    },
    {
        "parentCode": "808",
        "code": "8080646",
        "name": "楠梓分行",
        "shortcut": "玉山楠梓"
    },
    {
        "parentCode": "808",
        "code": "8080657",
        "name": "士林分行",
        "shortcut": "玉山士林"
    },
    {
        "parentCode": "808",
        "code": "8080668",
        "name": "苓雅分行",
        "shortcut": "玉山苓雅"
    },
    {
        "parentCode": "808",
        "code": "8080679",
        "name": "仁愛分行",
        "shortcut": "玉山仁愛"
    },
    {
        "parentCode": "808",
        "code": "8080680",
        "name": "南屯分行",
        "shortcut": "玉山南屯"
    },
    {
        "parentCode": "808",
        "code": "8080691",
        "name": "木柵分行",
        "shortcut": "玉山木柵"
    },
    {
        "parentCode": "808",
        "code": "8080705",
        "name": "大昌分行",
        "shortcut": "玉山大昌"
    },
    {
        "parentCode": "808",
        "code": "8080716",
        "name": "台東簡易型分行",
        "shortcut": "玉山台東簡"
    },
    {
        "parentCode": "808",
        "code": "8080727",
        "name": "澄清分行",
        "shortcut": "玉山澄清"
    },
    {
        "parentCode": "808",
        "code": "8080738",
        "name": "小港分行",
        "shortcut": "玉山小港"
    },
    {
        "parentCode": "808",
        "code": "8080749",
        "name": "北新莊分行",
        "shortcut": "玉山北新莊"
    },
    {
        "parentCode": "808",
        "code": "8080750",
        "name": "竹北分行",
        "shortcut": "玉山竹北"
    },
    {
        "parentCode": "808",
        "code": "8080761",
        "name": "東台南分行",
        "shortcut": "玉山東台南"
    },
    {
        "parentCode": "808",
        "code": "8080772",
        "name": "集賢分行",
        "shortcut": "玉山集賢"
    },
    {
        "parentCode": "808",
        "code": "8080783",
        "name": "基隆分行",
        "shortcut": "玉山基隆"
    },
    {
        "parentCode": "808",
        "code": "8080794",
        "name": "岡山分行",
        "shortcut": "玉山岡山"
    },
    {
        "parentCode": "808",
        "code": "8080808",
        "name": "連城分行",
        "shortcut": "玉山連城"
    },
    {
        "parentCode": "808",
        "code": "8080819",
        "name": "林園分行",
        "shortcut": "玉山林園"
    },
    {
        "parentCode": "808",
        "code": "8080820",
        "name": "蘆洲分行",
        "shortcut": "玉山蘆洲"
    },
    {
        "parentCode": "808",
        "code": "8080831",
        "name": "後庄分行",
        "shortcut": "玉山後庄"
    },
    {
        "parentCode": "808",
        "code": "8080842",
        "name": "南桃園分行",
        "shortcut": "玉山南桃園"
    },
    {
        "parentCode": "808",
        "code": "8080853",
        "name": "東三重分行",
        "shortcut": "玉山東三重"
    },
    {
        "parentCode": "808",
        "code": "8080864",
        "name": "北投分行",
        "shortcut": "玉山北投"
    },
    {
        "parentCode": "808",
        "code": "8080875",
        "name": "忠孝分行",
        "shortcut": "玉山忠孝"
    },
    {
        "parentCode": "808",
        "code": "8080886",
        "name": "林口分行",
        "shortcut": "玉山林口"
    },
    {
        "parentCode": "808",
        "code": "8080897",
        "name": "東湖分行",
        "shortcut": "玉山東湖"
    },
    {
        "parentCode": "808",
        "code": "8080901",
        "name": "北新分行",
        "shortcut": "玉山北新"
    },
    {
        "parentCode": "808",
        "code": "8080912",
        "name": "中崙分行",
        "shortcut": "玉山中崙"
    },
    {
        "parentCode": "808",
        "code": "8080923",
        "name": "建成分行",
        "shortcut": "玉山建成"
    },
    {
        "parentCode": "808",
        "code": "8080934",
        "name": "屏東分行",
        "shortcut": "玉山屏東"
    },
    {
        "parentCode": "808",
        "code": "8080945",
        "name": "二重分行",
        "shortcut": "玉山二重"
    },
    {
        "parentCode": "808",
        "code": "8080956",
        "name": "台北分行",
        "shortcut": "玉山台北"
    },
    {
        "parentCode": "808",
        "code": "8080967",
        "name": "大安分行",
        "shortcut": "玉山大安"
    },
    {
        "parentCode": "808",
        "code": "8080978",
        "name": "東港分行",
        "shortcut": "玉山東港"
    },
    {
        "parentCode": "808",
        "code": "8080989",
        "name": "古亭分行",
        "shortcut": "玉山古亭"
    },
    {
        "parentCode": "808",
        "code": "8080990",
        "name": "草屯分行",
        "shortcut": "玉山草屯"
    },
    {
        "parentCode": "808",
        "code": "8081001",
        "name": "新營分行",
        "shortcut": "玉山新營"
    },
    {
        "parentCode": "808",
        "code": "8081012",
        "name": "迴龍分行",
        "shortcut": "玉山迴龍"
    },
    {
        "parentCode": "808",
        "code": "8081023",
        "name": "花蓮分行",
        "shortcut": "玉山花蓮"
    },
    {
        "parentCode": "808",
        "code": "8081034",
        "name": "澎湖分行",
        "shortcut": "玉山澎湖"
    },
    {
        "parentCode": "808",
        "code": "8081045",
        "name": "三峽分行",
        "shortcut": "玉山三峽"
    },
    {
        "parentCode": "808",
        "code": "8081056",
        "name": "松江分行",
        "shortcut": "玉山松江"
    },
    {
        "parentCode": "808",
        "code": "8081067",
        "name": "金華分行",
        "shortcut": "玉山金華"
    },
    {
        "parentCode": "808",
        "code": "8081078",
        "name": "光復分行",
        "shortcut": "玉山光復"
    },
    {
        "parentCode": "808",
        "code": "8081089",
        "name": "新湖分行",
        "shortcut": "玉山新湖"
    },
    {
        "parentCode": "808",
        "code": "8081090",
        "name": "桃鶯分行",
        "shortcut": "玉山桃鶯"
    },
    {
        "parentCode": "808",
        "code": "8081104",
        "name": "員林分行",
        "shortcut": "玉山員林"
    },
    {
        "parentCode": "808",
        "code": "8081115",
        "name": "永安分行",
        "shortcut": "玉山永安"
    },
    {
        "parentCode": "808",
        "code": "8081126",
        "name": "新豐分行",
        "shortcut": "玉山新豐"
    },
    {
        "parentCode": "808",
        "code": "8081137",
        "name": "仁德分行",
        "shortcut": "玉山仁德"
    },
    {
        "parentCode": "808",
        "code": "8081148",
        "name": "泰和分行",
        "shortcut": "玉山泰和"
    },
    {
        "parentCode": "808",
        "code": "8081159",
        "name": "西屯分行",
        "shortcut": "玉山西屯"
    },
    {
        "parentCode": "808",
        "code": "8081160",
        "name": "南勢角分行",
        "shortcut": "玉山南勢角"
    },
    {
        "parentCode": "808",
        "code": "8081171",
        "name": "板橋分行",
        "shortcut": "玉山板橋"
    },
    {
        "parentCode": "808",
        "code": "8081182",
        "name": "南港分行",
        "shortcut": "玉山南港"
    },
    {
        "parentCode": "808",
        "code": "8081193",
        "name": "東門分行",
        "shortcut": "玉山東門"
    },
    {
        "parentCode": "808",
        "code": "8081207",
        "name": "南土城分行",
        "shortcut": "玉山南土城"
    },
    {
        "parentCode": "808",
        "code": "8081218",
        "name": "朴子分行",
        "shortcut": "玉山朴子"
    },
    {
        "parentCode": "808",
        "code": "8081229",
        "name": "信義分行",
        "shortcut": "玉山信義"
    },
    {
        "parentCode": "808",
        "code": "8081230",
        "name": "汐止分行",
        "shortcut": "玉山汐止"
    },
    {
        "parentCode": "808",
        "code": "8081241",
        "name": "新樹分行",
        "shortcut": "玉山新樹"
    },
    {
        "parentCode": "808",
        "code": "8081252",
        "name": "中工分行",
        "shortcut": "玉山中工"
    },
    {
        "parentCode": "808",
        "code": "8081263",
        "name": "龍井分行",
        "shortcut": "玉山龍井"
    },
    {
        "parentCode": "808",
        "code": "8081274",
        "name": "鹽行分行",
        "shortcut": "玉山鹽行"
    },
    {
        "parentCode": "808",
        "code": "8081285",
        "name": "頭份分行",
        "shortcut": "玉山頭份"
    },
    {
        "parentCode": "808",
        "code": "8081296",
        "name": "後龍分行",
        "shortcut": "玉山後龍"
    },
    {
        "parentCode": "808",
        "code": "8081300",
        "name": "五權分行",
        "shortcut": "玉山五權"
    },
    {
        "parentCode": "808",
        "code": "8081311",
        "name": "南永康分行",
        "shortcut": "玉山南永康"
    },
    {
        "parentCode": "808",
        "code": "8081322",
        "name": "藝文分行",
        "shortcut": "玉山藝文"
    },
    {
        "parentCode": "808",
        "code": "8081333",
        "name": "中原分行",
        "shortcut": "玉山中原"
    },
    {
        "parentCode": "808",
        "code": "8081344",
        "name": "竹科分行",
        "shortcut": "玉山竹科"
    },
    {
        "parentCode": "808",
        "code": "8081355",
        "name": "太平分行",
        "shortcut": "玉山太平"
    },
    {
        "parentCode": "808",
        "code": "8081366",
        "name": "台中分行",
        "shortcut": "玉山台中"
    },
    {
        "parentCode": "808",
        "code": "8081377",
        "name": "六家分行",
        "shortcut": "玉山六家"
    },
    {
        "parentCode": "808",
        "code": "8081388",
        "name": "北屯分行",
        "shortcut": "玉山北屯"
    },
    {
        "parentCode": "808",
        "code": "8081399",
        "name": "烏日分行",
        "shortcut": "玉山烏日"
    },
    {
        "parentCode": "808",
        "code": "8081403",
        "name": "安南分行",
        "shortcut": "玉山安南"
    },
    {
        "parentCode": "809",
        "code": "8090016",
        "name": "建成分行",
        "shortcut": "凱基建成"
    },
    {
        "parentCode": "809",
        "code": "8090027",
        "name": "忠孝分行",
        "shortcut": "凱基忠孝"
    },
    {
        "parentCode": "809",
        "code": "8090038",
        "name": "三重分行",
        "shortcut": "凱基三重"
    },
    {
        "parentCode": "809",
        "code": "8090049",
        "name": "台中分行",
        "shortcut": "凱基台中"
    },
    {
        "parentCode": "809",
        "code": "8090050",
        "name": "台南分行",
        "shortcut": "凱基台南"
    },
    {
        "parentCode": "809",
        "code": "8090061",
        "name": "高雄分行",
        "shortcut": "凱基高雄"
    },
    {
        "parentCode": "809",
        "code": "8090072",
        "name": "城東分行",
        "shortcut": "凱基城東"
    },
    {
        "parentCode": "809",
        "code": "8090094",
        "name": "營業部",
        "shortcut": "凱基營業部"
    },
    {
        "parentCode": "809",
        "code": "8090108",
        "name": "板橋分行",
        "shortcut": "凱基板橋"
    },
    {
        "parentCode": "809",
        "code": "8090119",
        "name": "桃園分行",
        "shortcut": "凱基桃園"
    },
    {
        "parentCode": "809",
        "code": "8090120",
        "name": "中壢分行",
        "shortcut": "凱基中壢"
    },
    {
        "parentCode": "809",
        "code": "8090131",
        "name": "北高雄分行",
        "shortcut": "凱基北高雄"
    },
    {
        "parentCode": "809",
        "code": "8090153",
        "name": "新莊分行",
        "shortcut": "凱基新莊"
    },
    {
        "parentCode": "809",
        "code": "8090164",
        "name": "中正分行",
        "shortcut": "凱基中正"
    },
    {
        "parentCode": "809",
        "code": "8090175",
        "name": "員林分行",
        "shortcut": "凱基員林"
    },
    {
        "parentCode": "809",
        "code": "8090197",
        "name": "敦南分行",
        "shortcut": "凱基敦南"
    },
    {
        "parentCode": "809",
        "code": "8090201",
        "name": "松江分行",
        "shortcut": "凱基松江"
    },
    {
        "parentCode": "809",
        "code": "8090212",
        "name": "蘆洲分行",
        "shortcut": "凱基蘆洲"
    },
    {
        "parentCode": "809",
        "code": "8090223",
        "name": "鳳山分行",
        "shortcut": "凱基鳳山"
    },
    {
        "parentCode": "809",
        "code": "8090234",
        "name": "新竹分行",
        "shortcut": "凱基新竹"
    },
    {
        "parentCode": "809",
        "code": "8090245",
        "name": "松山分行",
        "shortcut": "凱基松山"
    },
    {
        "parentCode": "809",
        "code": "8090256",
        "name": "土城分行",
        "shortcut": "凱基土城"
    },
    {
        "parentCode": "809",
        "code": "8090267",
        "name": "中和分行",
        "shortcut": "凱基中和"
    },
    {
        "parentCode": "809",
        "code": "8090278",
        "name": "赤崁分行",
        "shortcut": "凱基赤崁"
    },
    {
        "parentCode": "809",
        "code": "8090289",
        "name": "基隆分行",
        "shortcut": "凱基基隆"
    },
    {
        "parentCode": "809",
        "code": "8090304",
        "name": "東門分行",
        "shortcut": "凱基東門"
    },
    {
        "parentCode": "809",
        "code": "8090315",
        "name": "北門分行",
        "shortcut": "凱基北門"
    },
    {
        "parentCode": "809",
        "code": "8090326",
        "name": "屏東分行",
        "shortcut": "凱基屏東"
    },
    {
        "parentCode": "809",
        "code": "8090337",
        "name": "林森分行",
        "shortcut": "凱基林森"
    },
    {
        "parentCode": "809",
        "code": "8090348",
        "name": "歸仁分行",
        "shortcut": "凱基歸仁"
    },
    {
        "parentCode": "809",
        "code": "8090359",
        "name": "海東分行",
        "shortcut": "凱基海東"
    },
    {
        "parentCode": "809",
        "code": "8090371",
        "name": "永康分行",
        "shortcut": "凱基永康"
    },
    {
        "parentCode": "809",
        "code": "8090382",
        "name": "天母分行",
        "shortcut": "凱基天母"
    },
    {
        "parentCode": "809",
        "code": "8090393",
        "name": "新店分行",
        "shortcut": "凱基新店"
    },
    {
        "parentCode": "809",
        "code": "8090407",
        "name": "大安分行",
        "shortcut": "凱基大安"
    },
    {
        "parentCode": "809",
        "code": "8090429",
        "name": "苗栗分行",
        "shortcut": "凱基苗栗"
    },
    {
        "parentCode": "809",
        "code": "8090430",
        "name": "內湖分行",
        "shortcut": "凱基內湖"
    },
    {
        "parentCode": "809",
        "code": "8090452",
        "name": "花蓮分行",
        "shortcut": "凱基花蓮"
    },
    {
        "parentCode": "809",
        "code": "8090463",
        "name": "繼光分行",
        "shortcut": "凱基繼光"
    },
    {
        "parentCode": "809",
        "code": "8090474",
        "name": "彰化分行",
        "shortcut": "凱基彰化"
    },
    {
        "parentCode": "809",
        "code": "8090485",
        "name": "豐原分行",
        "shortcut": "凱基豐原"
    },
    {
        "parentCode": "809",
        "code": "8090511",
        "name": "大里分行",
        "shortcut": "凱基大里"
    },
    {
        "parentCode": "809",
        "code": "8090522",
        "name": "嘉義分行",
        "shortcut": "凱基嘉義"
    },
    {
        "parentCode": "809",
        "code": "8090577",
        "name": "風城分行",
        "shortcut": "凱基風城"
    },
    {
        "parentCode": "809",
        "code": "8090588",
        "name": "南大分行",
        "shortcut": "凱基南大"
    },
    {
        "parentCode": "809",
        "code": "8090599",
        "name": "竹科分行",
        "shortcut": "凱基竹科"
    },
    {
        "parentCode": "809",
        "code": "8090625",
        "name": "台東分行",
        "shortcut": "凱基台東"
    },
    {
        "parentCode": "809",
        "code": "8090669",
        "name": "羅東分行",
        "shortcut": "凱基羅東"
    },
    {
        "parentCode": "809",
        "code": "8090670",
        "name": "大直分行",
        "shortcut": "凱基大直"
    },
    {
        "parentCode": "809",
        "code": "8090681",
        "name": "高美館分行",
        "shortcut": "凱基高美館"
    },
    {
        "parentCode": "809",
        "code": "8090692",
        "name": "市政分行",
        "shortcut": "凱基市政"
    },
    {
        "parentCode": "809",
        "code": "8090706",
        "name": "藝文分行",
        "shortcut": "凱基藝文"
    },
    {
        "parentCode": "810",
        "code": "8100010",
        "name": "台中分行",
        "shortcut": "星展台中"
    },
    {
        "parentCode": "810",
        "code": "8100021",
        "name": "忠孝分行",
        "shortcut": "星展忠孝"
    },
    {
        "parentCode": "810",
        "code": "8100032",
        "name": "敦北分行",
        "shortcut": "星展敦北"
    },
    {
        "parentCode": "810",
        "code": "8100043",
        "name": "高雄分行",
        "shortcut": "星展高雄"
    },
    {
        "parentCode": "810",
        "code": "8100054",
        "name": "桃園分行",
        "shortcut": "星展桃園"
    },
    {
        "parentCode": "810",
        "code": "8100065",
        "name": "豐原分行",
        "shortcut": "星展豐原"
    },
    {
        "parentCode": "810",
        "code": "8100076",
        "name": "林口分行",
        "shortcut": "星展林口"
    },
    {
        "parentCode": "810",
        "code": "8100098",
        "name": "台南分行",
        "shortcut": "星展台南"
    },
    {
        "parentCode": "810",
        "code": "8100102",
        "name": "大安分行",
        "shortcut": "星展大安"
    },
    {
        "parentCode": "810",
        "code": "8100113",
        "name": "華山分行",
        "shortcut": "星展華山"
    },
    {
        "parentCode": "810",
        "code": "8100124",
        "name": "太平分行",
        "shortcut": "星展太平"
    },
    {
        "parentCode": "810",
        "code": "8100135",
        "name": "中清分行",
        "shortcut": "星展中清"
    },
    {
        "parentCode": "810",
        "code": "8100157",
        "name": "三重分行",
        "shortcut": "星展三重"
    },
    {
        "parentCode": "810",
        "code": "8100168",
        "name": "蘆洲分行",
        "shortcut": "星展蘆洲"
    },
    {
        "parentCode": "810",
        "code": "8100179",
        "name": "信義分行",
        "shortcut": "星展信義"
    },
    {
        "parentCode": "810",
        "code": "8100180",
        "name": "中港分行",
        "shortcut": "星展中港"
    },
    {
        "parentCode": "810",
        "code": "8100205",
        "name": "左營分行",
        "shortcut": "星展左營"
    },
    {
        "parentCode": "810",
        "code": "8100216",
        "name": "汐止分行",
        "shortcut": "星展汐止"
    },
    {
        "parentCode": "810",
        "code": "8100227",
        "name": "天母分行",
        "shortcut": "星展天母"
    },
    {
        "parentCode": "810",
        "code": "8100238",
        "name": "八德分行",
        "shortcut": "星展八德"
    },
    {
        "parentCode": "810",
        "code": "8100249",
        "name": "中山分行",
        "shortcut": "星展中山"
    },
    {
        "parentCode": "810",
        "code": "8100250",
        "name": "新莊分行",
        "shortcut": "星展新莊"
    },
    {
        "parentCode": "810",
        "code": "8100261",
        "name": "板橋分行",
        "shortcut": "星展板橋"
    },
    {
        "parentCode": "810",
        "code": "8100272",
        "name": "中和分行",
        "shortcut": "星展中和"
    },
    {
        "parentCode": "810",
        "code": "8100283",
        "name": "楠梓分行",
        "shortcut": "星展楠梓"
    },
    {
        "parentCode": "810",
        "code": "8100294",
        "name": "民權分行",
        "shortcut": "星展民權"
    },
    {
        "parentCode": "810",
        "code": "8100308",
        "name": "苓雅分行",
        "shortcut": "星展苓雅"
    },
    {
        "parentCode": "810",
        "code": "8100319",
        "name": "東台南分行",
        "shortcut": "星展東台南"
    },
    {
        "parentCode": "810",
        "code": "8100320",
        "name": "新店分行",
        "shortcut": "星展新店"
    },
    {
        "parentCode": "810",
        "code": "8100331",
        "name": "前鎮分行",
        "shortcut": "星展前鎮"
    },
    {
        "parentCode": "810",
        "code": "8100342",
        "name": "東新竹分行",
        "shortcut": "星展東新竹"
    },
    {
        "parentCode": "810",
        "code": "8100353",
        "name": "鼎強分行",
        "shortcut": "星展鼎強"
    },
    {
        "parentCode": "810",
        "code": "8100364",
        "name": "南京東路分行",
        "shortcut": "星展南東"
    },
    {
        "parentCode": "810",
        "code": "8100375",
        "name": "莒光分行",
        "shortcut": "星展莒光"
    },
    {
        "parentCode": "810",
        "code": "8100386",
        "name": "中壢分行",
        "shortcut": "星展中壢"
    },
    {
        "parentCode": "810",
        "code": "8100397",
        "name": "新竹分行",
        "shortcut": "星展新竹"
    },
    {
        "parentCode": "810",
        "code": "8100401",
        "name": "大同分行",
        "shortcut": "星展大同"
    },
    {
        "parentCode": "810",
        "code": "8100412",
        "name": "敦南分行",
        "shortcut": "星展敦南"
    },
    {
        "parentCode": "810",
        "code": "8100423",
        "name": "內湖分行",
        "shortcut": "星展內湖"
    },
    {
        "parentCode": "812",
        "code": "8120012",
        "name": "營業部",
        "shortcut": "台新營業部"
    },
    {
        "parentCode": "812",
        "code": "8120023",
        "name": "敦南分行",
        "shortcut": "台新敦南"
    },
    {
        "parentCode": "812",
        "code": "8120045",
        "name": "新生分行",
        "shortcut": "台新新生"
    },
    {
        "parentCode": "812",
        "code": "8120056",
        "name": "新莊分行",
        "shortcut": "台新新莊"
    },
    {
        "parentCode": "812",
        "code": "8120067",
        "name": "桃園分行",
        "shortcut": "台新桃園"
    },
    {
        "parentCode": "812",
        "code": "8120078",
        "name": "台中分行",
        "shortcut": "台新台中"
    },
    {
        "parentCode": "812",
        "code": "8120089",
        "name": "板橋分行",
        "shortcut": "台新板橋"
    },
    {
        "parentCode": "812",
        "code": "8120090",
        "name": "高雄分行",
        "shortcut": "台新高雄"
    },
    {
        "parentCode": "812",
        "code": "8120104",
        "name": "台南分行",
        "shortcut": "台新台南"
    },
    {
        "parentCode": "812",
        "code": "8120115",
        "name": "南京東路分行",
        "shortcut": "台新南東"
    },
    {
        "parentCode": "812",
        "code": "8120126",
        "name": "中壢分行",
        "shortcut": "台新中壢"
    },
    {
        "parentCode": "812",
        "code": "8120137",
        "name": "信託部",
        "shortcut": "台新信託部"
    },
    {
        "parentCode": "812",
        "code": "8120159",
        "name": "苓雅分行",
        "shortcut": "台新苓雅"
    },
    {
        "parentCode": "812",
        "code": "8120160",
        "name": "蘆洲分行",
        "shortcut": "台新蘆洲"
    },
    {
        "parentCode": "812",
        "code": "8120171",
        "name": "天母分行",
        "shortcut": "台新天母"
    },
    {
        "parentCode": "812",
        "code": "8120182",
        "name": "大里分行",
        "shortcut": "台新大里"
    },
    {
        "parentCode": "812",
        "code": "8120207",
        "name": "中和分行",
        "shortcut": "台新中和"
    },
    {
        "parentCode": "812",
        "code": "8120218",
        "name": "嘉義分行",
        "shortcut": "台新嘉義"
    },
    {
        "parentCode": "812",
        "code": "8120229",
        "name": "豐原分行",
        "shortcut": "台新豐原"
    },
    {
        "parentCode": "812",
        "code": "8120230",
        "name": "花蓮分行",
        "shortcut": "台新花蓮"
    },
    {
        "parentCode": "812",
        "code": "8120241",
        "name": "新竹分行",
        "shortcut": "台新新竹"
    },
    {
        "parentCode": "812",
        "code": "8120252",
        "name": "七賢分行",
        "shortcut": "台新七賢"
    },
    {
        "parentCode": "812",
        "code": "8120263",
        "name": "信義分行",
        "shortcut": "台新信義"
    },
    {
        "parentCode": "812",
        "code": "8120274",
        "name": "北台中分行",
        "shortcut": "台新北台中"
    },
    {
        "parentCode": "812",
        "code": "8120285",
        "name": "三重分行",
        "shortcut": "台新三重"
    },
    {
        "parentCode": "812",
        "code": "8120296",
        "name": "永福分行",
        "shortcut": "台新永福"
    },
    {
        "parentCode": "812",
        "code": "8120300",
        "name": "屏東分行",
        "shortcut": "台新屏東"
    },
    {
        "parentCode": "812",
        "code": "8120311",
        "name": "大安分行",
        "shortcut": "台新大安"
    },
    {
        "parentCode": "812",
        "code": "8120322",
        "name": "龍潭分行",
        "shortcut": "台新龍潭"
    },
    {
        "parentCode": "812",
        "code": "8120333",
        "name": "崇德分行",
        "shortcut": "台新崇德"
    },
    {
        "parentCode": "812",
        "code": "8120344",
        "name": "金華分行",
        "shortcut": "台新金華"
    },
    {
        "parentCode": "812",
        "code": "8120355",
        "name": "後甲分行",
        "shortcut": "台新後甲"
    },
    {
        "parentCode": "812",
        "code": "8120366",
        "name": "海佃分行",
        "shortcut": "台新海佃"
    },
    {
        "parentCode": "812",
        "code": "8120377",
        "name": "彰化分行",
        "shortcut": "台新彰化"
    },
    {
        "parentCode": "812",
        "code": "8120388",
        "name": "五甲分行",
        "shortcut": "台新五甲"
    },
    {
        "parentCode": "812",
        "code": "8120399",
        "name": "鳳山分行",
        "shortcut": "台新鳳山"
    },
    {
        "parentCode": "812",
        "code": "8120403",
        "name": "古亭分行",
        "shortcut": "台新古亭"
    },
    {
        "parentCode": "812",
        "code": "8120414",
        "name": "建橋分行",
        "shortcut": "台新建橋"
    },
    {
        "parentCode": "812",
        "code": "8120436",
        "name": "民生分行",
        "shortcut": "台新民生"
    },
    {
        "parentCode": "812",
        "code": "8120447",
        "name": "太平分行",
        "shortcut": "台新太平"
    },
    {
        "parentCode": "812",
        "code": "8120458",
        "name": "和平分行",
        "shortcut": "台新和平"
    },
    {
        "parentCode": "812",
        "code": "8120469",
        "name": "北高雄分行",
        "shortcut": "台新北高雄"
    },
    {
        "parentCode": "812",
        "code": "8120470",
        "name": "南新莊分行",
        "shortcut": "台新南新莊"
    },
    {
        "parentCode": "812",
        "code": "8120481",
        "name": "內湖分行",
        "shortcut": "台新內湖"
    },
    {
        "parentCode": "812",
        "code": "8120492",
        "name": "永和分行",
        "shortcut": "台新永和"
    },
    {
        "parentCode": "812",
        "code": "8120517",
        "name": "逢甲分行",
        "shortcut": "台新逢甲"
    },
    {
        "parentCode": "812",
        "code": "8120528",
        "name": "竹科分行",
        "shortcut": "台新竹科"
    },
    {
        "parentCode": "812",
        "code": "8120540",
        "name": "南屯分行",
        "shortcut": "台新南屯"
    },
    {
        "parentCode": "812",
        "code": "8120551",
        "name": "大直分行",
        "shortcut": "台新大直"
    },
    {
        "parentCode": "812",
        "code": "8120562",
        "name": "南門分行",
        "shortcut": "台新南門"
    },
    {
        "parentCode": "812",
        "code": "8120610",
        "name": "西門分行",
        "shortcut": "台新西門"
    },
    {
        "parentCode": "812",
        "code": "8120621",
        "name": "敦北分行",
        "shortcut": "台新敦北"
    },
    {
        "parentCode": "812",
        "code": "8120632",
        "name": "忠孝分行",
        "shortcut": "台新忠孝"
    },
    {
        "parentCode": "812",
        "code": "8120643",
        "name": "復興分行",
        "shortcut": "台新復興"
    },
    {
        "parentCode": "812",
        "code": "8120654",
        "name": "板南分行",
        "shortcut": "台新板南"
    },
    {
        "parentCode": "812",
        "code": "8120665",
        "name": "民權分行",
        "shortcut": "台新民權"
    },
    {
        "parentCode": "812",
        "code": "8120676",
        "name": "三民分行",
        "shortcut": "台新三民"
    },
    {
        "parentCode": "812",
        "code": "8120687",
        "name": "建北分行",
        "shortcut": "台新建北"
    },
    {
        "parentCode": "812",
        "code": "8120698",
        "name": "三和分行",
        "shortcut": "台新三和"
    },
    {
        "parentCode": "812",
        "code": "8120702",
        "name": "北新店分行",
        "shortcut": "台新北新店"
    },
    {
        "parentCode": "812",
        "code": "8120713",
        "name": "府城分行",
        "shortcut": "台新府城"
    },
    {
        "parentCode": "812",
        "code": "8120724",
        "name": "東高雄分行",
        "shortcut": "台新東高雄"
    },
    {
        "parentCode": "812",
        "code": "8120735",
        "name": "基隆路分行",
        "shortcut": "台新基隆路"
    },
    {
        "parentCode": "812",
        "code": "8120746",
        "name": "新店分行",
        "shortcut": "台新新店"
    },
    {
        "parentCode": "812",
        "code": "8120757",
        "name": "北桃園分行",
        "shortcut": "台新北桃園"
    },
    {
        "parentCode": "812",
        "code": "8120768",
        "name": "大墩分行",
        "shortcut": "台新大墩"
    },
    {
        "parentCode": "812",
        "code": "8120779",
        "name": "延平分行",
        "shortcut": "台新延平"
    },
    {
        "parentCode": "812",
        "code": "8120780",
        "name": "南崁分行",
        "shortcut": "台新南崁"
    },
    {
        "parentCode": "812",
        "code": "8120791",
        "name": "景平分行",
        "shortcut": "台新景平"
    },
    {
        "parentCode": "812",
        "code": "8120805",
        "name": "文心分行",
        "shortcut": "台新文心"
    },
    {
        "parentCode": "812",
        "code": "8120816",
        "name": "岡山分行",
        "shortcut": "台新岡山"
    },
    {
        "parentCode": "812",
        "code": "8120827",
        "name": "松江分行",
        "shortcut": "台新松江"
    },
    {
        "parentCode": "812",
        "code": "8120838",
        "name": "大雅分行",
        "shortcut": "台新大雅"
    },
    {
        "parentCode": "812",
        "code": "8120849",
        "name": "汐止分行",
        "shortcut": "台新汐止"
    },
    {
        "parentCode": "812",
        "code": "8120850",
        "name": "南屏分行",
        "shortcut": "台新南屏"
    },
    {
        "parentCode": "812",
        "code": "8120861",
        "name": "石牌分行",
        "shortcut": "台新石牌"
    },
    {
        "parentCode": "812",
        "code": "8120872",
        "name": "永康分行",
        "shortcut": "台新永康"
    },
    {
        "parentCode": "812",
        "code": "8120883",
        "name": "南松山分行",
        "shortcut": "台新南松山"
    },
    {
        "parentCode": "812",
        "code": "8120894",
        "name": "淡水分行",
        "shortcut": "台新淡水"
    },
    {
        "parentCode": "812",
        "code": "8120908",
        "name": "東基隆分行",
        "shortcut": "台新東基隆"
    },
    {
        "parentCode": "812",
        "code": "8120919",
        "name": "新板分行",
        "shortcut": "台新新板"
    },
    {
        "parentCode": "812",
        "code": "8120920",
        "name": "北師分行",
        "shortcut": "台新北師"
    },
    {
        "parentCode": "812",
        "code": "8120931",
        "name": "佳里分行",
        "shortcut": "台新佳里"
    },
    {
        "parentCode": "812",
        "code": "8120942",
        "name": "江翠分行",
        "shortcut": "台新江翠"
    },
    {
        "parentCode": "812",
        "code": "8120975",
        "name": "松德分行",
        "shortcut": "台新松德"
    },
    {
        "parentCode": "812",
        "code": "8120986",
        "name": "北大分行",
        "shortcut": "台新北大"
    },
    {
        "parentCode": "812",
        "code": "8120997",
        "name": "南港分行",
        "shortcut": "台新南港"
    },
    {
        "parentCode": "812",
        "code": "8121008",
        "name": "關東橋分行",
        "shortcut": "台新關東橋"
    },
    {
        "parentCode": "812",
        "code": "8121019",
        "name": "竹北分行",
        "shortcut": "台新竹北"
    },
    {
        "parentCode": "812",
        "code": "8121020",
        "name": "南寮分行",
        "shortcut": "台新南寮"
    },
    {
        "parentCode": "812",
        "code": "8121031",
        "name": "八德分行",
        "shortcut": "台新八德"
    },
    {
        "parentCode": "812",
        "code": "8121042",
        "name": "員林分行",
        "shortcut": "台新員林"
    },
    {
        "parentCode": "812",
        "code": "8121053",
        "name": "市府分行",
        "shortcut": "台新市府"
    },
    {
        "parentCode": "812",
        "code": "8121064",
        "name": "右昌分行",
        "shortcut": "台新右昌"
    },
    {
        "parentCode": "812",
        "code": "8121075",
        "name": "沙鹿分行",
        "shortcut": "台新沙鹿"
    },
    {
        "parentCode": "812",
        "code": "8121086",
        "name": "竹南分行",
        "shortcut": "台新竹南"
    },
    {
        "parentCode": "812",
        "code": "8121097",
        "name": "羅東分行",
        "shortcut": "台新羅東"
    },
    {
        "parentCode": "812",
        "code": "8121101",
        "name": "東湖分行",
        "shortcut": "台新東湖"
    },
    {
        "parentCode": "812",
        "code": "8121112",
        "name": "文山分行",
        "shortcut": "台新文山"
    },
    {
        "parentCode": "812",
        "code": "8121123",
        "name": "副都心分行",
        "shortcut": "台新副都心"
    },
    {
        "parentCode": "814",
        "code": "8140014",
        "name": "苓雅分行",
        "shortcut": "大眾苓雅"
    },
    {
        "parentCode": "814",
        "code": "8140025",
        "name": "敦化分行",
        "shortcut": "大眾敦化"
    },
    {
        "parentCode": "814",
        "code": "8140036",
        "name": "屏東分行",
        "shortcut": "大眾屏東"
    },
    {
        "parentCode": "814",
        "code": "8140047",
        "name": "高雄分行",
        "shortcut": "大眾高雄"
    },
    {
        "parentCode": "814",
        "code": "8140058",
        "name": "鳳山分行",
        "shortcut": "大眾鳳山"
    },
    {
        "parentCode": "814",
        "code": "8140069",
        "name": "台南分行",
        "shortcut": "大眾台南"
    },
    {
        "parentCode": "814",
        "code": "8140070",
        "name": "嘉義分行",
        "shortcut": "大眾嘉義"
    },
    {
        "parentCode": "814",
        "code": "8140081",
        "name": "台北分行",
        "shortcut": "大眾台北"
    },
    {
        "parentCode": "814",
        "code": "8140092",
        "name": "新莊分行",
        "shortcut": "大眾新莊"
    },
    {
        "parentCode": "814",
        "code": "8140106",
        "name": "中壢分行",
        "shortcut": "大眾中壢"
    },
    {
        "parentCode": "814",
        "code": "8140117",
        "name": "台中分行",
        "shortcut": "大眾台中"
    },
    {
        "parentCode": "814",
        "code": "8140128",
        "name": "大昌分行",
        "shortcut": "大眾大昌"
    },
    {
        "parentCode": "814",
        "code": "8140151",
        "name": "新生分行",
        "shortcut": "大眾新生"
    },
    {
        "parentCode": "814",
        "code": "8140162",
        "name": "板橋分行",
        "shortcut": "大眾板橋"
    },
    {
        "parentCode": "814",
        "code": "8140173",
        "name": "沙鹿分行",
        "shortcut": "大眾沙鹿"
    },
    {
        "parentCode": "814",
        "code": "8140184",
        "name": "博愛分行",
        "shortcut": "大眾博愛"
    },
    {
        "parentCode": "814",
        "code": "8140209",
        "name": "長春分行",
        "shortcut": "大眾長春"
    },
    {
        "parentCode": "814",
        "code": "8140210",
        "name": "三重分行",
        "shortcut": "大眾三重"
    },
    {
        "parentCode": "814",
        "code": "8140221",
        "name": "桃園分行",
        "shortcut": "大眾桃園"
    },
    {
        "parentCode": "814",
        "code": "8140232",
        "name": "永康分行",
        "shortcut": "大眾永康"
    },
    {
        "parentCode": "814",
        "code": "8140243",
        "name": "右昌分行",
        "shortcut": "大眾右昌"
    },
    {
        "parentCode": "814",
        "code": "8140254",
        "name": "前金分行",
        "shortcut": "大眾前金"
    },
    {
        "parentCode": "814",
        "code": "8140265",
        "name": "五甲分行",
        "shortcut": "大眾五甲"
    },
    {
        "parentCode": "814",
        "code": "8140276",
        "name": "文山簡易型分行",
        "shortcut": "大眾文山簡"
    },
    {
        "parentCode": "814",
        "code": "8140287",
        "name": "中和分行",
        "shortcut": "大眾中和"
    },
    {
        "parentCode": "814",
        "code": "8140298",
        "name": "新營分行",
        "shortcut": "大眾新營"
    },
    {
        "parentCode": "814",
        "code": "8140302",
        "name": "大和平分行",
        "shortcut": "大眾大和平"
    },
    {
        "parentCode": "814",
        "code": "8140313",
        "name": "西台南分行",
        "shortcut": "大眾西台南"
    },
    {
        "parentCode": "814",
        "code": "8140324",
        "name": "內湖分行",
        "shortcut": "大眾內湖"
    },
    {
        "parentCode": "814",
        "code": "8140335",
        "name": "灣裡分行",
        "shortcut": "大眾灣裡"
    },
    {
        "parentCode": "814",
        "code": "8140346",
        "name": "安南分行",
        "shortcut": "大眾安南"
    },
    {
        "parentCode": "814",
        "code": "8140357",
        "name": "江翠分行",
        "shortcut": "大眾江翠"
    },
    {
        "parentCode": "814",
        "code": "8140368",
        "name": "長庚分行",
        "shortcut": "大眾長庚"
    },
    {
        "parentCode": "814",
        "code": "8140379",
        "name": "東台南分行",
        "shortcut": "大眾東台南"
    },
    {
        "parentCode": "814",
        "code": "8140380",
        "name": "營業部",
        "shortcut": "大眾營業部"
    },
    {
        "parentCode": "814",
        "code": "8140391",
        "name": "新竹分行",
        "shortcut": "大眾新竹"
    },
    {
        "parentCode": "814",
        "code": "8140405",
        "name": "汐止簡易型分行",
        "shortcut": "大眾汐止簡"
    },
    {
        "parentCode": "814",
        "code": "8140416",
        "name": "天母分行",
        "shortcut": "大眾天母"
    },
    {
        "parentCode": "814",
        "code": "8140427",
        "name": "仁愛分行",
        "shortcut": "大眾仁愛"
    },
    {
        "parentCode": "814",
        "code": "8140438",
        "name": "澄清分行",
        "shortcut": "大眾澄清"
    },
    {
        "parentCode": "814",
        "code": "8140450",
        "name": "永和分行",
        "shortcut": "大眾永和"
    },
    {
        "parentCode": "814",
        "code": "8140461",
        "name": "小港分行",
        "shortcut": "大眾小港"
    },
    {
        "parentCode": "814",
        "code": "8140472",
        "name": "竹北簡易型分行",
        "shortcut": "大眾竹北簡"
    },
    {
        "parentCode": "814",
        "code": "8140483",
        "name": "北桃園分行",
        "shortcut": "大眾北桃園"
    },
    {
        "parentCode": "814",
        "code": "8140494",
        "name": "永春分行",
        "shortcut": "大眾永春"
    },
    {
        "parentCode": "814",
        "code": "8140508",
        "name": "圓山分行",
        "shortcut": "大眾圓山"
    },
    {
        "parentCode": "814",
        "code": "8140519",
        "name": "豐原簡易型分行",
        "shortcut": "大眾豐原簡"
    },
    {
        "parentCode": "814",
        "code": "8140520",
        "name": "彰化分行",
        "shortcut": "大眾彰化"
    },
    {
        "parentCode": "814",
        "code": "8140531",
        "name": "北高雄分行",
        "shortcut": "大眾北高雄"
    },
    {
        "parentCode": "814",
        "code": "8140542",
        "name": "大里簡易型分行",
        "shortcut": "大眾大里簡"
    },
    {
        "parentCode": "814",
        "code": "8140553",
        "name": "北台中分行",
        "shortcut": "大眾北台中"
    },
    {
        "parentCode": "814",
        "code": "8140564",
        "name": "中屏簡易型分行",
        "shortcut": "大眾中屏簡"
    },
    {
        "parentCode": "814",
        "code": "8140575",
        "name": "旗津分行",
        "shortcut": "大眾旗津"
    },
    {
        "parentCode": "814",
        "code": "8140586",
        "name": "左營分行",
        "shortcut": "大眾左營"
    },
    {
        "parentCode": "814",
        "code": "8140597",
        "name": "鹽埕簡易型分行",
        "shortcut": "大眾鹽埕簡"
    },
    {
        "parentCode": "814",
        "code": "8140601",
        "name": "前鎮分行",
        "shortcut": "大眾前鎮"
    },
    {
        "parentCode": "814",
        "code": "8140612",
        "name": "明誠分行",
        "shortcut": "大眾明誠"
    },
    {
        "parentCode": "814",
        "code": "8140726",
        "name": "中正分行",
        "shortcut": "大眾中正"
    },
    {
        "parentCode": "814",
        "code": "8140737",
        "name": "東湖分行",
        "shortcut": "大眾東湖"
    },
    {
        "parentCode": "814",
        "code": "8140759",
        "name": "安和分行",
        "shortcut": "大眾安和"
    },
    {
        "parentCode": "814",
        "code": "8140760",
        "name": "敦南分行",
        "shortcut": "大眾敦南"
    },
    {
        "parentCode": "814",
        "code": "8140771",
        "name": "南門分行",
        "shortcut": "大眾南門"
    },
    {
        "parentCode": "814",
        "code": "8140782",
        "name": "新板分行",
        "shortcut": "大眾新板"
    },
    {
        "parentCode": "814",
        "code": "8140793",
        "name": "新店分行",
        "shortcut": "大眾新店"
    },
    {
        "parentCode": "814",
        "code": "8140807",
        "name": "南屯分行",
        "shortcut": "大眾南屯"
    },
    {
        "parentCode": "814",
        "code": "8140818",
        "name": "中山分行",
        "shortcut": "大眾中山"
    },
    {
        "parentCode": "814",
        "code": "8140829",
        "name": "東門分行",
        "shortcut": "大眾東門"
    },
    {
        "parentCode": "815",
        "code": "8150015",
        "name": "營業部",
        "shortcut": "日盛銀營"
    },
    {
        "parentCode": "815",
        "code": "8150026",
        "name": "松江分行",
        "shortcut": "日盛銀松江"
    },
    {
        "parentCode": "815",
        "code": "8150037",
        "name": "延平分行",
        "shortcut": "日盛銀延平"
    },
    {
        "parentCode": "815",
        "code": "8150048",
        "name": "內湖分行",
        "shortcut": "日盛銀內湖"
    },
    {
        "parentCode": "815",
        "code": "8150059",
        "name": "桃園分行",
        "shortcut": "日盛銀桃園"
    },
    {
        "parentCode": "815",
        "code": "8150060",
        "name": "台中分行",
        "shortcut": "日盛銀台中"
    },
    {
        "parentCode": "815",
        "code": "8150071",
        "name": "台南分行",
        "shortcut": "日盛銀台南"
    },
    {
        "parentCode": "815",
        "code": "8150093",
        "name": "高雄分行",
        "shortcut": "日盛銀高雄"
    },
    {
        "parentCode": "815",
        "code": "8150107",
        "name": "新莊分行",
        "shortcut": "日盛銀新莊"
    },
    {
        "parentCode": "815",
        "code": "8150118",
        "name": "中壢分行",
        "shortcut": "日盛銀中壢"
    },
    {
        "parentCode": "815",
        "code": "8150129",
        "name": "松山分行",
        "shortcut": "日盛銀松山"
    },
    {
        "parentCode": "815",
        "code": "8150130",
        "name": "松南分行",
        "shortcut": "日盛銀松南"
    },
    {
        "parentCode": "815",
        "code": "8150141",
        "name": "八德分行",
        "shortcut": "日盛銀八德"
    },
    {
        "parentCode": "815",
        "code": "8150163",
        "name": "板橋分行",
        "shortcut": "日盛銀板橋"
    },
    {
        "parentCode": "815",
        "code": "8150174",
        "name": "信義分行",
        "shortcut": "日盛銀信義"
    },
    {
        "parentCode": "815",
        "code": "8150185",
        "name": "苓雅分行",
        "shortcut": "日盛銀苓雅"
    },
    {
        "parentCode": "815",
        "code": "8150196",
        "name": "前金分行",
        "shortcut": "日盛銀前金"
    },
    {
        "parentCode": "815",
        "code": "8150211",
        "name": "嘉義分行",
        "shortcut": "日盛銀嘉義"
    },
    {
        "parentCode": "815",
        "code": "8150222",
        "name": "三重分行",
        "shortcut": "日盛銀三重"
    },
    {
        "parentCode": "815",
        "code": "8150233",
        "name": "北台中分行",
        "shortcut": "日盛銀北台"
    },
    {
        "parentCode": "815",
        "code": "8150244",
        "name": "雙和分行",
        "shortcut": "日盛銀雙和"
    },
    {
        "parentCode": "815",
        "code": "8150255",
        "name": "士林分行",
        "shortcut": "日盛銀士林"
    },
    {
        "parentCode": "815",
        "code": "8150266",
        "name": "樹林分行",
        "shortcut": "日盛銀樹林"
    },
    {
        "parentCode": "815",
        "code": "8150277",
        "name": "北桃園分行",
        "shortcut": "日盛銀北桃"
    },
    {
        "parentCode": "815",
        "code": "8150288",
        "name": "新竹分行",
        "shortcut": "日盛銀新竹"
    },
    {
        "parentCode": "815",
        "code": "8150299",
        "name": "敦化分行",
        "shortcut": "日盛銀敦化"
    },
    {
        "parentCode": "815",
        "code": "8150303",
        "name": "北高雄分行",
        "shortcut": "日盛銀北高"
    },
    {
        "parentCode": "815",
        "code": "8150314",
        "name": "新營分行",
        "shortcut": "日盛銀新營"
    },
    {
        "parentCode": "815",
        "code": "8150325",
        "name": "頭份分行",
        "shortcut": "日盛銀頭份"
    },
    {
        "parentCode": "815",
        "code": "8150336",
        "name": "大里分行",
        "shortcut": "日盛銀大里"
    },
    {
        "parentCode": "815",
        "code": "8150347",
        "name": "光復分行",
        "shortcut": "日盛銀光復"
    },
    {
        "parentCode": "815",
        "code": "8150358",
        "name": "員林分行",
        "shortcut": "日盛銀員林"
    },
    {
        "parentCode": "815",
        "code": "8150369",
        "name": "虎尾分行",
        "shortcut": "日盛銀虎尾"
    },
    {
        "parentCode": "815",
        "code": "8150370",
        "name": "屏東分行",
        "shortcut": "日盛銀屏東"
    },
    {
        "parentCode": "815",
        "code": "8150381",
        "name": "南門分行",
        "shortcut": "日盛銀南門"
    },
    {
        "parentCode": "815",
        "code": "8150392",
        "name": "大安分行",
        "shortcut": "日盛銀大安"
    },
    {
        "parentCode": "815",
        "code": "8150406",
        "name": "敦北分行",
        "shortcut": "日盛銀敦北"
    },
    {
        "parentCode": "815",
        "code": "8150417",
        "name": "南京分行",
        "shortcut": "日盛銀南京"
    },
    {
        "parentCode": "815",
        "code": "8150428",
        "name": "竹北分行",
        "shortcut": "日盛銀竹北"
    },
    {
        "parentCode": "815",
        "code": "8150439",
        "name": "安平分行",
        "shortcut": "日盛銀安平"
    },
    {
        "parentCode": "815",
        "code": "8150451",
        "name": "新店分行",
        "shortcut": "日盛銀新店"
    },
    {
        "parentCode": "815",
        "code": "8150462",
        "name": "土城分行",
        "shortcut": "日盛銀土城"
    },
    {
        "parentCode": "815",
        "code": "8150473",
        "name": "南港分行",
        "shortcut": "日盛銀南港"
    },
    {
        "parentCode": "815",
        "code": "8150484",
        "name": "蘆洲分行",
        "shortcut": "日盛銀蘆洲"
    },
    {
        "parentCode": "816",
        "code": "8160016",
        "name": "長安東路分行",
        "shortcut": "安泰長安東"
    },
    {
        "parentCode": "816",
        "code": "8160027",
        "name": "信義分行",
        "shortcut": "安泰信義"
    },
    {
        "parentCode": "816",
        "code": "8160038",
        "name": "營業部",
        "shortcut": "安泰營"
    },
    {
        "parentCode": "816",
        "code": "8160049",
        "name": "新生分行",
        "shortcut": "安泰新生"
    },
    {
        "parentCode": "816",
        "code": "8160050",
        "name": "桃園分行",
        "shortcut": "安泰桃園"
    },
    {
        "parentCode": "816",
        "code": "8160061",
        "name": "新莊分行",
        "shortcut": "安泰新莊"
    },
    {
        "parentCode": "816",
        "code": "8160072",
        "name": "台中分行",
        "shortcut": "安泰台中"
    },
    {
        "parentCode": "816",
        "code": "8160083",
        "name": "高雄分行",
        "shortcut": "安泰高雄"
    },
    {
        "parentCode": "816",
        "code": "8160094",
        "name": "台南分行",
        "shortcut": "安泰台南"
    },
    {
        "parentCode": "816",
        "code": "8160108",
        "name": "興隆分行",
        "shortcut": "安泰興隆"
    },
    {
        "parentCode": "816",
        "code": "8160119",
        "name": "延平分行",
        "shortcut": "安泰延平"
    },
    {
        "parentCode": "816",
        "code": "8160120",
        "name": "中壢分行",
        "shortcut": "安泰中壢"
    },
    {
        "parentCode": "816",
        "code": "8160131",
        "name": "板橋分行",
        "shortcut": "安泰板橋"
    },
    {
        "parentCode": "816",
        "code": "8160142",
        "name": "豐原分行",
        "shortcut": "安泰豐原"
    },
    {
        "parentCode": "816",
        "code": "8160153",
        "name": "前金分行",
        "shortcut": "安泰前金"
    },
    {
        "parentCode": "816",
        "code": "8160164",
        "name": "中和分行",
        "shortcut": "安泰中和"
    },
    {
        "parentCode": "816",
        "code": "8160186",
        "name": "鳳山分行",
        "shortcut": "安泰鳳山"
    },
    {
        "parentCode": "816",
        "code": "8160197",
        "name": "松江簡易型分行",
        "shortcut": "安泰松江簡"
    },
    {
        "parentCode": "816",
        "code": "8160201",
        "name": "中崙分行",
        "shortcut": "安泰中崙"
    },
    {
        "parentCode": "816",
        "code": "8160212",
        "name": "嘉義分行",
        "shortcut": "安泰嘉義"
    },
    {
        "parentCode": "816",
        "code": "8160223",
        "name": "三重簡易型分行",
        "shortcut": "安泰三重簡"
    },
    {
        "parentCode": "816",
        "code": "8160245",
        "name": "沙鹿分行",
        "shortcut": "安泰沙鹿"
    },
    {
        "parentCode": "816",
        "code": "8160256",
        "name": "新竹分行",
        "shortcut": "安泰新竹"
    },
    {
        "parentCode": "816",
        "code": "8160267",
        "name": "員林分行",
        "shortcut": "安泰員林"
    },
    {
        "parentCode": "816",
        "code": "8160278",
        "name": "建國分行",
        "shortcut": "安泰建國"
    },
    {
        "parentCode": "816",
        "code": "8160289",
        "name": "北高雄分行",
        "shortcut": "安泰北高雄"
    },
    {
        "parentCode": "816",
        "code": "8160290",
        "name": "新店分行",
        "shortcut": "安泰新店"
    },
    {
        "parentCode": "816",
        "code": "8160304",
        "name": "和平分行",
        "shortcut": "安泰和平"
    },
    {
        "parentCode": "816",
        "code": "8160315",
        "name": "成功簡易型分行",
        "shortcut": "安泰成功簡"
    },
    {
        "parentCode": "816",
        "code": "8160326",
        "name": "通化分行",
        "shortcut": "安泰通化"
    },
    {
        "parentCode": "816",
        "code": "8160337",
        "name": "南門分行",
        "shortcut": "安泰南門"
    },
    {
        "parentCode": "816",
        "code": "8160348",
        "name": "汀州分行",
        "shortcut": "安泰汀州"
    },
    {
        "parentCode": "816",
        "code": "8160359",
        "name": "農安簡易型分行",
        "shortcut": "安泰農安簡"
    },
    {
        "parentCode": "816",
        "code": "8160360",
        "name": "景美分行",
        "shortcut": "安泰景美"
    },
    {
        "parentCode": "816",
        "code": "8160371",
        "name": "民權分行",
        "shortcut": "安泰民權"
    },
    {
        "parentCode": "816",
        "code": "8160382",
        "name": "松山分行",
        "shortcut": "安泰松山"
    },
    {
        "parentCode": "816",
        "code": "8160393",
        "name": "內湖簡易型分行",
        "shortcut": "安泰內湖簡"
    },
    {
        "parentCode": "816",
        "code": "8160407",
        "name": "忠孝分行",
        "shortcut": "安泰忠孝"
    },
    {
        "parentCode": "816",
        "code": "8160418",
        "name": "蘆洲分行",
        "shortcut": "安泰蘆洲"
    },
    {
        "parentCode": "816",
        "code": "8160429",
        "name": "永康分行",
        "shortcut": "安泰永康"
    },
    {
        "parentCode": "816",
        "code": "8160430",
        "name": "西壢分行",
        "shortcut": "安泰西壢"
    },
    {
        "parentCode": "816",
        "code": "8160441",
        "name": "北桃園簡易型分行",
        "shortcut": "安泰北桃園"
    },
    {
        "parentCode": "816",
        "code": "8160452",
        "name": "幸福分行",
        "shortcut": "安泰幸福"
    },
    {
        "parentCode": "816",
        "code": "8160463",
        "name": "天母分行",
        "shortcut": "安泰天母"
    },
    {
        "parentCode": "816",
        "code": "8160474",
        "name": "龍潭簡易型分行",
        "shortcut": "安泰龍潭簡"
    },
    {
        "parentCode": "816",
        "code": "8160485",
        "name": "復興分行",
        "shortcut": "安泰復興"
    },
    {
        "parentCode": "816",
        "code": "8160496",
        "name": "永吉簡易型分行",
        "shortcut": "安泰永吉簡"
    },
    {
        "parentCode": "816",
        "code": "8160500",
        "name": "石牌簡易型分行",
        "shortcut": "安泰石牌簡"
    },
    {
        "parentCode": "816",
        "code": "8160511",
        "name": "八德簡易型分行",
        "shortcut": "安泰八德簡"
    },
    {
        "parentCode": "816",
        "code": "8160522",
        "name": "竹北簡易型分行",
        "shortcut": "安泰竹北簡"
    },
    {
        "parentCode": "816",
        "code": "8160533",
        "name": "永和分行",
        "shortcut": "安泰永和"
    },
    {
        "parentCode": "816",
        "code": "8160544",
        "name": "大直分行",
        "shortcut": "安泰大直"
    },
    {
        "parentCode": "816",
        "code": "8160555",
        "name": "土城簡易型分行",
        "shortcut": "安泰土城簡"
    },
    {
        "parentCode": "816",
        "code": "8160566",
        "name": "大里分行",
        "shortcut": "安泰大里"
    },
    {
        "parentCode": "822",
        "code": "8220015",
        "name": "敦北分行",
        "shortcut": "中信銀敦北"
    },
    {
        "parentCode": "822",
        "code": "8220026",
        "name": "台中分行",
        "shortcut": "中信銀台中"
    },
    {
        "parentCode": "822",
        "code": "8220037",
        "name": "高雄分行",
        "shortcut": "中信銀高雄"
    },
    {
        "parentCode": "822",
        "code": "8220059",
        "name": "台南分行",
        "shortcut": "中信銀台南"
    },
    {
        "parentCode": "822",
        "code": "8220060",
        "name": "三重分行",
        "shortcut": "中信銀三重"
    },
    {
        "parentCode": "822",
        "code": "8220071",
        "name": "城東分行",
        "shortcut": "中信銀城東"
    },
    {
        "parentCode": "822",
        "code": "8220082",
        "name": "嘉義分行",
        "shortcut": "中信銀嘉義"
    },
    {
        "parentCode": "822",
        "code": "8220093",
        "name": "中崙分行",
        "shortcut": "中信銀中崙"
    },
    {
        "parentCode": "822",
        "code": "8220107",
        "name": "城中分行",
        "shortcut": "中信銀城中"
    },
    {
        "parentCode": "822",
        "code": "8220118",
        "name": "三民分行",
        "shortcut": "中信銀三民"
    },
    {
        "parentCode": "822",
        "code": "8220129",
        "name": "中壢分行",
        "shortcut": "中信銀中壢"
    },
    {
        "parentCode": "822",
        "code": "8220130",
        "name": "雙和分行",
        "shortcut": "中信銀雙和"
    },
    {
        "parentCode": "822",
        "code": "8220141",
        "name": "中山分行",
        "shortcut": "中信銀中山"
    },
    {
        "parentCode": "822",
        "code": "8220152",
        "name": "仁愛分行",
        "shortcut": "中信銀仁愛"
    },
    {
        "parentCode": "822",
        "code": "8220163",
        "name": "敦南分行",
        "shortcut": "中信銀敦南"
    },
    {
        "parentCode": "822",
        "code": "8220174",
        "name": "板橋分行",
        "shortcut": "中信銀板橋"
    },
    {
        "parentCode": "822",
        "code": "8220185",
        "name": "忠孝分行",
        "shortcut": "中信銀忠孝"
    },
    {
        "parentCode": "822",
        "code": "8220196",
        "name": "新興分行",
        "shortcut": "中信銀新興"
    },
    {
        "parentCode": "822",
        "code": "8220200",
        "name": "南京東路分行",
        "shortcut": "中信銀南京"
    },
    {
        "parentCode": "822",
        "code": "8220222",
        "name": "西台南分行",
        "shortcut": "中信銀西台"
    },
    {
        "parentCode": "822",
        "code": "8220255",
        "name": "松山分行",
        "shortcut": "中信銀松山"
    },
    {
        "parentCode": "822",
        "code": "8220266",
        "name": "新莊分行",
        "shortcut": "中信銀新莊"
    },
    {
        "parentCode": "822",
        "code": "8220277",
        "name": "桃園分行",
        "shortcut": "中信銀桃園"
    },
    {
        "parentCode": "822",
        "code": "8220288",
        "name": "中港分行",
        "shortcut": "中信銀中港"
    },
    {
        "parentCode": "822",
        "code": "8220299",
        "name": "新竹分行",
        "shortcut": "中信銀新竹"
    },
    {
        "parentCode": "822",
        "code": "8220303",
        "name": "蘆洲分行",
        "shortcut": "中信銀蘆洲"
    },
    {
        "parentCode": "822",
        "code": "8220314",
        "name": "新店分行",
        "shortcut": "中信銀新店"
    },
    {
        "parentCode": "822",
        "code": "8220325",
        "name": "員林分行",
        "shortcut": "中信銀員林"
    },
    {
        "parentCode": "822",
        "code": "8220336",
        "name": "東新竹分行",
        "shortcut": "中信銀東新"
    },
    {
        "parentCode": "822",
        "code": "8220347",
        "name": "公館分行",
        "shortcut": "中信銀公館"
    },
    {
        "parentCode": "822",
        "code": "8220358",
        "name": "永康分行",
        "shortcut": "中信銀永康"
    },
    {
        "parentCode": "822",
        "code": "8220369",
        "name": "土城分行",
        "shortcut": "中信銀土城"
    },
    {
        "parentCode": "822",
        "code": "8220370",
        "name": "頭份分行",
        "shortcut": "中信銀頭份"
    },
    {
        "parentCode": "822",
        "code": "8220381",
        "name": "基隆分行",
        "shortcut": "中信銀基隆"
    },
    {
        "parentCode": "822",
        "code": "8220392",
        "name": "豐原分行",
        "shortcut": "中信銀豐原"
    },
    {
        "parentCode": "822",
        "code": "8220406",
        "name": "天母分行",
        "shortcut": "中信銀天母"
    },
    {
        "parentCode": "822",
        "code": "8220417",
        "name": "板新分行",
        "shortcut": "中信銀板新"
    },
    {
        "parentCode": "822",
        "code": "8220428",
        "name": "彰化分行",
        "shortcut": "中信銀彰化"
    },
    {
        "parentCode": "822",
        "code": "8220439",
        "name": "東高雄分行",
        "shortcut": "中信銀東高"
    },
    {
        "parentCode": "822",
        "code": "8220440",
        "name": "中華分行",
        "shortcut": "中信銀中華"
    },
    {
        "parentCode": "822",
        "code": "8220451",
        "name": "內湖分行",
        "shortcut": "中信銀內湖"
    },
    {
        "parentCode": "822",
        "code": "8220462",
        "name": "斗六分行",
        "shortcut": "中信銀斗六"
    },
    {
        "parentCode": "822",
        "code": "8220473",
        "name": "文心分行",
        "shortcut": "中信銀文心"
    },
    {
        "parentCode": "822",
        "code": "8220484",
        "name": "重陽分行",
        "shortcut": "中信銀重陽"
    },
    {
        "parentCode": "822",
        "code": "8220495",
        "name": "永吉分行",
        "shortcut": "中信銀永吉"
    },
    {
        "parentCode": "822",
        "code": "8220509",
        "name": "前鎮分行",
        "shortcut": "中信銀前鎮"
    },
    {
        "parentCode": "822",
        "code": "8220510",
        "name": "南崁分行",
        "shortcut": "中信銀南崁"
    },
    {
        "parentCode": "822",
        "code": "8220521",
        "name": "永和分行",
        "shortcut": "中信銀永和"
    },
    {
        "parentCode": "822",
        "code": "8220532",
        "name": "公益分行",
        "shortcut": "中信銀公益"
    },
    {
        "parentCode": "822",
        "code": "8220543",
        "name": "市府分行",
        "shortcut": "中信銀市府"
    },
    {
        "parentCode": "822",
        "code": "8220554",
        "name": "經國分行",
        "shortcut": "中信銀經國"
    },
    {
        "parentCode": "822",
        "code": "8220565",
        "name": "右昌簡易型分行",
        "shortcut": "中信銀右昌"
    },
    {
        "parentCode": "822",
        "code": "8220576",
        "name": "江翠分行",
        "shortcut": "中信銀江翠"
    },
    {
        "parentCode": "822",
        "code": "8220587",
        "name": "東湖簡易型分行",
        "shortcut": "中信銀東湖"
    },
    {
        "parentCode": "822",
        "code": "8220598",
        "name": "中原分行",
        "shortcut": "中信銀中原"
    },
    {
        "parentCode": "822",
        "code": "8220602",
        "name": "大安分行",
        "shortcut": "中信銀大安"
    },
    {
        "parentCode": "822",
        "code": "8220613",
        "name": "北高雄分行",
        "shortcut": "中信銀北高"
    },
    {
        "parentCode": "822",
        "code": "8220624",
        "name": "承德分行",
        "shortcut": "中信銀承德"
    },
    {
        "parentCode": "822",
        "code": "8220635",
        "name": "復興分行",
        "shortcut": "中信銀復興"
    },
    {
        "parentCode": "822",
        "code": "8220646",
        "name": "石牌分行",
        "shortcut": "中信銀石牌"
    },
    {
        "parentCode": "822",
        "code": "8220657",
        "name": "城北分行",
        "shortcut": "中信銀城北"
    },
    {
        "parentCode": "822",
        "code": "8220668",
        "name": "萬華分行",
        "shortcut": "中信銀萬華"
    },
    {
        "parentCode": "822",
        "code": "8220679",
        "name": "西松分行",
        "shortcut": "中信銀西松"
    },
    {
        "parentCode": "822",
        "code": "8220680",
        "name": "北投分行",
        "shortcut": "中信銀北投"
    },
    {
        "parentCode": "822",
        "code": "8220691",
        "name": "安和分行",
        "shortcut": "中信銀安和"
    },
    {
        "parentCode": "822",
        "code": "8220705",
        "name": "西湖分行",
        "shortcut": "中信銀西湖"
    },
    {
        "parentCode": "822",
        "code": "8220716",
        "name": "瑞光分行",
        "shortcut": "中信銀瑞光"
    },
    {
        "parentCode": "822",
        "code": "8220727",
        "name": "宜蘭分行",
        "shortcut": "中信銀宜蘭"
    },
    {
        "parentCode": "822",
        "code": "8220738",
        "name": "民生分行",
        "shortcut": "中信銀民生"
    },
    {
        "parentCode": "822",
        "code": "8220749",
        "name": "中和分行",
        "shortcut": "中信銀中和"
    },
    {
        "parentCode": "822",
        "code": "8220750",
        "name": "重新分行",
        "shortcut": "中信銀重新"
    },
    {
        "parentCode": "822",
        "code": "8220761",
        "name": "丹鳳分行",
        "shortcut": "中信銀丹鳳"
    },
    {
        "parentCode": "822",
        "code": "8220772",
        "name": "北新店分行",
        "shortcut": "中信北新店"
    },
    {
        "parentCode": "822",
        "code": "8220783",
        "name": "汐止分行",
        "shortcut": "中信銀汐止"
    },
    {
        "parentCode": "822",
        "code": "8220794",
        "name": "金城分行",
        "shortcut": "中信銀金城"
    },
    {
        "parentCode": "822",
        "code": "8220808",
        "name": "樹林分行",
        "shortcut": "中信銀樹林"
    },
    {
        "parentCode": "822",
        "code": "8220819",
        "name": "南勢角分行",
        "shortcut": "中信銀南勢"
    },
    {
        "parentCode": "822",
        "code": "8220820",
        "name": "北新莊分行",
        "shortcut": "中信北新莊"
    },
    {
        "parentCode": "822",
        "code": "8220853",
        "name": "南桃園分行",
        "shortcut": "中信銀南桃"
    },
    {
        "parentCode": "822",
        "code": "8220864",
        "name": "北桃園分行",
        "shortcut": "中信北桃園"
    },
    {
        "parentCode": "822",
        "code": "8220875",
        "name": "竹科分行",
        "shortcut": "中信銀竹科"
    },
    {
        "parentCode": "822",
        "code": "8220901",
        "name": "營業部",
        "shortcut": "中信銀營"
    },
    {
        "parentCode": "822",
        "code": "8221012",
        "name": "八德分行",
        "shortcut": "中信銀八德"
    },
    {
        "parentCode": "822",
        "code": "8221023",
        "name": "林口分行",
        "shortcut": "中信銀林口"
    },
    {
        "parentCode": "822",
        "code": "8221034",
        "name": "竹北分行",
        "shortcut": "中信銀竹北"
    },
    {
        "parentCode": "822",
        "code": "8221045",
        "name": "南屯分行",
        "shortcut": "中信銀南屯"
    },
    {
        "parentCode": "822",
        "code": "8221056",
        "name": "大里分行",
        "shortcut": "中信銀大里"
    },
    {
        "parentCode": "822",
        "code": "8221067",
        "name": "北台中簡易型分行",
        "shortcut": "中信銀北台"
    },
    {
        "parentCode": "822",
        "code": "8221078",
        "name": "南投簡易型分行",
        "shortcut": "中信銀南投"
    },
    {
        "parentCode": "822",
        "code": "8221090",
        "name": "仁德簡易型分行",
        "shortcut": "中信銀仁德"
    },
    {
        "parentCode": "822",
        "code": "8221104",
        "name": "中台南分行",
        "shortcut": "中信銀中台"
    },
    {
        "parentCode": "822",
        "code": "8221115",
        "name": "東台南分行",
        "shortcut": "中信銀東台"
    },
    {
        "parentCode": "822",
        "code": "8221126",
        "name": "台東簡易型分行",
        "shortcut": "中信銀台東"
    },
    {
        "parentCode": "822",
        "code": "8221137",
        "name": "南台南分行",
        "shortcut": "中信銀南台"
    },
    {
        "parentCode": "822",
        "code": "8221148",
        "name": "新營分行",
        "shortcut": "中信銀新營"
    },
    {
        "parentCode": "822",
        "code": "8221159",
        "name": "科博館分行",
        "shortcut": "中信銀科博"
    },
    {
        "parentCode": "822",
        "code": "8221160",
        "name": "西屯分行",
        "shortcut": "中信銀西屯"
    },
    {
        "parentCode": "822",
        "code": "8221171",
        "name": "民族分行",
        "shortcut": "中信銀民族"
    },
    {
        "parentCode": "822",
        "code": "8221182",
        "name": "南高雄分行",
        "shortcut": "中信銀南高"
    },
    {
        "parentCode": "822",
        "code": "8221193",
        "name": "九如分行",
        "shortcut": "中信銀九如"
    },
    {
        "parentCode": "822",
        "code": "8221207",
        "name": "屏東分行",
        "shortcut": "中信銀屏東"
    },
    {
        "parentCode": "822",
        "code": "8221218",
        "name": "復北分行",
        "shortcut": "中信銀復北"
    },
    {
        "parentCode": "822",
        "code": "8221229",
        "name": "鳳山分行",
        "shortcut": "中信銀鳳山"
    },
    {
        "parentCode": "822",
        "code": "8221230",
        "name": "五甲分行",
        "shortcut": "中信銀五甲"
    },
    {
        "parentCode": "822",
        "code": "8221241",
        "name": "延吉分行",
        "shortcut": "中信銀延吉"
    },
    {
        "parentCode": "822",
        "code": "8221252",
        "name": "信義分行",
        "shortcut": "中信銀信義"
    },
    {
        "parentCode": "822",
        "code": "8221263",
        "name": "青年分行",
        "shortcut": "中信銀青年"
    },
    {
        "parentCode": "822",
        "code": "8221274",
        "name": "岡山簡易型分行",
        "shortcut": "中信銀岡山"
    },
    {
        "parentCode": "822",
        "code": "8221285",
        "name": "士林分行",
        "shortcut": "中信銀士林"
    },
    {
        "parentCode": "822",
        "code": "8221296",
        "name": "東民生分行",
        "shortcut": "中信東民生"
    },
    {
        "parentCode": "822",
        "code": "8221300",
        "name": "文山分行",
        "shortcut": "中信銀文山"
    },
    {
        "parentCode": "822",
        "code": "8222101",
        "name": "淡水簡易型分行",
        "shortcut": "中信銀淡水"
    },
    {
        "parentCode": "822",
        "code": "8222112",
        "name": "民安分行",
        "shortcut": "中信銀民安"
    },
    {
        "parentCode": "822",
        "code": "8222123",
        "name": "二重埔簡易型分行",
        "shortcut": "中信銀二重"
    },
    {
        "parentCode": "822",
        "code": "8222134",
        "name": "富錦分行",
        "shortcut": "中信銀富錦"
    },
    {
        "parentCode": "822",
        "code": "8222145",
        "name": "站前分行",
        "shortcut": "中信銀站前"
    },
    {
        "parentCode": "822",
        "code": "8222156",
        "name": "惠中簡易型分行",
        "shortcut": "中信銀惠中"
    },
    {
        "parentCode": "822",
        "code": "8222167",
        "name": "東蘆洲分行",
        "shortcut": "中信銀東蘆"
    },
    {
        "parentCode": "822",
        "code": "8222178",
        "name": "木柵分行",
        "shortcut": "中信銀木柵"
    },
    {
        "parentCode": "822",
        "code": "8222189",
        "name": "東花蓮分行",
        "shortcut": "中信銀東花"
    },
    {
        "parentCode": "822",
        "code": "8222190",
        "name": "寶山分行",
        "shortcut": "中信銀寶山"
    },
    {
        "parentCode": "822",
        "code": "8222204",
        "name": "藝文分行",
        "shortcut": "中信銀藝文"
    },
    {
        "parentCode": "822",
        "code": "8222215",
        "name": "東門分行",
        "shortcut": "中信銀東門"
    },
    {
        "parentCode": "822",
        "code": "8222226",
        "name": "新板特區分行",
        "shortcut": "中信銀新板"
    },
    {
        "parentCode": "822",
        "code": "8222237",
        "name": "三和分行",
        "shortcut": "中信銀三和"
    },
    {
        "parentCode": "822",
        "code": "8222248",
        "name": "劍潭分行",
        "shortcut": "中信銀劍潭"
    },
    {
        "parentCode": "822",
        "code": "8222259",
        "name": "北蘆洲分行",
        "shortcut": "中信北蘆洲"
    },
    {
        "parentCode": "822",
        "code": "8222260",
        "name": "寶強分行",
        "shortcut": "中信銀寶強"
    },
    {
        "parentCode": "822",
        "code": "8222271",
        "name": "華山分行",
        "shortcut": "中信銀華山"
    },
    {
        "parentCode": "822",
        "code": "8222282",
        "name": "龍江簡易型分行",
        "shortcut": "中信銀龍江"
    },
    {
        "parentCode": "822",
        "code": "8222293",
        "name": "成功分行",
        "shortcut": "中信銀成功"
    },
    {
        "parentCode": "822",
        "code": "8223108",
        "name": "市政分行",
        "shortcut": "中信銀市政"
    },
    {
        "parentCode": "822",
        "code": "8223119",
        "name": "大直分行",
        "shortcut": "中信銀大直"
    },
    {
        "parentCode": "822",
        "code": "8223120",
        "name": "景美分行",
        "shortcut": "中信銀景美"
    },
    {
        "parentCode": "822",
        "code": "8223131",
        "name": "內壢簡易型分行",
        "shortcut": "中信銀內壢"
    },
    {
        "parentCode": "822",
        "code": "8223142",
        "name": "重慶分行",
        "shortcut": "中信銀重慶"
    },
    {
        "parentCode": "822",
        "code": "8223153",
        "name": "長安分行",
        "shortcut": "中信銀長安"
    },
    {
        "parentCode": "822",
        "code": "8223164",
        "name": "羅東分行",
        "shortcut": "中信銀羅東"
    },
    {
        "parentCode": "822",
        "code": "8223175",
        "name": "南中壢分行",
        "shortcut": "中信南中壢"
    },
    {
        "parentCode": "822",
        "code": "8223186",
        "name": "板和簡易型分行",
        "shortcut": "中信銀板和"
    },
    {
        "parentCode": "822",
        "code": "8223197",
        "name": "花蓮分行",
        "shortcut": "中信銀花蓮"
    },
    {
        "parentCode": "822",
        "code": "8223201",
        "name": "北天母分行",
        "shortcut": "中信銀北天"
    },
    {
        "parentCode": "822",
        "code": "8223212",
        "name": "民權西路分行",
        "shortcut": "中信銀民權"
    },
    {
        "parentCode": "822",
        "code": "8223223",
        "name": "佳里分行",
        "shortcut": "中信銀佳里"
    },
    {
        "parentCode": "822",
        "code": "8223234",
        "name": "博愛分行",
        "shortcut": "中信銀博愛"
    },
    {
        "parentCode": "822",
        "code": "8223245",
        "name": "鹽行分行",
        "shortcut": "中信銀鹽行"
    },
    {
        "parentCode": "822",
        "code": "8223267",
        "name": "光復分行",
        "shortcut": "中信銀光復"
    },
    {
        "parentCode": "822",
        "code": "8223278",
        "name": "埔墘簡易型分行",
        "shortcut": "中信銀埔墘"
    },
    {
        "parentCode": "901",
        "code": "9010015",
        "name": "大里區農會",
        "shortcut": "里農本會"
    },
    {
        "parentCode": "901",
        "code": "9010026",
        "name": "內新辦事處",
        "shortcut": "里農內新"
    },
    {
        "parentCode": "901",
        "code": "9010037",
        "name": "草湖辦事處",
        "shortcut": "里農草湖"
    },
    {
        "parentCode": "901",
        "code": "9010048",
        "name": "健民辦事處",
        "shortcut": "里農健民"
    },
    {
        "parentCode": "901",
        "code": "9010059",
        "name": "東榮辦事處",
        "shortcut": "里農東榮"
    },
    {
        "parentCode": "901",
        "code": "9010060",
        "name": "金城辦事處",
        "shortcut": "里農金城"
    },
    {
        "parentCode": "901",
        "code": "9010071",
        "name": "十九甲辦事處",
        "shortcut": "里農十九甲"
    },
    {
        "parentCode": "901",
        "code": "9010093",
        "name": "仁化辦事處",
        "shortcut": "里農仁化"
    },
    {
        "parentCode": "901",
        "code": "9010107",
        "name": "國光辦事處",
        "shortcut": "里農國光"
    },
    {
        "parentCode": "901",
        "code": "9010118",
        "name": "成功辦事處",
        "shortcut": "里農成功"
    },
    {
        "parentCode": "901",
        "code": "9010129",
        "name": "立新辦事處",
        "shortcut": "里農立新"
    },
    {
        "parentCode": "903",
        "code": "9030109",
        "name": "信用部",
        "shortcut": "汐農信"
    },
    {
        "parentCode": "903",
        "code": "9030110",
        "name": "社后分部",
        "shortcut": "汐農社后"
    },
    {
        "parentCode": "903",
        "code": "9030121",
        "name": "橫科分部",
        "shortcut": "汐農橫科"
    },
    {
        "parentCode": "903",
        "code": "9030132",
        "name": "白雲分部",
        "shortcut": "汐農白雲"
    },
    {
        "parentCode": "903",
        "code": "9030143",
        "name": "大新分部",
        "shortcut": "汐農大新"
    },
    {
        "parentCode": "903",
        "code": "9030154",
        "name": "中興分部",
        "shortcut": "汐農中興"
    },
    {
        "parentCode": "903",
        "code": "9030165",
        "name": "中正分部",
        "shortcut": "汐農中正"
    },
    {
        "parentCode": "903",
        "code": "9030176",
        "name": "樟樹分部",
        "shortcut": "汐農樟樹"
    },
    {
        "parentCode": "903",
        "code": "9030187",
        "name": "大同分部",
        "shortcut": "汐農大同"
    },
    {
        "parentCode": "904",
        "code": "9040018",
        "name": "新莊區農會信用部",
        "shortcut": "莊農信用部"
    },
    {
        "parentCode": "904",
        "code": "9040029",
        "name": "丹鳳分部",
        "shortcut": "莊農丹鳳"
    },
    {
        "parentCode": "904",
        "code": "9040030",
        "name": "頭前分部",
        "shortcut": "莊農頭前"
    },
    {
        "parentCode": "904",
        "code": "9040063",
        "name": "西盛分部",
        "shortcut": "莊農西盛"
    },
    {
        "parentCode": "904",
        "code": "9040074",
        "name": "中港分部",
        "shortcut": "莊農中港"
    },
    {
        "parentCode": "904",
        "code": "9040085",
        "name": "後港分部",
        "shortcut": "莊農後港"
    },
    {
        "parentCode": "904",
        "code": "9040096",
        "name": "營盤分部",
        "shortcut": "莊農營盤"
    },
    {
        "parentCode": "904",
        "code": "9040100",
        "name": "瓊林分部",
        "shortcut": "莊農瓊林"
    },
    {
        "parentCode": "904",
        "code": "9040111",
        "name": "思源分部",
        "shortcut": "莊農思源"
    },
    {
        "parentCode": "904",
        "code": "9040122",
        "name": "新泰分部",
        "shortcut": "莊農新泰"
    },
    {
        "parentCode": "912",
        "code": "9120086",
        "name": "冬山鄉農會信用部",
        "shortcut": "冬農信用部"
    },
    {
        "parentCode": "912",
        "code": "9120189",
        "name": "羅東辦事處",
        "shortcut": "冬農羅東"
    },
    {
        "parentCode": "912",
        "code": "9120190",
        "name": "順安辦事處",
        "shortcut": "冬農順安"
    },
    {
        "parentCode": "912",
        "code": "9120271",
        "name": "廣興辦事處",
        "shortcut": "冬農廣興"
    },
    {
        "parentCode": "912",
        "code": "9120488",
        "name": "群英分部",
        "shortcut": "冬農群英"
    },
    {
        "parentCode": "916",
        "code": "9160024",
        "name": "草屯鎮農會",
        "shortcut": "草屯本會"
    },
    {
        "parentCode": "916",
        "code": "9160183",
        "name": "雙冬辦事處",
        "shortcut": "草屯雙冬"
    },
    {
        "parentCode": "916",
        "code": "9160493",
        "name": "南埔分部",
        "shortcut": "草屯南埔"
    },
    {
        "parentCode": "916",
        "code": "9160600",
        "name": "芬草分部",
        "shortcut": "草屯芬草"
    },
    {
        "parentCode": "916",
        "code": "9160611",
        "name": "虎山分部",
        "shortcut": "草屯虎山"
    },
    {
        "parentCode": "916",
        "code": "9160677",
        "name": "北勢分部",
        "shortcut": "草屯北勢"
    },
    {
        "parentCode": "916",
        "code": "9160688",
        "name": "富寮分部",
        "shortcut": "草屯富寮"
    },
    {
        "parentCode": "916",
        "code": "9160758",
        "name": "新庄分部",
        "shortcut": "草屯新庄"
    },
    {
        "parentCode": "922",
        "code": "9220012",
        "name": "臺南地區農會信用部",
        "shortcut": "南區農信用"
    },
    {
        "parentCode": "922",
        "code": "9220023",
        "name": "成功分部",
        "shortcut": "南區農成功"
    },
    {
        "parentCode": "922",
        "code": "9220034",
        "name": "聯合分部",
        "shortcut": "南區農聯合"
    },
    {
        "parentCode": "922",
        "code": "9220056",
        "name": "安順分部",
        "shortcut": "南區農安順"
    },
    {
        "parentCode": "922",
        "code": "9220067",
        "name": "和順分部",
        "shortcut": "南區農和順"
    },
    {
        "parentCode": "922",
        "code": "9220078",
        "name": "土城分部",
        "shortcut": "南區農土城"
    },
    {
        "parentCode": "922",
        "code": "9220089",
        "name": "灣裡分部",
        "shortcut": "南區農灣裡"
    },
    {
        "parentCode": "928",
        "code": "9280018",
        "name": "板橋區農會信用部",
        "shortcut": "板農信用部"
    },
    {
        "parentCode": "928",
        "code": "9280029",
        "name": "後埔辦事處",
        "shortcut": "板農後埔"
    },
    {
        "parentCode": "928",
        "code": "9280030",
        "name": "埔墘辦事處",
        "shortcut": "板農埔墘"
    },
    {
        "parentCode": "928",
        "code": "9280041",
        "name": "社後辦事處",
        "shortcut": "板農社後"
    },
    {
        "parentCode": "928",
        "code": "9280052",
        "name": "江翠辦事處",
        "shortcut": "板農江翠"
    },
    {
        "parentCode": "928",
        "code": "9280063",
        "name": "溪崑辦事處",
        "shortcut": "板農溪崑"
    },
    {
        "parentCode": "928",
        "code": "9280074",
        "name": "浮洲辦事處",
        "shortcut": "板農浮洲"
    },
    {
        "parentCode": "928",
        "code": "9280111",
        "name": "新埔辦事處",
        "shortcut": "板農新埔"
    },
    {
        "parentCode": "928",
        "code": "9280122",
        "name": "文化辦事處",
        "shortcut": "板農文化"
    },
    {
        "parentCode": "952",
        "code": "9520103",
        "name": "小港區農會",
        "shortcut": "小港農會"
    },
    {
        "parentCode": "004",
        "code": "0040037",
        "name": "營業部",
        "shortcut": "臺銀營業部"
    },
    {
        "parentCode": "004",
        "code": "0040059",
        "name": "公庫部",
        "shortcut": "臺銀公庫部"
    },
    {
        "parentCode": "004",
        "code": "0040071",
        "name": "館前分行",
        "shortcut": "臺銀館前"
    },
    {
        "parentCode": "004",
        "code": "0040093",
        "name": "台南分行",
        "shortcut": "臺銀台南"
    },
    {
        "parentCode": "004",
        "code": "0040107",
        "name": "台中分行",
        "shortcut": "臺銀台中"
    },
    {
        "parentCode": "004",
        "code": "0040118",
        "name": "高雄分行",
        "shortcut": "臺銀高雄"
    },
    {
        "parentCode": "004",
        "code": "0040129",
        "name": "基隆分行",
        "shortcut": "臺銀基隆"
    },
    {
        "parentCode": "004",
        "code": "0040130",
        "name": "中興新村分行",
        "shortcut": "臺銀中興"
    },
    {
        "parentCode": "004",
        "code": "0040141",
        "name": "嘉義分行",
        "shortcut": "臺銀嘉義"
    },
    {
        "parentCode": "004",
        "code": "0040152",
        "name": "新竹分行",
        "shortcut": "臺銀新竹"
    },
    {
        "parentCode": "004",
        "code": "0040163",
        "name": "彰化分行",
        "shortcut": "臺銀彰化"
    },
    {
        "parentCode": "004",
        "code": "0040174",
        "name": "屏東分行",
        "shortcut": "臺銀屏東"
    },
    {
        "parentCode": "004",
        "code": "0040185",
        "name": "花蓮分行",
        "shortcut": "臺銀花蓮"
    },
    {
        "parentCode": "004",
        "code": "0040196",
        "name": "延平分行",
        "shortcut": "臺銀延平"
    },
    {
        "parentCode": "004",
        "code": "0040200",
        "name": "中山分行",
        "shortcut": "臺銀中山"
    },
    {
        "parentCode": "004",
        "code": "0040211",
        "name": "高雄加工出口區分行",
        "shortcut": "臺銀高加"
    },
    {
        "parentCode": "004",
        "code": "0040222",
        "name": "宜蘭分行",
        "shortcut": "臺銀宜蘭"
    },
    {
        "parentCode": "004",
        "code": "0040233",
        "name": "台東分行",
        "shortcut": "臺銀台東"
    },
    {
        "parentCode": "004",
        "code": "0040244",
        "name": "澎湖分行",
        "shortcut": "臺銀澎湖"
    },
    {
        "parentCode": "004",
        "code": "0040255",
        "name": "鳳山分行",
        "shortcut": "臺銀鳳山"
    },
    {
        "parentCode": "004",
        "code": "0040266",
        "name": "桃園分行",
        "shortcut": "臺銀桃園"
    },
    {
        "parentCode": "004",
        "code": "0040277",
        "name": "板橋分行",
        "shortcut": "臺銀板橋"
    },
    {
        "parentCode": "004",
        "code": "0040288",
        "name": "新營分行",
        "shortcut": "臺銀新營"
    },
    {
        "parentCode": "004",
        "code": "0040299",
        "name": "苗栗分行",
        "shortcut": "臺銀苗栗"
    },
    {
        "parentCode": "004",
        "code": "0040303",
        "name": "豐原分行",
        "shortcut": "臺銀豐原"
    },
    {
        "parentCode": "004",
        "code": "0040314",
        "name": "斗六分行",
        "shortcut": "臺銀斗六"
    },
    {
        "parentCode": "004",
        "code": "0040325",
        "name": "南投分行",
        "shortcut": "臺銀南投"
    },
    {
        "parentCode": "004",
        "code": "0040336",
        "name": "南門分行",
        "shortcut": "臺銀南門"
    },
    {
        "parentCode": "004",
        "code": "0040347",
        "name": "公館分行",
        "shortcut": "臺銀公館"
    },
    {
        "parentCode": "004",
        "code": "0040358",
        "name": "左營分行",
        "shortcut": "臺銀左營"
    },
    {
        "parentCode": "004",
        "code": "0040369",
        "name": "北投分行",
        "shortcut": "臺銀北投"
    },
    {
        "parentCode": "004",
        "code": "0040370",
        "name": "霧峰分行",
        "shortcut": "臺銀霧峰"
    },
    {
        "parentCode": "004",
        "code": "0040381",
        "name": "金門分行",
        "shortcut": "臺銀金門"
    },
    {
        "parentCode": "004",
        "code": "0040392",
        "name": "馬祖分行",
        "shortcut": "臺銀馬祖"
    },
    {
        "parentCode": "004",
        "code": "0040406",
        "name": "安平分行",
        "shortcut": "臺銀安平"
    },
    {
        "parentCode": "004",
        "code": "0040417",
        "name": "中壢分行",
        "shortcut": "臺銀中壢"
    },
    {
        "parentCode": "004",
        "code": "0040428",
        "name": "三重分行",
        "shortcut": "臺銀三重"
    },
    {
        "parentCode": "004",
        "code": "0040439",
        "name": "頭份分行",
        "shortcut": "臺銀頭份"
    },
    {
        "parentCode": "004",
        "code": "0040440",
        "name": "前鎮分行",
        "shortcut": "臺銀前鎮"
    },
    {
        "parentCode": "004",
        "code": "0040451",
        "name": "城中分行",
        "shortcut": "臺銀城中"
    },
    {
        "parentCode": "004",
        "code": "0040462",
        "name": "民權分行",
        "shortcut": "臺銀民權"
    },
    {
        "parentCode": "004",
        "code": "0040473",
        "name": "潭子分行",
        "shortcut": "臺銀潭子"
    },
    {
        "parentCode": "004",
        "code": "0040484",
        "name": "連城分行",
        "shortcut": "臺銀連城"
    },
    {
        "parentCode": "004",
        "code": "0040495",
        "name": "員林分行",
        "shortcut": "臺銀員林"
    },
    {
        "parentCode": "004",
        "code": "0040509",
        "name": "松江分行",
        "shortcut": "臺銀松江"
    },
    {
        "parentCode": "004",
        "code": "0040510",
        "name": "鼓山分行",
        "shortcut": "臺銀鼓山"
    },
    {
        "parentCode": "004",
        "code": "0040521",
        "name": "龍山分行",
        "shortcut": "臺銀龍山"
    },
    {
        "parentCode": "004",
        "code": "0040532",
        "name": "忠孝分行",
        "shortcut": "臺銀忠孝"
    },
    {
        "parentCode": "004",
        "code": "0040543",
        "name": "信義分行",
        "shortcut": "臺銀信義"
    },
    {
        "parentCode": "004",
        "code": "0040554",
        "name": "復興分行",
        "shortcut": "臺銀復興"
    },
    {
        "parentCode": "004",
        "code": "0040565",
        "name": "三民分行",
        "shortcut": "臺銀三民"
    },
    {
        "parentCode": "004",
        "code": "0040576",
        "name": "台中港分行",
        "shortcut": "臺銀台中港"
    },
    {
        "parentCode": "004",
        "code": "0040587",
        "name": "羅東分行",
        "shortcut": "臺銀羅東"
    },
    {
        "parentCode": "004",
        "code": "0040598",
        "name": "埔里分行",
        "shortcut": "臺銀埔里"
    },
    {
        "parentCode": "004",
        "code": "0040602",
        "name": "岡山分行",
        "shortcut": "臺銀岡山"
    },
    {
        "parentCode": "004",
        "code": "0040613",
        "name": "新興分行",
        "shortcut": "臺銀新興"
    },
    {
        "parentCode": "004",
        "code": "0040624",
        "name": "苓雅分行",
        "shortcut": "臺銀苓雅"
    },
    {
        "parentCode": "004",
        "code": "0040646",
        "name": "松山分行",
        "shortcut": "臺銀松山"
    },
    {
        "parentCode": "004",
        "code": "0040657",
        "name": "健行分行",
        "shortcut": "臺銀健行"
    },
    {
        "parentCode": "004",
        "code": "0040668",
        "name": "中和分行",
        "shortcut": "臺銀中和"
    },
    {
        "parentCode": "004",
        "code": "0040679",
        "name": "太保分行",
        "shortcut": "臺銀太保"
    },
    {
        "parentCode": "004",
        "code": "0040680",
        "name": "竹北分行",
        "shortcut": "臺銀竹北"
    },
    {
        "parentCode": "004",
        "code": "0040705",
        "name": "士林分行",
        "shortcut": "臺銀士林"
    },
    {
        "parentCode": "004",
        "code": "0040716",
        "name": "新莊分行",
        "shortcut": "臺銀新莊"
    },
    {
        "parentCode": "004",
        "code": "0040727",
        "name": "大甲分行",
        "shortcut": "臺銀大甲"
    },
    {
        "parentCode": "004",
        "code": "0040738",
        "name": "新竹科學園區分行",
        "shortcut": "臺銀科學園"
    },
    {
        "parentCode": "004",
        "code": "0040749",
        "name": "樹林分行",
        "shortcut": "臺銀樹林"
    },
    {
        "parentCode": "004",
        "code": "0040750",
        "name": "新店分行",
        "shortcut": "臺銀新店"
    },
    {
        "parentCode": "004",
        "code": "0040794",
        "name": "黎明分行",
        "shortcut": "臺銀黎明"
    },
    {
        "parentCode": "004",
        "code": "0040808",
        "name": "民生分行",
        "shortcut": "臺銀民生"
    },
    {
        "parentCode": "004",
        "code": "0040819",
        "name": "永康分行",
        "shortcut": "臺銀永康"
    },
    {
        "parentCode": "004",
        "code": "0040820",
        "name": "三多分行",
        "shortcut": "臺銀三多"
    },
    {
        "parentCode": "004",
        "code": "0040853",
        "name": "台北世貿中心分行",
        "shortcut": "臺銀世貿"
    },
    {
        "parentCode": "004",
        "code": "0040864",
        "name": "大安分行",
        "shortcut": "臺銀大安"
    },
    {
        "parentCode": "004",
        "code": "0040875",
        "name": "華江分行",
        "shortcut": "臺銀華江"
    },
    {
        "parentCode": "004",
        "code": "0040886",
        "name": "潮州分行",
        "shortcut": "臺銀潮州"
    },
    {
        "parentCode": "004",
        "code": "0040897",
        "name": "蘇澳分行",
        "shortcut": "臺銀蘇澳"
    },
    {
        "parentCode": "004",
        "code": "0040901",
        "name": "大雅分行",
        "shortcut": "臺銀大雅"
    },
    {
        "parentCode": "004",
        "code": "0040912",
        "name": "楠梓分行",
        "shortcut": "臺銀楠梓"
    },
    {
        "parentCode": "004",
        "code": "0040923",
        "name": "台中工業區分行",
        "shortcut": "臺銀中工"
    },
    {
        "parentCode": "004",
        "code": "0041067",
        "name": "敦化分行",
        "shortcut": "臺銀敦化"
    },
    {
        "parentCode": "004",
        "code": "0041078",
        "name": "南港分行",
        "shortcut": "臺銀南港"
    },
    {
        "parentCode": "004",
        "code": "0041089",
        "name": "和平分行",
        "shortcut": "臺銀和平"
    },
    {
        "parentCode": "004",
        "code": "0041090",
        "name": "水湳分行",
        "shortcut": "臺銀水湳"
    },
    {
        "parentCode": "004",
        "code": "0041104",
        "name": "中崙分行",
        "shortcut": "臺銀中崙"
    },
    {
        "parentCode": "004",
        "code": "0041115",
        "name": "土城分行",
        "shortcut": "臺銀土城"
    },
    {
        "parentCode": "004",
        "code": "0041159",
        "name": "桃園國際機場分行",
        "shortcut": "臺銀桃機"
    },
    {
        "parentCode": "004",
        "code": "0041160",
        "name": "大昌分行",
        "shortcut": "臺銀大昌"
    },
    {
        "parentCode": "004",
        "code": "0041182",
        "name": "五甲分行",
        "shortcut": "臺銀五甲"
    },
    {
        "parentCode": "004",
        "code": "0041193",
        "name": "博愛分行",
        "shortcut": "臺銀博愛"
    },
    {
        "parentCode": "004",
        "code": "0041207",
        "name": "中庄分行",
        "shortcut": "臺銀中庄"
    },
    {
        "parentCode": "004",
        "code": "0041218",
        "name": "平鎮分行",
        "shortcut": "臺銀平鎮"
    },
    {
        "parentCode": "004",
        "code": "0041229",
        "name": "仁愛分行",
        "shortcut": "臺銀仁愛"
    },
    {
        "parentCode": "004",
        "code": "0041230",
        "name": "南崁分行",
        "shortcut": "臺銀南崁"
    },
    {
        "parentCode": "004",
        "code": "0041241",
        "name": "圓山分行",
        "shortcut": "臺銀圓山"
    },
    {
        "parentCode": "004",
        "code": "0041355",
        "name": "五股分行",
        "shortcut": "臺銀五股"
    },
    {
        "parentCode": "004",
        "code": "0041366",
        "name": "大里分行",
        "shortcut": "臺銀大里"
    },
    {
        "parentCode": "004",
        "code": "0041377",
        "name": "安南分行",
        "shortcut": "臺銀安南"
    },
    {
        "parentCode": "004",
        "code": "0041414",
        "name": "西屯分行",
        "shortcut": "臺銀西屯"
    },
    {
        "parentCode": "004",
        "code": "0041425",
        "name": "天母分行",
        "shortcut": "臺銀天母"
    },
    {
        "parentCode": "004",
        "code": "0041436",
        "name": "鹿港分行",
        "shortcut": "臺銀鹿港"
    },
    {
        "parentCode": "004",
        "code": "0041447",
        "name": "內壢分行",
        "shortcut": "臺銀內壢"
    },
    {
        "parentCode": "004",
        "code": "0041469",
        "name": "台南科學園區分行",
        "shortcut": "臺銀南科"
    },
    {
        "parentCode": "004",
        "code": "0041470",
        "name": "虎尾分行",
        "shortcut": "臺銀虎尾"
    },
    {
        "parentCode": "004",
        "code": "0041481",
        "name": "淡水分行",
        "shortcut": "臺銀淡水"
    },
    {
        "parentCode": "004",
        "code": "0041539",
        "name": "內湖分行",
        "shortcut": "臺銀內湖"
    },
    {
        "parentCode": "004",
        "code": "0041540",
        "name": "嘉北分行",
        "shortcut": "臺銀嘉北"
    },
    {
        "parentCode": "004",
        "code": "0041551",
        "name": "東港分行",
        "shortcut": "臺銀東港"
    },
    {
        "parentCode": "004",
        "code": "0041562",
        "name": "汐止分行",
        "shortcut": "臺銀汐止"
    },
    {
        "parentCode": "004",
        "code": "0041573",
        "name": "梧棲分行",
        "shortcut": "臺銀梧棲"
    },
    {
        "parentCode": "004",
        "code": "0041595",
        "name": "小港分行",
        "shortcut": "臺銀小港"
    },
    {
        "parentCode": "004",
        "code": "0041609",
        "name": "中屏分行",
        "shortcut": "臺銀中屏"
    },
    {
        "parentCode": "004",
        "code": "0041621",
        "name": "群賢分行",
        "shortcut": "臺銀群賢"
    },
    {
        "parentCode": "004",
        "code": "0041643",
        "name": "北大路分行",
        "shortcut": "臺銀北大"
    },
    {
        "parentCode": "004",
        "code": "0041654",
        "name": "文山分行",
        "shortcut": "臺銀文山"
    },
    {
        "parentCode": "004",
        "code": "0041702",
        "name": "太平分行",
        "shortcut": "臺銀太平"
    },
    {
        "parentCode": "004",
        "code": "0041713",
        "name": "德芳分行",
        "shortcut": "臺銀德芳"
    },
    {
        "parentCode": "004",
        "code": "0041724",
        "name": "建國分行",
        "shortcut": "臺銀建國"
    },
    {
        "parentCode": "004",
        "code": "0041768",
        "name": "屏東農科園區分行",
        "shortcut": "臺銀農科"
    },
    {
        "parentCode": "004",
        "code": "0041805",
        "name": "新園分行",
        "shortcut": "臺銀新園"
    },
    {
        "parentCode": "004",
        "code": "0041861",
        "name": "東桃園分行",
        "shortcut": "臺銀東桃園"
    },
    {
        "parentCode": "004",
        "code": "0041872",
        "name": "蘆洲分行",
        "shortcut": "臺銀蘆洲"
    },
    {
        "parentCode": "004",
        "code": "0041919",
        "name": "高雄國際機場分行",
        "shortcut": "臺銀高機"
    },
    {
        "parentCode": "004",
        "code": "0041931",
        "name": "永吉簡易型分行",
        "shortcut": "臺銀永吉簡"
    },
    {
        "parentCode": "004",
        "code": "0041942",
        "name": "東門簡易型分行",
        "shortcut": "臺銀東門簡"
    },
    {
        "parentCode": "004",
        "code": "0041953",
        "name": "愛國簡易型分行",
        "shortcut": "臺銀愛國簡"
    },
    {
        "parentCode": "004",
        "code": "0041986",
        "name": "台電簡易型分行",
        "shortcut": "臺銀台電簡"
    },
    {
        "parentCode": "004",
        "code": "0042053",
        "name": "北府簡易型分行",
        "shortcut": "臺銀北府簡"
    },
    {
        "parentCode": "004",
        "code": "0042189",
        "name": "台北港分行",
        "shortcut": "臺銀台北港"
    },
    {
        "parentCode": "004",
        "code": "0042204",
        "name": "臺中科學園區分行",
        "shortcut": "臺銀中科"
    },
    {
        "parentCode": "004",
        "code": "0042215",
        "name": "高雄科學園區分行",
        "shortcut": "臺銀高科"
    },
    {
        "parentCode": "004",
        "code": "0042237",
        "name": "東湖分行",
        "shortcut": "臺銀東湖"
    },
    {
        "parentCode": "004",
        "code": "0042248",
        "name": "高榮分行",
        "shortcut": "臺銀高榮"
    },
    {
        "parentCode": "004",
        "code": "0042259",
        "name": "南港軟體園區分行",
        "shortcut": "臺銀南港園"
    },
    {
        "parentCode": "004",
        "code": "0042260",
        "name": "龍潭分行",
        "shortcut": "臺銀龍潭"
    },
    {
        "parentCode": "004",
        "code": "0042271",
        "name": "仁德分行",
        "shortcut": "臺銀仁德"
    },
    {
        "parentCode": "004",
        "code": "0042282",
        "name": "林口分行",
        "shortcut": "臺銀林口"
    },
    {
        "parentCode": "004",
        "code": "0042293",
        "name": "木柵分行",
        "shortcut": "臺銀木柵"
    },
    {
        "parentCode": "004",
        "code": "0042307",
        "name": "臺南創新園區分行",
        "shortcut": "臺銀南創"
    },
    {
        "parentCode": "004",
        "code": "0042363",
        "name": "武昌分行",
        "shortcut": "臺銀武昌"
    },
    {
        "parentCode": "004",
        "code": "0042385",
        "name": "臺北分行",
        "shortcut": "臺銀臺北"
    },
    {
        "parentCode": "004",
        "code": "0042396",
        "name": "金山分行",
        "shortcut": "臺銀金山"
    },
    {
        "parentCode": "004",
        "code": "0042400",
        "name": "信安分行",
        "shortcut": "臺銀信安"
    },
    {
        "parentCode": "004",
        "code": "0042411",
        "name": "劍潭分行",
        "shortcut": "臺銀劍潭"
    },
    {
        "parentCode": "004",
        "code": "0042422",
        "name": "萬華分行",
        "shortcut": "臺銀萬華"
    },
    {
        "parentCode": "004",
        "code": "0042433",
        "name": "板新分行",
        "shortcut": "臺銀板新"
    },
    {
        "parentCode": "004",
        "code": "0042444",
        "name": "新永和分行",
        "shortcut": "臺銀新永和"
    },
    {
        "parentCode": "004",
        "code": "0042455",
        "name": "南新莊分行",
        "shortcut": "臺銀南新莊"
    },
    {
        "parentCode": "004",
        "code": "0042466",
        "name": "桃興分行",
        "shortcut": "臺銀桃興"
    },
    {
        "parentCode": "004",
        "code": "0042477",
        "name": "新明分行",
        "shortcut": "臺銀新明"
    },
    {
        "parentCode": "004",
        "code": "0042488",
        "name": "六家分行",
        "shortcut": "臺銀六家"
    },
    {
        "parentCode": "004",
        "code": "0042499",
        "name": "北臺中分行",
        "shortcut": "臺銀北臺中"
    },
    {
        "parentCode": "004",
        "code": "0042503",
        "name": "中臺中分行",
        "shortcut": "臺銀中臺中"
    },
    {
        "parentCode": "004",
        "code": "0042525",
        "name": "嘉南分行",
        "shortcut": "臺銀嘉南"
    },
    {
        "parentCode": "004",
        "code": "0042536",
        "name": "南都分行",
        "shortcut": "臺銀南都"
    },
    {
        "parentCode": "004",
        "code": "0042558",
        "name": "北高雄分行",
        "shortcut": "臺銀北高雄"
    },
    {
        "parentCode": "004",
        "code": "0042569",
        "name": "成功分行",
        "shortcut": "臺銀成功"
    },
    {
        "parentCode": "004",
        "code": "0042570",
        "name": "北花蓮分行",
        "shortcut": "臺銀北花蓮"
    },
    {
        "parentCode": "004",
        "code": "0042709",
        "name": "新湖分行",
        "shortcut": "臺銀新湖"
    },
    {
        "parentCode": "004",
        "code": "0042710",
        "name": "五福分行",
        "shortcut": "臺銀五福"
    },
    {
        "parentCode": "004",
        "code": "0042721",
        "name": "六甲頂分行",
        "shortcut": "臺銀六甲頂"
    },
    {
        "parentCode": "004",
        "code": "0042787",
        "name": "中都分行",
        "shortcut": "臺銀中都"
    },
    {
        "parentCode": "004",
        "code": "0042798",
        "name": "臺北國際機場分行",
        "shortcut": "臺銀北機"
    },
    {
        "parentCode": "004",
        "code": "0042802",
        "name": "新莊副都心分行",
        "shortcut": "臺銀副都"
    },
    {
        "parentCode": "004",
        "code": "0042835",
        "name": "仁武分行",
        "shortcut": "臺銀仁武"
    },
    {
        "parentCode": "005",
        "code": "0050027",
        "name": "基隆分行",
        "shortcut": "土銀基隆"
    },
    {
        "parentCode": "005",
        "code": "0050038",
        "name": "中和分行",
        "shortcut": "土銀中和"
    },
    {
        "parentCode": "005",
        "code": "0050049",
        "name": "南港分行",
        "shortcut": "土銀南港"
    },
    {
        "parentCode": "005",
        "code": "0050050",
        "name": "台北分行",
        "shortcut": "土銀台北"
    },
    {
        "parentCode": "005",
        "code": "0050061",
        "name": "民權分行",
        "shortcut": "土銀民權"
    },
    {
        "parentCode": "005",
        "code": "0050072",
        "name": "古亭分行",
        "shortcut": "土銀古亭"
    },
    {
        "parentCode": "005",
        "code": "0050083",
        "name": "長安分行",
        "shortcut": "土銀長安"
    },
    {
        "parentCode": "005",
        "code": "0050094",
        "name": "士林分行",
        "shortcut": "土銀士林"
    },
    {
        "parentCode": "005",
        "code": "0050108",
        "name": "三重分行",
        "shortcut": "土銀三重"
    },
    {
        "parentCode": "005",
        "code": "0050119",
        "name": "宜蘭分行",
        "shortcut": "土銀宜蘭"
    },
    {
        "parentCode": "005",
        "code": "0050120",
        "name": "羅東分行",
        "shortcut": "土銀羅東"
    },
    {
        "parentCode": "005",
        "code": "0050131",
        "name": "桃園分行",
        "shortcut": "土銀桃園"
    },
    {
        "parentCode": "005",
        "code": "0050142",
        "name": "中壢分行",
        "shortcut": "土銀中壢"
    },
    {
        "parentCode": "005",
        "code": "0050153",
        "name": "石門分行",
        "shortcut": "土銀石門"
    },
    {
        "parentCode": "005",
        "code": "0050164",
        "name": "新竹分行",
        "shortcut": "土銀新竹"
    },
    {
        "parentCode": "005",
        "code": "0050175",
        "name": "竹東分行",
        "shortcut": "土銀竹東"
    },
    {
        "parentCode": "005",
        "code": "0050186",
        "name": "花蓮分行",
        "shortcut": "土銀花蓮"
    },
    {
        "parentCode": "005",
        "code": "0050197",
        "name": "玉里分行",
        "shortcut": "土銀玉里"
    },
    {
        "parentCode": "005",
        "code": "0050201",
        "name": "苗栗分行",
        "shortcut": "土銀苗栗"
    },
    {
        "parentCode": "005",
        "code": "0050212",
        "name": "頭份分行",
        "shortcut": "土銀頭份"
    },
    {
        "parentCode": "005",
        "code": "0050223",
        "name": "豐原分行",
        "shortcut": "土銀豐原"
    },
    {
        "parentCode": "005",
        "code": "0050234",
        "name": "大甲分行",
        "shortcut": "土銀大甲"
    },
    {
        "parentCode": "005",
        "code": "0050245",
        "name": "台中分行",
        "shortcut": "土銀台中"
    },
    {
        "parentCode": "005",
        "code": "0050256",
        "name": "南投分行",
        "shortcut": "土銀南投"
    },
    {
        "parentCode": "005",
        "code": "0050267",
        "name": "員林分行",
        "shortcut": "土銀員林"
    },
    {
        "parentCode": "005",
        "code": "0050278",
        "name": "斗六分行",
        "shortcut": "土銀斗六"
    },
    {
        "parentCode": "005",
        "code": "0050289",
        "name": "北港分行",
        "shortcut": "土銀北港"
    },
    {
        "parentCode": "005",
        "code": "0050290",
        "name": "嘉義分行",
        "shortcut": "土銀嘉義"
    },
    {
        "parentCode": "005",
        "code": "0050304",
        "name": "新營分行",
        "shortcut": "土銀新營"
    },
    {
        "parentCode": "005",
        "code": "0050315",
        "name": "永康分行",
        "shortcut": "土銀永康"
    },
    {
        "parentCode": "005",
        "code": "0050326",
        "name": "台南分行",
        "shortcut": "土銀台南"
    },
    {
        "parentCode": "005",
        "code": "0050337",
        "name": "高雄分行",
        "shortcut": "土銀高雄"
    },
    {
        "parentCode": "005",
        "code": "0050348",
        "name": "岡山分行",
        "shortcut": "土銀岡山"
    },
    {
        "parentCode": "005",
        "code": "0050359",
        "name": "美濃分行",
        "shortcut": "土銀美濃"
    },
    {
        "parentCode": "005",
        "code": "0050360",
        "name": "屏東分行",
        "shortcut": "土銀屏東"
    },
    {
        "parentCode": "005",
        "code": "0050371",
        "name": "台東分行",
        "shortcut": "土銀台東"
    },
    {
        "parentCode": "005",
        "code": "0050382",
        "name": "青年分行",
        "shortcut": "土銀青年"
    },
    {
        "parentCode": "005",
        "code": "0050393",
        "name": "金門分行",
        "shortcut": "土銀金門"
    },
    {
        "parentCode": "005",
        "code": "0050407",
        "name": "澎湖分行",
        "shortcut": "土銀澎湖"
    },
    {
        "parentCode": "005",
        "code": "0050418",
        "name": "營業部",
        "shortcut": "土銀營業部"
    },
    {
        "parentCode": "005",
        "code": "0050452",
        "name": "和平分行",
        "shortcut": "土銀和平"
    },
    {
        "parentCode": "005",
        "code": "0050463",
        "name": "潮州分行",
        "shortcut": "土銀潮州"
    },
    {
        "parentCode": "005",
        "code": "0050474",
        "name": "彰化分行",
        "shortcut": "土銀彰化"
    },
    {
        "parentCode": "005",
        "code": "0050485",
        "name": "中山分行",
        "shortcut": "土銀中山"
    },
    {
        "parentCode": "005",
        "code": "0050496",
        "name": "永和分行",
        "shortcut": "土銀永和"
    },
    {
        "parentCode": "005",
        "code": "0050500",
        "name": "板橋分行",
        "shortcut": "土銀板橋"
    },
    {
        "parentCode": "005",
        "code": "0050511",
        "name": "鳳山分行",
        "shortcut": "土銀鳳山"
    },
    {
        "parentCode": "005",
        "code": "0050522",
        "name": "湖口分行",
        "shortcut": "土銀湖口"
    },
    {
        "parentCode": "005",
        "code": "0050533",
        "name": "蘇澳分行",
        "shortcut": "土銀蘇澳"
    },
    {
        "parentCode": "005",
        "code": "0050544",
        "name": "新興分行",
        "shortcut": "土銀新興"
    },
    {
        "parentCode": "005",
        "code": "0050555",
        "name": "西台中分行",
        "shortcut": "土銀西台中"
    },
    {
        "parentCode": "005",
        "code": "0050566",
        "name": "虎尾分行",
        "shortcut": "土銀虎尾"
    },
    {
        "parentCode": "005",
        "code": "0050577",
        "name": "仁愛分行",
        "shortcut": "土銀仁愛"
    },
    {
        "parentCode": "005",
        "code": "0050588",
        "name": "忠孝分行",
        "shortcut": "土銀忠孝"
    },
    {
        "parentCode": "005",
        "code": "0050599",
        "name": "中正分行",
        "shortcut": "土銀中正"
    },
    {
        "parentCode": "005",
        "code": "0050614",
        "name": "新店分行",
        "shortcut": "土銀新店"
    },
    {
        "parentCode": "005",
        "code": "0050625",
        "name": "北台南分行",
        "shortcut": "土銀北台南"
    },
    {
        "parentCode": "005",
        "code": "0050636",
        "name": "松山分行",
        "shortcut": "土銀松山"
    },
    {
        "parentCode": "005",
        "code": "0050647",
        "name": "內湖分行",
        "shortcut": "土銀內湖"
    },
    {
        "parentCode": "005",
        "code": "0050658",
        "name": "三民分行",
        "shortcut": "土銀三民"
    },
    {
        "parentCode": "005",
        "code": "0050669",
        "name": "民雄分行",
        "shortcut": "土銀民雄"
    },
    {
        "parentCode": "005",
        "code": "0050670",
        "name": "大社分行",
        "shortcut": "土銀大社"
    },
    {
        "parentCode": "005",
        "code": "0050692",
        "name": "前鎮分行",
        "shortcut": "土銀前鎮"
    },
    {
        "parentCode": "005",
        "code": "0050706",
        "name": "路竹分行",
        "shortcut": "土銀路竹"
    },
    {
        "parentCode": "005",
        "code": "0050717",
        "name": "五甲分行",
        "shortcut": "土銀五甲"
    },
    {
        "parentCode": "005",
        "code": "0050728",
        "name": "太平分行",
        "shortcut": "土銀太平"
    },
    {
        "parentCode": "005",
        "code": "0050739",
        "name": "正濱分行",
        "shortcut": "土銀正濱"
    },
    {
        "parentCode": "005",
        "code": "0050740",
        "name": "敦化分行",
        "shortcut": "土銀敦化"
    },
    {
        "parentCode": "005",
        "code": "0050762",
        "name": "蘆洲分行",
        "shortcut": "土銀蘆洲"
    },
    {
        "parentCode": "005",
        "code": "0050773",
        "name": "北台中分行",
        "shortcut": "土銀北台中"
    },
    {
        "parentCode": "005",
        "code": "0050784",
        "name": "苓雅分行",
        "shortcut": "土銀苓雅"
    },
    {
        "parentCode": "005",
        "code": "0050795",
        "name": "信義分行",
        "shortcut": "土銀信義"
    },
    {
        "parentCode": "005",
        "code": "0050809",
        "name": "土城分行",
        "shortcut": "土銀土城"
    },
    {
        "parentCode": "005",
        "code": "0050810",
        "name": "淡水分行",
        "shortcut": "土銀淡水"
    },
    {
        "parentCode": "005",
        "code": "0050821",
        "name": "草屯分行",
        "shortcut": "土銀草屯"
    },
    {
        "parentCode": "005",
        "code": "0050832",
        "name": "東台南分行",
        "shortcut": "土銀東台南"
    },
    {
        "parentCode": "005",
        "code": "0050843",
        "name": "通霄分行",
        "shortcut": "土銀通霄"
    },
    {
        "parentCode": "005",
        "code": "0050854",
        "name": "學甲分行",
        "shortcut": "土銀學甲"
    },
    {
        "parentCode": "005",
        "code": "0050865",
        "name": "新莊分行",
        "shortcut": "土銀新莊"
    },
    {
        "parentCode": "005",
        "code": "0050876",
        "name": "雙和分行",
        "shortcut": "土銀雙和"
    },
    {
        "parentCode": "005",
        "code": "0050898",
        "name": "白河分行",
        "shortcut": "土銀白河"
    },
    {
        "parentCode": "005",
        "code": "0050902",
        "name": "復興分行",
        "shortcut": "土銀復興"
    },
    {
        "parentCode": "005",
        "code": "0050913",
        "name": "平鎮分行",
        "shortcut": "土銀平鎮"
    },
    {
        "parentCode": "005",
        "code": "0050935",
        "name": "文山分行",
        "shortcut": "土銀文山"
    },
    {
        "parentCode": "005",
        "code": "0050946",
        "name": "中港分行",
        "shortcut": "土銀中港"
    },
    {
        "parentCode": "005",
        "code": "0050957",
        "name": "東板橋分行",
        "shortcut": "土銀東板橋"
    },
    {
        "parentCode": "005",
        "code": "0050968",
        "name": "南崁分行",
        "shortcut": "土銀南崁"
    },
    {
        "parentCode": "005",
        "code": "0050979",
        "name": "建國分行",
        "shortcut": "土銀建國"
    },
    {
        "parentCode": "005",
        "code": "0050980",
        "name": "樹林分行",
        "shortcut": "土銀樹林"
    },
    {
        "parentCode": "005",
        "code": "0050991",
        "name": "東台北分行",
        "shortcut": "土銀東台北"
    },
    {
        "parentCode": "005",
        "code": "0051002",
        "name": "西三重分行",
        "shortcut": "土銀西三重"
    },
    {
        "parentCode": "005",
        "code": "0051013",
        "name": "南台中分行",
        "shortcut": "土銀南台中"
    },
    {
        "parentCode": "005",
        "code": "0051024",
        "name": "長春分行",
        "shortcut": "土銀長春"
    },
    {
        "parentCode": "005",
        "code": "0051035",
        "name": "東新竹分行",
        "shortcut": "土銀東新竹"
    },
    {
        "parentCode": "005",
        "code": "0051046",
        "name": "新市分行",
        "shortcut": "土銀新市"
    },
    {
        "parentCode": "005",
        "code": "0051057",
        "name": "博愛分行",
        "shortcut": "土銀博愛"
    },
    {
        "parentCode": "005",
        "code": "0051068",
        "name": "中崙分行",
        "shortcut": "土銀中崙"
    },
    {
        "parentCode": "005",
        "code": "0051079",
        "name": "華江分行",
        "shortcut": "土銀華江"
    },
    {
        "parentCode": "005",
        "code": "0051080",
        "name": "竹北分行",
        "shortcut": "土銀竹北"
    },
    {
        "parentCode": "005",
        "code": "0051091",
        "name": "安平分行",
        "shortcut": "土銀安平"
    },
    {
        "parentCode": "005",
        "code": "0051105",
        "name": "嘉興分行",
        "shortcut": "土銀嘉興"
    },
    {
        "parentCode": "005",
        "code": "0051116",
        "name": "南新莊分行",
        "shortcut": "土銀南新莊"
    },
    {
        "parentCode": "005",
        "code": "0051127",
        "name": "三峽分行",
        "shortcut": "土銀三峽"
    },
    {
        "parentCode": "005",
        "code": "0051138",
        "name": "沙鹿分行",
        "shortcut": "土銀沙鹿"
    },
    {
        "parentCode": "005",
        "code": "0051149",
        "name": "南桃園分行",
        "shortcut": "土銀南桃園"
    },
    {
        "parentCode": "005",
        "code": "0051150",
        "name": "汐止分行",
        "shortcut": "土銀汐止"
    },
    {
        "parentCode": "005",
        "code": "0051161",
        "name": "萬華分行",
        "shortcut": "土銀萬華"
    },
    {
        "parentCode": "005",
        "code": "0051172",
        "name": "小港分行",
        "shortcut": "土銀小港"
    },
    {
        "parentCode": "005",
        "code": "0051183",
        "name": "新工分行",
        "shortcut": "土銀新工"
    },
    {
        "parentCode": "005",
        "code": "0051194",
        "name": "烏日分行",
        "shortcut": "土銀烏日"
    },
    {
        "parentCode": "005",
        "code": "0051208",
        "name": "西湖分行",
        "shortcut": "土銀西湖"
    },
    {
        "parentCode": "005",
        "code": "0051219",
        "name": "八德分行",
        "shortcut": "土銀八德"
    },
    {
        "parentCode": "005",
        "code": "0051220",
        "name": "北屯分行",
        "shortcut": "土銀北屯"
    },
    {
        "parentCode": "005",
        "code": "0051231",
        "name": "大安分行",
        "shortcut": "土銀大安"
    },
    {
        "parentCode": "005",
        "code": "0051242",
        "name": "北中壢分行",
        "shortcut": "土銀北中壢"
    },
    {
        "parentCode": "005",
        "code": "0051253",
        "name": "高樹分行",
        "shortcut": "土銀高樹"
    },
    {
        "parentCode": "005",
        "code": "0051264",
        "name": "枋寮分行",
        "shortcut": "土銀枋寮"
    },
    {
        "parentCode": "005",
        "code": "0051286",
        "name": "金城分行",
        "shortcut": "土銀金城"
    },
    {
        "parentCode": "005",
        "code": "0051297",
        "name": "光復分行",
        "shortcut": "土銀光復"
    },
    {
        "parentCode": "005",
        "code": "0051301",
        "name": "左營分行",
        "shortcut": "土銀左營"
    },
    {
        "parentCode": "005",
        "code": "0051312",
        "name": "北桃園分行",
        "shortcut": "土銀北桃園"
    },
    {
        "parentCode": "005",
        "code": "0051323",
        "name": "東港分行",
        "shortcut": "土銀東港"
    },
    {
        "parentCode": "005",
        "code": "0051334",
        "name": "天母分行",
        "shortcut": "土銀天母"
    },
    {
        "parentCode": "005",
        "code": "0051345",
        "name": "泰山分行",
        "shortcut": "土銀泰山"
    },
    {
        "parentCode": "005",
        "code": "0051356",
        "name": "中科分行",
        "shortcut": "土銀中科"
    },
    {
        "parentCode": "005",
        "code": "0051367",
        "name": "大園分行",
        "shortcut": "土銀大園"
    },
    {
        "parentCode": "005",
        "code": "0051378",
        "name": "楊梅分行",
        "shortcut": "土銀楊梅"
    },
    {
        "parentCode": "005",
        "code": "0051389",
        "name": "東門分行",
        "shortcut": "土銀東門"
    },
    {
        "parentCode": "005",
        "code": "0051404",
        "name": "城東分行",
        "shortcut": "土銀城東"
    },
    {
        "parentCode": "005",
        "code": "0051415",
        "name": "松南分行",
        "shortcut": "土銀松南"
    },
    {
        "parentCode": "005",
        "code": "0051426",
        "name": "福興分行",
        "shortcut": "土銀福興"
    },
    {
        "parentCode": "005",
        "code": "0051437",
        "name": "林口分行",
        "shortcut": "土銀林口"
    },
    {
        "parentCode": "005",
        "code": "0051448",
        "name": "西屯分行",
        "shortcut": "土銀西屯"
    },
    {
        "parentCode": "005",
        "code": "0051459",
        "name": "內壢分行",
        "shortcut": "土銀內壢"
    },
    {
        "parentCode": "005",
        "code": "0051460",
        "name": "竹南分行",
        "shortcut": "土銀竹南"
    },
    {
        "parentCode": "005",
        "code": "0051471",
        "name": "安南分行",
        "shortcut": "土銀安南"
    },
    {
        "parentCode": "005",
        "code": "0051482",
        "name": "汐科分行",
        "shortcut": "土銀汐科"
    },
    {
        "parentCode": "005",
        "code": "0051493",
        "name": "楠梓分行",
        "shortcut": "土銀楠梓"
    },
    {
        "parentCode": "005",
        "code": "0051507",
        "name": "大里分行",
        "shortcut": "土銀大里"
    },
    {
        "parentCode": "005",
        "code": "0051518",
        "name": "大灣分行",
        "shortcut": "土銀大灣"
    },
    {
        "parentCode": "005",
        "code": "0051530",
        "name": "大發分行",
        "shortcut": "土銀大發"
    },
    {
        "parentCode": "005",
        "code": "0051552",
        "name": "圓山分行",
        "shortcut": "土銀圓山"
    },
    {
        "parentCode": "005",
        "code": "0051563",
        "name": "工研院分行",
        "shortcut": "土銀工研院"
    },
    {
        "parentCode": "005",
        "code": "0051574",
        "name": "北三重分行",
        "shortcut": "土銀北三重"
    },
    {
        "parentCode": "005",
        "code": "0051585",
        "name": "圓通分行",
        "shortcut": "土銀圓通"
    },
    {
        "parentCode": "005",
        "code": "0051600",
        "name": "石牌分行",
        "shortcut": "土銀石牌"
    },
    {
        "parentCode": "005",
        "code": "0051611",
        "name": "南屯分行",
        "shortcut": "土銀南屯"
    },
    {
        "parentCode": "005",
        "code": "0051633",
        "name": "寶中分行",
        "shortcut": "土銀寶中"
    },
    {
        "parentCode": "005",
        "code": "0051644",
        "name": "中清分行",
        "shortcut": "土銀中清"
    },
    {
        "parentCode": "005",
        "code": "0051655",
        "name": "南京東路分行",
        "shortcut": "土銀南京東"
    },
    {
        "parentCode": "006",
        "code": "0060017",
        "name": "總行",
        "shortcut": "合庫總行"
    },
    {
        "parentCode": "006",
        "code": "0060028",
        "name": "中山路分行",
        "shortcut": "合庫中山路"
    },
    {
        "parentCode": "006",
        "code": "0060039",
        "name": "西門分行",
        "shortcut": "合庫西門"
    },
    {
        "parentCode": "006",
        "code": "0060040",
        "name": "延平分行",
        "shortcut": "合庫延平"
    },
    {
        "parentCode": "006",
        "code": "0060051",
        "name": "大稻埕分行",
        "shortcut": "合庫大稻埕"
    },
    {
        "parentCode": "006",
        "code": "0060062",
        "name": "東門分行",
        "shortcut": "合庫東門"
    },
    {
        "parentCode": "006",
        "code": "0060073",
        "name": "松山分行",
        "shortcut": "合庫松山"
    },
    {
        "parentCode": "006",
        "code": "0060084",
        "name": "新店分行",
        "shortcut": "合庫新店"
    },
    {
        "parentCode": "006",
        "code": "0060095",
        "name": "永和分行",
        "shortcut": "合庫永和"
    },
    {
        "parentCode": "006",
        "code": "0060109",
        "name": "三重分行",
        "shortcut": "合庫三重"
    },
    {
        "parentCode": "006",
        "code": "0060110",
        "name": "板橋分行",
        "shortcut": "合庫板橋"
    },
    {
        "parentCode": "006",
        "code": "0060121",
        "name": "基隆分行",
        "shortcut": "合庫基隆"
    },
    {
        "parentCode": "006",
        "code": "0060132",
        "name": "宜蘭分行",
        "shortcut": "合庫宜蘭"
    },
    {
        "parentCode": "006",
        "code": "0060143",
        "name": "蘇澳分行",
        "shortcut": "合庫蘇澳"
    },
    {
        "parentCode": "006",
        "code": "0060154",
        "name": "桃園分行",
        "shortcut": "合庫桃園"
    },
    {
        "parentCode": "006",
        "code": "0060165",
        "name": "中壢分行",
        "shortcut": "合庫中壢"
    },
    {
        "parentCode": "006",
        "code": "0060176",
        "name": "新竹分行",
        "shortcut": "合庫新竹"
    },
    {
        "parentCode": "006",
        "code": "0060187",
        "name": "苗栗分行",
        "shortcut": "合庫苗栗"
    },
    {
        "parentCode": "006",
        "code": "0060198",
        "name": "頭份分行",
        "shortcut": "合庫頭份"
    },
    {
        "parentCode": "006",
        "code": "0060202",
        "name": "豐原分行",
        "shortcut": "合庫豐原"
    },
    {
        "parentCode": "006",
        "code": "0060213",
        "name": "沙鹿分行",
        "shortcut": "合庫沙鹿"
    },
    {
        "parentCode": "006",
        "code": "0060224",
        "name": "台中分行",
        "shortcut": "合庫台中"
    },
    {
        "parentCode": "006",
        "code": "0060235",
        "name": "彰化分行",
        "shortcut": "合庫彰化"
    },
    {
        "parentCode": "006",
        "code": "0060246",
        "name": "員林分行",
        "shortcut": "合庫員林"
    },
    {
        "parentCode": "006",
        "code": "0060257",
        "name": "南投分行",
        "shortcut": "合庫南投"
    },
    {
        "parentCode": "006",
        "code": "0060268",
        "name": "斗六分行",
        "shortcut": "合庫斗六"
    },
    {
        "parentCode": "006",
        "code": "0060279",
        "name": "北港分行",
        "shortcut": "合庫北港"
    },
    {
        "parentCode": "006",
        "code": "0060280",
        "name": "嘉義分行",
        "shortcut": "合庫嘉義"
    },
    {
        "parentCode": "006",
        "code": "0060291",
        "name": "新營分行",
        "shortcut": "合庫新營"
    },
    {
        "parentCode": "006",
        "code": "0060305",
        "name": "台南分行",
        "shortcut": "合庫台南"
    },
    {
        "parentCode": "006",
        "code": "0060316",
        "name": "成功分行",
        "shortcut": "合庫成功"
    },
    {
        "parentCode": "006",
        "code": "0060327",
        "name": "鳳山分行",
        "shortcut": "合庫鳳山"
    },
    {
        "parentCode": "006",
        "code": "0060338",
        "name": "岡山分行",
        "shortcut": "合庫岡山"
    },
    {
        "parentCode": "006",
        "code": "0060349",
        "name": "高雄分行",
        "shortcut": "合庫高雄"
    },
    {
        "parentCode": "006",
        "code": "0060350",
        "name": "新興分行",
        "shortcut": "合庫新興"
    },
    {
        "parentCode": "006",
        "code": "0060361",
        "name": "屏東分行",
        "shortcut": "合庫屏東"
    },
    {
        "parentCode": "006",
        "code": "0060372",
        "name": "潮州分行",
        "shortcut": "合庫潮州"
    },
    {
        "parentCode": "006",
        "code": "0060383",
        "name": "花蓮分行",
        "shortcut": "合庫花蓮"
    },
    {
        "parentCode": "006",
        "code": "0060394",
        "name": "台東分行",
        "shortcut": "合庫台東"
    },
    {
        "parentCode": "006",
        "code": "0060408",
        "name": "澎湖分行",
        "shortcut": "合庫澎湖"
    },
    {
        "parentCode": "006",
        "code": "0060419",
        "name": "南京東路分行",
        "shortcut": "合庫南京東"
    },
    {
        "parentCode": "006",
        "code": "0060420",
        "name": "北高雄分行",
        "shortcut": "合庫北高雄"
    },
    {
        "parentCode": "006",
        "code": "0060431",
        "name": "大同分行",
        "shortcut": "合庫大同"
    },
    {
        "parentCode": "006",
        "code": "0060453",
        "name": "忠孝分行",
        "shortcut": "合庫忠孝"
    },
    {
        "parentCode": "006",
        "code": "0060464",
        "name": "景美分行",
        "shortcut": "合庫景美"
    },
    {
        "parentCode": "006",
        "code": "0060475",
        "name": "士林分行",
        "shortcut": "合庫士林"
    },
    {
        "parentCode": "006",
        "code": "0060486",
        "name": "汐止分行",
        "shortcut": "合庫汐止"
    },
    {
        "parentCode": "006",
        "code": "0060497",
        "name": "新莊分行",
        "shortcut": "合庫新莊"
    },
    {
        "parentCode": "006",
        "code": "0060501",
        "name": "中興分行",
        "shortcut": "合庫中興"
    },
    {
        "parentCode": "006",
        "code": "0060512",
        "name": "苓雅分行",
        "shortcut": "合庫苓雅"
    },
    {
        "parentCode": "006",
        "code": "0060545",
        "name": "台北分行",
        "shortcut": "合庫台北"
    },
    {
        "parentCode": "006",
        "code": "0060567",
        "name": "營業部",
        "shortcut": "合庫營"
    },
    {
        "parentCode": "006",
        "code": "0060578",
        "name": "南豐原分行",
        "shortcut": "合庫南豐原"
    },
    {
        "parentCode": "006",
        "code": "0060589",
        "name": "羅東分行",
        "shortcut": "合庫羅東"
    },
    {
        "parentCode": "006",
        "code": "0060590",
        "name": "三民分行",
        "shortcut": "合庫三民"
    },
    {
        "parentCode": "006",
        "code": "0060604",
        "name": "城東分行",
        "shortcut": "合庫城東"
    },
    {
        "parentCode": "006",
        "code": "0060615",
        "name": "佳里分行",
        "shortcut": "合庫佳里"
    },
    {
        "parentCode": "006",
        "code": "0060626",
        "name": "中和分行",
        "shortcut": "合庫中和"
    },
    {
        "parentCode": "006",
        "code": "0060637",
        "name": "南高雄分行",
        "shortcut": "合庫南高雄"
    },
    {
        "parentCode": "006",
        "code": "0060648",
        "name": "南嘉義分行",
        "shortcut": "合庫南嘉義"
    },
    {
        "parentCode": "006",
        "code": "0060659",
        "name": "竹東分行",
        "shortcut": "合庫竹東"
    },
    {
        "parentCode": "006",
        "code": "0060671",
        "name": "東三重分行",
        "shortcut": "合庫東三重"
    },
    {
        "parentCode": "006",
        "code": "0060682",
        "name": "南興分行",
        "shortcut": "合庫南興"
    },
    {
        "parentCode": "006",
        "code": "0060693",
        "name": "五權分行",
        "shortcut": "合庫五權"
    },
    {
        "parentCode": "006",
        "code": "0060707",
        "name": "埔里分行",
        "shortcut": "合庫埔里"
    },
    {
        "parentCode": "006",
        "code": "0060718",
        "name": "大順分行",
        "shortcut": "合庫大順"
    },
    {
        "parentCode": "006",
        "code": "0060729",
        "name": "南勢角分行",
        "shortcut": "合庫南勢角"
    },
    {
        "parentCode": "006",
        "code": "0060730",
        "name": "朴子分行",
        "shortcut": "合庫朴子"
    },
    {
        "parentCode": "006",
        "code": "0060763",
        "name": "大安分行",
        "shortcut": "合庫大安"
    },
    {
        "parentCode": "006",
        "code": "0060774",
        "name": "民權分行",
        "shortcut": "合庫民權"
    },
    {
        "parentCode": "006",
        "code": "0060785",
        "name": "東高雄分行",
        "shortcut": "合庫東高雄"
    },
    {
        "parentCode": "006",
        "code": "0060796",
        "name": "東台北分行",
        "shortcut": "合庫東台北"
    },
    {
        "parentCode": "006",
        "code": "0060800",
        "name": "城內分行",
        "shortcut": "合庫城內"
    },
    {
        "parentCode": "006",
        "code": "0060811",
        "name": "建國分行",
        "shortcut": "合庫建國"
    },
    {
        "parentCode": "006",
        "code": "0060822",
        "name": "圓山分行",
        "shortcut": "合庫圓山"
    },
    {
        "parentCode": "006",
        "code": "0060833",
        "name": "信義分行",
        "shortcut": "合庫信義"
    },
    {
        "parentCode": "006",
        "code": "0060844",
        "name": "長春分行",
        "shortcut": "合庫長春"
    },
    {
        "parentCode": "006",
        "code": "0060855",
        "name": "仁愛分行",
        "shortcut": "合庫仁愛"
    },
    {
        "parentCode": "006",
        "code": "0060866",
        "name": "玉成分行",
        "shortcut": "合庫玉成"
    },
    {
        "parentCode": "006",
        "code": "0060877",
        "name": "古亭分行",
        "shortcut": "合庫古亭"
    },
    {
        "parentCode": "006",
        "code": "0060888",
        "name": "長安分行",
        "shortcut": "合庫長安"
    },
    {
        "parentCode": "006",
        "code": "0060899",
        "name": "松興分行",
        "shortcut": "合庫松興"
    },
    {
        "parentCode": "006",
        "code": "0060903",
        "name": "民族分行",
        "shortcut": "合庫民族"
    },
    {
        "parentCode": "006",
        "code": "0060914",
        "name": "復興分行",
        "shortcut": "合庫復興"
    },
    {
        "parentCode": "006",
        "code": "0060925",
        "name": "雙連分行",
        "shortcut": "合庫雙連"
    },
    {
        "parentCode": "006",
        "code": "0060936",
        "name": "民生分行",
        "shortcut": "合庫民生"
    },
    {
        "parentCode": "006",
        "code": "0060947",
        "name": "新生分行",
        "shortcut": "合庫新生"
    },
    {
        "parentCode": "006",
        "code": "0060958",
        "name": "松江分行",
        "shortcut": "合庫松江"
    },
    {
        "parentCode": "006",
        "code": "0060969",
        "name": "永吉分行",
        "shortcut": "合庫永吉"
    },
    {
        "parentCode": "006",
        "code": "0060981",
        "name": "東新莊分行",
        "shortcut": "合庫東新莊"
    },
    {
        "parentCode": "006",
        "code": "0060992",
        "name": "蘆洲分行",
        "shortcut": "合庫蘆洲"
    },
    {
        "parentCode": "006",
        "code": "0061003",
        "name": "前金分行",
        "shortcut": "合庫前金"
    },
    {
        "parentCode": "006",
        "code": "0061014",
        "name": "成大分行",
        "shortcut": "合庫成大"
    },
    {
        "parentCode": "006",
        "code": "0061025",
        "name": "大里分行",
        "shortcut": "合庫大里"
    },
    {
        "parentCode": "006",
        "code": "0061036",
        "name": "海山分行",
        "shortcut": "合庫海山"
    },
    {
        "parentCode": "006",
        "code": "0061070",
        "name": "南台中分行",
        "shortcut": "合庫南台中"
    },
    {
        "parentCode": "006",
        "code": "0061081",
        "name": "埔墘分行",
        "shortcut": "合庫埔墘"
    },
    {
        "parentCode": "006",
        "code": "0061092",
        "name": "慈文分行",
        "shortcut": "合庫慈文"
    },
    {
        "parentCode": "006",
        "code": "0061106",
        "name": "北寧分行",
        "shortcut": "合庫北寧"
    },
    {
        "parentCode": "006",
        "code": "0061117",
        "name": "迴龍分行",
        "shortcut": "合庫迴龍"
    },
    {
        "parentCode": "006",
        "code": "0061128",
        "name": "太平分行",
        "shortcut": "合庫太平"
    },
    {
        "parentCode": "006",
        "code": "0061139",
        "name": "彰營分行",
        "shortcut": "合庫彰營"
    },
    {
        "parentCode": "006",
        "code": "0061140",
        "name": "彰儲分行",
        "shortcut": "合庫彰儲"
    },
    {
        "parentCode": "006",
        "code": "0061151",
        "name": "虎尾分行",
        "shortcut": "合庫虎尾"
    },
    {
        "parentCode": "006",
        "code": "0061162",
        "name": "南屯分行",
        "shortcut": "合庫南屯"
    },
    {
        "parentCode": "006",
        "code": "0061173",
        "name": "西台中分行",
        "shortcut": "合庫西台中"
    },
    {
        "parentCode": "006",
        "code": "0061184",
        "name": "溪湖分行",
        "shortcut": "合庫溪湖"
    },
    {
        "parentCode": "006",
        "code": "0061195",
        "name": "烏日分行",
        "shortcut": "合庫烏日"
    },
    {
        "parentCode": "006",
        "code": "0061209",
        "name": "和美分行",
        "shortcut": "合庫和美"
    },
    {
        "parentCode": "006",
        "code": "0061210",
        "name": "南桃園分行",
        "shortcut": "合庫南桃園"
    },
    {
        "parentCode": "006",
        "code": "0061221",
        "name": "屏南分行",
        "shortcut": "合庫屏南"
    },
    {
        "parentCode": "006",
        "code": "0061232",
        "name": "東台南分行",
        "shortcut": "合庫東台南"
    },
    {
        "parentCode": "006",
        "code": "0061243",
        "name": "北新竹分行",
        "shortcut": "合庫北新竹"
    },
    {
        "parentCode": "006",
        "code": "0061254",
        "name": "復旦分行",
        "shortcut": "合庫復旦"
    },
    {
        "parentCode": "006",
        "code": "0061265",
        "name": "竹山分行",
        "shortcut": "合庫竹山"
    },
    {
        "parentCode": "006",
        "code": "0061276",
        "name": "前鎮分行",
        "shortcut": "合庫前鎮"
    },
    {
        "parentCode": "006",
        "code": "0061287",
        "name": "灣內分行",
        "shortcut": "合庫灣內"
    },
    {
        "parentCode": "006",
        "code": "0061298",
        "name": "路竹分行",
        "shortcut": "合庫路竹"
    },
    {
        "parentCode": "006",
        "code": "0061302",
        "name": "憲德分行",
        "shortcut": "合庫憲德"
    },
    {
        "parentCode": "006",
        "code": "0061313",
        "name": "竹北分行",
        "shortcut": "合庫竹北"
    },
    {
        "parentCode": "006",
        "code": "0061324",
        "name": "六合分行",
        "shortcut": "合庫六合"
    },
    {
        "parentCode": "006",
        "code": "0061335",
        "name": "五洲分行",
        "shortcut": "合庫五洲"
    },
    {
        "parentCode": "006",
        "code": "0061346",
        "name": "臺大分行",
        "shortcut": "合庫臺大"
    },
    {
        "parentCode": "006",
        "code": "0061368",
        "name": "龜山分行",
        "shortcut": "合庫龜山"
    },
    {
        "parentCode": "006",
        "code": "0061379",
        "name": "大溪分行",
        "shortcut": "合庫大溪"
    },
    {
        "parentCode": "006",
        "code": "0061380",
        "name": "龍潭分行",
        "shortcut": "合庫龍潭"
    },
    {
        "parentCode": "006",
        "code": "0061391",
        "name": "中原分行",
        "shortcut": "合庫中原"
    },
    {
        "parentCode": "006",
        "code": "0061405",
        "name": "三興分行",
        "shortcut": "合庫三興"
    },
    {
        "parentCode": "006",
        "code": "0061416",
        "name": "敦化分行",
        "shortcut": "合庫敦化"
    },
    {
        "parentCode": "006",
        "code": "0061427",
        "name": "石牌分行",
        "shortcut": "合庫石牌"
    },
    {
        "parentCode": "006",
        "code": "0061438",
        "name": "西屯分行",
        "shortcut": "合庫西屯"
    },
    {
        "parentCode": "006",
        "code": "0061449",
        "name": "雙和分行",
        "shortcut": "合庫雙和"
    },
    {
        "parentCode": "006",
        "code": "0061450",
        "name": "土城分行",
        "shortcut": "合庫土城"
    },
    {
        "parentCode": "006",
        "code": "0061461",
        "name": "光華分行",
        "shortcut": "合庫光華"
    },
    {
        "parentCode": "006",
        "code": "0061472",
        "name": "北台南分行",
        "shortcut": "合庫北台南"
    },
    {
        "parentCode": "006",
        "code": "0061483",
        "name": "興鳳分行",
        "shortcut": "合庫興鳳"
    },
    {
        "parentCode": "006",
        "code": "0061494",
        "name": "北屯分行",
        "shortcut": "合庫北屯"
    },
    {
        "parentCode": "006",
        "code": "0061508",
        "name": "一心路分行",
        "shortcut": "合庫一心路"
    },
    {
        "parentCode": "006",
        "code": "0061519",
        "name": "三峽分行",
        "shortcut": "合庫三峽"
    },
    {
        "parentCode": "006",
        "code": "0061520",
        "name": "北嘉義分行",
        "shortcut": "合庫北嘉義"
    },
    {
        "parentCode": "006",
        "code": "0061531",
        "name": "永康分行",
        "shortcut": "合庫永康"
    },
    {
        "parentCode": "006",
        "code": "0061542",
        "name": "平鎮分行",
        "shortcut": "合庫平鎮"
    },
    {
        "parentCode": "006",
        "code": "0061553",
        "name": "大發分行",
        "shortcut": "合庫大發"
    },
    {
        "parentCode": "006",
        "code": "0061564",
        "name": "內湖分行",
        "shortcut": "合庫內湖"
    },
    {
        "parentCode": "006",
        "code": "0061575",
        "name": "光復分行",
        "shortcut": "合庫光復"
    },
    {
        "parentCode": "006",
        "code": "0061586",
        "name": "二重分行",
        "shortcut": "合庫二重"
    },
    {
        "parentCode": "006",
        "code": "0061601",
        "name": "北台中分行",
        "shortcut": "合庫北台中"
    },
    {
        "parentCode": "006",
        "code": "0061656",
        "name": "軍功分行",
        "shortcut": "合庫軍功"
    },
    {
        "parentCode": "006",
        "code": "0061715",
        "name": "永安分行",
        "shortcut": "合庫永安"
    },
    {
        "parentCode": "006",
        "code": "0061726",
        "name": "中權分行",
        "shortcut": "合庫中權"
    },
    {
        "parentCode": "006",
        "code": "0061759",
        "name": "太原分行",
        "shortcut": "合庫太原"
    },
    {
        "parentCode": "006",
        "code": "0061760",
        "name": "松竹分行",
        "shortcut": "合庫松竹"
    },
    {
        "parentCode": "006",
        "code": "0061852",
        "name": "中清分行",
        "shortcut": "合庫中清"
    },
    {
        "parentCode": "006",
        "code": "0061885",
        "name": "建成分行",
        "shortcut": "合庫建成"
    },
    {
        "parentCode": "006",
        "code": "0061900",
        "name": "朝馬分行",
        "shortcut": "合庫朝馬"
    },
    {
        "parentCode": "006",
        "code": "0061966",
        "name": "東台中分行",
        "shortcut": "合庫東台中"
    },
    {
        "parentCode": "006",
        "code": "0061988",
        "name": "美村分行",
        "shortcut": "合庫美村"
    },
    {
        "parentCode": "006",
        "code": "0062000",
        "name": "黎明分行",
        "shortcut": "合庫黎明"
    },
    {
        "parentCode": "006",
        "code": "0062033",
        "name": "昌平分行",
        "shortcut": "合庫昌平"
    },
    {
        "parentCode": "006",
        "code": "0062044",
        "name": "精武分行",
        "shortcut": "合庫精武"
    },
    {
        "parentCode": "006",
        "code": "0062055",
        "name": "文心分行",
        "shortcut": "合庫文心"
    },
    {
        "parentCode": "006",
        "code": "0062077",
        "name": "神岡分行",
        "shortcut": "合庫神岡"
    },
    {
        "parentCode": "006",
        "code": "0063030",
        "name": "大竹分行",
        "shortcut": "合庫大竹"
    },
    {
        "parentCode": "006",
        "code": "0063074",
        "name": "林內分行",
        "shortcut": "合庫林內"
    },
    {
        "parentCode": "006",
        "code": "0063100",
        "name": "南港分行",
        "shortcut": "合庫南港"
    },
    {
        "parentCode": "006",
        "code": "0063111",
        "name": "伸港分行",
        "shortcut": "合庫伸港"
    },
    {
        "parentCode": "006",
        "code": "0063122",
        "name": "五股分行",
        "shortcut": "合庫五股"
    },
    {
        "parentCode": "006",
        "code": "0063133",
        "name": "樹林分行",
        "shortcut": "合庫樹林"
    },
    {
        "parentCode": "006",
        "code": "0063155",
        "name": "林口分行",
        "shortcut": "合庫林口"
    },
    {
        "parentCode": "006",
        "code": "0063166",
        "name": "左營分行",
        "shortcut": "合庫左營"
    },
    {
        "parentCode": "006",
        "code": "0063177",
        "name": "泰山分行",
        "shortcut": "合庫泰山"
    },
    {
        "parentCode": "006",
        "code": "0063199",
        "name": "大坪林分行",
        "shortcut": "合庫大坪林"
    },
    {
        "parentCode": "006",
        "code": "0063373",
        "name": "鶯歌分行",
        "shortcut": "合庫鶯歌"
    },
    {
        "parentCode": "006",
        "code": "0063395",
        "name": "鹿港分行",
        "shortcut": "合庫鹿港"
    },
    {
        "parentCode": "006",
        "code": "0063409",
        "name": "新樹分行",
        "shortcut": "合庫新樹"
    },
    {
        "parentCode": "006",
        "code": "0063410",
        "name": "六家分行",
        "shortcut": "合庫六家"
    },
    {
        "parentCode": "006",
        "code": "0063421",
        "name": "大雅分行",
        "shortcut": "合庫大雅"
    },
    {
        "parentCode": "006",
        "code": "0063443",
        "name": "八德分行",
        "shortcut": "合庫八德"
    },
    {
        "parentCode": "006",
        "code": "0063465",
        "name": "鼓山分行",
        "shortcut": "合庫鼓山"
    },
    {
        "parentCode": "006",
        "code": "0063476",
        "name": "大社分行",
        "shortcut": "合庫大社"
    },
    {
        "parentCode": "006",
        "code": "0063498",
        "name": "逢甲分行",
        "shortcut": "合庫逢甲"
    },
    {
        "parentCode": "006",
        "code": "0063502",
        "name": "立德分行",
        "shortcut": "合庫立德"
    },
    {
        "parentCode": "006",
        "code": "0063513",
        "name": "林園分行",
        "shortcut": "合庫林園"
    },
    {
        "parentCode": "006",
        "code": "0063524",
        "name": "高雄科技園區分行",
        "shortcut": "合庫高科"
    },
    {
        "parentCode": "006",
        "code": "0063535",
        "name": "蘆竹分行",
        "shortcut": "合庫蘆竹"
    },
    {
        "parentCode": "006",
        "code": "0063546",
        "name": "北中和分行",
        "shortcut": "合庫北中和"
    },
    {
        "parentCode": "006",
        "code": "0063557",
        "name": "新湖分行",
        "shortcut": "合庫新湖"
    },
    {
        "parentCode": "006",
        "code": "0063568",
        "name": "丹鳳分行",
        "shortcut": "合庫丹鳳"
    },
    {
        "parentCode": "006",
        "code": "0063580",
        "name": "旗山分行",
        "shortcut": "合庫旗山"
    },
    {
        "parentCode": "006",
        "code": "0063591",
        "name": "大園分行",
        "shortcut": "合庫大園"
    },
    {
        "parentCode": "006",
        "code": "0063605",
        "name": "南土城分行",
        "shortcut": "合庫南土城"
    },
    {
        "parentCode": "006",
        "code": "0063616",
        "name": "潭子分行",
        "shortcut": "合庫潭子"
    },
    {
        "parentCode": "006",
        "code": "0063627",
        "name": "后里分行",
        "shortcut": "合庫后里"
    },
    {
        "parentCode": "006",
        "code": "0063638",
        "name": "長庚分行",
        "shortcut": "合庫長庚"
    },
    {
        "parentCode": "006",
        "code": "0063649",
        "name": "楠梓分行",
        "shortcut": "合庫楠梓"
    },
    {
        "parentCode": "006",
        "code": "0065012",
        "name": "自強分行",
        "shortcut": "合庫自強"
    },
    {
        "parentCode": "006",
        "code": "0065023",
        "name": "中山分行",
        "shortcut": "合庫中山"
    },
    {
        "parentCode": "006",
        "code": "0065034",
        "name": "敦南分行",
        "shortcut": "合庫敦南"
    },
    {
        "parentCode": "006",
        "code": "0065056",
        "name": "世貿分行",
        "shortcut": "合庫世貿"
    },
    {
        "parentCode": "006",
        "code": "0065078",
        "name": "光復南路分行",
        "shortcut": "合庫光復南"
    },
    {
        "parentCode": "006",
        "code": "0065089",
        "name": "北士林分行",
        "shortcut": "合庫北士林"
    },
    {
        "parentCode": "006",
        "code": "0065090",
        "name": "信維分行",
        "shortcut": "合庫信維"
    },
    {
        "parentCode": "006",
        "code": "0065104",
        "name": "西湖分行",
        "shortcut": "合庫西湖"
    },
    {
        "parentCode": "006",
        "code": "0065115",
        "name": "大湖分行",
        "shortcut": "合庫大湖"
    },
    {
        "parentCode": "006",
        "code": "0065137",
        "name": "國醫中心分行",
        "shortcut": "合庫國醫"
    },
    {
        "parentCode": "006",
        "code": "0065159",
        "name": "南門分行",
        "shortcut": "合庫南門"
    },
    {
        "parentCode": "006",
        "code": "0065160",
        "name": "新中分行",
        "shortcut": "合庫新中"
    },
    {
        "parentCode": "006",
        "code": "0065171",
        "name": "中港分行",
        "shortcut": "合庫中港"
    },
    {
        "parentCode": "006",
        "code": "0065182",
        "name": "衛道分行",
        "shortcut": "合庫衛道"
    },
    {
        "parentCode": "006",
        "code": "0065193",
        "name": "忠明南路分行",
        "shortcut": "合庫忠明南"
    },
    {
        "parentCode": "006",
        "code": "0065207",
        "name": "東基隆分行",
        "shortcut": "合庫東基隆"
    },
    {
        "parentCode": "006",
        "code": "0065218",
        "name": "赤崁分行",
        "shortcut": "合庫赤崁"
    },
    {
        "parentCode": "006",
        "code": "0065229",
        "name": "府城分行",
        "shortcut": "合庫府城"
    },
    {
        "parentCode": "006",
        "code": "0065230",
        "name": "開元分行",
        "shortcut": "合庫開元"
    },
    {
        "parentCode": "006",
        "code": "0065241",
        "name": "港都分行",
        "shortcut": "合庫港都"
    },
    {
        "parentCode": "006",
        "code": "0065252",
        "name": "七賢分行",
        "shortcut": "合庫七賢"
    },
    {
        "parentCode": "006",
        "code": "0065274",
        "name": "十全分行",
        "shortcut": "合庫十全"
    },
    {
        "parentCode": "006",
        "code": "0065285",
        "name": "九如分行",
        "shortcut": "合庫九如"
    },
    {
        "parentCode": "006",
        "code": "0065296",
        "name": "小港分行",
        "shortcut": "合庫小港"
    },
    {
        "parentCode": "006",
        "code": "0065300",
        "name": "北三峽分行",
        "shortcut": "合庫北三峽"
    },
    {
        "parentCode": "006",
        "code": "0065311",
        "name": "板新分行",
        "shortcut": "合庫板新"
    },
    {
        "parentCode": "006",
        "code": "0065322",
        "name": "南三重分行",
        "shortcut": "合庫南三重"
    },
    {
        "parentCode": "006",
        "code": "0065333",
        "name": "北新分行",
        "shortcut": "合庫北新"
    },
    {
        "parentCode": "006",
        "code": "0065355",
        "name": "寶橋分行",
        "shortcut": "合庫寶橋"
    },
    {
        "parentCode": "006",
        "code": "0065366",
        "name": "新泰分行",
        "shortcut": "合庫新泰"
    },
    {
        "parentCode": "006",
        "code": "0065377",
        "name": "北土城分行",
        "shortcut": "合庫北土城"
    },
    {
        "parentCode": "006",
        "code": "0065388",
        "name": "南汐止分行",
        "shortcut": "合庫南汐止"
    },
    {
        "parentCode": "006",
        "code": "0065399",
        "name": "北樹林分行",
        "shortcut": "合庫北樹林"
    },
    {
        "parentCode": "006",
        "code": "0065403",
        "name": "五股工業區分行",
        "shortcut": "合庫五股工"
    },
    {
        "parentCode": "006",
        "code": "0065414",
        "name": "北羅東分行",
        "shortcut": "合庫北羅東"
    },
    {
        "parentCode": "006",
        "code": "0065425",
        "name": "礁溪分行",
        "shortcut": "合庫礁溪"
    },
    {
        "parentCode": "006",
        "code": "0065436",
        "name": "東桃園分行",
        "shortcut": "合庫東桃園"
    },
    {
        "parentCode": "006",
        "code": "0065447",
        "name": "壢新分行",
        "shortcut": "合庫壢新"
    },
    {
        "parentCode": "006",
        "code": "0065458",
        "name": "南崁分行",
        "shortcut": "合庫南崁"
    },
    {
        "parentCode": "006",
        "code": "0065470",
        "name": "新明分行",
        "shortcut": "合庫新明"
    },
    {
        "parentCode": "006",
        "code": "0065481",
        "name": "楊梅分行",
        "shortcut": "合庫楊梅"
    },
    {
        "parentCode": "006",
        "code": "0065492",
        "name": "東台東分行",
        "shortcut": "合庫東台東"
    },
    {
        "parentCode": "006",
        "code": "0065517",
        "name": "東竹北分行",
        "shortcut": "合庫東竹北"
    },
    {
        "parentCode": "006",
        "code": "0065528",
        "name": "北苗栗分行",
        "shortcut": "合庫北苗栗"
    },
    {
        "parentCode": "006",
        "code": "0065539",
        "name": "竹南分行",
        "shortcut": "合庫竹南"
    },
    {
        "parentCode": "006",
        "code": "0065540",
        "name": "豐中分行",
        "shortcut": "合庫豐中"
    },
    {
        "parentCode": "006",
        "code": "0065551",
        "name": "北大里分行",
        "shortcut": "合庫北大里"
    },
    {
        "parentCode": "006",
        "code": "0065562",
        "name": "東沙鹿分行",
        "shortcut": "合庫東沙鹿"
    },
    {
        "parentCode": "006",
        "code": "0065573",
        "name": "草屯分行",
        "shortcut": "合庫草屯"
    },
    {
        "parentCode": "006",
        "code": "0065584",
        "name": "集集分行",
        "shortcut": "合庫集集"
    },
    {
        "parentCode": "006",
        "code": "0065595",
        "name": "東埔里分行",
        "shortcut": "合庫東埔里"
    },
    {
        "parentCode": "006",
        "code": "0065609",
        "name": "員新分行",
        "shortcut": "合庫員新"
    },
    {
        "parentCode": "006",
        "code": "0065610",
        "name": "南彰化分行",
        "shortcut": "合庫南彰化"
    },
    {
        "parentCode": "006",
        "code": "0065621",
        "name": "北斗分行",
        "shortcut": "合庫北斗"
    },
    {
        "parentCode": "006",
        "code": "0065632",
        "name": "竹塹分行",
        "shortcut": "合庫竹塹"
    },
    {
        "parentCode": "006",
        "code": "0065643",
        "name": "新竹科學園區分行",
        "shortcut": "合庫竹科"
    },
    {
        "parentCode": "006",
        "code": "0065665",
        "name": "雲林分行",
        "shortcut": "合庫雲林"
    },
    {
        "parentCode": "006",
        "code": "0065687",
        "name": "北朴子分行",
        "shortcut": "合庫北朴子"
    },
    {
        "parentCode": "006",
        "code": "0065698",
        "name": "北新營分行",
        "shortcut": "合庫北新營"
    },
    {
        "parentCode": "006",
        "code": "0065702",
        "name": "仁德分行",
        "shortcut": "合庫仁德"
    },
    {
        "parentCode": "006",
        "code": "0065713",
        "name": "南永康分行",
        "shortcut": "合庫南永康"
    },
    {
        "parentCode": "006",
        "code": "0065735",
        "name": "鳳松分行",
        "shortcut": "合庫鳳松"
    },
    {
        "parentCode": "006",
        "code": "0065746",
        "name": "北岡山分行",
        "shortcut": "合庫北岡山"
    },
    {
        "parentCode": "006",
        "code": "0065757",
        "name": "美濃分行",
        "shortcut": "合庫美濃"
    },
    {
        "parentCode": "006",
        "code": "0065768",
        "name": "大樹分行",
        "shortcut": "合庫大樹"
    },
    {
        "parentCode": "006",
        "code": "0065816",
        "name": "東港分行",
        "shortcut": "合庫東港"
    },
    {
        "parentCode": "006",
        "code": "0065827",
        "name": "萬丹分行",
        "shortcut": "合庫萬丹"
    },
    {
        "parentCode": "006",
        "code": "0065838",
        "name": "枋寮分行",
        "shortcut": "合庫枋寮"
    },
    {
        "parentCode": "006",
        "code": "0065849",
        "name": "東嘉義分行",
        "shortcut": "合庫東嘉義"
    },
    {
        "parentCode": "006",
        "code": "0065850",
        "name": "北花蓮分行",
        "shortcut": "合庫北花蓮"
    },
    {
        "parentCode": "006",
        "code": "0065919",
        "name": "大直分行",
        "shortcut": "合庫大直"
    },
    {
        "parentCode": "006",
        "code": "0065942",
        "name": "水湳分行",
        "shortcut": "合庫水湳"
    },
    {
        "parentCode": "006",
        "code": "0066064",
        "name": "仁美分行",
        "shortcut": "合庫仁美"
    },
    {
        "parentCode": "006",
        "code": "0066097",
        "name": "社皮分行",
        "shortcut": "合庫社皮"
    },
    {
        "parentCode": "006",
        "code": "0066123",
        "name": "港湖分行",
        "shortcut": "合庫港湖"
    },
    {
        "parentCode": "006",
        "code": "0069021",
        "name": "五甲分行",
        "shortcut": "合庫五甲"
    },
    {
        "parentCode": "006",
        "code": "0069043",
        "name": "淡水分行",
        "shortcut": "合庫淡水"
    },
    {
        "parentCode": "007",
        "code": "0070937",
        "name": "營業部",
        "shortcut": "一銀營業部"
    },
    {
        "parentCode": "007",
        "code": "0070948",
        "name": "安和分行",
        "shortcut": "一銀安和"
    },
    {
        "parentCode": "007",
        "code": "0071015",
        "name": "南港分行",
        "shortcut": "一銀南港"
    },
    {
        "parentCode": "007",
        "code": "0071026",
        "name": "西門分行",
        "shortcut": "一銀西門"
    },
    {
        "parentCode": "007",
        "code": "0071037",
        "name": "忠孝路分行",
        "shortcut": "一銀忠孝路"
    },
    {
        "parentCode": "007",
        "code": "0071059",
        "name": "東湖分行",
        "shortcut": "一銀東湖"
    },
    {
        "parentCode": "007",
        "code": "0071060",
        "name": "景美分行",
        "shortcut": "一銀景美"
    },
    {
        "parentCode": "007",
        "code": "0071071",
        "name": "大直分行",
        "shortcut": "一銀大直"
    },
    {
        "parentCode": "007",
        "code": "0071118",
        "name": "大稻埕分行",
        "shortcut": "一銀大稻埕"
    },
    {
        "parentCode": "007",
        "code": "0071130",
        "name": "信維分行",
        "shortcut": "一銀信維"
    },
    {
        "parentCode": "007",
        "code": "0071211",
        "name": "建成分行",
        "shortcut": "一銀建成"
    },
    {
        "parentCode": "007",
        "code": "0071299",
        "name": "華山分行",
        "shortcut": "一銀華山"
    },
    {
        "parentCode": "007",
        "code": "0071314",
        "name": "大同分行",
        "shortcut": "一銀大同"
    },
    {
        "parentCode": "007",
        "code": "0071325",
        "name": "光隆分行",
        "shortcut": "一銀光隆"
    },
    {
        "parentCode": "007",
        "code": "0071336",
        "name": "新生分行",
        "shortcut": "一銀新生"
    },
    {
        "parentCode": "007",
        "code": "0071358",
        "name": "劍潭分行",
        "shortcut": "一銀劍潭"
    },
    {
        "parentCode": "007",
        "code": "0071406",
        "name": "圓山分行",
        "shortcut": "一銀圓山"
    },
    {
        "parentCode": "007",
        "code": "0071417",
        "name": "中山分行",
        "shortcut": "一銀中山"
    },
    {
        "parentCode": "007",
        "code": "0071428",
        "name": "中崙分行",
        "shortcut": "一銀中崙"
    },
    {
        "parentCode": "007",
        "code": "0071439",
        "name": "南京東路分行",
        "shortcut": "一銀南京東"
    },
    {
        "parentCode": "007",
        "code": "0071440",
        "name": "城東分行",
        "shortcut": "一銀城東"
    },
    {
        "parentCode": "007",
        "code": "0071451",
        "name": "民生分行",
        "shortcut": "一銀民生"
    },
    {
        "parentCode": "007",
        "code": "0071462",
        "name": "松江分行",
        "shortcut": "一銀松江"
    },
    {
        "parentCode": "007",
        "code": "0071473",
        "name": "民權分行",
        "shortcut": "一銀民權"
    },
    {
        "parentCode": "007",
        "code": "0071484",
        "name": "八德分行",
        "shortcut": "一銀八德"
    },
    {
        "parentCode": "007",
        "code": "0071495",
        "name": "長春分行",
        "shortcut": "一銀長春"
    },
    {
        "parentCode": "007",
        "code": "0071509",
        "name": "內湖分行",
        "shortcut": "一銀內湖"
    },
    {
        "parentCode": "007",
        "code": "0071510",
        "name": "松山分行",
        "shortcut": "一銀松山"
    },
    {
        "parentCode": "007",
        "code": "0071521",
        "name": "延吉分行",
        "shortcut": "一銀延吉"
    },
    {
        "parentCode": "007",
        "code": "0071532",
        "name": "光復分行",
        "shortcut": "一銀光復"
    },
    {
        "parentCode": "007",
        "code": "0071554",
        "name": "興雅分行",
        "shortcut": "一銀興雅"
    },
    {
        "parentCode": "007",
        "code": "0071576",
        "name": "永春分行",
        "shortcut": "一銀永春"
    },
    {
        "parentCode": "007",
        "code": "0071587",
        "name": "內科園區分行",
        "shortcut": "一銀內科"
    },
    {
        "parentCode": "007",
        "code": "0071598",
        "name": "吉林分行",
        "shortcut": "一銀吉林"
    },
    {
        "parentCode": "007",
        "code": "0071602",
        "name": "仁愛分行",
        "shortcut": "一銀仁愛"
    },
    {
        "parentCode": "007",
        "code": "0071613",
        "name": "大安分行",
        "shortcut": "一銀大安"
    },
    {
        "parentCode": "007",
        "code": "0071624",
        "name": "信義分行",
        "shortcut": "一銀信義"
    },
    {
        "parentCode": "007",
        "code": "0071635",
        "name": "復興分行",
        "shortcut": "一銀復興"
    },
    {
        "parentCode": "007",
        "code": "0071646",
        "name": "敦化分行",
        "shortcut": "一銀敦化"
    },
    {
        "parentCode": "007",
        "code": "0071657",
        "name": "仁和分行",
        "shortcut": "一銀仁和"
    },
    {
        "parentCode": "007",
        "code": "0071668",
        "name": "世貿分行",
        "shortcut": "一銀世貿"
    },
    {
        "parentCode": "007",
        "code": "0071679",
        "name": "木柵分行",
        "shortcut": "一銀木柵"
    },
    {
        "parentCode": "007",
        "code": "0071680",
        "name": "松貿分行",
        "shortcut": "一銀松貿"
    },
    {
        "parentCode": "007",
        "code": "0071691",
        "name": "新湖分行",
        "shortcut": "一銀新湖"
    },
    {
        "parentCode": "007",
        "code": "0071716",
        "name": "古亭分行",
        "shortcut": "一銀古亭"
    },
    {
        "parentCode": "007",
        "code": "0071727",
        "name": "南門分行",
        "shortcut": "一銀南門"
    },
    {
        "parentCode": "007",
        "code": "0071738",
        "name": "公館分行",
        "shortcut": "一銀公館"
    },
    {
        "parentCode": "007",
        "code": "0071761",
        "name": "和平分行",
        "shortcut": "一銀和平"
    },
    {
        "parentCode": "007",
        "code": "0071819",
        "name": "萬華分行",
        "shortcut": "一銀萬華"
    },
    {
        "parentCode": "007",
        "code": "0071831",
        "name": "雙園分行",
        "shortcut": "一銀雙園"
    },
    {
        "parentCode": "007",
        "code": "0071901",
        "name": "天母分行",
        "shortcut": "一銀天母"
    },
    {
        "parentCode": "007",
        "code": "0071912",
        "name": "北投分行",
        "shortcut": "一銀北投"
    },
    {
        "parentCode": "007",
        "code": "0071923",
        "name": "士林分行",
        "shortcut": "一銀士林"
    },
    {
        "parentCode": "007",
        "code": "0071934",
        "name": "建國分行",
        "shortcut": "一銀建國"
    },
    {
        "parentCode": "007",
        "code": "0071945",
        "name": "萬隆分行",
        "shortcut": "一銀萬隆"
    },
    {
        "parentCode": "007",
        "code": "0071956",
        "name": "石牌分行",
        "shortcut": "一銀石牌"
    },
    {
        "parentCode": "007",
        "code": "0072012",
        "name": "板橋分行",
        "shortcut": "一銀板橋"
    },
    {
        "parentCode": "007",
        "code": "0072023",
        "name": "華江分行",
        "shortcut": "一銀華江"
    },
    {
        "parentCode": "007",
        "code": "0072034",
        "name": "樹林分行",
        "shortcut": "一銀樹林"
    },
    {
        "parentCode": "007",
        "code": "0072056",
        "name": "土城分行",
        "shortcut": "一銀土城"
    },
    {
        "parentCode": "007",
        "code": "0072067",
        "name": "江子翠分行",
        "shortcut": "一銀江子翠"
    },
    {
        "parentCode": "007",
        "code": "0072078",
        "name": "北土城分行",
        "shortcut": "一銀北土城"
    },
    {
        "parentCode": "007",
        "code": "0072089",
        "name": "林口工二分行",
        "shortcut": "一銀林口工"
    },
    {
        "parentCode": "007",
        "code": "0072115",
        "name": "三重埔分行",
        "shortcut": "一銀三重埔"
    },
    {
        "parentCode": "007",
        "code": "0072126",
        "name": "長泰分行",
        "shortcut": "一銀長泰"
    },
    {
        "parentCode": "007",
        "code": "0072137",
        "name": "蘆洲分行",
        "shortcut": "一銀蘆洲"
    },
    {
        "parentCode": "007",
        "code": "0072148",
        "name": "頭前分行",
        "shortcut": "一銀頭前"
    },
    {
        "parentCode": "007",
        "code": "0072159",
        "name": "五股分行",
        "shortcut": "一銀五股"
    },
    {
        "parentCode": "007",
        "code": "0072160",
        "name": "重陽分行",
        "shortcut": "一銀重陽"
    },
    {
        "parentCode": "007",
        "code": "0072171",
        "name": "五股工業區分行",
        "shortcut": "一銀五股工"
    },
    {
        "parentCode": "007",
        "code": "0072182",
        "name": "淡水分行",
        "shortcut": "一銀淡水"
    },
    {
        "parentCode": "007",
        "code": "0072218",
        "name": "新店分行",
        "shortcut": "一銀新店"
    },
    {
        "parentCode": "007",
        "code": "0072229",
        "name": "大坪林分行",
        "shortcut": "一銀大坪林"
    },
    {
        "parentCode": "007",
        "code": "0072300",
        "name": "泰山分行",
        "shortcut": "一銀泰山"
    },
    {
        "parentCode": "007",
        "code": "0072311",
        "name": "新莊分行",
        "shortcut": "一銀新莊"
    },
    {
        "parentCode": "007",
        "code": "0072322",
        "name": "鶯歌分行",
        "shortcut": "一銀鶯歌"
    },
    {
        "parentCode": "007",
        "code": "0072333",
        "name": "中和分行",
        "shortcut": "一銀中和"
    },
    {
        "parentCode": "007",
        "code": "0072344",
        "name": "永和分行",
        "shortcut": "一銀永和"
    },
    {
        "parentCode": "007",
        "code": "0072355",
        "name": "雙和分行",
        "shortcut": "一銀雙和"
    },
    {
        "parentCode": "007",
        "code": "0072366",
        "name": "連城分行",
        "shortcut": "一銀連城"
    },
    {
        "parentCode": "007",
        "code": "0072377",
        "name": "瑞芳分行",
        "shortcut": "一銀瑞芳"
    },
    {
        "parentCode": "007",
        "code": "0072388",
        "name": "埔墘分行",
        "shortcut": "一銀埔墘"
    },
    {
        "parentCode": "007",
        "code": "0072399",
        "name": "丹鳳分行",
        "shortcut": "一銀丹鳳"
    },
    {
        "parentCode": "007",
        "code": "0072414",
        "name": "基隆分行",
        "shortcut": "一銀基隆"
    },
    {
        "parentCode": "007",
        "code": "0072436",
        "name": "哨船頭分行",
        "shortcut": "一銀哨船頭"
    },
    {
        "parentCode": "007",
        "code": "0072458",
        "name": "汐止分行",
        "shortcut": "一銀汐止"
    },
    {
        "parentCode": "007",
        "code": "0072469",
        "name": "汐科分行",
        "shortcut": "一銀汐科"
    },
    {
        "parentCode": "007",
        "code": "0072470",
        "name": "幸福分行",
        "shortcut": "一銀幸福"
    },
    {
        "parentCode": "007",
        "code": "0072517",
        "name": "宜蘭分行",
        "shortcut": "一銀宜蘭"
    },
    {
        "parentCode": "007",
        "code": "0072610",
        "name": "羅東分行",
        "shortcut": "一銀羅東"
    },
    {
        "parentCode": "007",
        "code": "0072621",
        "name": "蘇澳分行",
        "shortcut": "一銀蘇澳"
    },
    {
        "parentCode": "007",
        "code": "0072713",
        "name": "桃園分行",
        "shortcut": "一銀桃園"
    },
    {
        "parentCode": "007",
        "code": "0072724",
        "name": "北桃分行",
        "shortcut": "一銀北桃"
    },
    {
        "parentCode": "007",
        "code": "0072735",
        "name": "中正分行",
        "shortcut": "一銀中正"
    },
    {
        "parentCode": "007",
        "code": "0072791",
        "name": "大湳分行",
        "shortcut": "一銀大湳"
    },
    {
        "parentCode": "007",
        "code": "0072805",
        "name": "內壢分行",
        "shortcut": "一銀內壢"
    },
    {
        "parentCode": "007",
        "code": "0072816",
        "name": "中壢分行",
        "shortcut": "一銀中壢"
    },
    {
        "parentCode": "007",
        "code": "0072827",
        "name": "西壢分行",
        "shortcut": "一銀西壢"
    },
    {
        "parentCode": "007",
        "code": "0072838",
        "name": "平鎮分行",
        "shortcut": "一銀平鎮"
    },
    {
        "parentCode": "007",
        "code": "0072849",
        "name": "大園分行",
        "shortcut": "一銀大園"
    },
    {
        "parentCode": "007",
        "code": "0072850",
        "name": "南崁分行",
        "shortcut": "一銀南崁"
    },
    {
        "parentCode": "007",
        "code": "0072872",
        "name": "迴龍分行",
        "shortcut": "一銀迴龍"
    },
    {
        "parentCode": "007",
        "code": "0072908",
        "name": "林口分行",
        "shortcut": "一銀林口"
    },
    {
        "parentCode": "007",
        "code": "0072919",
        "name": "大溪分行",
        "shortcut": "一銀大溪"
    },
    {
        "parentCode": "007",
        "code": "0072920",
        "name": "龍潭分行",
        "shortcut": "一銀龍潭"
    },
    {
        "parentCode": "007",
        "code": "0073019",
        "name": "新竹分行",
        "shortcut": "一銀新竹"
    },
    {
        "parentCode": "007",
        "code": "0073020",
        "name": "東門分行",
        "shortcut": "一銀東門"
    },
    {
        "parentCode": "007",
        "code": "0073031",
        "name": "竹科分行",
        "shortcut": "一銀竹科"
    },
    {
        "parentCode": "007",
        "code": "0073112",
        "name": "竹東分行",
        "shortcut": "一銀竹東"
    },
    {
        "parentCode": "007",
        "code": "0073123",
        "name": "關西分行",
        "shortcut": "一銀關西"
    },
    {
        "parentCode": "007",
        "code": "0073134",
        "name": "竹北分行",
        "shortcut": "一銀竹北"
    },
    {
        "parentCode": "007",
        "code": "0073215",
        "name": "苗栗分行",
        "shortcut": "一銀苗栗"
    },
    {
        "parentCode": "007",
        "code": "0073318",
        "name": "竹南分行",
        "shortcut": "一銀竹南"
    },
    {
        "parentCode": "007",
        "code": "0073329",
        "name": "頭份分行",
        "shortcut": "一銀頭份"
    },
    {
        "parentCode": "007",
        "code": "0074016",
        "name": "台中分行",
        "shortcut": "一銀台中"
    },
    {
        "parentCode": "007",
        "code": "0074027",
        "name": "南台中分行",
        "shortcut": "一銀南台中"
    },
    {
        "parentCode": "007",
        "code": "0074038",
        "name": "北台中分行",
        "shortcut": "一銀北台中"
    },
    {
        "parentCode": "007",
        "code": "0074049",
        "name": "中港分行",
        "shortcut": "一銀中港"
    },
    {
        "parentCode": "007",
        "code": "0074050",
        "name": "北屯分行",
        "shortcut": "一銀北屯"
    },
    {
        "parentCode": "007",
        "code": "0074061",
        "name": "進化分行",
        "shortcut": "一銀進化"
    },
    {
        "parentCode": "007",
        "code": "0074072",
        "name": "南屯分行",
        "shortcut": "一銀南屯"
    },
    {
        "parentCode": "007",
        "code": "0074119",
        "name": "豐原分行",
        "shortcut": "一銀豐原"
    },
    {
        "parentCode": "007",
        "code": "0074120",
        "name": "大里分行",
        "shortcut": "一銀大里"
    },
    {
        "parentCode": "007",
        "code": "0074153",
        "name": "中科分行",
        "shortcut": "一銀中科"
    },
    {
        "parentCode": "007",
        "code": "0074212",
        "name": "東勢分行",
        "shortcut": "一銀東勢"
    },
    {
        "parentCode": "007",
        "code": "0074223",
        "name": "沙鹿分行",
        "shortcut": "一銀沙鹿"
    },
    {
        "parentCode": "007",
        "code": "0074234",
        "name": "大甲分行",
        "shortcut": "一銀大甲"
    },
    {
        "parentCode": "007",
        "code": "0074245",
        "name": "太平分行",
        "shortcut": "一銀太平"
    },
    {
        "parentCode": "007",
        "code": "0074256",
        "name": "清水分行",
        "shortcut": "一銀清水"
    },
    {
        "parentCode": "007",
        "code": "0074267",
        "name": "大雅分行",
        "shortcut": "一銀大雅"
    },
    {
        "parentCode": "007",
        "code": "0074315",
        "name": "南投分行",
        "shortcut": "一銀南投"
    },
    {
        "parentCode": "007",
        "code": "0074418",
        "name": "草屯分行",
        "shortcut": "一銀草屯"
    },
    {
        "parentCode": "007",
        "code": "0074429",
        "name": "埔里分行",
        "shortcut": "一銀埔里"
    },
    {
        "parentCode": "007",
        "code": "0074511",
        "name": "彰化分行",
        "shortcut": "一銀彰化"
    },
    {
        "parentCode": "007",
        "code": "0074614",
        "name": "員林分行",
        "shortcut": "一銀員林"
    },
    {
        "parentCode": "007",
        "code": "0074625",
        "name": "鹿港分行",
        "shortcut": "一銀鹿港"
    },
    {
        "parentCode": "007",
        "code": "0074636",
        "name": "溪湖分行",
        "shortcut": "一銀溪湖"
    },
    {
        "parentCode": "007",
        "code": "0074647",
        "name": "北斗分行",
        "shortcut": "一銀北斗"
    },
    {
        "parentCode": "007",
        "code": "0074717",
        "name": "和美分行",
        "shortcut": "一銀和美"
    },
    {
        "parentCode": "007",
        "code": "0075013",
        "name": "嘉義分行",
        "shortcut": "一銀嘉義"
    },
    {
        "parentCode": "007",
        "code": "0075035",
        "name": "興嘉分行",
        "shortcut": "一銀興嘉"
    },
    {
        "parentCode": "007",
        "code": "0075116",
        "name": "朴子分行",
        "shortcut": "一銀朴子"
    },
    {
        "parentCode": "007",
        "code": "0075219",
        "name": "斗六分行",
        "shortcut": "一銀斗六"
    },
    {
        "parentCode": "007",
        "code": "0075312",
        "name": "北港分行",
        "shortcut": "一銀北港"
    },
    {
        "parentCode": "007",
        "code": "0075323",
        "name": "西螺分行",
        "shortcut": "一銀西螺"
    },
    {
        "parentCode": "007",
        "code": "0075334",
        "name": "虎尾分行",
        "shortcut": "一銀虎尾"
    },
    {
        "parentCode": "007",
        "code": "0076010",
        "name": "台南分行",
        "shortcut": "一銀台南"
    },
    {
        "parentCode": "007",
        "code": "0076021",
        "name": "運河分行（客戶移撥台南分行服務）",
        "shortcut": "一銀運河"
    },
    {
        "parentCode": "007",
        "code": "0076043",
        "name": "富強分行",
        "shortcut": "一銀富強"
    },
    {
        "parentCode": "007",
        "code": "0076054",
        "name": "赤崁分行",
        "shortcut": "一銀赤崁"
    },
    {
        "parentCode": "007",
        "code": "0076065",
        "name": "竹溪分行",
        "shortcut": "一銀竹溪"
    },
    {
        "parentCode": "007",
        "code": "0076076",
        "name": "金城分行",
        "shortcut": "一銀金城"
    },
    {
        "parentCode": "007",
        "code": "0076087",
        "name": "安南分行",
        "shortcut": "一銀安南"
    },
    {
        "parentCode": "007",
        "code": "0076113",
        "name": "新營分行",
        "shortcut": "一銀新營"
    },
    {
        "parentCode": "007",
        "code": "0076216",
        "name": "鹽水分行",
        "shortcut": "一銀鹽水"
    },
    {
        "parentCode": "007",
        "code": "0076227",
        "name": "麻豆分行",
        "shortcut": "一銀麻豆"
    },
    {
        "parentCode": "007",
        "code": "0076238",
        "name": "善化分行",
        "shortcut": "一銀善化"
    },
    {
        "parentCode": "007",
        "code": "0076249",
        "name": "佳里分行",
        "shortcut": "一銀佳里"
    },
    {
        "parentCode": "007",
        "code": "0076250",
        "name": "新化分行",
        "shortcut": "一銀新化"
    },
    {
        "parentCode": "007",
        "code": "0076261",
        "name": "大灣分行",
        "shortcut": "一銀大灣"
    },
    {
        "parentCode": "007",
        "code": "0076283",
        "name": "南科園區分行",
        "shortcut": "一銀南科園"
    },
    {
        "parentCode": "007",
        "code": "0076294",
        "name": "歸仁分行",
        "shortcut": "一銀歸仁"
    },
    {
        "parentCode": "007",
        "code": "0076308",
        "name": "永康分行",
        "shortcut": "一銀永康"
    },
    {
        "parentCode": "007",
        "code": "0077017",
        "name": "高雄分行",
        "shortcut": "一銀高雄"
    },
    {
        "parentCode": "007",
        "code": "0077028",
        "name": "鹽埕分行",
        "shortcut": "一銀鹽埕"
    },
    {
        "parentCode": "007",
        "code": "0077039",
        "name": "新興分行",
        "shortcut": "一銀新興"
    },
    {
        "parentCode": "007",
        "code": "0077040",
        "name": "三民分行",
        "shortcut": "一銀三民"
    },
    {
        "parentCode": "007",
        "code": "0077051",
        "name": "苓雅分行",
        "shortcut": "一銀苓雅"
    },
    {
        "parentCode": "007",
        "code": "0077062",
        "name": "左營分行",
        "shortcut": "一銀左營"
    },
    {
        "parentCode": "007",
        "code": "0077073",
        "name": "楠梓分行",
        "shortcut": "一銀楠梓"
    },
    {
        "parentCode": "007",
        "code": "0077084",
        "name": "五福分行",
        "shortcut": "一銀五福"
    },
    {
        "parentCode": "007",
        "code": "0077095",
        "name": "十全分行",
        "shortcut": "一銀十全"
    },
    {
        "parentCode": "007",
        "code": "0077109",
        "name": "前鎮分行",
        "shortcut": "一銀前鎮"
    },
    {
        "parentCode": "007",
        "code": "0077110",
        "name": "灣內分行",
        "shortcut": "一銀灣內"
    },
    {
        "parentCode": "007",
        "code": "0077121",
        "name": "博愛分行",
        "shortcut": "一銀博愛"
    },
    {
        "parentCode": "007",
        "code": "0077132",
        "name": "鼎泰分行（客戶移撥博愛分行服務）",
        "shortcut": "一銀鼎泰"
    },
    {
        "parentCode": "007",
        "code": "0077143",
        "name": "小港分行",
        "shortcut": "一銀小港"
    },
    {
        "parentCode": "007",
        "code": "0077202",
        "name": "五甲分行",
        "shortcut": "一銀五甲"
    },
    {
        "parentCode": "007",
        "code": "0077213",
        "name": "鳳山分行",
        "shortcut": "一銀鳳山"
    },
    {
        "parentCode": "007",
        "code": "0077305",
        "name": "路竹分行",
        "shortcut": "一銀路竹"
    },
    {
        "parentCode": "007",
        "code": "0077316",
        "name": "岡山分行",
        "shortcut": "一銀岡山"
    },
    {
        "parentCode": "007",
        "code": "0077327",
        "name": "旗山分行",
        "shortcut": "一銀旗山"
    },
    {
        "parentCode": "007",
        "code": "0077338",
        "name": "林園分行",
        "shortcut": "一銀林園"
    },
    {
        "parentCode": "007",
        "code": "0077419",
        "name": "屏東分行",
        "shortcut": "一銀屏東"
    },
    {
        "parentCode": "007",
        "code": "0077512",
        "name": "潮州分行",
        "shortcut": "一銀潮州"
    },
    {
        "parentCode": "007",
        "code": "0077523",
        "name": "東港分行",
        "shortcut": "一銀東港"
    },
    {
        "parentCode": "007",
        "code": "0077534",
        "name": "恆春分行",
        "shortcut": "一銀恆春"
    },
    {
        "parentCode": "007",
        "code": "0077785",
        "name": "梓本分行",
        "shortcut": "一銀梓本"
    },
    {
        "parentCode": "007",
        "code": "0077855",
        "name": "萬巒分行",
        "shortcut": "一銀萬巒"
    },
    {
        "parentCode": "007",
        "code": "0078014",
        "name": "花蓮分行",
        "shortcut": "一銀花蓮"
    },
    {
        "parentCode": "007",
        "code": "0078117",
        "name": "台東分行",
        "shortcut": "一銀台東"
    },
    {
        "parentCode": "007",
        "code": "0078210",
        "name": "澎湖分行",
        "shortcut": "一銀澎湖"
    },
    {
        "parentCode": "008",
        "code": "0081005",
        "name": "營業部",
        "shortcut": "華銀營"
    },
    {
        "parentCode": "008",
        "code": "0081016",
        "name": "儲蓄分行",
        "shortcut": "華銀儲蓄"
    },
    {
        "parentCode": "008",
        "code": "0081027",
        "name": "國外營業部",
        "shortcut": "華銀國"
    },
    {
        "parentCode": "008",
        "code": "0081038",
        "name": "城內分行",
        "shortcut": "華銀城內"
    },
    {
        "parentCode": "008",
        "code": "0081049",
        "name": "大稻埕分行",
        "shortcut": "華銀大稻埕"
    },
    {
        "parentCode": "008",
        "code": "0081050",
        "name": "建成分行",
        "shortcut": "華銀建成"
    },
    {
        "parentCode": "008",
        "code": "0081061",
        "name": "中山分行",
        "shortcut": "華銀中山"
    },
    {
        "parentCode": "008",
        "code": "0081072",
        "name": "圓山分行",
        "shortcut": "華銀圓山"
    },
    {
        "parentCode": "008",
        "code": "0081083",
        "name": "城東分行",
        "shortcut": "華銀城東"
    },
    {
        "parentCode": "008",
        "code": "0081094",
        "name": "西門分行",
        "shortcut": "華銀西門"
    },
    {
        "parentCode": "008",
        "code": "0081108",
        "name": "南松山分行",
        "shortcut": "華銀南松山"
    },
    {
        "parentCode": "008",
        "code": "0081119",
        "name": "仁愛路分行",
        "shortcut": "華銀仁愛"
    },
    {
        "parentCode": "008",
        "code": "0081120",
        "name": "南京東路分行",
        "shortcut": "華銀南京東"
    },
    {
        "parentCode": "008",
        "code": "0081131",
        "name": "新生分行",
        "shortcut": "華銀新生"
    },
    {
        "parentCode": "008",
        "code": "0081142",
        "name": "大同分行",
        "shortcut": "華銀大同"
    },
    {
        "parentCode": "008",
        "code": "0081153",
        "name": "松山分行",
        "shortcut": "華銀松山"
    },
    {
        "parentCode": "008",
        "code": "0081164",
        "name": "中崙分行",
        "shortcut": "華銀中崙"
    },
    {
        "parentCode": "008",
        "code": "0081175",
        "name": "臺北南門分行",
        "shortcut": "華銀南門"
    },
    {
        "parentCode": "008",
        "code": "0081186",
        "name": "公館分行",
        "shortcut": "華銀公館"
    },
    {
        "parentCode": "008",
        "code": "0081197",
        "name": "信義分行",
        "shortcut": "華銀信義"
    },
    {
        "parentCode": "008",
        "code": "0081201",
        "name": "忠孝東路分行",
        "shortcut": "華銀忠孝"
    },
    {
        "parentCode": "008",
        "code": "0081212",
        "name": "和平分行",
        "shortcut": "華銀和平"
    },
    {
        "parentCode": "008",
        "code": "0081223",
        "name": "雙園分行",
        "shortcut": "華銀雙園"
    },
    {
        "parentCode": "008",
        "code": "0081234",
        "name": "士林分行",
        "shortcut": "華銀士林"
    },
    {
        "parentCode": "008",
        "code": "0081245",
        "name": "東台北分行",
        "shortcut": "華銀東台北"
    },
    {
        "parentCode": "008",
        "code": "0081256",
        "name": "大安分行",
        "shortcut": "華銀大安"
    },
    {
        "parentCode": "008",
        "code": "0081267",
        "name": "民生分行",
        "shortcut": "華銀民生"
    },
    {
        "parentCode": "008",
        "code": "0081278",
        "name": "復興分行",
        "shortcut": "華銀復興"
    },
    {
        "parentCode": "008",
        "code": "0081289",
        "name": "龍江分行",
        "shortcut": "華銀龍江"
    },
    {
        "parentCode": "008",
        "code": "0081290",
        "name": "永吉分行",
        "shortcut": "華銀永吉"
    },
    {
        "parentCode": "008",
        "code": "0081304",
        "name": "敦化分行",
        "shortcut": "華銀敦化"
    },
    {
        "parentCode": "008",
        "code": "0081326",
        "name": "大直分行",
        "shortcut": "華銀大直"
    },
    {
        "parentCode": "008",
        "code": "0081337",
        "name": "敦和分行",
        "shortcut": "華銀敦和"
    },
    {
        "parentCode": "008",
        "code": "0081348",
        "name": "東湖分行",
        "shortcut": "華銀東湖"
    },
    {
        "parentCode": "008",
        "code": "0081360",
        "name": "東興分行",
        "shortcut": "華銀東興"
    },
    {
        "parentCode": "008",
        "code": "0081371",
        "name": "北南港分行",
        "shortcut": "華銀北南港"
    },
    {
        "parentCode": "008",
        "code": "0081382",
        "name": "木柵分行",
        "shortcut": "華銀木柵"
    },
    {
        "parentCode": "008",
        "code": "0081393",
        "name": "板橋文化分行",
        "shortcut": "華銀板橋文"
    },
    {
        "parentCode": "008",
        "code": "0081430",
        "name": "南內湖分行",
        "shortcut": "華銀南內湖"
    },
    {
        "parentCode": "008",
        "code": "0081452",
        "name": "長安分行",
        "shortcut": "華銀長安"
    },
    {
        "parentCode": "008",
        "code": "0081474",
        "name": "懷生分行",
        "shortcut": "華銀懷生"
    },
    {
        "parentCode": "008",
        "code": "0081485",
        "name": "中華路分行",
        "shortcut": "華銀中華路"
    },
    {
        "parentCode": "008",
        "code": "0081496",
        "name": "信維分行",
        "shortcut": "華銀信維"
    },
    {
        "parentCode": "008",
        "code": "0081511",
        "name": "埔墘分行",
        "shortcut": "華銀埔墘"
    },
    {
        "parentCode": "008",
        "code": "0081522",
        "name": "石牌分行",
        "shortcut": "華銀石牌"
    },
    {
        "parentCode": "008",
        "code": "0081533",
        "name": "瑞祥分行",
        "shortcut": "華銀瑞祥"
    },
    {
        "parentCode": "008",
        "code": "0081544",
        "name": "台大分行",
        "shortcut": "華銀台大"
    },
    {
        "parentCode": "008",
        "code": "0081566",
        "name": "世貿分行",
        "shortcut": "華銀世貿"
    },
    {
        "parentCode": "008",
        "code": "0081577",
        "name": "萬華分行",
        "shortcut": "華銀萬華"
    },
    {
        "parentCode": "008",
        "code": "0081588",
        "name": "南港分行",
        "shortcut": "華銀南港"
    },
    {
        "parentCode": "008",
        "code": "0081599",
        "name": "華江分行",
        "shortcut": "華銀華江"
    },
    {
        "parentCode": "008",
        "code": "0081603",
        "name": "板橋分行",
        "shortcut": "華銀板橋"
    },
    {
        "parentCode": "008",
        "code": "0081614",
        "name": "三重分行",
        "shortcut": "華銀三重"
    },
    {
        "parentCode": "008",
        "code": "0081625",
        "name": "北三重分行",
        "shortcut": "華銀北三重"
    },
    {
        "parentCode": "008",
        "code": "0081636",
        "name": "新莊分行",
        "shortcut": "華銀新莊"
    },
    {
        "parentCode": "008",
        "code": "0081647",
        "name": "永和分行",
        "shortcut": "華銀永和"
    },
    {
        "parentCode": "008",
        "code": "0081658",
        "name": "中和分行",
        "shortcut": "華銀中和"
    },
    {
        "parentCode": "008",
        "code": "0081669",
        "name": "新店分行",
        "shortcut": "華銀新店"
    },
    {
        "parentCode": "008",
        "code": "0081670",
        "name": "淡水分行",
        "shortcut": "華銀淡水"
    },
    {
        "parentCode": "008",
        "code": "0081681",
        "name": "汐止分行",
        "shortcut": "華銀汐止"
    },
    {
        "parentCode": "008",
        "code": "0081692",
        "name": "南永和分行",
        "shortcut": "華銀南永和"
    },
    {
        "parentCode": "008",
        "code": "0081706",
        "name": "西三重分行",
        "shortcut": "華銀西三重"
    },
    {
        "parentCode": "008",
        "code": "0081717",
        "name": "南三重分行",
        "shortcut": "華銀南三重"
    },
    {
        "parentCode": "008",
        "code": "0081728",
        "name": "雙和分行",
        "shortcut": "華銀雙和"
    },
    {
        "parentCode": "008",
        "code": "0081739",
        "name": "新泰分行",
        "shortcut": "華銀新泰"
    },
    {
        "parentCode": "008",
        "code": "0081740",
        "name": "二重分行",
        "shortcut": "華銀二重"
    },
    {
        "parentCode": "008",
        "code": "0081751",
        "name": "板新分行",
        "shortcut": "華銀板新"
    },
    {
        "parentCode": "008",
        "code": "0081762",
        "name": "五股分行",
        "shortcut": "華銀五股"
    },
    {
        "parentCode": "008",
        "code": "0081784",
        "name": "北投分行",
        "shortcut": "華銀北投"
    },
    {
        "parentCode": "008",
        "code": "0081795",
        "name": "西湖分行",
        "shortcut": "華銀西湖"
    },
    {
        "parentCode": "008",
        "code": "0081809",
        "name": "積穗分行",
        "shortcut": "華銀積穗"
    },
    {
        "parentCode": "008",
        "code": "0081821",
        "name": "福和分行",
        "shortcut": "華銀福和"
    },
    {
        "parentCode": "008",
        "code": "0081832",
        "name": "南勢角分行",
        "shortcut": "華銀南勢角"
    },
    {
        "parentCode": "008",
        "code": "0081843",
        "name": "北蘆洲分行",
        "shortcut": "華銀北蘆洲"
    },
    {
        "parentCode": "008",
        "code": "0081854",
        "name": "蘆洲分行",
        "shortcut": "華銀蘆洲"
    },
    {
        "parentCode": "008",
        "code": "0081865",
        "name": "土城分行",
        "shortcut": "華銀土城"
    },
    {
        "parentCode": "008",
        "code": "0081876",
        "name": "北新分行",
        "shortcut": "華銀北新"
    },
    {
        "parentCode": "008",
        "code": "0081898",
        "name": "天母分行",
        "shortcut": "華銀天母"
    },
    {
        "parentCode": "008",
        "code": "0081902",
        "name": "內湖分行",
        "shortcut": "華銀內湖"
    },
    {
        "parentCode": "008",
        "code": "0081913",
        "name": "樹林分行",
        "shortcut": "華銀樹林"
    },
    {
        "parentCode": "008",
        "code": "0081924",
        "name": "樟樹灣分行",
        "shortcut": "華銀樟樹灣"
    },
    {
        "parentCode": "008",
        "code": "0081935",
        "name": "泰山分行",
        "shortcut": "華銀泰山"
    },
    {
        "parentCode": "008",
        "code": "0081946",
        "name": "三峽分行",
        "shortcut": "華銀三峽"
    },
    {
        "parentCode": "008",
        "code": "0081957",
        "name": "文山分行",
        "shortcut": "華銀文山"
    },
    {
        "parentCode": "008",
        "code": "0081968",
        "name": "鶯歌分行",
        "shortcut": "華銀鶯歌"
    },
    {
        "parentCode": "008",
        "code": "0081979",
        "name": "北新莊分行",
        "shortcut": "華銀北新莊"
    },
    {
        "parentCode": "008",
        "code": "0081980",
        "name": "北土城分行",
        "shortcut": "華銀北土城"
    },
    {
        "parentCode": "008",
        "code": "0081991",
        "name": "林口站前分行",
        "shortcut": "華銀林口站"
    },
    {
        "parentCode": "008",
        "code": "0082002",
        "name": "基隆分行",
        "shortcut": "華銀基隆"
    },
    {
        "parentCode": "008",
        "code": "0082013",
        "name": "基隆港口分行",
        "shortcut": "華銀港口"
    },
    {
        "parentCode": "008",
        "code": "0082116",
        "name": "七堵分行",
        "shortcut": "華銀七堵"
    },
    {
        "parentCode": "008",
        "code": "0082208",
        "name": "羅東分行",
        "shortcut": "華銀羅東"
    },
    {
        "parentCode": "008",
        "code": "0082219",
        "name": "宜蘭分行",
        "shortcut": "華銀宜蘭"
    },
    {
        "parentCode": "008",
        "code": "0082404",
        "name": "桃園分行",
        "shortcut": "華銀桃園"
    },
    {
        "parentCode": "008",
        "code": "0082415",
        "name": "中壢分行",
        "shortcut": "華銀中壢"
    },
    {
        "parentCode": "008",
        "code": "0082426",
        "name": "楊梅分行",
        "shortcut": "華銀楊梅"
    },
    {
        "parentCode": "008",
        "code": "0082437",
        "name": "壢昌分行",
        "shortcut": "華銀壢昌"
    },
    {
        "parentCode": "008",
        "code": "0082448",
        "name": "北桃園分行",
        "shortcut": "華銀北桃園"
    },
    {
        "parentCode": "008",
        "code": "0082459",
        "name": "南崁分行",
        "shortcut": "華銀南崁"
    },
    {
        "parentCode": "008",
        "code": "0082460",
        "name": "平鎮分行",
        "shortcut": "華銀平鎮"
    },
    {
        "parentCode": "008",
        "code": "0082471",
        "name": "八德分行",
        "shortcut": "華銀八德"
    },
    {
        "parentCode": "008",
        "code": "0082482",
        "name": "龜山分行",
        "shortcut": "華銀龜山"
    },
    {
        "parentCode": "008",
        "code": "0082493",
        "name": "龍潭分行",
        "shortcut": "華銀龍潭"
    },
    {
        "parentCode": "008",
        "code": "0082507",
        "name": "大溪分行",
        "shortcut": "華銀大溪"
    },
    {
        "parentCode": "008",
        "code": "0082518",
        "name": "內壢分行",
        "shortcut": "華銀內壢"
    },
    {
        "parentCode": "008",
        "code": "0082529",
        "name": "林口分行",
        "shortcut": "華銀林口"
    },
    {
        "parentCode": "008",
        "code": "0082600",
        "name": "觀音分行",
        "shortcut": "華銀觀音"
    },
    {
        "parentCode": "008",
        "code": "0082622",
        "name": "大園分行",
        "shortcut": "華銀大園"
    },
    {
        "parentCode": "008",
        "code": "0083009",
        "name": "新竹分行",
        "shortcut": "華銀新竹"
    },
    {
        "parentCode": "008",
        "code": "0083010",
        "name": "竹東分行",
        "shortcut": "華銀竹東"
    },
    {
        "parentCode": "008",
        "code": "0083021",
        "name": "竹科分行",
        "shortcut": "華銀竹科"
    },
    {
        "parentCode": "008",
        "code": "0083102",
        "name": "新豐分行",
        "shortcut": "華銀新豐"
    },
    {
        "parentCode": "008",
        "code": "0083135",
        "name": "六家分行",
        "shortcut": "華銀六家"
    },
    {
        "parentCode": "008",
        "code": "0083205",
        "name": "竹南分行",
        "shortcut": "華銀竹南"
    },
    {
        "parentCode": "008",
        "code": "0083216",
        "name": "頭份分行",
        "shortcut": "華銀頭份"
    },
    {
        "parentCode": "008",
        "code": "0083227",
        "name": "苗栗分行",
        "shortcut": "華銀苗栗"
    },
    {
        "parentCode": "008",
        "code": "0083238",
        "name": "竹北分行",
        "shortcut": "華銀竹北"
    },
    {
        "parentCode": "008",
        "code": "0083515",
        "name": "大眾分行",
        "shortcut": "華銀大眾"
    },
    {
        "parentCode": "008",
        "code": "0084006",
        "name": "豐原分行",
        "shortcut": "華銀豐原"
    },
    {
        "parentCode": "008",
        "code": "0084017",
        "name": "東勢分行",
        "shortcut": "華銀東勢"
    },
    {
        "parentCode": "008",
        "code": "0084028",
        "name": "清水分行",
        "shortcut": "華銀清水"
    },
    {
        "parentCode": "008",
        "code": "0084039",
        "name": "西豐原分行",
        "shortcut": "華銀西豐原"
    },
    {
        "parentCode": "008",
        "code": "0084202",
        "name": "台中分行",
        "shortcut": "華銀台中"
    },
    {
        "parentCode": "008",
        "code": "0084224",
        "name": "南台中分行",
        "shortcut": "華銀南台中"
    },
    {
        "parentCode": "008",
        "code": "0084235",
        "name": "北台中分行",
        "shortcut": "華銀北台中"
    },
    {
        "parentCode": "008",
        "code": "0084246",
        "name": "台中港路分行",
        "shortcut": "華銀台中港"
    },
    {
        "parentCode": "008",
        "code": "0084257",
        "name": "大里分行",
        "shortcut": "華銀大里"
    },
    {
        "parentCode": "008",
        "code": "0084268",
        "name": "水湳分行",
        "shortcut": "華銀水湳"
    },
    {
        "parentCode": "008",
        "code": "0084279",
        "name": "五權分行",
        "shortcut": "華銀五權"
    },
    {
        "parentCode": "008",
        "code": "0084291",
        "name": "大甲分行",
        "shortcut": "華銀大甲"
    },
    {
        "parentCode": "008",
        "code": "0084305",
        "name": "太平分行",
        "shortcut": "華銀太平"
    },
    {
        "parentCode": "008",
        "code": "0084316",
        "name": "中科分行",
        "shortcut": "華銀中科"
    },
    {
        "parentCode": "008",
        "code": "0084512",
        "name": "沙鹿分行",
        "shortcut": "華銀沙鹿"
    },
    {
        "parentCode": "008",
        "code": "0085003",
        "name": "草屯分行",
        "shortcut": "華銀草屯"
    },
    {
        "parentCode": "008",
        "code": "0085014",
        "name": "南投分行",
        "shortcut": "華銀南投"
    },
    {
        "parentCode": "008",
        "code": "0085209",
        "name": "彰化分行",
        "shortcut": "華銀彰化"
    },
    {
        "parentCode": "008",
        "code": "0085210",
        "name": "和美分行",
        "shortcut": "華銀和美"
    },
    {
        "parentCode": "008",
        "code": "0085221",
        "name": "員林分行",
        "shortcut": "華銀員林"
    },
    {
        "parentCode": "008",
        "code": "0085232",
        "name": "鹿港分行",
        "shortcut": "華銀鹿港"
    },
    {
        "parentCode": "008",
        "code": "0085243",
        "name": "溪湖分行",
        "shortcut": "華銀溪湖"
    },
    {
        "parentCode": "008",
        "code": "0085405",
        "name": "斗六分行",
        "shortcut": "華銀斗六"
    },
    {
        "parentCode": "008",
        "code": "0085416",
        "name": "虎尾分行",
        "shortcut": "華銀虎尾"
    },
    {
        "parentCode": "008",
        "code": "0085427",
        "name": "西螺分行",
        "shortcut": "華銀西螺"
    },
    {
        "parentCode": "008",
        "code": "0086000",
        "name": "嘉義分行",
        "shortcut": "華銀嘉義"
    },
    {
        "parentCode": "008",
        "code": "0086011",
        "name": "嘉南分行",
        "shortcut": "華銀嘉南"
    },
    {
        "parentCode": "008",
        "code": "0086022",
        "name": "朴子分行",
        "shortcut": "華銀朴子"
    },
    {
        "parentCode": "008",
        "code": "0086206",
        "name": "新營分行",
        "shortcut": "華銀新營"
    },
    {
        "parentCode": "008",
        "code": "0086217",
        "name": "麻豆分行",
        "shortcut": "華銀麻豆"
    },
    {
        "parentCode": "008",
        "code": "0086228",
        "name": "永康分行",
        "shortcut": "華銀永康"
    },
    {
        "parentCode": "008",
        "code": "0086402",
        "name": "台南分行",
        "shortcut": "華銀台南"
    },
    {
        "parentCode": "008",
        "code": "0086424",
        "name": "東台南分行",
        "shortcut": "華銀東台南"
    },
    {
        "parentCode": "008",
        "code": "0086435",
        "name": "西台南分行",
        "shortcut": "華銀西台南"
    },
    {
        "parentCode": "008",
        "code": "0086446",
        "name": "北台南分行",
        "shortcut": "華銀北台南"
    },
    {
        "parentCode": "008",
        "code": "0086457",
        "name": "南都分行",
        "shortcut": "華銀南都"
    },
    {
        "parentCode": "008",
        "code": "0086468",
        "name": "安南分行",
        "shortcut": "華銀安南"
    },
    {
        "parentCode": "008",
        "code": "0086479",
        "name": "仁德分行",
        "shortcut": "華銀仁德"
    },
    {
        "parentCode": "008",
        "code": "0086480",
        "name": "新市分行",
        "shortcut": "華銀新市"
    },
    {
        "parentCode": "008",
        "code": "0086815",
        "name": "金華分行",
        "shortcut": "華銀金華"
    },
    {
        "parentCode": "008",
        "code": "0087007",
        "name": "高雄分行",
        "shortcut": "華銀高雄"
    },
    {
        "parentCode": "008",
        "code": "0087018",
        "name": "東苓分行",
        "shortcut": "華銀東苓"
    },
    {
        "parentCode": "008",
        "code": "0087029",
        "name": "新興分行",
        "shortcut": "華銀新興"
    },
    {
        "parentCode": "008",
        "code": "0087030",
        "name": "高雄三民分行",
        "shortcut": "華銀三民"
    },
    {
        "parentCode": "008",
        "code": "0087041",
        "name": "苓雅分行",
        "shortcut": "華銀苓雅"
    },
    {
        "parentCode": "008",
        "code": "0087052",
        "name": "前鎮分行",
        "shortcut": "華銀前鎮"
    },
    {
        "parentCode": "008",
        "code": "0087063",
        "name": "高雄博愛分行",
        "shortcut": "華銀博愛"
    },
    {
        "parentCode": "008",
        "code": "0087074",
        "name": "南高雄分行",
        "shortcut": "華銀南高雄"
    },
    {
        "parentCode": "008",
        "code": "0087085",
        "name": "東高雄分行",
        "shortcut": "華銀東高雄"
    },
    {
        "parentCode": "008",
        "code": "0087096",
        "name": "大昌分行",
        "shortcut": "華銀大昌"
    },
    {
        "parentCode": "008",
        "code": "0087100",
        "name": "北高雄分行",
        "shortcut": "華銀北高雄"
    },
    {
        "parentCode": "008",
        "code": "0087111",
        "name": "楠梓分行",
        "shortcut": "華銀楠梓"
    },
    {
        "parentCode": "008",
        "code": "0087122",
        "name": "左營分行",
        "shortcut": "華銀左營"
    },
    {
        "parentCode": "008",
        "code": "0087199",
        "name": "岡山分行",
        "shortcut": "華銀岡山"
    },
    {
        "parentCode": "008",
        "code": "0087203",
        "name": "鳳山分行",
        "shortcut": "華銀鳳山"
    },
    {
        "parentCode": "008",
        "code": "0087214",
        "name": "路竹分行",
        "shortcut": "華銀路竹"
    },
    {
        "parentCode": "008",
        "code": "0087225",
        "name": "仁武分行",
        "shortcut": "華銀仁武"
    },
    {
        "parentCode": "008",
        "code": "0087513",
        "name": "籬仔內分行",
        "shortcut": "華銀籬仔內"
    },
    {
        "parentCode": "008",
        "code": "0087524",
        "name": "五甲分行",
        "shortcut": "華銀五甲"
    },
    {
        "parentCode": "008",
        "code": "0087535",
        "name": "光華分行",
        "shortcut": "華銀光華"
    },
    {
        "parentCode": "008",
        "code": "0087605",
        "name": "小港分行",
        "shortcut": "華銀小港"
    },
    {
        "parentCode": "008",
        "code": "0087650",
        "name": "高雄桂林分行",
        "shortcut": "華銀桂林"
    },
    {
        "parentCode": "008",
        "code": "0088004",
        "name": "屏東分行",
        "shortcut": "華銀屏東"
    },
    {
        "parentCode": "008",
        "code": "0088015",
        "name": "內埔分行",
        "shortcut": "華銀內埔"
    },
    {
        "parentCode": "008",
        "code": "0088026",
        "name": "潮州分行",
        "shortcut": "華銀潮州"
    },
    {
        "parentCode": "008",
        "code": "0088130",
        "name": "佳冬分行",
        "shortcut": "華銀佳冬"
    },
    {
        "parentCode": "008",
        "code": "0088200",
        "name": "花蓮分行",
        "shortcut": "華銀花蓮"
    },
    {
        "parentCode": "008",
        "code": "0088303",
        "name": "台東分行",
        "shortcut": "華銀台東"
    },
    {
        "parentCode": "008",
        "code": "0088886",
        "name": "營運總部分行",
        "shortcut": "華銀營運總"
    },
    {
        "parentCode": "009",
        "code": "0092200",
        "name": "總行營業部",
        "shortcut": "彰銀營"
    },
    {
        "parentCode": "009",
        "code": "0093003",
        "name": "吉林分行",
        "shortcut": "彰銀吉林"
    },
    {
        "parentCode": "009",
        "code": "0093010",
        "name": "城內分行",
        "shortcut": "彰銀城內"
    },
    {
        "parentCode": "009",
        "code": "0094004",
        "name": "北台中分行",
        "shortcut": "彰銀北台中"
    },
    {
        "parentCode": "009",
        "code": "0094011",
        "name": "南台中分行",
        "shortcut": "彰銀南台中"
    },
    {
        "parentCode": "009",
        "code": "0094028",
        "name": "北屯分行",
        "shortcut": "彰銀北屯"
    },
    {
        "parentCode": "009",
        "code": "0094035",
        "name": "水湳分行",
        "shortcut": "彰銀水湳"
    },
    {
        "parentCode": "009",
        "code": "0094042",
        "name": "南屯分行",
        "shortcut": "彰銀南屯"
    },
    {
        "parentCode": "009",
        "code": "0094059",
        "name": "台中分行",
        "shortcut": "彰銀台中"
    },
    {
        "parentCode": "009",
        "code": "0094108",
        "name": "基隆分行",
        "shortcut": "彰銀基隆"
    },
    {
        "parentCode": "009",
        "code": "0094115",
        "name": "仁愛分行",
        "shortcut": "彰銀仁愛"
    },
    {
        "parentCode": "009",
        "code": "0094122",
        "name": "東基隆分行",
        "shortcut": "彰銀東基隆"
    },
    {
        "parentCode": "009",
        "code": "0094202",
        "name": "宜蘭分行",
        "shortcut": "彰銀宜蘭"
    },
    {
        "parentCode": "009",
        "code": "0094219",
        "name": "羅東分行",
        "shortcut": "彰銀羅東"
    },
    {
        "parentCode": "009",
        "code": "0095005",
        "name": "北門分行",
        "shortcut": "彰銀北門"
    },
    {
        "parentCode": "009",
        "code": "0095012",
        "name": "台北分行",
        "shortcut": "彰銀台北"
    },
    {
        "parentCode": "009",
        "code": "0095029",
        "name": "萬華分行",
        "shortcut": "彰銀萬華"
    },
    {
        "parentCode": "009",
        "code": "0095036",
        "name": "西門分行",
        "shortcut": "彰銀西門"
    },
    {
        "parentCode": "009",
        "code": "0095043",
        "name": "永樂分行",
        "shortcut": "彰銀永樂"
    },
    {
        "parentCode": "009",
        "code": "0095050",
        "name": "建成分行",
        "shortcut": "彰銀建成"
    },
    {
        "parentCode": "009",
        "code": "0095067",
        "name": "大同分行",
        "shortcut": "彰銀大同"
    },
    {
        "parentCode": "009",
        "code": "0095074",
        "name": "承德分行",
        "shortcut": "彰銀承德"
    },
    {
        "parentCode": "009",
        "code": "0095081",
        "name": "中山北路分行",
        "shortcut": "彰銀中山北"
    },
    {
        "parentCode": "009",
        "code": "0095109",
        "name": "東門分行",
        "shortcut": "彰銀東門"
    },
    {
        "parentCode": "009",
        "code": "0095116",
        "name": "古亭分行",
        "shortcut": "彰銀古亭"
    },
    {
        "parentCode": "009",
        "code": "0095123",
        "name": "五分埔分行",
        "shortcut": "彰銀五分埔"
    },
    {
        "parentCode": "009",
        "code": "0095130",
        "name": "大安分行",
        "shortcut": "彰銀大安"
    },
    {
        "parentCode": "009",
        "code": "0095147",
        "name": "城東分行",
        "shortcut": "彰銀城東"
    },
    {
        "parentCode": "009",
        "code": "0095154",
        "name": "中崙分行",
        "shortcut": "彰銀中崙"
    },
    {
        "parentCode": "009",
        "code": "0095161",
        "name": "雙園分行",
        "shortcut": "彰銀雙園"
    },
    {
        "parentCode": "009",
        "code": "0095178",
        "name": "士林分行",
        "shortcut": "彰銀士林"
    },
    {
        "parentCode": "009",
        "code": "0095185",
        "name": "總部分行",
        "shortcut": "彰銀總部"
    },
    {
        "parentCode": "009",
        "code": "0095192",
        "name": "松山分行",
        "shortcut": "彰銀松山"
    },
    {
        "parentCode": "009",
        "code": "0095203",
        "name": "忠孝東路分行",
        "shortcut": "彰銀忠孝東"
    },
    {
        "parentCode": "009",
        "code": "0095210",
        "name": "信義分行",
        "shortcut": "彰銀信義"
    },
    {
        "parentCode": "009",
        "code": "0095227",
        "name": "東台北分行",
        "shortcut": "彰銀東台北"
    },
    {
        "parentCode": "009",
        "code": "0095234",
        "name": "民生分行",
        "shortcut": "彰銀民生"
    },
    {
        "parentCode": "009",
        "code": "0095241",
        "name": "西松分行",
        "shortcut": "彰銀西松"
    },
    {
        "parentCode": "009",
        "code": "0095258",
        "name": "復興分行",
        "shortcut": "彰銀復興"
    },
    {
        "parentCode": "009",
        "code": "0095265",
        "name": "台北世貿中心分行",
        "shortcut": "彰銀世貿"
    },
    {
        "parentCode": "009",
        "code": "0095272",
        "name": "敦化分行",
        "shortcut": "彰銀敦化"
    },
    {
        "parentCode": "009",
        "code": "0095289",
        "name": "建國分行",
        "shortcut": "彰銀建國"
    },
    {
        "parentCode": "009",
        "code": "0095296",
        "name": "內湖分行",
        "shortcut": "彰銀內湖"
    },
    {
        "parentCode": "009",
        "code": "0095307",
        "name": "天母分行",
        "shortcut": "彰銀天母"
    },
    {
        "parentCode": "009",
        "code": "0095314",
        "name": "中正分行",
        "shortcut": "彰銀中正"
    },
    {
        "parentCode": "009",
        "code": "0095321",
        "name": "仁和分行",
        "shortcut": "彰銀仁和"
    },
    {
        "parentCode": "009",
        "code": "0095338",
        "name": "永春分行",
        "shortcut": "彰銀永春"
    },
    {
        "parentCode": "009",
        "code": "0095345",
        "name": "光隆分行",
        "shortcut": "彰銀光隆"
    },
    {
        "parentCode": "009",
        "code": "0095352",
        "name": "木柵分行",
        "shortcut": "彰銀木柵"
    },
    {
        "parentCode": "009",
        "code": "0095369",
        "name": "新湖分行",
        "shortcut": "彰銀新湖"
    },
    {
        "parentCode": "009",
        "code": "0095376",
        "name": "東湖分行",
        "shortcut": "彰銀東湖"
    },
    {
        "parentCode": "009",
        "code": "0095383",
        "name": "南港科學園區分行",
        "shortcut": "彰銀南港科"
    },
    {
        "parentCode": "009",
        "code": "0095390",
        "name": "北投分行",
        "shortcut": "彰銀北投"
    },
    {
        "parentCode": "009",
        "code": "0095401",
        "name": "和平分行",
        "shortcut": "彰銀和平"
    },
    {
        "parentCode": "009",
        "code": "0095418",
        "name": "東興分行",
        "shortcut": "彰銀東興"
    },
    {
        "parentCode": "009",
        "code": "0095425",
        "name": "龍潭分行",
        "shortcut": "彰銀龍潭"
    },
    {
        "parentCode": "009",
        "code": "0095432",
        "name": "汐科分行",
        "shortcut": "彰銀汐科"
    },
    {
        "parentCode": "009",
        "code": "0095456",
        "name": "新樹分行",
        "shortcut": "彰銀新樹"
    },
    {
        "parentCode": "009",
        "code": "0095463",
        "name": "立德分行",
        "shortcut": "彰銀立德"
    },
    {
        "parentCode": "009",
        "code": "0095470",
        "name": "泰山分行",
        "shortcut": "彰銀泰山"
    },
    {
        "parentCode": "009",
        "code": "0095487",
        "name": "五股工業區分行",
        "shortcut": "彰銀五工"
    },
    {
        "parentCode": "009",
        "code": "0095494",
        "name": "南新莊分行",
        "shortcut": "彰銀南新莊"
    },
    {
        "parentCode": "009",
        "code": "0095505",
        "name": "三重埔分行",
        "shortcut": "彰銀三重埔"
    },
    {
        "parentCode": "009",
        "code": "0095512",
        "name": "北三重埔分行",
        "shortcut": "彰銀北三重"
    },
    {
        "parentCode": "009",
        "code": "0095529",
        "name": "新店分行",
        "shortcut": "彰銀新店"
    },
    {
        "parentCode": "009",
        "code": "0095536",
        "name": "永和分行",
        "shortcut": "彰銀永和"
    },
    {
        "parentCode": "009",
        "code": "0095543",
        "name": "瑞芳分行",
        "shortcut": "彰銀瑞芳"
    },
    {
        "parentCode": "009",
        "code": "0095550",
        "name": "新莊分行",
        "shortcut": "彰銀新莊"
    },
    {
        "parentCode": "009",
        "code": "0095567",
        "name": "樹林分行",
        "shortcut": "彰銀樹林"
    },
    {
        "parentCode": "009",
        "code": "0095574",
        "name": "板橋分行",
        "shortcut": "彰銀板橋"
    },
    {
        "parentCode": "009",
        "code": "0095581",
        "name": "三峽分行",
        "shortcut": "彰銀三峽"
    },
    {
        "parentCode": "009",
        "code": "0095598",
        "name": "福和分行",
        "shortcut": "彰銀福和"
    },
    {
        "parentCode": "009",
        "code": "0095609",
        "name": "光復分行",
        "shortcut": "彰銀光復"
    },
    {
        "parentCode": "009",
        "code": "0095616",
        "name": "東三重分行",
        "shortcut": "彰銀東三重"
    },
    {
        "parentCode": "009",
        "code": "0095623",
        "name": "北新分行",
        "shortcut": "彰銀北新"
    },
    {
        "parentCode": "009",
        "code": "0095630",
        "name": "南三重分行",
        "shortcut": "彰銀南三重"
    },
    {
        "parentCode": "009",
        "code": "0095647",
        "name": "中和分行",
        "shortcut": "彰銀中和"
    },
    {
        "parentCode": "009",
        "code": "0095654",
        "name": "西三重分行",
        "shortcut": "彰銀西三重"
    },
    {
        "parentCode": "009",
        "code": "0095661",
        "name": "汐止分行",
        "shortcut": "彰銀汐止"
    },
    {
        "parentCode": "009",
        "code": "0095678",
        "name": "雙和分行",
        "shortcut": "彰銀雙和"
    },
    {
        "parentCode": "009",
        "code": "0095685",
        "name": "淡水分行",
        "shortcut": "彰銀淡水"
    },
    {
        "parentCode": "009",
        "code": "0095692",
        "name": "楊梅分行",
        "shortcut": "彰銀楊梅"
    },
    {
        "parentCode": "009",
        "code": "0095703",
        "name": "桃園分行",
        "shortcut": "彰銀桃園"
    },
    {
        "parentCode": "009",
        "code": "0095710",
        "name": "中壢分行",
        "shortcut": "彰銀中壢"
    },
    {
        "parentCode": "009",
        "code": "0095727",
        "name": "新竹分行",
        "shortcut": "彰銀新竹"
    },
    {
        "parentCode": "009",
        "code": "0095734",
        "name": "竹東分行",
        "shortcut": "彰銀竹東"
    },
    {
        "parentCode": "009",
        "code": "0095741",
        "name": "苗栗分行",
        "shortcut": "彰銀苗栗"
    },
    {
        "parentCode": "009",
        "code": "0095758",
        "name": "新竹科學園區分行",
        "shortcut": "彰銀新竹科"
    },
    {
        "parentCode": "009",
        "code": "0095765",
        "name": "北中壢分行",
        "shortcut": "彰銀北中壢"
    },
    {
        "parentCode": "009",
        "code": "0095772",
        "name": "八德分行",
        "shortcut": "彰銀八德"
    },
    {
        "parentCode": "009",
        "code": "0095789",
        "name": "竹南分行",
        "shortcut": "彰銀竹南"
    },
    {
        "parentCode": "009",
        "code": "0095796",
        "name": "南崁分行",
        "shortcut": "彰銀南崁"
    },
    {
        "parentCode": "009",
        "code": "0095807",
        "name": "苑裡分行",
        "shortcut": "彰銀苑裡"
    },
    {
        "parentCode": "009",
        "code": "0095814",
        "name": "大甲分行",
        "shortcut": "彰銀大甲"
    },
    {
        "parentCode": "009",
        "code": "0095821",
        "name": "清水分行",
        "shortcut": "彰銀清水"
    },
    {
        "parentCode": "009",
        "code": "0095838",
        "name": "豐原分行",
        "shortcut": "彰銀豐原"
    },
    {
        "parentCode": "009",
        "code": "0095845",
        "name": "東勢分行",
        "shortcut": "彰銀東勢"
    },
    {
        "parentCode": "009",
        "code": "0095852",
        "name": "霧峰分行",
        "shortcut": "彰銀霧峰"
    },
    {
        "parentCode": "009",
        "code": "0095869",
        "name": "大雅分行",
        "shortcut": "彰銀大雅"
    },
    {
        "parentCode": "009",
        "code": "0095876",
        "name": "沙鹿分行",
        "shortcut": "彰銀沙鹿"
    },
    {
        "parentCode": "009",
        "code": "0095890",
        "name": "太平分行",
        "shortcut": "彰銀太平"
    },
    {
        "parentCode": "009",
        "code": "0095901",
        "name": "草屯分行",
        "shortcut": "彰銀草屯"
    },
    {
        "parentCode": "009",
        "code": "0095918",
        "name": "南投分行",
        "shortcut": "彰銀南投"
    },
    {
        "parentCode": "009",
        "code": "0095925",
        "name": "埔里分行",
        "shortcut": "彰銀埔里"
    },
    {
        "parentCode": "009",
        "code": "0095932",
        "name": "水裡坑分行",
        "shortcut": "彰銀水裡坑"
    },
    {
        "parentCode": "009",
        "code": "0095963",
        "name": "大肚分行",
        "shortcut": "彰銀大肚"
    },
    {
        "parentCode": "009",
        "code": "0095987",
        "name": "竹北分行",
        "shortcut": "彰銀竹北"
    },
    {
        "parentCode": "009",
        "code": "0096006",
        "name": "彰化分行",
        "shortcut": "彰銀彰化"
    },
    {
        "parentCode": "009",
        "code": "0096013",
        "name": "鹿港分行",
        "shortcut": "彰銀鹿港"
    },
    {
        "parentCode": "009",
        "code": "0096020",
        "name": "員林分行",
        "shortcut": "彰銀員林"
    },
    {
        "parentCode": "009",
        "code": "0096037",
        "name": "溪湖分行",
        "shortcut": "彰銀溪湖"
    },
    {
        "parentCode": "009",
        "code": "0096044",
        "name": "北斗分行",
        "shortcut": "彰銀北斗"
    },
    {
        "parentCode": "009",
        "code": "0096051",
        "name": "西螺分行",
        "shortcut": "彰銀西螺"
    },
    {
        "parentCode": "009",
        "code": "0096068",
        "name": "和美分行",
        "shortcut": "彰銀和美"
    },
    {
        "parentCode": "009",
        "code": "0096075",
        "name": "大里分行",
        "shortcut": "彰銀大里"
    },
    {
        "parentCode": "009",
        "code": "0096099",
        "name": "二林分行",
        "shortcut": "彰銀二林"
    },
    {
        "parentCode": "009",
        "code": "0096100",
        "name": "斗六分行",
        "shortcut": "彰銀斗六"
    },
    {
        "parentCode": "009",
        "code": "0096117",
        "name": "斗南分行",
        "shortcut": "彰銀斗南"
    },
    {
        "parentCode": "009",
        "code": "0096124",
        "name": "虎尾分行",
        "shortcut": "彰銀虎尾"
    },
    {
        "parentCode": "009",
        "code": "0096131",
        "name": "北港分行",
        "shortcut": "彰銀北港"
    },
    {
        "parentCode": "009",
        "code": "0096204",
        "name": "大林分行",
        "shortcut": "彰銀大林"
    },
    {
        "parentCode": "009",
        "code": "0096211",
        "name": "嘉義分行",
        "shortcut": "彰銀嘉義"
    },
    {
        "parentCode": "009",
        "code": "0096228",
        "name": "東嘉義分行",
        "shortcut": "彰銀東嘉義"
    },
    {
        "parentCode": "009",
        "code": "0096235",
        "name": "新營分行",
        "shortcut": "彰銀新營"
    },
    {
        "parentCode": "009",
        "code": "0096242",
        "name": "永康分行",
        "shortcut": "彰銀永康"
    },
    {
        "parentCode": "009",
        "code": "0096259",
        "name": "南科樹谷分行",
        "shortcut": "彰銀南科"
    },
    {
        "parentCode": "009",
        "code": "0096266",
        "name": "歸仁分行",
        "shortcut": "彰銀歸仁"
    },
    {
        "parentCode": "009",
        "code": "0096402",
        "name": "台南分行",
        "shortcut": "彰銀台南"
    },
    {
        "parentCode": "009",
        "code": "0096419",
        "name": "延平分行",
        "shortcut": "彰銀延平"
    },
    {
        "parentCode": "009",
        "code": "0096426",
        "name": "西台南分行",
        "shortcut": "彰銀西台南"
    },
    {
        "parentCode": "009",
        "code": "0096433",
        "name": "東台南分行",
        "shortcut": "彰銀東台南"
    },
    {
        "parentCode": "009",
        "code": "0096440",
        "name": "南台南分行",
        "shortcut": "彰銀南台南"
    },
    {
        "parentCode": "009",
        "code": "0096457",
        "name": "安南分行",
        "shortcut": "彰銀安南"
    },
    {
        "parentCode": "009",
        "code": "0096506",
        "name": "旗山分行",
        "shortcut": "彰銀旗山"
    },
    {
        "parentCode": "009",
        "code": "0096513",
        "name": "岡山分行",
        "shortcut": "彰銀岡山"
    },
    {
        "parentCode": "009",
        "code": "0096520",
        "name": "鳳山分行",
        "shortcut": "彰銀鳳山"
    },
    {
        "parentCode": "009",
        "code": "0098119",
        "name": "高雄分行",
        "shortcut": "彰銀高雄"
    },
    {
        "parentCode": "009",
        "code": "0098126",
        "name": "七賢分行",
        "shortcut": "彰銀七賢"
    },
    {
        "parentCode": "009",
        "code": "0098133",
        "name": "鹽埕分行",
        "shortcut": "彰銀鹽埕"
    },
    {
        "parentCode": "009",
        "code": "0098140",
        "name": "東高雄分行",
        "shortcut": "彰銀東高雄"
    },
    {
        "parentCode": "009",
        "code": "0098157",
        "name": "南高雄分行",
        "shortcut": "彰銀南高雄"
    },
    {
        "parentCode": "009",
        "code": "0098164",
        "name": "北高雄分行",
        "shortcut": "彰銀北高雄"
    },
    {
        "parentCode": "009",
        "code": "0098171",
        "name": "三民分行",
        "shortcut": "彰銀三民"
    },
    {
        "parentCode": "009",
        "code": "0098195",
        "name": "前鎮分行",
        "shortcut": "彰銀前鎮"
    },
    {
        "parentCode": "009",
        "code": "0098206",
        "name": "九如路分行",
        "shortcut": "彰銀九如路"
    },
    {
        "parentCode": "009",
        "code": "0098213",
        "name": "新興分行",
        "shortcut": "彰銀新興"
    },
    {
        "parentCode": "009",
        "code": "0098220",
        "name": "苓雅分行",
        "shortcut": "彰銀苓雅"
    },
    {
        "parentCode": "009",
        "code": "0098237",
        "name": "大順分行",
        "shortcut": "彰銀大順"
    },
    {
        "parentCode": "009",
        "code": "0098244",
        "name": "博愛分行",
        "shortcut": "彰銀博愛"
    },
    {
        "parentCode": "009",
        "code": "0098251",
        "name": "左營分行",
        "shortcut": "彰銀左營"
    },
    {
        "parentCode": "009",
        "code": "0098300",
        "name": "屏東分行",
        "shortcut": "彰銀屏東"
    },
    {
        "parentCode": "009",
        "code": "0098317",
        "name": "東港分行",
        "shortcut": "彰銀東港"
    },
    {
        "parentCode": "009",
        "code": "0098324",
        "name": "潮州分行",
        "shortcut": "彰銀潮州"
    },
    {
        "parentCode": "009",
        "code": "0098348",
        "name": "恆春分行",
        "shortcut": "彰銀恆春"
    },
    {
        "parentCode": "009",
        "code": "0098404",
        "name": "花蓮分行",
        "shortcut": "彰銀花蓮"
    },
    {
        "parentCode": "009",
        "code": "0098508",
        "name": "台東分行",
        "shortcut": "彰銀台東"
    },
    {
        "parentCode": "009",
        "code": "0099269",
        "name": "思源分行",
        "shortcut": "彰銀思源"
    },
    {
        "parentCode": "009",
        "code": "0099283",
        "name": "土城分行",
        "shortcut": "彰銀土城"
    },
    {
        "parentCode": "009",
        "code": "0099290",
        "name": "北桃園分行",
        "shortcut": "彰銀北桃園"
    },
    {
        "parentCode": "009",
        "code": "0099301",
        "name": "新明分行",
        "shortcut": "彰銀新明"
    },
    {
        "parentCode": "009",
        "code": "0099318",
        "name": "埔心分行",
        "shortcut": "彰銀埔心"
    },
    {
        "parentCode": "009",
        "code": "0099332",
        "name": "西屯分行",
        "shortcut": "彰銀西屯"
    },
    {
        "parentCode": "009",
        "code": "0099349",
        "name": "大發分行",
        "shortcut": "彰銀大發"
    },
    {
        "parentCode": "009",
        "code": "0099356",
        "name": "東林口分行",
        "shortcut": "彰銀東林口"
    },
    {
        "parentCode": "009",
        "code": "0099523",
        "name": "土庫分行",
        "shortcut": "彰銀土庫"
    },
    {
        "parentCode": "009",
        "code": "0099547",
        "name": "路竹分行",
        "shortcut": "彰銀路竹"
    },
    {
        "parentCode": "009",
        "code": "0099554",
        "name": "蘇澳分行",
        "shortcut": "彰銀蘇澳"
    },
    {
        "parentCode": "009",
        "code": "0099561",
        "name": "北台南分行",
        "shortcut": "彰銀北台南"
    },
    {
        "parentCode": "009",
        "code": "0099603",
        "name": "潭子分行",
        "shortcut": "彰銀潭子"
    },
    {
        "parentCode": "009",
        "code": "0099610",
        "name": "北嘉義分行",
        "shortcut": "彰銀北嘉義"
    },
    {
        "parentCode": "009",
        "code": "0099627",
        "name": "北新竹分行",
        "shortcut": "彰銀北新竹"
    },
    {
        "parentCode": "009",
        "code": "0099634",
        "name": "建興分行",
        "shortcut": "彰銀建興"
    },
    {
        "parentCode": "009",
        "code": "0099641",
        "name": "三和路分行",
        "shortcut": "彰銀三和"
    },
    {
        "parentCode": "009",
        "code": "0099658",
        "name": "吉成分行",
        "shortcut": "彰銀吉成"
    },
    {
        "parentCode": "009",
        "code": "0099689",
        "name": "林口分行",
        "shortcut": "彰銀林口"
    },
    {
        "parentCode": "009",
        "code": "0099696",
        "name": "江翠分行",
        "shortcut": "彰銀江翠"
    },
    {
        "parentCode": "009",
        "code": "0099714",
        "name": "長安東路分行",
        "shortcut": "彰銀長安"
    },
    {
        "parentCode": "009",
        "code": "0099721",
        "name": "晴光分行",
        "shortcut": "彰銀晴光"
    },
    {
        "parentCode": "009",
        "code": "0099738",
        "name": "大直分行",
        "shortcut": "彰銀大直"
    },
    {
        "parentCode": "009",
        "code": "0099752",
        "name": "松江分行",
        "shortcut": "彰銀松江"
    },
    {
        "parentCode": "009",
        "code": "0099790",
        "name": "西內湖分行",
        "shortcut": "彰銀西內湖"
    },
    {
        "parentCode": "009",
        "code": "0099801",
        "name": "南港分行",
        "shortcut": "彰銀南港"
    },
    {
        "parentCode": "009",
        "code": "0099818",
        "name": "中港分行",
        "shortcut": "彰銀中港"
    },
    {
        "parentCode": "009",
        "code": "0099832",
        "name": "蘆洲分行",
        "shortcut": "彰銀蘆洲"
    },
    {
        "parentCode": "009",
        "code": "0099849",
        "name": "中華路分行",
        "shortcut": "彰銀中華路"
    },
    {
        "parentCode": "011",
        "code": "0110026",
        "name": "營業部",
        "shortcut": "上銀營"
    },
    {
        "parentCode": "011",
        "code": "0110037",
        "name": "儲蓄部",
        "shortcut": "上銀儲"
    },
    {
        "parentCode": "011",
        "code": "0110048",
        "name": "高雄分行",
        "shortcut": "上銀高雄"
    },
    {
        "parentCode": "011",
        "code": "0110060",
        "name": "城中分行",
        "shortcut": "上銀城中"
    },
    {
        "parentCode": "011",
        "code": "0110082",
        "name": "台中分行",
        "shortcut": "上銀台中"
    },
    {
        "parentCode": "011",
        "code": "0110093",
        "name": "東台北分行",
        "shortcut": "上銀東台北"
    },
    {
        "parentCode": "011",
        "code": "0110107",
        "name": "台南分行",
        "shortcut": "上銀台南"
    },
    {
        "parentCode": "011",
        "code": "0110118",
        "name": "桃園分行",
        "shortcut": "上銀桃園"
    },
    {
        "parentCode": "011",
        "code": "0110129",
        "name": "北高雄分行",
        "shortcut": "上銀北高雄"
    },
    {
        "parentCode": "011",
        "code": "0110130",
        "name": "三重分行",
        "shortcut": "上銀三重"
    },
    {
        "parentCode": "011",
        "code": "0110141",
        "name": "板橋分行",
        "shortcut": "上銀板橋"
    },
    {
        "parentCode": "011",
        "code": "0110152",
        "name": "永和分行",
        "shortcut": "上銀永和"
    },
    {
        "parentCode": "011",
        "code": "0110163",
        "name": "員林分行",
        "shortcut": "上銀員林"
    },
    {
        "parentCode": "011",
        "code": "0110185",
        "name": "信義分行",
        "shortcut": "上銀信義"
    },
    {
        "parentCode": "011",
        "code": "0110196",
        "name": "民生分行",
        "shortcut": "上銀民生"
    },
    {
        "parentCode": "011",
        "code": "0110200",
        "name": "忠孝分行",
        "shortcut": "上銀忠孝"
    },
    {
        "parentCode": "011",
        "code": "0110211",
        "name": "龍山分行",
        "shortcut": "上銀龍山"
    },
    {
        "parentCode": "011",
        "code": "0110222",
        "name": "內湖分行",
        "shortcut": "上銀內湖"
    },
    {
        "parentCode": "011",
        "code": "0110233",
        "name": "中山分行",
        "shortcut": "上銀中山"
    },
    {
        "parentCode": "011",
        "code": "0110244",
        "name": "仁愛分行",
        "shortcut": "上銀仁愛"
    },
    {
        "parentCode": "011",
        "code": "0110255",
        "name": "新莊分行",
        "shortcut": "上銀新莊"
    },
    {
        "parentCode": "011",
        "code": "0110266",
        "name": "中壢分行",
        "shortcut": "上銀中壢"
    },
    {
        "parentCode": "011",
        "code": "0110288",
        "name": "松山分行",
        "shortcut": "上銀松山"
    },
    {
        "parentCode": "011",
        "code": "0110299",
        "name": "新店分行",
        "shortcut": "上銀新店"
    },
    {
        "parentCode": "011",
        "code": "0110303",
        "name": "前金分行",
        "shortcut": "上銀前金"
    },
    {
        "parentCode": "011",
        "code": "0110314",
        "name": "松南分行",
        "shortcut": "上銀松南"
    },
    {
        "parentCode": "011",
        "code": "0110325",
        "name": "蘆洲分行",
        "shortcut": "上銀蘆洲"
    },
    {
        "parentCode": "011",
        "code": "0110336",
        "name": "中和分行",
        "shortcut": "上銀中和"
    },
    {
        "parentCode": "011",
        "code": "0110347",
        "name": "新竹分行",
        "shortcut": "上銀新竹"
    },
    {
        "parentCode": "011",
        "code": "0110358",
        "name": "中港分行",
        "shortcut": "上銀中港"
    },
    {
        "parentCode": "011",
        "code": "0110369",
        "name": "士林分行",
        "shortcut": "上銀士林"
    },
    {
        "parentCode": "011",
        "code": "0110370",
        "name": "東台南分行",
        "shortcut": "上銀東台南"
    },
    {
        "parentCode": "011",
        "code": "0110381",
        "name": "永康分行",
        "shortcut": "上銀永康"
    },
    {
        "parentCode": "011",
        "code": "0110392",
        "name": "北三重分行",
        "shortcut": "上銀北三重"
    },
    {
        "parentCode": "011",
        "code": "0110406",
        "name": "南京東路分行",
        "shortcut": "上銀南京東"
    },
    {
        "parentCode": "011",
        "code": "0110417",
        "name": "大里分行",
        "shortcut": "上銀大里"
    },
    {
        "parentCode": "011",
        "code": "0110428",
        "name": "土城分行",
        "shortcut": "上銀土城"
    },
    {
        "parentCode": "011",
        "code": "0110439",
        "name": "世貿分行",
        "shortcut": "上銀世貿"
    },
    {
        "parentCode": "011",
        "code": "0110440",
        "name": "承德分行",
        "shortcut": "上銀承德"
    },
    {
        "parentCode": "011",
        "code": "0110451",
        "name": "汐止分行",
        "shortcut": "上銀汐止"
    },
    {
        "parentCode": "011",
        "code": "0110462",
        "name": "屏東分行",
        "shortcut": "上銀屏東"
    },
    {
        "parentCode": "011",
        "code": "0110473",
        "name": "三民分行",
        "shortcut": "上銀三民"
    },
    {
        "parentCode": "011",
        "code": "0110484",
        "name": "華江分行",
        "shortcut": "上銀華江"
    },
    {
        "parentCode": "011",
        "code": "0110495",
        "name": "豐原分行",
        "shortcut": "上銀豐原"
    },
    {
        "parentCode": "011",
        "code": "0110509",
        "name": "鳳山分行",
        "shortcut": "上銀鳳山"
    },
    {
        "parentCode": "011",
        "code": "0110510",
        "name": "天母分行",
        "shortcut": "上銀天母"
    },
    {
        "parentCode": "011",
        "code": "0110521",
        "name": "樹林分行",
        "shortcut": "上銀樹林"
    },
    {
        "parentCode": "011",
        "code": "0110532",
        "name": "基隆分行",
        "shortcut": "上銀基隆"
    },
    {
        "parentCode": "011",
        "code": "0110543",
        "name": "楊梅分行",
        "shortcut": "上銀楊梅"
    },
    {
        "parentCode": "011",
        "code": "0110554",
        "name": "內湖科技園區分行",
        "shortcut": "上銀內科"
    },
    {
        "parentCode": "011",
        "code": "0110565",
        "name": "北中和分行",
        "shortcut": "上銀北中和"
    },
    {
        "parentCode": "011",
        "code": "0110576",
        "name": "西湖分行",
        "shortcut": "上銀西湖"
    },
    {
        "parentCode": "011",
        "code": "0110598",
        "name": "二重分行",
        "shortcut": "上銀二重"
    },
    {
        "parentCode": "011",
        "code": "0110602",
        "name": "延平分行",
        "shortcut": "上銀延平"
    },
    {
        "parentCode": "011",
        "code": "0110613",
        "name": "南崁分行",
        "shortcut": "上銀南崁"
    },
    {
        "parentCode": "011",
        "code": "0110624",
        "name": "南港分行",
        "shortcut": "上銀南港"
    },
    {
        "parentCode": "011",
        "code": "0110635",
        "name": "北新竹分行",
        "shortcut": "上銀北新竹"
    },
    {
        "parentCode": "011",
        "code": "0110657",
        "name": "宜蘭分行",
        "shortcut": "上銀宜蘭"
    },
    {
        "parentCode": "011",
        "code": "0110668",
        "name": "文山分行",
        "shortcut": "上銀文山"
    },
    {
        "parentCode": "011",
        "code": "0110680",
        "name": "敦北分行",
        "shortcut": "上銀敦北"
    },
    {
        "parentCode": "011",
        "code": "0110691",
        "name": "東高雄分行",
        "shortcut": "上銀東高雄"
    },
    {
        "parentCode": "011",
        "code": "0110705",
        "name": "竹北分行",
        "shortcut": "上銀竹北"
    },
    {
        "parentCode": "011",
        "code": "0110716",
        "name": "松江分行",
        "shortcut": "上銀松江"
    },
    {
        "parentCode": "011",
        "code": "0110727",
        "name": "中科分行",
        "shortcut": "上銀中科"
    },
    {
        "parentCode": "011",
        "code": "0110738",
        "name": "觀音分行",
        "shortcut": "上銀觀音"
    },
    {
        "parentCode": "011",
        "code": "0110749",
        "name": "北桃園分行",
        "shortcut": "上銀北桃園"
    },
    {
        "parentCode": "011",
        "code": "0110750",
        "name": "丹鳳分行",
        "shortcut": "上銀丹鳳"
    },
    {
        "parentCode": "011",
        "code": "0110761",
        "name": "北新莊分行",
        "shortcut": "上銀北新莊"
    },
    {
        "parentCode": "011",
        "code": "0110772",
        "name": "南屯分行",
        "shortcut": "上銀南屯"
    },
    {
        "parentCode": "012",
        "code": "0122009",
        "name": "營業部",
        "shortcut": "北富銀營"
    },
    {
        "parentCode": "012",
        "code": "0122032",
        "name": "長安東路分行",
        "shortcut": "北富銀長東"
    },
    {
        "parentCode": "012",
        "code": "0122102",
        "name": "公庫處",
        "shortcut": "北富銀公庫"
    },
    {
        "parentCode": "012",
        "code": "0122205",
        "name": "城東分行",
        "shortcut": "北富銀城東"
    },
    {
        "parentCode": "012",
        "code": "0122216",
        "name": "農安分行",
        "shortcut": "北富銀農安"
    },
    {
        "parentCode": "012",
        "code": "0123006",
        "name": "士林分行",
        "shortcut": "北富銀士林"
    },
    {
        "parentCode": "012",
        "code": "0123017",
        "name": "士東分行",
        "shortcut": "北富銀士東"
    },
    {
        "parentCode": "012",
        "code": "0123028",
        "name": "瑞光分行（客戶移撥瑞湖分行服務）",
        "shortcut": "北富銀瑞光"
    },
    {
        "parentCode": "012",
        "code": "0123039",
        "name": "玉成分行",
        "shortcut": "北富銀玉成"
    },
    {
        "parentCode": "012",
        "code": "0123040",
        "name": "福港分行",
        "shortcut": "北富銀福港"
    },
    {
        "parentCode": "012",
        "code": "0123051",
        "name": "忠孝分行",
        "shortcut": "北富銀忠孝"
    },
    {
        "parentCode": "012",
        "code": "0123062",
        "name": "承德分行",
        "shortcut": "北富銀承德"
    },
    {
        "parentCode": "012",
        "code": "0123073",
        "name": "龍江分行（客戶移撥南京東路分行服務）",
        "shortcut": "北富銀龍江"
    },
    {
        "parentCode": "012",
        "code": "0123109",
        "name": "延平分行",
        "shortcut": "北富銀延平"
    },
    {
        "parentCode": "012",
        "code": "0123202",
        "name": "木柵分行",
        "shortcut": "北富銀木柵"
    },
    {
        "parentCode": "012",
        "code": "0123213",
        "name": "木新分行",
        "shortcut": "北富銀木新"
    },
    {
        "parentCode": "012",
        "code": "0123305",
        "name": "西門分行",
        "shortcut": "北富銀西門"
    },
    {
        "parentCode": "012",
        "code": "0123408",
        "name": "八德分行",
        "shortcut": "北富銀八德"
    },
    {
        "parentCode": "012",
        "code": "0123419",
        "name": "松南分行",
        "shortcut": "北富銀松南"
    },
    {
        "parentCode": "012",
        "code": "0123420",
        "name": "永吉分行",
        "shortcut": "北富銀永吉"
    },
    {
        "parentCode": "012",
        "code": "0123501",
        "name": "中山分行",
        "shortcut": "北富銀中山"
    },
    {
        "parentCode": "012",
        "code": "0123604",
        "name": "北投分行",
        "shortcut": "北富銀北投"
    },
    {
        "parentCode": "012",
        "code": "0123615",
        "name": "石牌分行",
        "shortcut": "北富銀石牌"
    },
    {
        "parentCode": "012",
        "code": "0123707",
        "name": "大安分行",
        "shortcut": "北富銀大安"
    },
    {
        "parentCode": "012",
        "code": "0123800",
        "name": "大同分行",
        "shortcut": "北富銀大同"
    },
    {
        "parentCode": "012",
        "code": "0123903",
        "name": "古亭分行",
        "shortcut": "北富銀古亭"
    },
    {
        "parentCode": "012",
        "code": "0124003",
        "name": "雙園分行",
        "shortcut": "北富銀雙園"
    },
    {
        "parentCode": "012",
        "code": "0124014",
        "name": "萬華分行",
        "shortcut": "北富銀萬華"
    },
    {
        "parentCode": "012",
        "code": "0124106",
        "name": "建成分行",
        "shortcut": "北富銀建成"
    },
    {
        "parentCode": "012",
        "code": "0124117",
        "name": "市府分行",
        "shortcut": "北富銀市府"
    },
    {
        "parentCode": "012",
        "code": "0124209",
        "name": "南港分行",
        "shortcut": "北富銀南港"
    },
    {
        "parentCode": "012",
        "code": "0124302",
        "name": "景美分行",
        "shortcut": "北富銀景美"
    },
    {
        "parentCode": "012",
        "code": "0124313",
        "name": "興隆分行",
        "shortcut": "北富銀興隆"
    },
    {
        "parentCode": "012",
        "code": "0124405",
        "name": "內湖分行",
        "shortcut": "北富銀內湖"
    },
    {
        "parentCode": "012",
        "code": "0124427",
        "name": "文德分行",
        "shortcut": "北富銀文德"
    },
    {
        "parentCode": "012",
        "code": "0124508",
        "name": "敦化分行",
        "shortcut": "北富銀敦化"
    },
    {
        "parentCode": "012",
        "code": "0124542",
        "name": "民生分行",
        "shortcut": "北富銀民生"
    },
    {
        "parentCode": "012",
        "code": "0124601",
        "name": "信義分行",
        "shortcut": "北富銀信義"
    },
    {
        "parentCode": "012",
        "code": "0124612",
        "name": "莊敬分行",
        "shortcut": "北富銀莊敬"
    },
    {
        "parentCode": "012",
        "code": "0124623",
        "name": "東門分行",
        "shortcut": "北富銀東門"
    },
    {
        "parentCode": "012",
        "code": "0124704",
        "name": "松江分行",
        "shortcut": "北富銀松江"
    },
    {
        "parentCode": "012",
        "code": "0124807",
        "name": "和平分行",
        "shortcut": "北富銀和平"
    },
    {
        "parentCode": "012",
        "code": "0124900",
        "name": "延吉分行",
        "shortcut": "北富銀延吉"
    },
    {
        "parentCode": "012",
        "code": "0125103",
        "name": "南門分行",
        "shortcut": "北富銀南門"
    },
    {
        "parentCode": "012",
        "code": "0125206",
        "name": "復興分行",
        "shortcut": "北富銀復興"
    },
    {
        "parentCode": "012",
        "code": "0125309",
        "name": "西松分行",
        "shortcut": "北富銀西松"
    },
    {
        "parentCode": "012",
        "code": "0125402",
        "name": "長安分行",
        "shortcut": "北富銀長安"
    },
    {
        "parentCode": "012",
        "code": "0125505",
        "name": "桂林分行",
        "shortcut": "北富銀桂林"
    },
    {
        "parentCode": "012",
        "code": "0125701",
        "name": "敦和分行",
        "shortcut": "北富銀敦和"
    },
    {
        "parentCode": "012",
        "code": "0125804",
        "name": "臨沂分行",
        "shortcut": "北富銀臨沂"
    },
    {
        "parentCode": "012",
        "code": "0125907",
        "name": "中崙分行",
        "shortcut": "北富銀中崙"
    },
    {
        "parentCode": "012",
        "code": "0126007",
        "name": "基隆路分行",
        "shortcut": "北富基隆路"
    },
    {
        "parentCode": "012",
        "code": "0126100",
        "name": "師大分行",
        "shortcut": "北富銀師大"
    },
    {
        "parentCode": "012",
        "code": "0126203",
        "name": "永春分行",
        "shortcut": "北富銀永春"
    },
    {
        "parentCode": "012",
        "code": "0126214",
        "name": "懷生分行",
        "shortcut": "北富銀懷生"
    },
    {
        "parentCode": "012",
        "code": "0126306",
        "name": "民權分行",
        "shortcut": "北富銀民權"
    },
    {
        "parentCode": "012",
        "code": "0126409",
        "name": "吉林簡易型分行",
        "shortcut": "北富銀吉林"
    },
    {
        "parentCode": "012",
        "code": "0126502",
        "name": "社子分行",
        "shortcut": "北富銀社子"
    },
    {
        "parentCode": "012",
        "code": "0126605",
        "name": "港都分行",
        "shortcut": "北富銀港都"
    },
    {
        "parentCode": "012",
        "code": "0126683",
        "name": "西湖分行",
        "shortcut": "北富銀西湖"
    },
    {
        "parentCode": "012",
        "code": "0126694",
        "name": "金城分行（客戶移撥土城分行服務）",
        "shortcut": "北富銀金城"
    },
    {
        "parentCode": "012",
        "code": "0126708",
        "name": "萬隆分行",
        "shortcut": "北富銀萬隆"
    },
    {
        "parentCode": "012",
        "code": "0126719",
        "name": "中港分行",
        "shortcut": "北富銀中港"
    },
    {
        "parentCode": "012",
        "code": "0126720",
        "name": "新莊分行",
        "shortcut": "北富銀新莊"
    },
    {
        "parentCode": "012",
        "code": "0126731",
        "name": "桃園分行",
        "shortcut": "北富銀桃園"
    },
    {
        "parentCode": "012",
        "code": "0126742",
        "name": "安平分行",
        "shortcut": "北富銀安平"
    },
    {
        "parentCode": "012",
        "code": "0126764",
        "name": "松隆簡易型分行",
        "shortcut": "北富銀松隆"
    },
    {
        "parentCode": "012",
        "code": "0126775",
        "name": "埔墘分行",
        "shortcut": "北富銀埔墘"
    },
    {
        "parentCode": "012",
        "code": "0126786",
        "name": "北中壢分行",
        "shortcut": "北富銀北中"
    },
    {
        "parentCode": "012",
        "code": "0126797",
        "name": "三重分行",
        "shortcut": "北富銀三重"
    },
    {
        "parentCode": "012",
        "code": "0126801",
        "name": "豐原分行",
        "shortcut": "北富銀豐原"
    },
    {
        "parentCode": "012",
        "code": "0126812",
        "name": "中和分行",
        "shortcut": "北富銀中和"
    },
    {
        "parentCode": "012",
        "code": "0126823",
        "name": "鼓山分行",
        "shortcut": "北富銀鼓山"
    },
    {
        "parentCode": "012",
        "code": "0126845",
        "name": "風城分行",
        "shortcut": "北富銀風城"
    },
    {
        "parentCode": "012",
        "code": "0126856",
        "name": "彰化分行",
        "shortcut": "北富銀彰化"
    },
    {
        "parentCode": "012",
        "code": "0126867",
        "name": "東湖分行",
        "shortcut": "北富銀東湖"
    },
    {
        "parentCode": "012",
        "code": "0126878",
        "name": "永和分行",
        "shortcut": "北富銀永和"
    },
    {
        "parentCode": "012",
        "code": "0126889",
        "name": "岡山簡易型分行",
        "shortcut": "北富銀岡山"
    },
    {
        "parentCode": "012",
        "code": "0126890",
        "name": "台北一０一分行",
        "shortcut": "北富一０一"
    },
    {
        "parentCode": "012",
        "code": "0127015",
        "name": "雙連分行（客戶移撥營業部服務）",
        "shortcut": "北富銀雙連"
    },
    {
        "parentCode": "012",
        "code": "0127026",
        "name": "南京東路分行",
        "shortcut": "北富銀南東"
    },
    {
        "parentCode": "012",
        "code": "0127037",
        "name": "敦北分行",
        "shortcut": "北富銀敦北"
    },
    {
        "parentCode": "012",
        "code": "0127048",
        "name": "仁愛分行",
        "shortcut": "北富銀仁愛"
    },
    {
        "parentCode": "012",
        "code": "0127059",
        "name": "高雄分行",
        "shortcut": "北富銀高雄"
    },
    {
        "parentCode": "012",
        "code": "0127060",
        "name": "中正分行",
        "shortcut": "北富銀中正"
    },
    {
        "parentCode": "012",
        "code": "0127071",
        "name": "台中分行",
        "shortcut": "北富銀台中"
    },
    {
        "parentCode": "012",
        "code": "0127093",
        "name": "松山分行",
        "shortcut": "北富銀松山"
    },
    {
        "parentCode": "012",
        "code": "0127107",
        "name": "土城分行",
        "shortcut": "北富銀土城"
    },
    {
        "parentCode": "012",
        "code": "0127118",
        "name": "台南分行",
        "shortcut": "北富銀台南"
    },
    {
        "parentCode": "012",
        "code": "0127129",
        "name": "鳳山分行",
        "shortcut": "北富銀鳳山"
    },
    {
        "parentCode": "012",
        "code": "0127130",
        "name": "中壢分行",
        "shortcut": "北富銀中壢"
    },
    {
        "parentCode": "012",
        "code": "0127152",
        "name": "安和分行",
        "shortcut": "北富銀安和"
    },
    {
        "parentCode": "012",
        "code": "0127163",
        "name": "正義分行",
        "shortcut": "北富銀正義"
    },
    {
        "parentCode": "012",
        "code": "0127174",
        "name": "大湳分行",
        "shortcut": "北富銀大湳"
    },
    {
        "parentCode": "012",
        "code": "0127185",
        "name": "嘉義分行",
        "shortcut": "北富銀嘉義"
    },
    {
        "parentCode": "012",
        "code": "0127196",
        "name": "苓雅分行（客戶移撥港都分行服務）",
        "shortcut": "北富銀苓雅"
    },
    {
        "parentCode": "012",
        "code": "0127211",
        "name": "板橋分行",
        "shortcut": "北富銀板橋"
    },
    {
        "parentCode": "012",
        "code": "0127222",
        "name": "北台中分行",
        "shortcut": "北富銀北台"
    },
    {
        "parentCode": "012",
        "code": "0127233",
        "name": "三民分行",
        "shortcut": "北富銀三民"
    },
    {
        "parentCode": "012",
        "code": "0127244",
        "name": "建國分行",
        "shortcut": "北富銀建國"
    },
    {
        "parentCode": "012",
        "code": "0127255",
        "name": "新竹分行",
        "shortcut": "北富銀新竹"
    },
    {
        "parentCode": "012",
        "code": "0127266",
        "name": "新店分行",
        "shortcut": "北富銀新店"
    },
    {
        "parentCode": "012",
        "code": "0127277",
        "name": "天母分行",
        "shortcut": "北富銀天母"
    },
    {
        "parentCode": "012",
        "code": "0127288",
        "name": "汐止分行",
        "shortcut": "北富銀汐止"
    },
    {
        "parentCode": "012",
        "code": "0127303",
        "name": "永康分行",
        "shortcut": "北富銀永康"
    },
    {
        "parentCode": "012",
        "code": "0127314",
        "name": "城中分行",
        "shortcut": "北富銀城中"
    },
    {
        "parentCode": "012",
        "code": "0127336",
        "name": "五股分行",
        "shortcut": "北富銀五股"
    },
    {
        "parentCode": "012",
        "code": "0127347",
        "name": "新營分行",
        "shortcut": "北富銀新營"
    },
    {
        "parentCode": "012",
        "code": "0127358",
        "name": "屏東分行",
        "shortcut": "北富銀屏東"
    },
    {
        "parentCode": "012",
        "code": "0127369",
        "name": "前鎮分行",
        "shortcut": "北富銀前鎮"
    },
    {
        "parentCode": "012",
        "code": "0127370",
        "name": "敦南分行",
        "shortcut": "北富銀敦南"
    },
    {
        "parentCode": "012",
        "code": "0127381",
        "name": "保生分行",
        "shortcut": "北富銀保生"
    },
    {
        "parentCode": "012",
        "code": "0127392",
        "name": "員林分行",
        "shortcut": "北富銀員林"
    },
    {
        "parentCode": "012",
        "code": "0127406",
        "name": "羅東分行",
        "shortcut": "北富銀羅東"
    },
    {
        "parentCode": "012",
        "code": "0127417",
        "name": "瑞湖分行",
        "shortcut": "北富銀瑞湖"
    },
    {
        "parentCode": "012",
        "code": "0127428",
        "name": "基和簡易型分行（客戶移撥敦和分行服務）",
        "shortcut": "北富銀基和"
    },
    {
        "parentCode": "012",
        "code": "0127439",
        "name": "南昌簡易型分行（客戶移撥金華分行服務）",
        "shortcut": "北富銀南昌"
    },
    {
        "parentCode": "012",
        "code": "0127451",
        "name": "花蓮簡易型分行",
        "shortcut": "北富銀花蓮"
    },
    {
        "parentCode": "012",
        "code": "0127462",
        "name": "竹北分行",
        "shortcut": "北富銀竹北"
    },
    {
        "parentCode": "012",
        "code": "0127473",
        "name": "南台中分行",
        "shortcut": "北富銀南台"
    },
    {
        "parentCode": "012",
        "code": "0127484",
        "name": "博愛分行",
        "shortcut": "北富銀博愛"
    },
    {
        "parentCode": "012",
        "code": "0127495",
        "name": "蘆洲分行",
        "shortcut": "北富銀蘆洲"
    },
    {
        "parentCode": "012",
        "code": "0127509",
        "name": "華江分行",
        "shortcut": "北富銀華江"
    },
    {
        "parentCode": "012",
        "code": "0127510",
        "name": "大直分行",
        "shortcut": "北富銀大直"
    },
    {
        "parentCode": "012",
        "code": "0127521",
        "name": "樹林分行",
        "shortcut": "北富銀樹林"
    },
    {
        "parentCode": "012",
        "code": "0127532",
        "name": "基隆分行",
        "shortcut": "北富銀基隆"
    },
    {
        "parentCode": "012",
        "code": "0127543",
        "name": "竹科分行",
        "shortcut": "北富銀竹科"
    },
    {
        "parentCode": "012",
        "code": "0127554",
        "name": "林口簡易型分行",
        "shortcut": "北富銀林口"
    },
    {
        "parentCode": "012",
        "code": "0127565",
        "name": "新板分行",
        "shortcut": "北富銀新板"
    },
    {
        "parentCode": "012",
        "code": "0127576",
        "name": "三峽簡易型分行",
        "shortcut": "北富銀三峽"
    },
    {
        "parentCode": "012",
        "code": "0127587",
        "name": "南崁分行",
        "shortcut": "北富銀南崁"
    },
    {
        "parentCode": "012",
        "code": "0127598",
        "name": "東台南分行",
        "shortcut": "北富東台南"
    },
    {
        "parentCode": "012",
        "code": "0127602",
        "name": "左營分行",
        "shortcut": "北富銀左營"
    },
    {
        "parentCode": "012",
        "code": "0127613",
        "name": "大里分行",
        "shortcut": "北富銀大里"
    },
    {
        "parentCode": "012",
        "code": "0127624",
        "name": "得和分行",
        "shortcut": "北富銀得和"
    },
    {
        "parentCode": "013",
        "code": "0130017",
        "name": "館前分行",
        "shortcut": "國世館前"
    },
    {
        "parentCode": "013",
        "code": "0130028",
        "name": "國外部",
        "shortcut": "國世國外部"
    },
    {
        "parentCode": "013",
        "code": "0130039",
        "name": "南京東路分行",
        "shortcut": "國世南京東"
    },
    {
        "parentCode": "013",
        "code": "0130051",
        "name": "高雄分行",
        "shortcut": "國世高雄"
    },
    {
        "parentCode": "013",
        "code": "0130062",
        "name": "台中分行",
        "shortcut": "國世台中"
    },
    {
        "parentCode": "013",
        "code": "0130073",
        "name": "台北分行",
        "shortcut": "國世台北"
    },
    {
        "parentCode": "013",
        "code": "0130095",
        "name": "永和分行",
        "shortcut": "國世永和"
    },
    {
        "parentCode": "013",
        "code": "0130109",
        "name": "台南分行",
        "shortcut": "國世台南"
    },
    {
        "parentCode": "013",
        "code": "0130110",
        "name": "忠孝分行",
        "shortcut": "國世忠孝"
    },
    {
        "parentCode": "013",
        "code": "0130121",
        "name": "天母分行",
        "shortcut": "國世天母"
    },
    {
        "parentCode": "013",
        "code": "0130132",
        "name": "西台中分行",
        "shortcut": "國世西台中"
    },
    {
        "parentCode": "013",
        "code": "0130143",
        "name": "東高雄分行",
        "shortcut": "國世東高雄"
    },
    {
        "parentCode": "013",
        "code": "0130154",
        "name": "信義分行",
        "shortcut": "國世信義"
    },
    {
        "parentCode": "013",
        "code": "0130165",
        "name": "光復分行",
        "shortcut": "國世光復"
    },
    {
        "parentCode": "013",
        "code": "0130176",
        "name": "板橋分行",
        "shortcut": "國世板橋"
    },
    {
        "parentCode": "013",
        "code": "0130187",
        "name": "復興分行",
        "shortcut": "國世復興"
    },
    {
        "parentCode": "013",
        "code": "0130198",
        "name": "民權分行",
        "shortcut": "國世民權"
    },
    {
        "parentCode": "013",
        "code": "0130202",
        "name": "大安分行",
        "shortcut": "國世大安"
    },
    {
        "parentCode": "013",
        "code": "0130213",
        "name": "三重分行",
        "shortcut": "國世三重"
    },
    {
        "parentCode": "013",
        "code": "0130224",
        "name": "中壢分行",
        "shortcut": "國世中壢"
    },
    {
        "parentCode": "013",
        "code": "0130235",
        "name": "員林分行",
        "shortcut": "國世員林"
    },
    {
        "parentCode": "013",
        "code": "0130246",
        "name": "新店分行",
        "shortcut": "國世新店"
    },
    {
        "parentCode": "013",
        "code": "0130257",
        "name": "新莊分行",
        "shortcut": "國世新莊"
    },
    {
        "parentCode": "013",
        "code": "0130268",
        "name": "鳳山分行",
        "shortcut": "國世鳳山"
    },
    {
        "parentCode": "013",
        "code": "0130279",
        "name": "安和分行",
        "shortcut": "國世安和"
    },
    {
        "parentCode": "013",
        "code": "0130280",
        "name": "松江分行",
        "shortcut": "國世松江"
    },
    {
        "parentCode": "013",
        "code": "0130291",
        "name": "苓雅分行",
        "shortcut": "國世苓雅"
    },
    {
        "parentCode": "013",
        "code": "0130305",
        "name": "古亭分行",
        "shortcut": "國世古亭"
    },
    {
        "parentCode": "013",
        "code": "0130316",
        "name": "建成分行",
        "shortcut": "國世建成"
    },
    {
        "parentCode": "013",
        "code": "0130327",
        "name": "東門分行",
        "shortcut": "國世東門"
    },
    {
        "parentCode": "013",
        "code": "0130349",
        "name": "桃園分行",
        "shortcut": "國世桃園"
    },
    {
        "parentCode": "013",
        "code": "0130350",
        "name": "豐原分行",
        "shortcut": "國世豐原"
    },
    {
        "parentCode": "013",
        "code": "0130361",
        "name": "嘉義分行",
        "shortcut": "國世嘉義"
    },
    {
        "parentCode": "013",
        "code": "0130372",
        "name": "松山分行",
        "shortcut": "國世松山"
    },
    {
        "parentCode": "013",
        "code": "0130383",
        "name": "前金分行",
        "shortcut": "國世前金"
    },
    {
        "parentCode": "013",
        "code": "0130394",
        "name": "北三重分行",
        "shortcut": "國世北三重"
    },
    {
        "parentCode": "013",
        "code": "0130408",
        "name": "新竹分行",
        "shortcut": "國世新竹"
    },
    {
        "parentCode": "013",
        "code": "0130419",
        "name": "永康分行",
        "shortcut": "國世永康"
    },
    {
        "parentCode": "013",
        "code": "0130420",
        "name": "中山分行",
        "shortcut": "國世中山"
    },
    {
        "parentCode": "013",
        "code": "0130431",
        "name": "埔墘分行",
        "shortcut": "國世埔墘"
    },
    {
        "parentCode": "013",
        "code": "0130442",
        "name": "新生分行",
        "shortcut": "國世新生"
    },
    {
        "parentCode": "013",
        "code": "0130453",
        "name": "中和分行",
        "shortcut": "國世中和"
    },
    {
        "parentCode": "013",
        "code": "0130464",
        "name": "清水分行",
        "shortcut": "國世清水"
    },
    {
        "parentCode": "013",
        "code": "0130475",
        "name": "彰化分行",
        "shortcut": "國世彰化"
    },
    {
        "parentCode": "013",
        "code": "0130486",
        "name": "敦北分行",
        "shortcut": "國世敦北"
    },
    {
        "parentCode": "013",
        "code": "0130497",
        "name": "中正分行",
        "shortcut": "國世中正"
    },
    {
        "parentCode": "013",
        "code": "0130501",
        "name": "五權分行",
        "shortcut": "國世五權"
    },
    {
        "parentCode": "013",
        "code": "0130512",
        "name": "東台南分行",
        "shortcut": "國世東台南"
    },
    {
        "parentCode": "013",
        "code": "0130523",
        "name": "新興分行",
        "shortcut": "國世新興"
    },
    {
        "parentCode": "013",
        "code": "0130534",
        "name": "敦南分行",
        "shortcut": "國世敦南"
    },
    {
        "parentCode": "013",
        "code": "0130545",
        "name": "後埔分行",
        "shortcut": "國世後埔"
    },
    {
        "parentCode": "013",
        "code": "0130556",
        "name": "蘆洲分行",
        "shortcut": "國世蘆洲"
    },
    {
        "parentCode": "013",
        "code": "0130567",
        "name": "西門分行",
        "shortcut": "國世西門"
    },
    {
        "parentCode": "013",
        "code": "0130578",
        "name": "三民分行",
        "shortcut": "國世三民"
    },
    {
        "parentCode": "013",
        "code": "0130590",
        "name": "前鎮分行",
        "shortcut": "國世前鎮"
    },
    {
        "parentCode": "013",
        "code": "0130604",
        "name": "土城分行",
        "shortcut": "國世土城"
    },
    {
        "parentCode": "013",
        "code": "0130615",
        "name": "南高雄分行",
        "shortcut": "國世南高雄"
    },
    {
        "parentCode": "013",
        "code": "0130626",
        "name": "大同分行",
        "shortcut": "國世大同"
    },
    {
        "parentCode": "013",
        "code": "0130637",
        "name": "成功分行",
        "shortcut": "國世成功"
    },
    {
        "parentCode": "013",
        "code": "0130648",
        "name": "中港分行",
        "shortcut": "國世中港"
    },
    {
        "parentCode": "013",
        "code": "0130659",
        "name": "世貿分行",
        "shortcut": "國世世貿"
    },
    {
        "parentCode": "013",
        "code": "0130660",
        "name": "北桃園分行",
        "shortcut": "國世北桃園"
    },
    {
        "parentCode": "013",
        "code": "0130671",
        "name": "善化分行",
        "shortcut": "國世善化"
    },
    {
        "parentCode": "013",
        "code": "0130682",
        "name": "士林分行",
        "shortcut": "國世士林"
    },
    {
        "parentCode": "013",
        "code": "0130693",
        "name": "雙和分行",
        "shortcut": "國世雙和"
    },
    {
        "parentCode": "013",
        "code": "0130707",
        "name": "二重分行",
        "shortcut": "國世二重"
    },
    {
        "parentCode": "013",
        "code": "0130729",
        "name": "花蓮分行",
        "shortcut": "國世花蓮"
    },
    {
        "parentCode": "013",
        "code": "0130730",
        "name": "內湖分行",
        "shortcut": "國世內湖"
    },
    {
        "parentCode": "013",
        "code": "0130741",
        "name": "華江分行",
        "shortcut": "國世華江"
    },
    {
        "parentCode": "013",
        "code": "0130752",
        "name": "竹科分行",
        "shortcut": "國世竹科"
    },
    {
        "parentCode": "013",
        "code": "0130763",
        "name": "南門分行",
        "shortcut": "國世南門"
    },
    {
        "parentCode": "013",
        "code": "0130774",
        "name": "八德分行",
        "shortcut": "國世八德"
    },
    {
        "parentCode": "013",
        "code": "0130785",
        "name": "永春分行",
        "shortcut": "國世永春"
    },
    {
        "parentCode": "013",
        "code": "0130796",
        "name": "萬華分行",
        "shortcut": "國世萬華"
    },
    {
        "parentCode": "013",
        "code": "0131014",
        "name": "屏東分行",
        "shortcut": "國世屏東"
    },
    {
        "parentCode": "013",
        "code": "0131025",
        "name": "台東分行",
        "shortcut": "國世台東"
    },
    {
        "parentCode": "013",
        "code": "0131036",
        "name": "文山分行",
        "shortcut": "國世文山"
    },
    {
        "parentCode": "013",
        "code": "0131047",
        "name": "新樹分行",
        "shortcut": "國世新樹"
    },
    {
        "parentCode": "013",
        "code": "0131058",
        "name": "宜蘭分行",
        "shortcut": "國世宜蘭"
    },
    {
        "parentCode": "013",
        "code": "0131069",
        "name": "南港分行",
        "shortcut": "國世南港"
    },
    {
        "parentCode": "013",
        "code": "0131070",
        "name": "斗六分行",
        "shortcut": "國世斗六"
    },
    {
        "parentCode": "013",
        "code": "0131081",
        "name": "南投分行",
        "shortcut": "國世南投"
    },
    {
        "parentCode": "013",
        "code": "0131092",
        "name": "汐止分行",
        "shortcut": "國世汐止"
    },
    {
        "parentCode": "013",
        "code": "0131106",
        "name": "大直分行",
        "shortcut": "國世大直"
    },
    {
        "parentCode": "013",
        "code": "0131117",
        "name": "左營分行",
        "shortcut": "國世左營"
    },
    {
        "parentCode": "013",
        "code": "0131128",
        "name": "新營分行",
        "shortcut": "國世新營"
    },
    {
        "parentCode": "013",
        "code": "0131139",
        "name": "大甲分行",
        "shortcut": "國世大甲"
    },
    {
        "parentCode": "013",
        "code": "0131140",
        "name": "苗栗分行",
        "shortcut": "國世苗栗"
    },
    {
        "parentCode": "013",
        "code": "0131151",
        "name": "北投分行",
        "shortcut": "國世北投"
    },
    {
        "parentCode": "013",
        "code": "0131162",
        "name": "臨安分行",
        "shortcut": "國世臨安"
    },
    {
        "parentCode": "013",
        "code": "0131173",
        "name": "幸福分行",
        "shortcut": "國世幸福"
    },
    {
        "parentCode": "013",
        "code": "0131184",
        "name": "華山分行",
        "shortcut": "國世華山"
    },
    {
        "parentCode": "013",
        "code": "0131195",
        "name": "東湖分行",
        "shortcut": "國世東湖"
    },
    {
        "parentCode": "013",
        "code": "0131209",
        "name": "岡山分行",
        "shortcut": "國世岡山"
    },
    {
        "parentCode": "013",
        "code": "0131210",
        "name": "臨沂分行",
        "shortcut": "國世臨沂"
    },
    {
        "parentCode": "013",
        "code": "0131221",
        "name": "新湖分行",
        "shortcut": "國世新湖"
    },
    {
        "parentCode": "013",
        "code": "0131232",
        "name": "中崙分行",
        "shortcut": "國世中崙"
    },
    {
        "parentCode": "013",
        "code": "0131243",
        "name": "基隆分行",
        "shortcut": "國世基隆"
    },
    {
        "parentCode": "013",
        "code": "0131254",
        "name": "樹林分行",
        "shortcut": "國世樹林"
    },
    {
        "parentCode": "013",
        "code": "0131265",
        "name": "羅東簡易型分行",
        "shortcut": "國世羅東簡"
    },
    {
        "parentCode": "013",
        "code": "0131276",
        "name": "淡水分行",
        "shortcut": "國世淡水"
    },
    {
        "parentCode": "013",
        "code": "0131287",
        "name": "連城分行",
        "shortcut": "國世連城"
    },
    {
        "parentCode": "013",
        "code": "0131298",
        "name": "石牌分行",
        "shortcut": "國世石牌"
    },
    {
        "parentCode": "013",
        "code": "0131302",
        "name": "南內湖分行",
        "shortcut": "國世南內湖"
    },
    {
        "parentCode": "013",
        "code": "0131313",
        "name": "景美分行",
        "shortcut": "國世景美"
    },
    {
        "parentCode": "013",
        "code": "0131324",
        "name": "大坪林分行",
        "shortcut": "國世大坪林"
    },
    {
        "parentCode": "013",
        "code": "0131335",
        "name": "丹鳳分行",
        "shortcut": "國世丹鳳"
    },
    {
        "parentCode": "013",
        "code": "0131346",
        "name": "林口分行",
        "shortcut": "國世林口"
    },
    {
        "parentCode": "013",
        "code": "0131357",
        "name": "南崁分行",
        "shortcut": "國世南崁"
    },
    {
        "parentCode": "013",
        "code": "0132011",
        "name": "仁愛分行",
        "shortcut": "國世仁愛"
    },
    {
        "parentCode": "013",
        "code": "0132022",
        "name": "大昌分行",
        "shortcut": "國世大昌"
    },
    {
        "parentCode": "013",
        "code": "0132033",
        "name": "民生分行",
        "shortcut": "國世民生"
    },
    {
        "parentCode": "013",
        "code": "0132044",
        "name": "篤行分行",
        "shortcut": "國世篤行"
    },
    {
        "parentCode": "013",
        "code": "0132055",
        "name": "板東分行",
        "shortcut": "國世板東"
    },
    {
        "parentCode": "013",
        "code": "0132066",
        "name": "桃興分行",
        "shortcut": "國世桃興"
    },
    {
        "parentCode": "013",
        "code": "0132088",
        "name": "彰泰分行",
        "shortcut": "國世彰泰"
    },
    {
        "parentCode": "013",
        "code": "0132099",
        "name": "嘉泰分行",
        "shortcut": "國世嘉泰"
    },
    {
        "parentCode": "013",
        "code": "0132103",
        "name": "竹城分行",
        "shortcut": "國世竹城"
    },
    {
        "parentCode": "013",
        "code": "0132114",
        "name": "忠誠分行",
        "shortcut": "國世忠誠"
    },
    {
        "parentCode": "013",
        "code": "0132125",
        "name": "敦化分行",
        "shortcut": "國世敦化"
    },
    {
        "parentCode": "013",
        "code": "0132147",
        "name": "新泰分行",
        "shortcut": "國世新泰"
    },
    {
        "parentCode": "013",
        "code": "0132158",
        "name": "文心分行",
        "shortcut": "國世文心"
    },
    {
        "parentCode": "013",
        "code": "0132169",
        "name": "福和分行",
        "shortcut": "國世福和"
    },
    {
        "parentCode": "013",
        "code": "0132170",
        "name": "四維分行",
        "shortcut": "國世四維"
    },
    {
        "parentCode": "013",
        "code": "0132181",
        "name": "營業部",
        "shortcut": "國世營"
    },
    {
        "parentCode": "013",
        "code": "0132192",
        "name": "豐北分行",
        "shortcut": "國世豐北"
    },
    {
        "parentCode": "013",
        "code": "0132206",
        "name": "重新分行",
        "shortcut": "國世重新"
    },
    {
        "parentCode": "013",
        "code": "0132217",
        "name": "北中壢分行",
        "shortcut": "國世北中壢"
    },
    {
        "parentCode": "013",
        "code": "0132228",
        "name": "城東分行",
        "shortcut": "國世城東"
    },
    {
        "parentCode": "013",
        "code": "0132239",
        "name": "建國分行",
        "shortcut": "國世建國"
    },
    {
        "parentCode": "013",
        "code": "0132251",
        "name": "學府分行",
        "shortcut": "國世學府"
    },
    {
        "parentCode": "013",
        "code": "0132262",
        "name": "北新分行",
        "shortcut": "國世北新"
    },
    {
        "parentCode": "013",
        "code": "0132273",
        "name": "信安分行",
        "shortcut": "國世信安"
    },
    {
        "parentCode": "013",
        "code": "0132295",
        "name": "文德分行",
        "shortcut": "國世文德"
    },
    {
        "parentCode": "013",
        "code": "0132321",
        "name": "中台中分行",
        "shortcut": "國世中台中"
    },
    {
        "parentCode": "013",
        "code": "0132332",
        "name": "水湳分行",
        "shortcut": "國世水湳"
    },
    {
        "parentCode": "013",
        "code": "0132343",
        "name": "市政分行",
        "shortcut": "國世市政"
    },
    {
        "parentCode": "013",
        "code": "0132354",
        "name": "東台中分行",
        "shortcut": "國世東台中"
    },
    {
        "parentCode": "013",
        "code": "0132365",
        "name": "國光分行",
        "shortcut": "國世國光"
    },
    {
        "parentCode": "013",
        "code": "0132376",
        "name": "健行分行",
        "shortcut": "國世健行"
    },
    {
        "parentCode": "013",
        "code": "0132398",
        "name": "西屯分行",
        "shortcut": "國世西屯"
    },
    {
        "parentCode": "013",
        "code": "0132402",
        "name": "南屯分行",
        "shortcut": "國世南屯"
    },
    {
        "parentCode": "013",
        "code": "0132413",
        "name": "崇德分行",
        "shortcut": "國世崇德"
    },
    {
        "parentCode": "013",
        "code": "0132457",
        "name": "香山分行",
        "shortcut": "國世香山"
    },
    {
        "parentCode": "013",
        "code": "0132468",
        "name": "竹北分行",
        "shortcut": "國世竹北"
    },
    {
        "parentCode": "013",
        "code": "0132480",
        "name": "同德分行",
        "shortcut": "國世同德"
    },
    {
        "parentCode": "013",
        "code": "0132527",
        "name": "秀水簡易型分行",
        "shortcut": "國世秀水簡"
    },
    {
        "parentCode": "013",
        "code": "0132549",
        "name": "彰美分行",
        "shortcut": "國世彰美"
    },
    {
        "parentCode": "013",
        "code": "0132550",
        "name": "太平分行",
        "shortcut": "國世太平"
    },
    {
        "parentCode": "013",
        "code": "0132561",
        "name": "沙鹿分行",
        "shortcut": "國世沙鹿"
    },
    {
        "parentCode": "013",
        "code": "0132583",
        "name": "大里分行",
        "shortcut": "國世大里"
    },
    {
        "parentCode": "013",
        "code": "0132608",
        "name": "文華簡易型分行",
        "shortcut": "國世文華簡"
    },
    {
        "parentCode": "013",
        "code": "0132619",
        "name": "大雅分行",
        "shortcut": "國世大雅"
    },
    {
        "parentCode": "013",
        "code": "0132620",
        "name": "潭子分行",
        "shortcut": "國世潭子"
    },
    {
        "parentCode": "013",
        "code": "0132631",
        "name": "光華分行",
        "shortcut": "國世光華"
    },
    {
        "parentCode": "013",
        "code": "0132642",
        "name": "西松分行",
        "shortcut": "國世西松"
    },
    {
        "parentCode": "013",
        "code": "0132653",
        "name": "蘭雅分行",
        "shortcut": "國世蘭雅"
    },
    {
        "parentCode": "013",
        "code": "0132664",
        "name": "永平分行",
        "shortcut": "國世永平"
    },
    {
        "parentCode": "013",
        "code": "0132675",
        "name": "和平分行",
        "shortcut": "國世和平"
    },
    {
        "parentCode": "013",
        "code": "0132686",
        "name": "慶城分行",
        "shortcut": "國世慶城"
    },
    {
        "parentCode": "013",
        "code": "0132697",
        "name": "瑞湖分行",
        "shortcut": "國世瑞湖"
    },
    {
        "parentCode": "013",
        "code": "0132701",
        "name": "文昌分行",
        "shortcut": "國世文昌"
    },
    {
        "parentCode": "013",
        "code": "0132712",
        "name": "正義分行",
        "shortcut": "國世正義"
    },
    {
        "parentCode": "013",
        "code": "0132723",
        "name": "新板分行",
        "shortcut": "國世新板"
    },
    {
        "parentCode": "013",
        "code": "0132734",
        "name": "永貞分行",
        "shortcut": "國世永貞"
    },
    {
        "parentCode": "013",
        "code": "0132778",
        "name": "明誠分行",
        "shortcut": "國世明誠"
    },
    {
        "parentCode": "016",
        "code": "0161017",
        "name": "營業部",
        "shortcut": "高銀營"
    },
    {
        "parentCode": "016",
        "code": "0161028",
        "name": "公庫部",
        "shortcut": "高銀公"
    },
    {
        "parentCode": "016",
        "code": "0161039",
        "name": "岡山本洲分行",
        "shortcut": "高銀岡山"
    },
    {
        "parentCode": "016",
        "code": "0161040",
        "name": "國外部",
        "shortcut": "高銀國"
    },
    {
        "parentCode": "016",
        "code": "0161051",
        "name": "信託部",
        "shortcut": "高銀信"
    },
    {
        "parentCode": "016",
        "code": "0162003",
        "name": "鳳山分行",
        "shortcut": "高銀鳳山"
    },
    {
        "parentCode": "016",
        "code": "0162025",
        "name": "前金分行",
        "shortcut": "高銀前金"
    },
    {
        "parentCode": "016",
        "code": "0162036",
        "name": "三民分行",
        "shortcut": "高銀三民"
    },
    {
        "parentCode": "016",
        "code": "0162081",
        "name": "左營分行",
        "shortcut": "高銀左營"
    },
    {
        "parentCode": "016",
        "code": "0162106",
        "name": "小港分行",
        "shortcut": "高銀小港"
    },
    {
        "parentCode": "016",
        "code": "0162117",
        "name": "桃園分行",
        "shortcut": "高銀桃園"
    },
    {
        "parentCode": "016",
        "code": "0162128",
        "name": "前鎮分行",
        "shortcut": "高銀前鎮"
    },
    {
        "parentCode": "016",
        "code": "0162139",
        "name": "右昌分行",
        "shortcut": "高銀右昌"
    },
    {
        "parentCode": "016",
        "code": "0162140",
        "name": "灣內分行",
        "shortcut": "高銀灣內"
    },
    {
        "parentCode": "016",
        "code": "0162151",
        "name": "屏東分行",
        "shortcut": "高銀屏東"
    },
    {
        "parentCode": "016",
        "code": "0162162",
        "name": "桂林分行",
        "shortcut": "高銀桂林"
    },
    {
        "parentCode": "016",
        "code": "0162173",
        "name": "六合分行",
        "shortcut": "高銀六合"
    },
    {
        "parentCode": "016",
        "code": "0162184",
        "name": "楠梓分行",
        "shortcut": "高銀楠梓"
    },
    {
        "parentCode": "016",
        "code": "0162195",
        "name": "鼓山分行",
        "shortcut": "高銀鼓山"
    },
    {
        "parentCode": "016",
        "code": "0162209",
        "name": "建國分行",
        "shortcut": "高銀建國"
    },
    {
        "parentCode": "016",
        "code": "0162210",
        "name": "九如分行",
        "shortcut": "高銀九如"
    },
    {
        "parentCode": "016",
        "code": "0162221",
        "name": "鹽埕簡易型分行",
        "shortcut": "高銀鹽埕簡"
    },
    {
        "parentCode": "016",
        "code": "0162232",
        "name": "草衙分行",
        "shortcut": "高銀草衙"
    },
    {
        "parentCode": "016",
        "code": "0162243",
        "name": "板橋分行",
        "shortcut": "高銀板橋"
    },
    {
        "parentCode": "016",
        "code": "0162254",
        "name": "台南分行",
        "shortcut": "高銀台南"
    },
    {
        "parentCode": "016",
        "code": "0162265",
        "name": "三多分行",
        "shortcut": "高銀三多"
    },
    {
        "parentCode": "016",
        "code": "0162276",
        "name": "大發分行",
        "shortcut": "高銀大發"
    },
    {
        "parentCode": "016",
        "code": "0162287",
        "name": "大直分行",
        "shortcut": "高銀大直"
    },
    {
        "parentCode": "016",
        "code": "0162298",
        "name": "台中分行",
        "shortcut": "高銀台中"
    },
    {
        "parentCode": "016",
        "code": "0162313",
        "name": "旗津簡易型分行",
        "shortcut": "高銀旗津簡"
    },
    {
        "parentCode": "016",
        "code": "0162324",
        "name": "南高雄分行",
        "shortcut": "高銀南高雄"
    },
    {
        "parentCode": "016",
        "code": "0162335",
        "name": "大里分行",
        "shortcut": "高銀大里"
    },
    {
        "parentCode": "016",
        "code": "0162346",
        "name": "新竹分行",
        "shortcut": "高銀新竹"
    },
    {
        "parentCode": "016",
        "code": "0162357",
        "name": "市府分行",
        "shortcut": "高銀市府"
    },
    {
        "parentCode": "016",
        "code": "0162379",
        "name": "中和分行",
        "shortcut": "高銀中和"
    },
    {
        "parentCode": "016",
        "code": "0162380",
        "name": "台北分行",
        "shortcut": "高銀台北"
    },
    {
        "parentCode": "016",
        "code": "0162405",
        "name": "明誠分行",
        "shortcut": "高銀明誠"
    },
    {
        "parentCode": "016",
        "code": "0162416",
        "name": "北高雄分行",
        "shortcut": "高銀北高雄"
    },
    {
        "parentCode": "016",
        "code": "0162450",
        "name": "博愛分行",
        "shortcut": "高銀博愛"
    },
    {
        "parentCode": "017",
        "code": "0170022",
        "name": "港都分行",
        "shortcut": "兆豐銀港都"
    },
    {
        "parentCode": "017",
        "code": "0170033",
        "name": "楠梓分行",
        "shortcut": "兆豐銀楠梓"
    },
    {
        "parentCode": "017",
        "code": "0170044",
        "name": "台中分行",
        "shortcut": "兆豐銀台中"
    },
    {
        "parentCode": "017",
        "code": "0170055",
        "name": "忠孝分行",
        "shortcut": "兆豐銀忠孝"
    },
    {
        "parentCode": "017",
        "code": "0170066",
        "name": "府城分行",
        "shortcut": "兆豐銀府城"
    },
    {
        "parentCode": "017",
        "code": "0170077",
        "name": "國外部",
        "shortcut": "兆豐銀國外"
    },
    {
        "parentCode": "017",
        "code": "0170088",
        "name": "台北復興分行",
        "shortcut": "兆豐銀復興"
    },
    {
        "parentCode": "017",
        "code": "0170099",
        "name": "財富管理處",
        "shortcut": "兆豐銀財管"
    },
    {
        "parentCode": "017",
        "code": "0170103",
        "name": "蘭雅分行",
        "shortcut": "兆豐銀蘭雅"
    },
    {
        "parentCode": "017",
        "code": "0170114",
        "name": "財務部",
        "shortcut": "兆豐銀財務"
    },
    {
        "parentCode": "017",
        "code": "0170125",
        "name": "三重分行",
        "shortcut": "兆豐銀三重"
    },
    {
        "parentCode": "017",
        "code": "0170136",
        "name": "新興分行",
        "shortcut": "兆豐銀新興"
    },
    {
        "parentCode": "017",
        "code": "0170147",
        "name": "桃園分行",
        "shortcut": "兆豐銀桃園"
    },
    {
        "parentCode": "017",
        "code": "0170158",
        "name": "中山分行",
        "shortcut": "兆豐銀中山"
    },
    {
        "parentCode": "017",
        "code": "0170169",
        "name": "高雄加工出口區分行",
        "shortcut": "兆豐銀高加"
    },
    {
        "parentCode": "017",
        "code": "0170170",
        "name": "城中分行",
        "shortcut": "兆豐銀城中"
    },
    {
        "parentCode": "017",
        "code": "0170181",
        "name": "北彰化分行",
        "shortcut": "兆豐銀北彰"
    },
    {
        "parentCode": "017",
        "code": "0170192",
        "name": "安和分行",
        "shortcut": "兆豐銀安和"
    },
    {
        "parentCode": "017",
        "code": "0170206",
        "name": "竹科新安分行",
        "shortcut": "兆豐銀新安"
    },
    {
        "parentCode": "017",
        "code": "0170217",
        "name": "天母分行",
        "shortcut": "兆豐銀天母"
    },
    {
        "parentCode": "017",
        "code": "0170228",
        "name": "嘉義分行",
        "shortcut": "兆豐銀嘉義"
    },
    {
        "parentCode": "017",
        "code": "0170239",
        "name": "花蓮分行",
        "shortcut": "兆豐銀花蓮"
    },
    {
        "parentCode": "017",
        "code": "0170251",
        "name": "國際金融業務分行",
        "shortcut": "兆豐銀國金"
    },
    {
        "parentCode": "017",
        "code": "0170262",
        "name": "北新竹分行",
        "shortcut": "兆豐銀北竹"
    },
    {
        "parentCode": "017",
        "code": "0170273",
        "name": "板南分行",
        "shortcut": "兆豐銀板南"
    },
    {
        "parentCode": "017",
        "code": "0170284",
        "name": "潭子分行",
        "shortcut": "兆豐銀潭子"
    },
    {
        "parentCode": "017",
        "code": "0170295",
        "name": "桃園國際機場分行",
        "shortcut": "兆豐銀桃機"
    },
    {
        "parentCode": "017",
        "code": "0170309",
        "name": "南台北分行",
        "shortcut": "兆豐銀南北"
    },
    {
        "parentCode": "017",
        "code": "0170310",
        "name": "敦南分行",
        "shortcut": "兆豐銀敦南"
    },
    {
        "parentCode": "017",
        "code": "0170321",
        "name": "員林分行",
        "shortcut": "兆豐銀員林"
    },
    {
        "parentCode": "017",
        "code": "0170343",
        "name": "永和分行",
        "shortcut": "兆豐銀永和"
    },
    {
        "parentCode": "017",
        "code": "0170354",
        "name": "豐原分行",
        "shortcut": "兆豐銀豐原"
    },
    {
        "parentCode": "017",
        "code": "0170365",
        "name": "民生分行",
        "shortcut": "兆豐銀民生"
    },
    {
        "parentCode": "017",
        "code": "0170376",
        "name": "北台中分行",
        "shortcut": "兆豐銀北中"
    },
    {
        "parentCode": "017",
        "code": "0170387",
        "name": "三多分行",
        "shortcut": "兆豐銀三多"
    },
    {
        "parentCode": "017",
        "code": "0170398",
        "name": "中壢分行",
        "shortcut": "兆豐銀中壢"
    },
    {
        "parentCode": "017",
        "code": "0170402",
        "name": "三民分行",
        "shortcut": "兆豐銀三民"
    },
    {
        "parentCode": "017",
        "code": "0170413",
        "name": "新莊分行",
        "shortcut": "兆豐銀新莊"
    },
    {
        "parentCode": "017",
        "code": "0170424",
        "name": "松南分行",
        "shortcut": "兆豐銀松南"
    },
    {
        "parentCode": "017",
        "code": "0170435",
        "name": "大同分行",
        "shortcut": "兆豐銀大同"
    },
    {
        "parentCode": "017",
        "code": "0170446",
        "name": "南台中分行",
        "shortcut": "兆豐銀南中"
    },
    {
        "parentCode": "017",
        "code": "0170457",
        "name": "鳳山分行",
        "shortcut": "兆豐銀鳳山"
    },
    {
        "parentCode": "017",
        "code": "0170468",
        "name": "新店分行",
        "shortcut": "兆豐銀新店"
    },
    {
        "parentCode": "017",
        "code": "0170479",
        "name": "東高雄分行",
        "shortcut": "兆豐銀東高"
    },
    {
        "parentCode": "017",
        "code": "0170480",
        "name": "信義分行",
        "shortcut": "兆豐銀信義"
    },
    {
        "parentCode": "017",
        "code": "0170491",
        "name": "基隆分行",
        "shortcut": "兆豐銀基隆"
    },
    {
        "parentCode": "017",
        "code": "0170505",
        "name": "永康分行",
        "shortcut": "兆豐銀永康"
    },
    {
        "parentCode": "017",
        "code": "0170516",
        "name": "內湖分行",
        "shortcut": "兆豐銀內湖"
    },
    {
        "parentCode": "017",
        "code": "0170527",
        "name": "岡山分行",
        "shortcut": "兆豐銀岡山"
    },
    {
        "parentCode": "017",
        "code": "0170538",
        "name": "屏東分行",
        "shortcut": "兆豐銀屏東"
    },
    {
        "parentCode": "017",
        "code": "0170550",
        "name": "松山機場分行",
        "shortcut": "兆豐銀松山"
    },
    {
        "parentCode": "017",
        "code": "0170561",
        "name": "土城分行",
        "shortcut": "兆豐銀土城"
    },
    {
        "parentCode": "017",
        "code": "0170572",
        "name": "大稻埕分行",
        "shortcut": "兆豐銀大埕"
    },
    {
        "parentCode": "017",
        "code": "0170583",
        "name": "頭份分行",
        "shortcut": "兆豐銀頭份"
    },
    {
        "parentCode": "017",
        "code": "0170594",
        "name": "苓雅分行",
        "shortcut": "兆豐銀苓雅"
    },
    {
        "parentCode": "017",
        "code": "0170608",
        "name": "沙鹿分行",
        "shortcut": "兆豐銀沙鹿"
    },
    {
        "parentCode": "017",
        "code": "0170619",
        "name": "八德分行",
        "shortcut": "兆豐銀八德"
    },
    {
        "parentCode": "017",
        "code": "0170620",
        "name": "宜蘭分行",
        "shortcut": "兆豐銀宜蘭"
    },
    {
        "parentCode": "017",
        "code": "0170631",
        "name": "斗六分行",
        "shortcut": "兆豐銀斗六"
    },
    {
        "parentCode": "017",
        "code": "0170642",
        "name": "南投分行",
        "shortcut": "兆豐銀南投"
    },
    {
        "parentCode": "017",
        "code": "0170653",
        "name": "東台南分行",
        "shortcut": "兆豐銀東南"
    },
    {
        "parentCode": "017",
        "code": "0170664",
        "name": "高雄國際機場分行",
        "shortcut": "兆豐銀高機"
    },
    {
        "parentCode": "017",
        "code": "0170675",
        "name": "東內湖分行",
        "shortcut": "兆豐銀東內"
    },
    {
        "parentCode": "017",
        "code": "0170686",
        "name": "太平分行",
        "shortcut": "兆豐銀太平"
    },
    {
        "parentCode": "017",
        "code": "0170697",
        "name": "中和分行",
        "shortcut": "兆豐銀中和"
    },
    {
        "parentCode": "017",
        "code": "0170701",
        "name": "南京東路分行",
        "shortcut": "兆豐銀南東"
    },
    {
        "parentCode": "017",
        "code": "0170712",
        "name": "東台中分行",
        "shortcut": "兆豐銀東中"
    },
    {
        "parentCode": "017",
        "code": "0170723",
        "name": "北高雄分行",
        "shortcut": "兆豐銀北高"
    },
    {
        "parentCode": "017",
        "code": "0170745",
        "name": "南港分行",
        "shortcut": "兆豐銀南港"
    },
    {
        "parentCode": "017",
        "code": "0170756",
        "name": "竹南科學園區分行",
        "shortcut": "兆豐銀竹南"
    },
    {
        "parentCode": "017",
        "code": "0170767",
        "name": "中科分行",
        "shortcut": "兆豐銀中科"
    },
    {
        "parentCode": "017",
        "code": "0170778",
        "name": "大甲分行",
        "shortcut": "兆豐銀大甲"
    },
    {
        "parentCode": "017",
        "code": "0170789",
        "name": "成功簡易型分行",
        "shortcut": "兆豐銀成功"
    },
    {
        "parentCode": "017",
        "code": "0170790",
        "name": "金門分行",
        "shortcut": "兆豐銀金門"
    },
    {
        "parentCode": "017",
        "code": "0170804",
        "name": "南崁分行",
        "shortcut": "兆豐銀南崁"
    },
    {
        "parentCode": "017",
        "code": "0170815",
        "name": "中鋼簡易型分行",
        "shortcut": "兆豐銀中鋼"
    },
    {
        "parentCode": "017",
        "code": "0170837",
        "name": "榮總分行",
        "shortcut": "兆豐銀榮總"
    },
    {
        "parentCode": "017",
        "code": "0170860",
        "name": "駐外交部簡易型分行",
        "shortcut": "兆豐銀外交"
    },
    {
        "parentCode": "017",
        "code": "0171030",
        "name": "仁武分行",
        "shortcut": "兆豐銀仁武"
    },
    {
        "parentCode": "017",
        "code": "0171166",
        "name": "高雄漁港簡易型分行",
        "shortcut": "兆豐銀高港"
    },
    {
        "parentCode": "017",
        "code": "0172015",
        "name": "金控總部分行",
        "shortcut": "兆豐銀總部"
    },
    {
        "parentCode": "017",
        "code": "0172026",
        "name": "台北分行",
        "shortcut": "兆豐銀台北"
    },
    {
        "parentCode": "017",
        "code": "0172037",
        "name": "新竹分行",
        "shortcut": "兆豐銀新竹"
    },
    {
        "parentCode": "017",
        "code": "0172048",
        "name": "中台中分行",
        "shortcut": "兆豐銀中台"
    },
    {
        "parentCode": "017",
        "code": "0172059",
        "name": "高雄分行",
        "shortcut": "兆豐銀高雄"
    },
    {
        "parentCode": "017",
        "code": "0172060",
        "name": "板橋分行",
        "shortcut": "兆豐銀板橋"
    },
    {
        "parentCode": "017",
        "code": "0172071",
        "name": "桃興分行",
        "shortcut": "兆豐銀桃興"
    },
    {
        "parentCode": "017",
        "code": "0172082",
        "name": "竹北分行",
        "shortcut": "兆豐銀竹北"
    },
    {
        "parentCode": "017",
        "code": "0172107",
        "name": "敦化分行",
        "shortcut": "兆豐銀敦化"
    },
    {
        "parentCode": "017",
        "code": "0172129",
        "name": "嘉興分行",
        "shortcut": "兆豐銀嘉興"
    },
    {
        "parentCode": "017",
        "code": "0172130",
        "name": "台南分行",
        "shortcut": "兆豐銀台南"
    },
    {
        "parentCode": "017",
        "code": "0172141",
        "name": "寶成分行",
        "shortcut": "兆豐銀寶成"
    },
    {
        "parentCode": "017",
        "code": "0172152",
        "name": "竹科竹村分行",
        "shortcut": "兆豐銀竹村"
    },
    {
        "parentCode": "017",
        "code": "0172163",
        "name": "世貿分行",
        "shortcut": "兆豐銀世貿"
    },
    {
        "parentCode": "017",
        "code": "0172196",
        "name": "南三重分行",
        "shortcut": "兆豐銀南重"
    },
    {
        "parentCode": "017",
        "code": "0172200",
        "name": "南彰化分行",
        "shortcut": "兆豐銀南彰"
    },
    {
        "parentCode": "017",
        "code": "0172266",
        "name": "城東分行",
        "shortcut": "兆豐銀城東"
    },
    {
        "parentCode": "017",
        "code": "0172277",
        "name": "五福分行",
        "shortcut": "兆豐銀五福"
    },
    {
        "parentCode": "017",
        "code": "0172288",
        "name": "羅東分行",
        "shortcut": "兆豐銀羅東"
    },
    {
        "parentCode": "017",
        "code": "0172299",
        "name": "大安分行",
        "shortcut": "兆豐銀大安"
    },
    {
        "parentCode": "017",
        "code": "0172314",
        "name": "城北分行",
        "shortcut": "兆豐銀城北"
    },
    {
        "parentCode": "017",
        "code": "0172325",
        "name": "后里分行",
        "shortcut": "兆豐銀后里"
    },
    {
        "parentCode": "017",
        "code": "0172336",
        "name": "鹿港分行",
        "shortcut": "兆豐銀鹿港"
    },
    {
        "parentCode": "017",
        "code": "0172347",
        "name": "圓山分行",
        "shortcut": "兆豐銀圓山"
    },
    {
        "parentCode": "017",
        "code": "0172358",
        "name": "思源分行",
        "shortcut": "兆豐銀思源"
    },
    {
        "parentCode": "017",
        "code": "0172369",
        "name": "北中壢分行",
        "shortcut": "兆豐銀北壢"
    },
    {
        "parentCode": "017",
        "code": "0172370",
        "name": "內湖科學園區分行",
        "shortcut": "兆豐銀內科"
    },
    {
        "parentCode": "017",
        "code": "0172381",
        "name": "雙和分行",
        "shortcut": "兆豐銀雙和"
    },
    {
        "parentCode": "017",
        "code": "0172406",
        "name": "衡陽分行",
        "shortcut": "兆豐銀衡陽"
    },
    {
        "parentCode": "017",
        "code": "0172417",
        "name": "大里分行",
        "shortcut": "兆豐銀大里"
    },
    {
        "parentCode": "017",
        "code": "0172428",
        "name": "台南科學園區分行",
        "shortcut": "兆豐銀南科"
    },
    {
        "parentCode": "018",
        "code": "0180012",
        "name": "營業部",
        "shortcut": "農業金庫營"
    },
    {
        "parentCode": "018",
        "code": "0180034",
        "name": "臺中分行",
        "shortcut": "農金臺中"
    },
    {
        "parentCode": "018",
        "code": "0180045",
        "name": "高雄分行",
        "shortcut": "農金高雄"
    },
    {
        "parentCode": "018",
        "code": "0180067",
        "name": "新竹分行",
        "shortcut": "農金新竹"
    },
    {
        "parentCode": "018",
        "code": "0180078",
        "name": "嘉義分行",
        "shortcut": "農金嘉義"
    },
    {
        "parentCode": "020",
        "code": "0200017",
        "name": "台北分行",
        "shortcut": "瑞穗銀台北"
    },
    {
        "parentCode": "020",
        "code": "0200028",
        "name": "高雄分行",
        "shortcut": "瑞穗銀高雄"
    },
    {
        "parentCode": "020",
        "code": "0200040",
        "name": "台中分行",
        "shortcut": "瑞穗銀台中"
    },
    {
        "parentCode": "021",
        "code": "0210018",
        "name": "營業部",
        "shortcut": "花旗台營業"
    },
    {
        "parentCode": "021",
        "code": "0210030",
        "name": "高雄分行",
        "shortcut": "花旗台高雄"
    },
    {
        "parentCode": "021",
        "code": "0210041",
        "name": "台中分行",
        "shortcut": "花旗台台中"
    },
    {
        "parentCode": "021",
        "code": "0210052",
        "name": "新竹分行",
        "shortcut": "花旗台新竹"
    },
    {
        "parentCode": "021",
        "code": "0210063",
        "name": "台南分行",
        "shortcut": "花旗台台南"
    },
    {
        "parentCode": "021",
        "code": "0210074",
        "name": "桃園分行",
        "shortcut": "花旗台桃園"
    },
    {
        "parentCode": "021",
        "code": "0210085",
        "name": "松江分行",
        "shortcut": "花旗台松江"
    },
    {
        "parentCode": "021",
        "code": "0210096",
        "name": "板橋分行",
        "shortcut": "花旗台板橋"
    },
    {
        "parentCode": "021",
        "code": "0210100",
        "name": "文心分行",
        "shortcut": "花旗台文心"
    },
    {
        "parentCode": "021",
        "code": "0210111",
        "name": "信義分行",
        "shortcut": "花旗台信義"
    },
    {
        "parentCode": "021",
        "code": "0210122",
        "name": "天母分行",
        "shortcut": "花旗台天母"
    },
    {
        "parentCode": "021",
        "code": "0210133",
        "name": "建成分行",
        "shortcut": "花旗台建成"
    },
    {
        "parentCode": "021",
        "code": "0210144",
        "name": "中山分行",
        "shortcut": "花旗台中山"
    },
    {
        "parentCode": "021",
        "code": "0210166",
        "name": "永福分行",
        "shortcut": "花旗台永福"
    },
    {
        "parentCode": "021",
        "code": "0210177",
        "name": "港都分行",
        "shortcut": "花旗台港都"
    },
    {
        "parentCode": "021",
        "code": "0210188",
        "name": "中港分行",
        "shortcut": "花旗台中港"
    },
    {
        "parentCode": "021",
        "code": "0210199",
        "name": "襄陽分行",
        "shortcut": "花旗台襄陽"
    },
    {
        "parentCode": "021",
        "code": "0210203",
        "name": "復興分行",
        "shortcut": "花旗台復興"
    },
    {
        "parentCode": "021",
        "code": "0210214",
        "name": "新興分行",
        "shortcut": "花旗台新興"
    },
    {
        "parentCode": "021",
        "code": "0210225",
        "name": "基隆分行",
        "shortcut": "花旗台基隆"
    },
    {
        "parentCode": "021",
        "code": "0210236",
        "name": "北桃園分行",
        "shortcut": "花旗台北桃"
    },
    {
        "parentCode": "021",
        "code": "0210247",
        "name": "永和分行",
        "shortcut": "花旗台永和"
    },
    {
        "parentCode": "021",
        "code": "0210258",
        "name": "豐原分行",
        "shortcut": "花旗台豐原"
    },
    {
        "parentCode": "021",
        "code": "0210269",
        "name": "民權分行",
        "shortcut": "花旗台民權"
    },
    {
        "parentCode": "021",
        "code": "0210270",
        "name": "府城分行",
        "shortcut": "花旗台府城"
    },
    {
        "parentCode": "021",
        "code": "0210281",
        "name": "苓雅分行",
        "shortcut": "花旗台苓雅"
    },
    {
        "parentCode": "021",
        "code": "0210292",
        "name": "松山分行",
        "shortcut": "花旗台松山"
    },
    {
        "parentCode": "021",
        "code": "0210306",
        "name": "三重分行",
        "shortcut": "花旗台三重"
    },
    {
        "parentCode": "021",
        "code": "0210317",
        "name": "員林分行",
        "shortcut": "花旗台員林"
    },
    {
        "parentCode": "021",
        "code": "0210328",
        "name": "士林分行",
        "shortcut": "花旗台士林"
    },
    {
        "parentCode": "021",
        "code": "0210339",
        "name": "板新分行",
        "shortcut": "花旗台板新"
    },
    {
        "parentCode": "021",
        "code": "0210340",
        "name": "中壢分行",
        "shortcut": "花旗台中壢"
    },
    {
        "parentCode": "021",
        "code": "0210351",
        "name": "大安分行",
        "shortcut": "花旗台大安"
    },
    {
        "parentCode": "021",
        "code": "0210362",
        "name": "竹城分行",
        "shortcut": "花旗台竹城"
    },
    {
        "parentCode": "021",
        "code": "0210373",
        "name": "嘉義分行",
        "shortcut": "花旗台嘉義"
    },
    {
        "parentCode": "021",
        "code": "0210384",
        "name": "北台中分行",
        "shortcut": "花旗台北中"
    },
    {
        "parentCode": "021",
        "code": "0210395",
        "name": "北高雄分行",
        "shortcut": "花旗台北高"
    },
    {
        "parentCode": "021",
        "code": "0210409",
        "name": "新莊分行",
        "shortcut": "花旗台新莊"
    },
    {
        "parentCode": "021",
        "code": "0210410",
        "name": "民生分行",
        "shortcut": "花旗台民生"
    },
    {
        "parentCode": "021",
        "code": "0210421",
        "name": "彰化分行",
        "shortcut": "花旗台彰化"
    },
    {
        "parentCode": "021",
        "code": "0210432",
        "name": "鳳山分行",
        "shortcut": "花旗台鳳山"
    },
    {
        "parentCode": "021",
        "code": "0210443",
        "name": "新店分行",
        "shortcut": "花旗台新店"
    },
    {
        "parentCode": "021",
        "code": "0210454",
        "name": "仁德分行",
        "shortcut": "花旗台仁德"
    },
    {
        "parentCode": "021",
        "code": "0210465",
        "name": "高美館分行",
        "shortcut": "花旗台高美"
    },
    {
        "parentCode": "021",
        "code": "0210476",
        "name": "南京分行",
        "shortcut": "花旗台南京"
    },
    {
        "parentCode": "021",
        "code": "0210498",
        "name": "清水分行",
        "shortcut": "花旗台清水"
    },
    {
        "parentCode": "021",
        "code": "0210502",
        "name": "中正分行",
        "shortcut": "花旗台中正"
    },
    {
        "parentCode": "021",
        "code": "0210513",
        "name": "永康分行",
        "shortcut": "花旗台永康"
    },
    {
        "parentCode": "021",
        "code": "0210524",
        "name": "羅東分行",
        "shortcut": "花旗台羅東"
    },
    {
        "parentCode": "021",
        "code": "0210535",
        "name": "敦化分行",
        "shortcut": "花旗台敦化"
    },
    {
        "parentCode": "021",
        "code": "0210579",
        "name": "內湖分行",
        "shortcut": "花旗台內湖"
    },
    {
        "parentCode": "021",
        "code": "0210580",
        "name": "南港分行",
        "shortcut": "花旗台南港"
    },
    {
        "parentCode": "021",
        "code": "0210627",
        "name": "斗六分行",
        "shortcut": "花旗台斗六"
    },
    {
        "parentCode": "021",
        "code": "0210649",
        "name": "麥寮分行",
        "shortcut": "花旗台麥寮"
    },
    {
        "parentCode": "021",
        "code": "0210650",
        "name": "土城分行",
        "shortcut": "花旗台土城"
    },
    {
        "parentCode": "021",
        "code": "0210661",
        "name": "竹科分行",
        "shortcut": "花旗台竹科"
    },
    {
        "parentCode": "022",
        "code": "0220019",
        "name": "美國銀行台北分行",
        "shortcut": "美銀台北"
    },
    {
        "parentCode": "023",
        "code": "0230010",
        "name": "台北分行",
        "shortcut": "盤谷台北"
    },
    {
        "parentCode": "023",
        "code": "0230032",
        "name": "高雄分行",
        "shortcut": "盤谷高雄"
    },
    {
        "parentCode": "023",
        "code": "0230043",
        "name": "台中分行",
        "shortcut": "盤谷台中"
    },
    {
        "parentCode": "025",
        "code": "0250012",
        "name": "台北分行",
        "shortcut": "首都台北"
    },
    {
        "parentCode": "029",
        "code": "0290005",
        "name": "台北分行",
        "shortcut": "大華銀台北"
    },
    {
        "parentCode": "030",
        "code": "0300010",
        "name": "台北分行",
        "shortcut": "道富銀台北"
    },
    {
        "parentCode": "037",
        "code": "0370017",
        "name": "台北分行",
        "shortcut": "法興台北"
    },
    {
        "parentCode": "039",
        "code": "0390019",
        "name": "營業部",
        "shortcut": "澳盛營業部"
    },
    {
        "parentCode": "039",
        "code": "0390020",
        "name": "高雄分行",
        "shortcut": "澳盛高雄"
    },
    {
        "parentCode": "039",
        "code": "0390053",
        "name": "松山分行",
        "shortcut": "澳盛松山"
    },
    {
        "parentCode": "039",
        "code": "0390064",
        "name": "台中分行",
        "shortcut": "澳盛台中"
    },
    {
        "parentCode": "039",
        "code": "0390075",
        "name": "板橋分行",
        "shortcut": "澳盛板橋"
    },
    {
        "parentCode": "039",
        "code": "0390167",
        "name": "北屯分行",
        "shortcut": "澳盛北屯"
    },
    {
        "parentCode": "039",
        "code": "0390204",
        "name": "中港分行",
        "shortcut": "澳盛中港"
    },
    {
        "parentCode": "039",
        "code": "0390260",
        "name": "信義分行",
        "shortcut": "澳盛信義"
    },
    {
        "parentCode": "039",
        "code": "0390330",
        "name": "北高雄分行",
        "shortcut": "澳盛北高雄"
    },
    {
        "parentCode": "039",
        "code": "0390341",
        "name": "台南分行",
        "shortcut": "澳盛台南"
    },
    {
        "parentCode": "039",
        "code": "0390400",
        "name": "松江分行",
        "shortcut": "澳盛松江"
    },
    {
        "parentCode": "039",
        "code": "0390411",
        "name": "桃園分行",
        "shortcut": "澳盛桃園"
    },
    {
        "parentCode": "048",
        "code": "0480011",
        "name": "營業部",
        "shortcut": "台灣工銀營"
    },
    {
        "parentCode": "048",
        "code": "0480022",
        "name": "竹科分行",
        "shortcut": "台灣工銀竹"
    },
    {
        "parentCode": "048",
        "code": "0480033",
        "name": "高雄分行",
        "shortcut": "台灣工銀高"
    },
    {
        "parentCode": "048",
        "code": "0480055",
        "name": "台中分行",
        "shortcut": "臺灣工銀中"
    },
    {
        "parentCode": "050",
        "code": "0500016",
        "name": "吉林分行",
        "shortcut": "臺企吉林"
    },
    {
        "parentCode": "050",
        "code": "0500027",
        "name": "中和分行",
        "shortcut": "臺企中和"
    },
    {
        "parentCode": "050",
        "code": "0500038",
        "name": "博愛分行",
        "shortcut": "臺企博愛"
    },
    {
        "parentCode": "050",
        "code": "0500049",
        "name": "北桃園分行",
        "shortcut": "臺企北桃園"
    },
    {
        "parentCode": "050",
        "code": "0500050",
        "name": "南崁分行",
        "shortcut": "臺企南崁"
    },
    {
        "parentCode": "050",
        "code": "0500061",
        "name": "西屯分行",
        "shortcut": "臺企西屯"
    },
    {
        "parentCode": "050",
        "code": "0500072",
        "name": "忠明分行",
        "shortcut": "臺企忠明"
    },
    {
        "parentCode": "050",
        "code": "0500094",
        "name": "金門分行",
        "shortcut": "臺企金門"
    },
    {
        "parentCode": "050",
        "code": "0500108",
        "name": "營業部",
        "shortcut": "臺企營"
    },
    {
        "parentCode": "050",
        "code": "0500119",
        "name": "大雅分行",
        "shortcut": "臺企大雅"
    },
    {
        "parentCode": "050",
        "code": "0500120",
        "name": "仁大分行",
        "shortcut": "臺企仁大"
    },
    {
        "parentCode": "050",
        "code": "0500201",
        "name": "仁愛分行",
        "shortcut": "臺企仁愛"
    },
    {
        "parentCode": "050",
        "code": "0500212",
        "name": "松山分行",
        "shortcut": "臺企松山"
    },
    {
        "parentCode": "050",
        "code": "0500223",
        "name": "建成分行",
        "shortcut": "臺企建成"
    },
    {
        "parentCode": "050",
        "code": "0500234",
        "name": "士林分行",
        "shortcut": "臺企士林"
    },
    {
        "parentCode": "050",
        "code": "0500245",
        "name": "永和分行",
        "shortcut": "臺企永和"
    },
    {
        "parentCode": "050",
        "code": "0500256",
        "name": "新店分行",
        "shortcut": "臺企新店"
    },
    {
        "parentCode": "050",
        "code": "0500267",
        "name": "新莊分行",
        "shortcut": "臺企新莊"
    },
    {
        "parentCode": "050",
        "code": "0500278",
        "name": "化成分行",
        "shortcut": "臺企化成"
    },
    {
        "parentCode": "050",
        "code": "0500407",
        "name": "松江分行",
        "shortcut": "臺企松江"
    },
    {
        "parentCode": "050",
        "code": "0500500",
        "name": "台北分行",
        "shortcut": "臺企台北"
    },
    {
        "parentCode": "050",
        "code": "0500603",
        "name": "萬華分行",
        "shortcut": "臺企萬華"
    },
    {
        "parentCode": "050",
        "code": "0500614",
        "name": "南台北分行",
        "shortcut": "臺企南台北"
    },
    {
        "parentCode": "050",
        "code": "0500706",
        "name": "復興分行",
        "shortcut": "臺企復興"
    },
    {
        "parentCode": "050",
        "code": "0500809",
        "name": "中山分行",
        "shortcut": "臺企中山"
    },
    {
        "parentCode": "050",
        "code": "0500810",
        "name": "建國分行",
        "shortcut": "臺企建國"
    },
    {
        "parentCode": "050",
        "code": "0500821",
        "name": "內湖分行",
        "shortcut": "臺企內湖"
    },
    {
        "parentCode": "050",
        "code": "0500902",
        "name": "南京東路分行",
        "shortcut": "臺企南京東"
    },
    {
        "parentCode": "050",
        "code": "0501002",
        "name": "忠孝分行",
        "shortcut": "臺企忠孝"
    },
    {
        "parentCode": "050",
        "code": "0501013",
        "name": "東台北分行",
        "shortcut": "臺企東台北"
    },
    {
        "parentCode": "050",
        "code": "0501024",
        "name": "世貿分行",
        "shortcut": "臺企世貿"
    },
    {
        "parentCode": "050",
        "code": "0501035",
        "name": "永春分行",
        "shortcut": "臺企永春"
    },
    {
        "parentCode": "050",
        "code": "0501057",
        "name": "南港分行",
        "shortcut": "臺企南港"
    },
    {
        "parentCode": "050",
        "code": "0501105",
        "name": "松南分行",
        "shortcut": "臺企松南"
    },
    {
        "parentCode": "050",
        "code": "0501116",
        "name": "東湖分行",
        "shortcut": "臺企東湖"
    },
    {
        "parentCode": "050",
        "code": "0501208",
        "name": "大安分行",
        "shortcut": "臺企大安"
    },
    {
        "parentCode": "050",
        "code": "0501219",
        "name": "雙和分行",
        "shortcut": "臺企雙和"
    },
    {
        "parentCode": "050",
        "code": "0501220",
        "name": "錦和分行",
        "shortcut": "臺企錦和"
    },
    {
        "parentCode": "050",
        "code": "0501301",
        "name": "五股分行",
        "shortcut": "臺企五股"
    },
    {
        "parentCode": "050",
        "code": "0501312",
        "name": "林口分行",
        "shortcut": "臺企林口"
    },
    {
        "parentCode": "050",
        "code": "0501404",
        "name": "板橋分行",
        "shortcut": "臺企板橋"
    },
    {
        "parentCode": "050",
        "code": "0501415",
        "name": "樹林分行",
        "shortcut": "臺企樹林"
    },
    {
        "parentCode": "050",
        "code": "0501426",
        "name": "土城分行",
        "shortcut": "臺企土城"
    },
    {
        "parentCode": "050",
        "code": "0501437",
        "name": "迴龍分行",
        "shortcut": "臺企迴龍"
    },
    {
        "parentCode": "050",
        "code": "0501448",
        "name": "汐止分行",
        "shortcut": "臺企汐止"
    },
    {
        "parentCode": "050",
        "code": "0501507",
        "name": "基隆分行",
        "shortcut": "臺企基隆"
    },
    {
        "parentCode": "050",
        "code": "0501518",
        "name": "埔墘分行",
        "shortcut": "臺企埔墘"
    },
    {
        "parentCode": "050",
        "code": "0501529",
        "name": "北三重分行",
        "shortcut": "臺企北三重"
    },
    {
        "parentCode": "050",
        "code": "0501530",
        "name": "南三重分行",
        "shortcut": "臺企南三重"
    },
    {
        "parentCode": "050",
        "code": "0501541",
        "name": "蘆洲分行",
        "shortcut": "臺企蘆洲"
    },
    {
        "parentCode": "050",
        "code": "0501600",
        "name": "宜蘭分行",
        "shortcut": "臺企宜蘭"
    },
    {
        "parentCode": "050",
        "code": "0501703",
        "name": "羅東分行",
        "shortcut": "臺企羅東"
    },
    {
        "parentCode": "050",
        "code": "0501714",
        "name": "蘇澳分行",
        "shortcut": "臺企蘇澳"
    },
    {
        "parentCode": "050",
        "code": "0502906",
        "name": "楊梅分行",
        "shortcut": "臺企楊梅"
    },
    {
        "parentCode": "050",
        "code": "0502917",
        "name": "湖口分行",
        "shortcut": "臺企湖口"
    },
    {
        "parentCode": "050",
        "code": "0503006",
        "name": "桃園分行",
        "shortcut": "臺企桃園"
    },
    {
        "parentCode": "050",
        "code": "0503017",
        "name": "大園分行",
        "shortcut": "臺企大園"
    },
    {
        "parentCode": "050",
        "code": "0503028",
        "name": "大溪分行",
        "shortcut": "臺企大溪"
    },
    {
        "parentCode": "050",
        "code": "0503109",
        "name": "中壢分行",
        "shortcut": "臺企中壢"
    },
    {
        "parentCode": "050",
        "code": "0503110",
        "name": "內壢分行",
        "shortcut": "臺企內壢"
    },
    {
        "parentCode": "050",
        "code": "0503121",
        "name": "新明分行",
        "shortcut": "臺企新明"
    },
    {
        "parentCode": "050",
        "code": "0503132",
        "name": "東桃園分行",
        "shortcut": "臺企東桃園"
    },
    {
        "parentCode": "050",
        "code": "0503154",
        "name": "新屋分行",
        "shortcut": "臺企新屋"
    },
    {
        "parentCode": "050",
        "code": "0503202",
        "name": "新竹分行",
        "shortcut": "臺企新竹"
    },
    {
        "parentCode": "050",
        "code": "0503213",
        "name": "竹北分行",
        "shortcut": "臺企竹北"
    },
    {
        "parentCode": "050",
        "code": "0503224",
        "name": "竹科分行",
        "shortcut": "臺企竹科"
    },
    {
        "parentCode": "050",
        "code": "0503305",
        "name": "八德分行",
        "shortcut": "臺企八德"
    },
    {
        "parentCode": "050",
        "code": "0503327",
        "name": "龍潭分行",
        "shortcut": "臺企龍潭"
    },
    {
        "parentCode": "050",
        "code": "0503408",
        "name": "竹東分行",
        "shortcut": "臺企竹東"
    },
    {
        "parentCode": "050",
        "code": "0503501",
        "name": "竹南分行",
        "shortcut": "臺企竹南"
    },
    {
        "parentCode": "050",
        "code": "0503512",
        "name": "頭份分行",
        "shortcut": "臺企頭份"
    },
    {
        "parentCode": "050",
        "code": "0503604",
        "name": "苗栗分行",
        "shortcut": "臺企苗栗"
    },
    {
        "parentCode": "050",
        "code": "0504601",
        "name": "豐原分行",
        "shortcut": "臺企豐原"
    },
    {
        "parentCode": "050",
        "code": "0504690",
        "name": "后里分行",
        "shortcut": "臺企后里"
    },
    {
        "parentCode": "050",
        "code": "0504704",
        "name": "太平分行",
        "shortcut": "臺企太平"
    },
    {
        "parentCode": "050",
        "code": "0504807",
        "name": "大甲分行",
        "shortcut": "臺企大甲"
    },
    {
        "parentCode": "050",
        "code": "0504829",
        "name": "沙鹿分行",
        "shortcut": "臺企沙鹿"
    },
    {
        "parentCode": "050",
        "code": "0504830",
        "name": "烏日分行",
        "shortcut": "臺企烏日"
    },
    {
        "parentCode": "050",
        "code": "0504900",
        "name": "台中分行",
        "shortcut": "臺企台中"
    },
    {
        "parentCode": "050",
        "code": "0504911",
        "name": "民權分行",
        "shortcut": "臺企民權"
    },
    {
        "parentCode": "050",
        "code": "0505000",
        "name": "興中分行",
        "shortcut": "臺企興中"
    },
    {
        "parentCode": "050",
        "code": "0505011",
        "name": "北屯分行",
        "shortcut": "臺企北屯"
    },
    {
        "parentCode": "050",
        "code": "0505103",
        "name": "南投分行",
        "shortcut": "臺企南投"
    },
    {
        "parentCode": "050",
        "code": "0505114",
        "name": "草屯分行",
        "shortcut": "臺企草屯"
    },
    {
        "parentCode": "050",
        "code": "0505206",
        "name": "埔里分行",
        "shortcut": "臺企埔里"
    },
    {
        "parentCode": "050",
        "code": "0505217",
        "name": "潭子分行",
        "shortcut": "臺企潭子"
    },
    {
        "parentCode": "050",
        "code": "0505309",
        "name": "竹山分行",
        "shortcut": "臺企竹山"
    },
    {
        "parentCode": "050",
        "code": "0505402",
        "name": "彰化分行",
        "shortcut": "臺企彰化"
    },
    {
        "parentCode": "050",
        "code": "0505413",
        "name": "和美分行",
        "shortcut": "臺企和美"
    },
    {
        "parentCode": "050",
        "code": "0505505",
        "name": "員林分行",
        "shortcut": "臺企員林"
    },
    {
        "parentCode": "050",
        "code": "0505608",
        "name": "北斗分行",
        "shortcut": "臺企北斗"
    },
    {
        "parentCode": "050",
        "code": "0505619",
        "name": "二林分行",
        "shortcut": "臺企二林"
    },
    {
        "parentCode": "050",
        "code": "0506605",
        "name": "斗六分行",
        "shortcut": "臺企斗六"
    },
    {
        "parentCode": "050",
        "code": "0506708",
        "name": "北港分行",
        "shortcut": "臺企北港"
    },
    {
        "parentCode": "050",
        "code": "0506719",
        "name": "虎尾分行",
        "shortcut": "臺企虎尾"
    },
    {
        "parentCode": "050",
        "code": "0506801",
        "name": "嘉義分行",
        "shortcut": "臺企嘉義"
    },
    {
        "parentCode": "050",
        "code": "0506812",
        "name": "民雄分行",
        "shortcut": "臺企民雄"
    },
    {
        "parentCode": "050",
        "code": "0506904",
        "name": "新營分行",
        "shortcut": "臺企新營"
    },
    {
        "parentCode": "050",
        "code": "0506915",
        "name": "開元分行",
        "shortcut": "臺企開元"
    },
    {
        "parentCode": "050",
        "code": "0507004",
        "name": "永康分行",
        "shortcut": "臺企永康"
    },
    {
        "parentCode": "050",
        "code": "0507015",
        "name": "學甲分行",
        "shortcut": "臺企學甲"
    },
    {
        "parentCode": "050",
        "code": "0507026",
        "name": "善化分行",
        "shortcut": "臺企善化"
    },
    {
        "parentCode": "050",
        "code": "0507037",
        "name": "永大分行",
        "shortcut": "臺企永大"
    },
    {
        "parentCode": "050",
        "code": "0507107",
        "name": "台南分行",
        "shortcut": "臺企台南"
    },
    {
        "parentCode": "050",
        "code": "0507118",
        "name": "仁德分行",
        "shortcut": "臺企仁德"
    },
    {
        "parentCode": "050",
        "code": "0507200",
        "name": "成功分行",
        "shortcut": "臺企成功"
    },
    {
        "parentCode": "050",
        "code": "0507211",
        "name": "東台南分行",
        "shortcut": "臺企東台南"
    },
    {
        "parentCode": "050",
        "code": "0507303",
        "name": "安平分行",
        "shortcut": "臺企安平"
    },
    {
        "parentCode": "050",
        "code": "0507602",
        "name": "花蓮分行",
        "shortcut": "臺企花蓮"
    },
    {
        "parentCode": "050",
        "code": "0507705",
        "name": "台東分行",
        "shortcut": "臺企台東"
    },
    {
        "parentCode": "050",
        "code": "0508207",
        "name": "東高雄分行",
        "shortcut": "臺企東高雄"
    },
    {
        "parentCode": "050",
        "code": "0508300",
        "name": "岡山分行",
        "shortcut": "臺企岡山"
    },
    {
        "parentCode": "050",
        "code": "0508403",
        "name": "北鳳山分行",
        "shortcut": "臺企北鳳山"
    },
    {
        "parentCode": "050",
        "code": "0508414",
        "name": "苓雅分行",
        "shortcut": "臺企苓雅"
    },
    {
        "parentCode": "050",
        "code": "0508506",
        "name": "高雄分行",
        "shortcut": "臺企高雄"
    },
    {
        "parentCode": "050",
        "code": "0508517",
        "name": "北高雄分行",
        "shortcut": "臺企北高雄"
    },
    {
        "parentCode": "050",
        "code": "0508528",
        "name": "大昌分行",
        "shortcut": "臺企大昌"
    },
    {
        "parentCode": "050",
        "code": "0508539",
        "name": "前鎮分行",
        "shortcut": "臺企前鎮"
    },
    {
        "parentCode": "050",
        "code": "0508609",
        "name": "九如分行",
        "shortcut": "臺企九如"
    },
    {
        "parentCode": "050",
        "code": "0508702",
        "name": "三民分行",
        "shortcut": "臺企三民"
    },
    {
        "parentCode": "050",
        "code": "0508805",
        "name": "鳳山分行",
        "shortcut": "臺企鳳山"
    },
    {
        "parentCode": "050",
        "code": "0508816",
        "name": "大發分行",
        "shortcut": "臺企大發"
    },
    {
        "parentCode": "050",
        "code": "0508908",
        "name": "屏東分行",
        "shortcut": "臺企屏東"
    },
    {
        "parentCode": "050",
        "code": "0508919",
        "name": "小港分行",
        "shortcut": "臺企小港"
    },
    {
        "parentCode": "050",
        "code": "0508920",
        "name": "潮州分行",
        "shortcut": "臺企潮州"
    },
    {
        "parentCode": "052",
        "code": "0520030",
        "name": "桃園分行",
        "shortcut": "渣商銀桃園"
    },
    {
        "parentCode": "052",
        "code": "0520041",
        "name": "中壢分行",
        "shortcut": "渣商銀中壢"
    },
    {
        "parentCode": "052",
        "code": "0520052",
        "name": "竹東分行",
        "shortcut": "渣商銀竹東"
    },
    {
        "parentCode": "052",
        "code": "0520063",
        "name": "竹南分行",
        "shortcut": "渣商銀竹南"
    },
    {
        "parentCode": "052",
        "code": "0520074",
        "name": "苗栗分行",
        "shortcut": "渣商銀苗栗"
    },
    {
        "parentCode": "052",
        "code": "0520085",
        "name": "新埔分行",
        "shortcut": "渣商銀新埔"
    },
    {
        "parentCode": "052",
        "code": "0520096",
        "name": "頭份分行",
        "shortcut": "渣商銀頭份"
    },
    {
        "parentCode": "052",
        "code": "0520100",
        "name": "大溪分行",
        "shortcut": "渣商銀大溪"
    },
    {
        "parentCode": "052",
        "code": "0520111",
        "name": "楊梅分行",
        "shortcut": "渣商銀楊梅"
    },
    {
        "parentCode": "052",
        "code": "0520122",
        "name": "新屋分行",
        "shortcut": "渣商銀新屋"
    },
    {
        "parentCode": "052",
        "code": "0520133",
        "name": "湖口分行",
        "shortcut": "渣商銀湖口"
    },
    {
        "parentCode": "052",
        "code": "0520144",
        "name": "苑裡分行",
        "shortcut": "渣商銀苑裡"
    },
    {
        "parentCode": "052",
        "code": "0520155",
        "name": "大園分行",
        "shortcut": "渣商銀大園"
    },
    {
        "parentCode": "052",
        "code": "0520166",
        "name": "龍潭分行",
        "shortcut": "渣商銀龍潭"
    },
    {
        "parentCode": "052",
        "code": "0520177",
        "name": "竹北分行",
        "shortcut": "渣商銀竹北"
    },
    {
        "parentCode": "052",
        "code": "0520199",
        "name": "三民分行",
        "shortcut": "渣商銀三民"
    },
    {
        "parentCode": "052",
        "code": "0520203",
        "name": "內壢分行",
        "shortcut": "渣商銀內壢"
    },
    {
        "parentCode": "052",
        "code": "0520214",
        "name": "八德分行",
        "shortcut": "渣商銀八德"
    },
    {
        "parentCode": "052",
        "code": "0520225",
        "name": "新明分行",
        "shortcut": "渣商銀新明"
    },
    {
        "parentCode": "052",
        "code": "0520247",
        "name": "公館分行",
        "shortcut": "渣商銀公館"
    },
    {
        "parentCode": "052",
        "code": "0520269",
        "name": "後龍分行",
        "shortcut": "渣商銀後龍"
    },
    {
        "parentCode": "052",
        "code": "0520270",
        "name": "龜山分行",
        "shortcut": "渣商銀龜山"
    },
    {
        "parentCode": "052",
        "code": "0520281",
        "name": "南崁分行",
        "shortcut": "渣商銀南崁"
    },
    {
        "parentCode": "052",
        "code": "0520306",
        "name": "關西分行",
        "shortcut": "渣商銀關西"
    },
    {
        "parentCode": "052",
        "code": "0520317",
        "name": "大樹林分行",
        "shortcut": "渣商銀大樹"
    },
    {
        "parentCode": "052",
        "code": "0520328",
        "name": "光復分行",
        "shortcut": "渣商銀光復"
    },
    {
        "parentCode": "052",
        "code": "0520351",
        "name": "龍岡分行",
        "shortcut": "渣商銀龍岡"
    },
    {
        "parentCode": "052",
        "code": "0520362",
        "name": "山子頂分行",
        "shortcut": "渣商銀山頂"
    },
    {
        "parentCode": "052",
        "code": "0520373",
        "name": "埔心分行",
        "shortcut": "渣商銀埔心"
    },
    {
        "parentCode": "052",
        "code": "0520384",
        "name": "新豐分行",
        "shortcut": "渣商銀新豐"
    },
    {
        "parentCode": "052",
        "code": "0520410",
        "name": "三義分行",
        "shortcut": "渣商銀三義"
    },
    {
        "parentCode": "052",
        "code": "0520454",
        "name": "中正分行",
        "shortcut": "渣商銀中正"
    },
    {
        "parentCode": "052",
        "code": "0520476",
        "name": "環北分行",
        "shortcut": "渣商銀環北"
    },
    {
        "parentCode": "052",
        "code": "0520513",
        "name": "新興分行",
        "shortcut": "渣商銀新興"
    },
    {
        "parentCode": "052",
        "code": "0520524",
        "name": "新社分行",
        "shortcut": "渣商銀新社"
    },
    {
        "parentCode": "052",
        "code": "0520546",
        "name": "科學園區分行",
        "shortcut": "渣商銀園區"
    },
    {
        "parentCode": "052",
        "code": "0520557",
        "name": "觀音分行",
        "shortcut": "渣商銀觀音"
    },
    {
        "parentCode": "052",
        "code": "0520579",
        "name": "北新竹分行",
        "shortcut": "渣商銀北新"
    },
    {
        "parentCode": "052",
        "code": "0520591",
        "name": "平鎮分行",
        "shortcut": "渣商銀平鎮"
    },
    {
        "parentCode": "052",
        "code": "0520605",
        "name": "國外部",
        "shortcut": "渣商銀國外"
    },
    {
        "parentCode": "052",
        "code": "0520649",
        "name": "東內壢分行",
        "shortcut": "渣商銀東壢"
    },
    {
        "parentCode": "052",
        "code": "0520672",
        "name": "建國分行",
        "shortcut": "渣商銀建國"
    },
    {
        "parentCode": "052",
        "code": "0520683",
        "name": "公西分行",
        "shortcut": "渣商銀公西"
    },
    {
        "parentCode": "052",
        "code": "0520708",
        "name": "莊敬分行",
        "shortcut": "渣商銀莊敬"
    },
    {
        "parentCode": "052",
        "code": "0520719",
        "name": "板橋分行",
        "shortcut": "渣商銀板橋"
    },
    {
        "parentCode": "052",
        "code": "0520731",
        "name": "豐原分行",
        "shortcut": "渣商銀豐原"
    },
    {
        "parentCode": "052",
        "code": "0520742",
        "name": "文心分行",
        "shortcut": "渣商銀文心"
    },
    {
        "parentCode": "052",
        "code": "0520753",
        "name": "內湖分行",
        "shortcut": "渣商銀內湖"
    },
    {
        "parentCode": "052",
        "code": "0520764",
        "name": "九如分行",
        "shortcut": "渣商銀九如"
    },
    {
        "parentCode": "052",
        "code": "0520775",
        "name": "台南分行",
        "shortcut": "渣商銀台南"
    },
    {
        "parentCode": "052",
        "code": "0520786",
        "name": "嘉義分行",
        "shortcut": "渣商銀嘉義"
    },
    {
        "parentCode": "052",
        "code": "0520797",
        "name": "南屯分行",
        "shortcut": "渣商銀南屯"
    },
    {
        "parentCode": "052",
        "code": "0520812",
        "name": "東寧分行",
        "shortcut": "渣商銀東寧"
    },
    {
        "parentCode": "052",
        "code": "0520823",
        "name": "東海分行",
        "shortcut": "渣商銀東海"
    },
    {
        "parentCode": "052",
        "code": "0520878",
        "name": "北屯分行",
        "shortcut": "渣商銀北屯"
    },
    {
        "parentCode": "052",
        "code": "0520889",
        "name": "西屯分行",
        "shortcut": "渣商銀西屯"
    },
    {
        "parentCode": "052",
        "code": "0520926",
        "name": "彰化分行",
        "shortcut": "渣商銀彰化"
    },
    {
        "parentCode": "052",
        "code": "0520959",
        "name": "營業部",
        "shortcut": "渣商銀營業"
    },
    {
        "parentCode": "052",
        "code": "0520960",
        "name": "北高雄分行",
        "shortcut": "渣商銀北高"
    },
    {
        "parentCode": "052",
        "code": "0520971",
        "name": "台中分行",
        "shortcut": "渣商銀台中"
    },
    {
        "parentCode": "052",
        "code": "0520982",
        "name": "高雄分行",
        "shortcut": "渣商銀高雄"
    },
    {
        "parentCode": "052",
        "code": "0521015",
        "name": "復興分行",
        "shortcut": "渣商銀復興"
    },
    {
        "parentCode": "052",
        "code": "0521037",
        "name": "中清分行",
        "shortcut": "渣商銀中清"
    },
    {
        "parentCode": "052",
        "code": "0521060",
        "name": "三多分行",
        "shortcut": "渣商銀三多"
    },
    {
        "parentCode": "052",
        "code": "0521071",
        "name": "東台南分行",
        "shortcut": "渣商銀東台"
    },
    {
        "parentCode": "052",
        "code": "0521093",
        "name": "南京分行",
        "shortcut": "渣商銀南京"
    },
    {
        "parentCode": "052",
        "code": "0521118",
        "name": "信義分行",
        "shortcut": "渣商銀信義"
    },
    {
        "parentCode": "052",
        "code": "0521129",
        "name": "東台北分行",
        "shortcut": "渣商銀台北"
    },
    {
        "parentCode": "052",
        "code": "0521141",
        "name": "敦化分行",
        "shortcut": "渣商銀敦化"
    },
    {
        "parentCode": "052",
        "code": "0521152",
        "name": "天母分行",
        "shortcut": "渣商銀天母"
    },
    {
        "parentCode": "052",
        "code": "0521163",
        "name": "仁愛分行",
        "shortcut": "渣商銀仁愛"
    },
    {
        "parentCode": "052",
        "code": "0521185",
        "name": "中山分行",
        "shortcut": "渣商銀中山"
    },
    {
        "parentCode": "052",
        "code": "0521196",
        "name": "八八分行",
        "shortcut": "渣商銀八八"
    },
    {
        "parentCode": "052",
        "code": "0521200",
        "name": "大直分行",
        "shortcut": "渣商銀大直"
    },
    {
        "parentCode": "052",
        "code": "0521211",
        "name": "金山分行",
        "shortcut": "渣商銀金山"
    },
    {
        "parentCode": "053",
        "code": "0530019",
        "name": "中正分行",
        "shortcut": "台中銀中正"
    },
    {
        "parentCode": "053",
        "code": "0530020",
        "name": "營業部",
        "shortcut": "台中銀營業"
    },
    {
        "parentCode": "053",
        "code": "0530042",
        "name": "南台中分行",
        "shortcut": "台中銀南台"
    },
    {
        "parentCode": "053",
        "code": "0530053",
        "name": "霧峰分行",
        "shortcut": "台中銀霧峰"
    },
    {
        "parentCode": "053",
        "code": "0530064",
        "name": "大甲分行",
        "shortcut": "台中銀大甲"
    },
    {
        "parentCode": "053",
        "code": "0530075",
        "name": "沙鹿分行",
        "shortcut": "台中銀沙鹿"
    },
    {
        "parentCode": "053",
        "code": "0530086",
        "name": "豐原分行",
        "shortcut": "台中銀豐原"
    },
    {
        "parentCode": "053",
        "code": "0530097",
        "name": "東勢分行",
        "shortcut": "台中銀東勢"
    },
    {
        "parentCode": "053",
        "code": "0530101",
        "name": "彰化分行",
        "shortcut": "台中銀彰化"
    },
    {
        "parentCode": "053",
        "code": "0530112",
        "name": "溪湖分行",
        "shortcut": "台中銀溪湖"
    },
    {
        "parentCode": "053",
        "code": "0530123",
        "name": "員林分行",
        "shortcut": "台中銀員林"
    },
    {
        "parentCode": "053",
        "code": "0530134",
        "name": "田中分行",
        "shortcut": "台中銀田中"
    },
    {
        "parentCode": "053",
        "code": "0530145",
        "name": "北斗分行",
        "shortcut": "台中銀北斗"
    },
    {
        "parentCode": "053",
        "code": "0530156",
        "name": "二林分行",
        "shortcut": "台中銀二林"
    },
    {
        "parentCode": "053",
        "code": "0530167",
        "name": "南投分行",
        "shortcut": "台中銀南投"
    },
    {
        "parentCode": "053",
        "code": "0530189",
        "name": "草屯分行",
        "shortcut": "台中銀草屯"
    },
    {
        "parentCode": "053",
        "code": "0530190",
        "name": "竹山分行",
        "shortcut": "台中銀竹山"
    },
    {
        "parentCode": "053",
        "code": "0530204",
        "name": "埔里分行",
        "shortcut": "台中銀埔里"
    },
    {
        "parentCode": "053",
        "code": "0530215",
        "name": "鹿港分行",
        "shortcut": "台中銀鹿港"
    },
    {
        "parentCode": "053",
        "code": "0530226",
        "name": "西台中分行",
        "shortcut": "台中銀西台"
    },
    {
        "parentCode": "053",
        "code": "0530237",
        "name": "清水分行",
        "shortcut": "台中銀清水"
    },
    {
        "parentCode": "053",
        "code": "0530248",
        "name": "東豐原分行",
        "shortcut": "台中銀東豐"
    },
    {
        "parentCode": "053",
        "code": "0530259",
        "name": "烏日分行",
        "shortcut": "台中銀烏日"
    },
    {
        "parentCode": "053",
        "code": "0530271",
        "name": "北台中分行",
        "shortcut": "台中銀北台"
    },
    {
        "parentCode": "053",
        "code": "0530307",
        "name": "南豐原分行",
        "shortcut": "台中銀南豐"
    },
    {
        "parentCode": "053",
        "code": "0530318",
        "name": "和美分行",
        "shortcut": "台中銀和美"
    },
    {
        "parentCode": "053",
        "code": "0530330",
        "name": "西屯分行",
        "shortcut": "台中銀西屯"
    },
    {
        "parentCode": "053",
        "code": "0530341",
        "name": "太平分行",
        "shortcut": "台中銀太平"
    },
    {
        "parentCode": "053",
        "code": "0530363",
        "name": "大雅分行",
        "shortcut": "台中銀大雅"
    },
    {
        "parentCode": "053",
        "code": "0530374",
        "name": "后里分行",
        "shortcut": "台中銀后里"
    },
    {
        "parentCode": "053",
        "code": "0530385",
        "name": "潭子分行",
        "shortcut": "台中銀潭子"
    },
    {
        "parentCode": "053",
        "code": "0530396",
        "name": "社頭分行",
        "shortcut": "台中銀社頭"
    },
    {
        "parentCode": "053",
        "code": "0530400",
        "name": "永靖分行",
        "shortcut": "台中銀永靖"
    },
    {
        "parentCode": "053",
        "code": "0530411",
        "name": "花壇分行",
        "shortcut": "台中銀花壇"
    },
    {
        "parentCode": "053",
        "code": "0530422",
        "name": "秀水分行",
        "shortcut": "台中銀秀水"
    },
    {
        "parentCode": "053",
        "code": "0530444",
        "name": "南屯分行",
        "shortcut": "台中銀南屯"
    },
    {
        "parentCode": "053",
        "code": "0530455",
        "name": "大肚分行",
        "shortcut": "台中銀大肚"
    },
    {
        "parentCode": "053",
        "code": "0530466",
        "name": "神岡分行",
        "shortcut": "台中銀神岡"
    },
    {
        "parentCode": "053",
        "code": "0530477",
        "name": "內新分行",
        "shortcut": "台中銀內新"
    },
    {
        "parentCode": "053",
        "code": "0530488",
        "name": "伸港分行",
        "shortcut": "台中銀伸港"
    },
    {
        "parentCode": "053",
        "code": "0530499",
        "name": "北太平分行",
        "shortcut": "台中銀北太"
    },
    {
        "parentCode": "053",
        "code": "0530503",
        "name": "台中港分行",
        "shortcut": "台中銀台中"
    },
    {
        "parentCode": "053",
        "code": "0530514",
        "name": "北員林分行",
        "shortcut": "台中銀北員"
    },
    {
        "parentCode": "053",
        "code": "0530525",
        "name": "南陽分行",
        "shortcut": "台中銀南陽"
    },
    {
        "parentCode": "053",
        "code": "0530536",
        "name": "大竹分行",
        "shortcut": "台中銀大竹"
    },
    {
        "parentCode": "053",
        "code": "0530570",
        "name": "埤頭分行",
        "shortcut": "台中銀埤頭"
    },
    {
        "parentCode": "053",
        "code": "0530581",
        "name": "四民分行",
        "shortcut": "台中銀四民"
    },
    {
        "parentCode": "053",
        "code": "0530592",
        "name": "軍功分行",
        "shortcut": "台中銀軍功"
    },
    {
        "parentCode": "053",
        "code": "0530639",
        "name": "北屯分行",
        "shortcut": "台中銀北屯"
    },
    {
        "parentCode": "053",
        "code": "0530662",
        "name": "埔心分行",
        "shortcut": "台中銀埔心"
    },
    {
        "parentCode": "053",
        "code": "0530684",
        "name": "台北分行",
        "shortcut": "台中銀台北"
    },
    {
        "parentCode": "053",
        "code": "0530710",
        "name": "松山分行",
        "shortcut": "台中銀松山"
    },
    {
        "parentCode": "053",
        "code": "0530721",
        "name": "三重分行",
        "shortcut": "台中銀三重"
    },
    {
        "parentCode": "053",
        "code": "0530732",
        "name": "高雄分行",
        "shortcut": "台中銀高雄"
    },
    {
        "parentCode": "053",
        "code": "0530754",
        "name": "龍井分行",
        "shortcut": "台中銀龍井"
    },
    {
        "parentCode": "053",
        "code": "0530776",
        "name": "林口分行",
        "shortcut": "台中銀林口"
    },
    {
        "parentCode": "053",
        "code": "0530787",
        "name": "虎尾分行",
        "shortcut": "台中銀虎尾"
    },
    {
        "parentCode": "053",
        "code": "0530798",
        "name": "苑裡分行",
        "shortcut": "台中銀苑裡"
    },
    {
        "parentCode": "053",
        "code": "0530813",
        "name": "斗南分行",
        "shortcut": "台中銀斗南"
    },
    {
        "parentCode": "053",
        "code": "0530824",
        "name": "竹南分行",
        "shortcut": "台中銀竹南"
    },
    {
        "parentCode": "053",
        "code": "0530846",
        "name": "內湖分行",
        "shortcut": "台中銀內湖"
    },
    {
        "parentCode": "053",
        "code": "0530857",
        "name": "鳳山分行",
        "shortcut": "台中銀鳳山"
    },
    {
        "parentCode": "053",
        "code": "0530868",
        "name": "板橋分行",
        "shortcut": "台中銀板橋"
    },
    {
        "parentCode": "053",
        "code": "0530880",
        "name": "新莊分行",
        "shortcut": "台中銀新莊"
    },
    {
        "parentCode": "053",
        "code": "0530891",
        "name": "民雄分行",
        "shortcut": "台中銀民雄"
    },
    {
        "parentCode": "053",
        "code": "0530905",
        "name": "桃園分行",
        "shortcut": "台中銀桃園"
    },
    {
        "parentCode": "053",
        "code": "0530916",
        "name": "永康分行",
        "shortcut": "台中銀永康"
    },
    {
        "parentCode": "053",
        "code": "0530927",
        "name": "竹北分行",
        "shortcut": "台中銀竹北"
    },
    {
        "parentCode": "053",
        "code": "0530938",
        "name": "南崁分行",
        "shortcut": "台中銀南崁"
    },
    {
        "parentCode": "053",
        "code": "0530949",
        "name": "新竹分行",
        "shortcut": "台中銀新竹"
    },
    {
        "parentCode": "053",
        "code": "0530950",
        "name": "內壢分行",
        "shortcut": "台中銀內壢"
    },
    {
        "parentCode": "053",
        "code": "0530961",
        "name": "新豐分行",
        "shortcut": "台中銀新豐"
    },
    {
        "parentCode": "053",
        "code": "0530972",
        "name": "大園分行",
        "shortcut": "台中銀大園"
    },
    {
        "parentCode": "053",
        "code": "0530983",
        "name": "中壢分行",
        "shortcut": "台中銀中壢"
    },
    {
        "parentCode": "053",
        "code": "0530994",
        "name": "龜山分行",
        "shortcut": "台中銀龜山"
    },
    {
        "parentCode": "053",
        "code": "0531005",
        "name": "楊梅分行",
        "shortcut": "台中銀楊梅"
    },
    {
        "parentCode": "053",
        "code": "0531016",
        "name": "土城分行",
        "shortcut": "台中銀土城"
    },
    {
        "parentCode": "053",
        "code": "0531038",
        "name": "復興分行",
        "shortcut": "台中銀復興"
    },
    {
        "parentCode": "053",
        "code": "0531050",
        "name": "中山分行",
        "shortcut": "台中銀中山"
    },
    {
        "parentCode": "053",
        "code": "0531061",
        "name": "大同分行",
        "shortcut": "台中銀大同"
    },
    {
        "parentCode": "054",
        "code": "0540010",
        "name": "營業部",
        "shortcut": "京城營業部"
    },
    {
        "parentCode": "054",
        "code": "0540032",
        "name": "台南分行",
        "shortcut": "京城台南"
    },
    {
        "parentCode": "054",
        "code": "0540043",
        "name": "嘉義分行",
        "shortcut": "京城嘉義"
    },
    {
        "parentCode": "054",
        "code": "0540054",
        "name": "新營分行",
        "shortcut": "京城新營"
    },
    {
        "parentCode": "054",
        "code": "0540065",
        "name": "鹽水分行",
        "shortcut": "京城鹽水"
    },
    {
        "parentCode": "054",
        "code": "0540076",
        "name": "虎尾分行",
        "shortcut": "京城虎尾"
    },
    {
        "parentCode": "054",
        "code": "0540087",
        "name": "斗六分行",
        "shortcut": "京城斗六"
    },
    {
        "parentCode": "054",
        "code": "0540098",
        "name": "民雄分行",
        "shortcut": "京城民雄"
    },
    {
        "parentCode": "054",
        "code": "0540102",
        "name": "北港分行",
        "shortcut": "京城北港"
    },
    {
        "parentCode": "054",
        "code": "0540113",
        "name": "麻豆分行",
        "shortcut": "京城麻豆"
    },
    {
        "parentCode": "054",
        "code": "0540124",
        "name": "朴子分行",
        "shortcut": "京城朴子"
    },
    {
        "parentCode": "054",
        "code": "0540135",
        "name": "佳里分行",
        "shortcut": "京城佳里"
    },
    {
        "parentCode": "054",
        "code": "0540146",
        "name": "梅山分行",
        "shortcut": "京城梅山"
    },
    {
        "parentCode": "054",
        "code": "0540157",
        "name": "斗南分行",
        "shortcut": "京城斗南"
    },
    {
        "parentCode": "054",
        "code": "0540180",
        "name": "東台南分行",
        "shortcut": "京城東台南"
    },
    {
        "parentCode": "054",
        "code": "0540205",
        "name": "北台南分行",
        "shortcut": "京城北台南"
    },
    {
        "parentCode": "054",
        "code": "0540227",
        "name": "西螺分行",
        "shortcut": "京城西螺"
    },
    {
        "parentCode": "054",
        "code": "0540249",
        "name": "善化分行",
        "shortcut": "京城善化"
    },
    {
        "parentCode": "054",
        "code": "0540250",
        "name": "學甲分行",
        "shortcut": "京城學甲"
    },
    {
        "parentCode": "054",
        "code": "0540272",
        "name": "開元分行",
        "shortcut": "京城開元"
    },
    {
        "parentCode": "054",
        "code": "0540283",
        "name": "永康分行",
        "shortcut": "京城永康"
    },
    {
        "parentCode": "054",
        "code": "0540294",
        "name": "新市分行",
        "shortcut": "京城新市"
    },
    {
        "parentCode": "054",
        "code": "0540308",
        "name": "仁德分行",
        "shortcut": "京城仁德"
    },
    {
        "parentCode": "054",
        "code": "0540319",
        "name": "六甲分行",
        "shortcut": "京城六甲"
    },
    {
        "parentCode": "054",
        "code": "0540320",
        "name": "安南分行",
        "shortcut": "京城安南"
    },
    {
        "parentCode": "054",
        "code": "0540331",
        "name": "中埔分行",
        "shortcut": "京城中埔"
    },
    {
        "parentCode": "054",
        "code": "0540342",
        "name": "崙背分行",
        "shortcut": "京城崙背"
    },
    {
        "parentCode": "054",
        "code": "0540353",
        "name": "西港分行",
        "shortcut": "京城西港"
    },
    {
        "parentCode": "054",
        "code": "0540364",
        "name": "新化分行",
        "shortcut": "京城新化"
    },
    {
        "parentCode": "054",
        "code": "0540375",
        "name": "竹崎分行",
        "shortcut": "京城竹崎"
    },
    {
        "parentCode": "054",
        "code": "0540397",
        "name": "安和分行",
        "shortcut": "京城安和"
    },
    {
        "parentCode": "054",
        "code": "0540401",
        "name": "新興分行",
        "shortcut": "京城新興"
    },
    {
        "parentCode": "054",
        "code": "0540412",
        "name": "中華分行",
        "shortcut": "京城中華"
    },
    {
        "parentCode": "054",
        "code": "0540434",
        "name": "白河分行",
        "shortcut": "京城白河"
    },
    {
        "parentCode": "054",
        "code": "0540445",
        "name": "興業分行",
        "shortcut": "京城興業"
    },
    {
        "parentCode": "054",
        "code": "0540467",
        "name": "水上分行",
        "shortcut": "京城水上"
    },
    {
        "parentCode": "054",
        "code": "0540489",
        "name": "府城分行",
        "shortcut": "京城府城"
    },
    {
        "parentCode": "054",
        "code": "0540504",
        "name": "歸仁分行",
        "shortcut": "京城歸仁"
    },
    {
        "parentCode": "054",
        "code": "0540515",
        "name": "鹽行分行",
        "shortcut": "京城鹽行"
    },
    {
        "parentCode": "054",
        "code": "0540537",
        "name": "台北分行",
        "shortcut": "京城台北"
    },
    {
        "parentCode": "054",
        "code": "0540548",
        "name": "大林分行",
        "shortcut": "京城大林"
    },
    {
        "parentCode": "054",
        "code": "0540607",
        "name": "關廟分行",
        "shortcut": "京城關廟"
    },
    {
        "parentCode": "054",
        "code": "0540641",
        "name": "太保分行",
        "shortcut": "京城太保"
    },
    {
        "parentCode": "054",
        "code": "0540652",
        "name": "蘆洲分行",
        "shortcut": "京城蘆洲"
    },
    {
        "parentCode": "054",
        "code": "0540663",
        "name": "玉井分行",
        "shortcut": "京城玉井"
    },
    {
        "parentCode": "054",
        "code": "0540696",
        "name": "高雄分行",
        "shortcut": "京城高雄"
    },
    {
        "parentCode": "054",
        "code": "0540700",
        "name": "台中分行",
        "shortcut": "京城台中"
    },
    {
        "parentCode": "054",
        "code": "0540711",
        "name": "內湖分行",
        "shortcut": "京城內湖"
    },
    {
        "parentCode": "054",
        "code": "0540744",
        "name": "桃園分行",
        "shortcut": "京城桃園"
    },
    {
        "parentCode": "054",
        "code": "0540755",
        "name": "雙和分行",
        "shortcut": "京城雙和"
    },
    {
        "parentCode": "054",
        "code": "0540766",
        "name": "岡山分行",
        "shortcut": "京城岡山"
    },
    {
        "parentCode": "054",
        "code": "0540777",
        "name": "中正分行",
        "shortcut": "京城中正"
    },
    {
        "parentCode": "054",
        "code": "0540788",
        "name": "忠孝分行",
        "shortcut": "京城忠孝"
    },
    {
        "parentCode": "054",
        "code": "0540799",
        "name": "北高雄分行",
        "shortcut": "京城北高雄"
    },
    {
        "parentCode": "054",
        "code": "0540814",
        "name": "板橋分行",
        "shortcut": "京城板橋"
    },
    {
        "parentCode": "054",
        "code": "0540825",
        "name": "文心分行",
        "shortcut": "京城文心"
    },
    {
        "parentCode": "054",
        "code": "0540836",
        "name": "松山分行",
        "shortcut": "京城松山"
    },
    {
        "parentCode": "054",
        "code": "0540847",
        "name": "新莊分行",
        "shortcut": "京城新莊"
    },
    {
        "parentCode": "054",
        "code": "0540858",
        "name": "新竹分行",
        "shortcut": "京城新竹"
    },
    {
        "parentCode": "054",
        "code": "0540869",
        "name": "中壢分行",
        "shortcut": "京城中壢"
    },
    {
        "parentCode": "054",
        "code": "0540870",
        "name": "新店分行",
        "shortcut": "京城新店"
    },
    {
        "parentCode": "054",
        "code": "0540881",
        "name": "南崁分行",
        "shortcut": "京城南崁"
    },
    {
        "parentCode": "054",
        "code": "0540892",
        "name": "大里分行",
        "shortcut": "京城大里"
    },
    {
        "parentCode": "054",
        "code": "0540906",
        "name": "楠梓分行",
        "shortcut": "京城楠梓"
    },
    {
        "parentCode": "054",
        "code": "0540917",
        "name": "彰化分行",
        "shortcut": "京城彰化"
    },
    {
        "parentCode": "054",
        "code": "0540928",
        "name": "東新竹分行",
        "shortcut": "京城東新竹"
    },
    {
        "parentCode": "060",
        "code": "0600019",
        "name": "總公司",
        "shortcut": "兆票總公司"
    },
    {
        "parentCode": "061",
        "code": "0610010",
        "name": "台北總公司",
        "shortcut": "華票台北"
    },
    {
        "parentCode": "061",
        "code": "0610021",
        "name": "高雄分公司",
        "shortcut": "華票高雄"
    },
    {
        "parentCode": "061",
        "code": "0610032",
        "name": "台中分公司",
        "shortcut": "華票台中"
    },
    {
        "parentCode": "061",
        "code": "0610043",
        "name": "台南分公司",
        "shortcut": "華票台南"
    },
    {
        "parentCode": "061",
        "code": "0610054",
        "name": "桃園分公司",
        "shortcut": "華票桃園"
    },
    {
        "parentCode": "061",
        "code": "0610065",
        "name": "板橋分公司",
        "shortcut": "華票板橋"
    },
    {
        "parentCode": "062",
        "code": "0620011",
        "name": "台北總公司",
        "shortcut": "國票台北"
    },
    {
        "parentCode": "062",
        "code": "0620022",
        "name": "台中分公司",
        "shortcut": "國票台中"
    },
    {
        "parentCode": "062",
        "code": "0620033",
        "name": "台南分公司",
        "shortcut": "國票台南"
    },
    {
        "parentCode": "062",
        "code": "0620044",
        "name": "高雄分公司",
        "shortcut": "國票高雄"
    },
    {
        "parentCode": "062",
        "code": "0620055",
        "name": "嘉義分公司",
        "shortcut": "國票嘉義"
    },
    {
        "parentCode": "062",
        "code": "0620066",
        "name": "桃園分公司",
        "shortcut": "國票桃園"
    },
    {
        "parentCode": "062",
        "code": "0620077",
        "name": "板橋分公司",
        "shortcut": "國票板橋"
    },
    {
        "parentCode": "062",
        "code": "0620088",
        "name": "中山分公司",
        "shortcut": "國票中山"
    },
    {
        "parentCode": "062",
        "code": "0620099",
        "name": "新竹分公司",
        "shortcut": "國票新竹"
    },
    {
        "parentCode": "066",
        "code": "0660015",
        "name": "總公司",
        "shortcut": "萬票總公司"
    },
    {
        "parentCode": "072",
        "code": "0720014",
        "name": "台北分行",
        "shortcut": "德銀台北"
    },
    {
        "parentCode": "075",
        "code": "0750006",
        "name": "台北分行",
        "shortcut": "東亞銀台北"
    },
    {
        "parentCode": "075",
        "code": "0750028",
        "name": "高雄分行",
        "shortcut": "東亞銀高雄"
    },
    {
        "parentCode": "076",
        "code": "0760018",
        "name": "台北分行",
        "shortcut": "摩根大通銀"
    },
    {
        "parentCode": "081",
        "code": "0810016",
        "name": "台北分行",
        "shortcut": "匯豐台台北"
    },
    {
        "parentCode": "081",
        "code": "0810027",
        "name": "高雄分行",
        "shortcut": "匯豐台高雄"
    },
    {
        "parentCode": "081",
        "code": "0810049",
        "name": "台中分行",
        "shortcut": "匯豐台台中"
    },
    {
        "parentCode": "081",
        "code": "0810050",
        "name": "台南分行",
        "shortcut": "匯豐台台南"
    },
    {
        "parentCode": "081",
        "code": "0810061",
        "name": "板橋分行",
        "shortcut": "匯豐台板橋"
    },
    {
        "parentCode": "081",
        "code": "0810072",
        "name": "桃園分行",
        "shortcut": "匯豐台桃園"
    },
    {
        "parentCode": "081",
        "code": "0810083",
        "name": "建國分行",
        "shortcut": "匯豐台建國"
    },
    {
        "parentCode": "081",
        "code": "0810094",
        "name": "天母分行",
        "shortcut": "匯豐台天母"
    },
    {
        "parentCode": "081",
        "code": "0810108",
        "name": "東門分行",
        "shortcut": "匯豐台東門"
    },
    {
        "parentCode": "081",
        "code": "0810120",
        "name": "光復分行",
        "shortcut": "匯豐台光復"
    },
    {
        "parentCode": "081",
        "code": "0810131",
        "name": "中山分行",
        "shortcut": "匯豐台中山"
    },
    {
        "parentCode": "081",
        "code": "0810164",
        "name": "新板分行",
        "shortcut": "匯豐台新板"
    },
    {
        "parentCode": "081",
        "code": "0810186",
        "name": "古亭分行",
        "shortcut": "匯豐台古亭"
    },
    {
        "parentCode": "081",
        "code": "0810197",
        "name": "民生分行",
        "shortcut": "匯豐台民生"
    },
    {
        "parentCode": "081",
        "code": "0810201",
        "name": "南港分行",
        "shortcut": "匯豐台南港"
    },
    {
        "parentCode": "081",
        "code": "0810212",
        "name": "林口分行",
        "shortcut": "匯豐台林口"
    },
    {
        "parentCode": "081",
        "code": "0810223",
        "name": "新竹分行",
        "shortcut": "匯豐台新竹"
    },
    {
        "parentCode": "081",
        "code": "0810234",
        "name": "松江分行",
        "shortcut": "匯豐台松江"
    },
    {
        "parentCode": "081",
        "code": "0810290",
        "name": "新店分行",
        "shortcut": "匯豐台新店"
    },
    {
        "parentCode": "081",
        "code": "0810304",
        "name": "安和分行",
        "shortcut": "匯豐台安和"
    },
    {
        "parentCode": "081",
        "code": "0810315",
        "name": "中壢分行",
        "shortcut": "匯豐台中壢"
    },
    {
        "parentCode": "081",
        "code": "0810326",
        "name": "仁愛分行",
        "shortcut": "匯豐台仁愛"
    },
    {
        "parentCode": "081",
        "code": "0810337",
        "name": "敦北分行",
        "shortcut": "匯豐台敦北"
    },
    {
        "parentCode": "081",
        "code": "0810360",
        "name": "內湖分行",
        "shortcut": "匯豐台內湖"
    },
    {
        "parentCode": "081",
        "code": "0810382",
        "name": "復興分行",
        "shortcut": "匯豐台復興"
    },
    {
        "parentCode": "081",
        "code": "0810511",
        "name": "國美分行",
        "shortcut": "匯豐台國美"
    },
    {
        "parentCode": "081",
        "code": "0810522",
        "name": "敦南分行",
        "shortcut": "匯豐台敦南"
    },
    {
        "parentCode": "081",
        "code": "0810533",
        "name": "大興分行",
        "shortcut": "匯豐台大興"
    },
    {
        "parentCode": "081",
        "code": "0810555",
        "name": "左營分行",
        "shortcut": "匯豐台左營"
    },
    {
        "parentCode": "081",
        "code": "0810566",
        "name": "大直分行",
        "shortcut": "匯豐台大直"
    },
    {
        "parentCode": "081",
        "code": "0810577",
        "name": "士林分行",
        "shortcut": "匯豐台士林"
    },
    {
        "parentCode": "081",
        "code": "0810588",
        "name": "蘆洲分行",
        "shortcut": "匯豐台蘆洲"
    },
    {
        "parentCode": "081",
        "code": "0810599",
        "name": "崇德分行",
        "shortcut": "匯豐台崇德"
    },
    {
        "parentCode": "082",
        "code": "0820017",
        "name": "台北分行",
        "shortcut": "巴黎台北"
    },
    {
        "parentCode": "082",
        "code": "0820039",
        "name": "高雄分行",
        "shortcut": "巴黎高雄"
    },
    {
        "parentCode": "082",
        "code": "0820051",
        "name": "台中分行",
        "shortcut": "巴黎台中"
    },
    {
        "parentCode": "085",
        "code": "0850009",
        "name": "台北分行",
        "shortcut": "新僑台北"
    },
    {
        "parentCode": "086",
        "code": "0860011",
        "name": "台北分行",
        "shortcut": "東方匯理北"
    },
    {
        "parentCode": "092",
        "code": "0920010",
        "name": "台北分行",
        "shortcut": "瑞士台北"
    },
    {
        "parentCode": "092",
        "code": "0920032",
        "name": "台中分行",
        "shortcut": "瑞士台中"
    },
    {
        "parentCode": "092",
        "code": "0920043",
        "name": "高雄分行",
        "shortcut": "瑞士高雄"
    },
    {
        "parentCode": "093",
        "code": "0930011",
        "name": "台北分行",
        "shortcut": "安智銀行"
    },
    {
        "parentCode": "098",
        "code": "0980016",
        "name": "台北分行",
        "shortcut": "三菱日聯台"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9540703",
        "name": "南龍區漁會",
        "shortcut": "南龍漁本會"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9540714",
        "name": "竹南分部",
        "shortcut": "南龍漁竹南"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9548905",
        "name": "彰化區漁會",
        "shortcut": "彰漁本會"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9548916",
        "name": "王功分部",
        "shortcut": "彰漁王功"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9548927",
        "name": "芳苑分部",
        "shortcut": "彰漁芳苑"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9548938",
        "name": "大城分部",
        "shortcut": "彰漁大城"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9548949",
        "name": "草港分部",
        "shortcut": "彰漁草港"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9548950",
        "name": "福興分部",
        "shortcut": "彰漁福興"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9548961",
        "name": "伸港分部",
        "shortcut": "彰漁伸港"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9548972",
        "name": "埔心分部",
        "shortcut": "彰漁埔心"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9549500",
        "name": "通苑區漁會",
        "shortcut": "通苑漁本會"
    },
    {
        "parentCode": "954(漁會)",
        "code": "9549511",
        "name": "苑裡分部",
        "shortcut": "通苑漁苑裡"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540024",
        "name": "彰化市農會",
        "shortcut": "彰化市本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540105",
        "name": "礁溪鄉農會",
        "shortcut": "礁溪本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540116",
        "name": "四結分部",
        "shortcut": "礁溪四結"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540127",
        "name": "龍潭分部",
        "shortcut": "礁溪龍潭"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540138",
        "name": "德陽分部",
        "shortcut": "礁溪德陽"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540208",
        "name": "二水鄉農會",
        "shortcut": "二水本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540301",
        "name": "公館鄉農會",
        "shortcut": "公館本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540312",
        "name": "福基分部",
        "shortcut": "公館福基"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540334",
        "name": "五谷分部",
        "shortcut": "公館五谷"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540345",
        "name": "鶴岡分部",
        "shortcut": "公館鶴岡"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540404",
        "name": "阿里山鄉農會",
        "shortcut": "阿里山本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540415",
        "name": "中山分部",
        "shortcut": "阿里山中山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540426",
        "name": "達邦分部",
        "shortcut": "阿里山達邦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540507",
        "name": "獅潭鄉農會",
        "shortcut": "獅潭本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540518",
        "name": "汶水分部",
        "shortcut": "獅潭汶水"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540600",
        "name": "大安區農會",
        "shortcut": "大安農本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540611",
        "name": "海墘辦事處",
        "shortcut": "大安農海墘"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540622",
        "name": "松雅分部",
        "shortcut": "大安農松雅"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540806",
        "name": "大肚區農會",
        "shortcut": "大肚本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540817",
        "name": "王田分部",
        "shortcut": "大肚王田"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540828",
        "name": "福山辦事處",
        "shortcut": "大肚福山辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540839",
        "name": "蔗廓辦事處",
        "shortcut": "大肚蔗廓辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540840",
        "name": "成功分部",
        "shortcut": "大肚成功"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540909",
        "name": "龍井區農會",
        "shortcut": "龍井本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540910",
        "name": "龍泉辦事處",
        "shortcut": "龍井龍泉辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540921",
        "name": "龍津辦事處",
        "shortcut": "龍井龍津辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540932",
        "name": "新庄辦事處",
        "shortcut": "龍井新庄辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9540943",
        "name": "國際辦事處",
        "shortcut": "龍井國際辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541009",
        "name": "卓蘭鎮農會",
        "shortcut": "卓蘭本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541102",
        "name": "竹南鎮農會",
        "shortcut": "竹農本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541113",
        "name": "中港分部",
        "shortcut": "竹農中港"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541124",
        "name": "大埔分部",
        "shortcut": "竹農大埔"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541135",
        "name": "龍鳳分部",
        "shortcut": "竹農龍鳳"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541205",
        "name": "頭份市農會",
        "shortcut": "頭份本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541216",
        "name": "珊湖分部",
        "shortcut": "頭份珊湖"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541227",
        "name": "上街分部",
        "shortcut": "頭份上街"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541238",
        "name": "尖山分部",
        "shortcut": "頭份尖山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541249",
        "name": "中山分部",
        "shortcut": "頭份中山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541308",
        "name": "三灣鄉農會",
        "shortcut": "三灣本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541401",
        "name": "造橋鄉農會",
        "shortcut": "造橋本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541412",
        "name": "大西分部",
        "shortcut": "造橋大西"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541423",
        "name": "朝陽分部",
        "shortcut": "造橋朝陽"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541434",
        "name": "龍昇分部",
        "shortcut": "造橋龍昇"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541456",
        "name": "豐湖分部",
        "shortcut": "造橋豐湖"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541504",
        "name": "頭屋鄉農會",
        "shortcut": "頭屋本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541515",
        "name": "明德分部",
        "shortcut": "頭屋明德"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541607",
        "name": "苗栗市農會",
        "shortcut": "苗栗本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541618",
        "name": "北苗辦事處",
        "shortcut": "苗栗北苗辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541629",
        "name": "南苗辦事處",
        "shortcut": "苗栗南苗辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541630",
        "name": "嘉盛辦事處",
        "shortcut": "苗栗嘉盛辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541700",
        "name": "西湖鄉農會",
        "shortcut": "西湖本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541711",
        "name": "五湖分部",
        "shortcut": "西湖五湖"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541803",
        "name": "通霄鎮農會",
        "shortcut": "通霄本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541814",
        "name": "白沙屯分部",
        "shortcut": "通霄白沙屯"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541906",
        "name": "銅鑼鄉農會",
        "shortcut": "銅鑼本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541917",
        "name": "中平分部",
        "shortcut": "銅鑼中平"
    },
    {
        "parentCode": "954(農會)",
        "code": "9541928",
        "name": "新隆分部",
        "shortcut": "銅鑼新隆"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542006",
        "name": "南投市農會",
        "shortcut": "南投本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542017",
        "name": "內興分部",
        "shortcut": "南投內興"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542028",
        "name": "永豐分部",
        "shortcut": "南投永豐"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542039",
        "name": "福山分部",
        "shortcut": "南投福山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542040",
        "name": "營南分部",
        "shortcut": "南投營南"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542051",
        "name": "營北分部",
        "shortcut": "南投營北"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542062",
        "name": "鳳山分部",
        "shortcut": "南投鳳山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542073",
        "name": "漳和分部",
        "shortcut": "南投漳和"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542109",
        "name": "水里鄉農會",
        "shortcut": "水里本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542110",
        "name": "上安分部",
        "shortcut": "水里上安"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542121",
        "name": "玉峰分部",
        "shortcut": "水里玉峰"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542132",
        "name": "民和分部",
        "shortcut": "水里民和"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542202",
        "name": "信義鄉農會",
        "shortcut": "信義本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542213",
        "name": "和社分部",
        "shortcut": "信義和社"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542224",
        "name": "地利分部",
        "shortcut": "信義地利"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542305",
        "name": "集集鎮農會",
        "shortcut": "集集本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542316",
        "name": "田寮分部",
        "shortcut": "集集田寮"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542408",
        "name": "埔里鎮農會",
        "shortcut": "埔里本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542419",
        "name": "合成分部",
        "shortcut": "埔里合成"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542420",
        "name": "愛蘭分部",
        "shortcut": "埔里愛蘭"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542431",
        "name": "東興分部",
        "shortcut": "埔里東興"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542501",
        "name": "中寮鄉農會",
        "shortcut": "中寮本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542512",
        "name": "爽文分部",
        "shortcut": "中寮爽文"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542545",
        "name": "義和分部",
        "shortcut": "中寮義和"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542556",
        "name": "內城分部",
        "shortcut": "中寮內城"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542604",
        "name": "魚池鄉農會",
        "shortcut": "魚池本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542615",
        "name": "頭社分部",
        "shortcut": "魚池頭社"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542626",
        "name": "日月潭分部",
        "shortcut": "魚池日月潭"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542637",
        "name": "東光分部",
        "shortcut": "魚池東光"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542648",
        "name": "共和分部",
        "shortcut": "魚池共和"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542659",
        "name": "五城分部",
        "shortcut": "魚池五城"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542707",
        "name": "國姓鄉農會",
        "shortcut": "國姓本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542718",
        "name": "國姓分部",
        "shortcut": "國姓國姓"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542729",
        "name": "長流分部",
        "shortcut": "國姓長流"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542730",
        "name": "北山分部",
        "shortcut": "國姓北山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542741",
        "name": "北港分部",
        "shortcut": "國姓北港"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542752",
        "name": "福龜分部",
        "shortcut": "國姓福龜"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542763",
        "name": "南港分部",
        "shortcut": "國姓南港"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542800",
        "name": "鹿谷鄉農會",
        "shortcut": "鹿谷本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542811",
        "name": "廣興分部",
        "shortcut": "鹿谷廣興"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542822",
        "name": "初鄉分部",
        "shortcut": "鹿谷初鄉"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542833",
        "name": "竹林分部",
        "shortcut": "鹿谷竹林"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542844",
        "name": "坪頂分部",
        "shortcut": "鹿谷坪頂"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542855",
        "name": "永隆分部",
        "shortcut": "鹿谷永隆"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542866",
        "name": "瑞田分部",
        "shortcut": "鹿谷瑞田"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542877",
        "name": "鳳凰分部",
        "shortcut": "鹿谷鳳凰"
    },
    {
        "parentCode": "954(農會)",
        "code": "9542903",
        "name": "仁愛鄉農會",
        "shortcut": "仁愛本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543003",
        "name": "苑裡鎮農會",
        "shortcut": "苑裡本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543014",
        "name": "山腳分部",
        "shortcut": "苑裡山腳"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543025",
        "name": "社苓分部",
        "shortcut": "苑裡社苓"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543106",
        "name": "三義鄉農會",
        "shortcut": "三義本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543117",
        "name": "鯉魚潭分部",
        "shortcut": "三義鯉魚潭"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543209",
        "name": "大湖地區農會",
        "shortcut": "大湖本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543210",
        "name": "新開分部",
        "shortcut": "大湖新開"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543221",
        "name": "南湖分部",
        "shortcut": "大湖南湖"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543232",
        "name": "清安分部",
        "shortcut": "大湖清安"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543243",
        "name": "大湖分部",
        "shortcut": "大湖農大湖"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543302",
        "name": "南庄鄉農會",
        "shortcut": "南庄本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543313",
        "name": "田美分部",
        "shortcut": "南庄田美"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543324",
        "name": "南富分部",
        "shortcut": "南庄南富"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543405",
        "name": "線西鄉農會",
        "shortcut": "線西本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543508",
        "name": "太平區農會",
        "shortcut": "太平本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543519",
        "name": "新光分部",
        "shortcut": "太平新光"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543520",
        "name": "頭汴分部",
        "shortcut": "太平頭汴"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543531",
        "name": "光隆分部",
        "shortcut": "太平光隆"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543542",
        "name": "中山分部",
        "shortcut": "太平中山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543553",
        "name": "東平分部",
        "shortcut": "太平東平"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543564",
        "name": "坪林分部",
        "shortcut": "太平坪林"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543575",
        "name": "新興分部",
        "shortcut": "太平新興"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543601",
        "name": "花蓮市農會",
        "shortcut": "花農本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543612",
        "name": "花商分部",
        "shortcut": "花農花商"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543634",
        "name": "自強分部",
        "shortcut": "花農自強"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543645",
        "name": "美崙分部",
        "shortcut": "花農美崙"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543704",
        "name": "林內鄉農會",
        "shortcut": "林內本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543807",
        "name": "田尾鄉農會",
        "shortcut": "田尾本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543818",
        "name": "溪畔分部",
        "shortcut": "田尾溪畔"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543829",
        "name": "福田分部",
        "shortcut": "田尾福田"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543830",
        "name": "海豐分部",
        "shortcut": "田尾海豐"
    },
    {
        "parentCode": "954(農會)",
        "code": "9543900",
        "name": "北斗鎮農會",
        "shortcut": "北斗本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544000",
        "name": "大甲區農會",
        "shortcut": "大甲本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544011",
        "name": "順天分部",
        "shortcut": "大甲順天"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544022",
        "name": "日南分部",
        "shortcut": "大甲日南"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544033",
        "name": "西岐分部",
        "shortcut": "大甲西岐"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544044",
        "name": "幼獅社區分部",
        "shortcut": "大甲幼獅"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544055",
        "name": "庄美分部",
        "shortcut": "大甲庄美"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544103",
        "name": "台中市石岡區農會",
        "shortcut": "石岡區農會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544114",
        "name": "和盛分部",
        "shortcut": "石岡和盛"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544206",
        "name": "新社區農會",
        "shortcut": "新社本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544217",
        "name": "福興分部",
        "shortcut": "新社福興"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544228",
        "name": "中興嶺分部",
        "shortcut": "新社中興嶺"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544309",
        "name": "梧棲區農會",
        "shortcut": "梧棲本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544310",
        "name": "興農分部",
        "shortcut": "梧棲興農"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544321",
        "name": "安寧分部",
        "shortcut": "梧棲安寧"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544332",
        "name": "大莊分部",
        "shortcut": "梧棲大莊"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544343",
        "name": "南簡分部",
        "shortcut": "梧棲南簡"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544402",
        "name": "后里區農會",
        "shortcut": "后里本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544413",
        "name": "義里分部",
        "shortcut": "后里義里"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544424",
        "name": "公館分部",
        "shortcut": "后里公館"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544435",
        "name": "眉山分部",
        "shortcut": "后里眉山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544505",
        "name": "清水區農會",
        "shortcut": "清水本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544516",
        "name": "臨江辦事處",
        "shortcut": "清水臨江辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544527",
        "name": "南社分部",
        "shortcut": "清水南社"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544608",
        "name": "霧峰區農會",
        "shortcut": "霧峰本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544619",
        "name": "北柳分部",
        "shortcut": "霧峰北柳"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544620",
        "name": "萬豐分部",
        "shortcut": "霧峰萬豐"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544631",
        "name": "吉峰分部",
        "shortcut": "霧峰吉峰"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544642",
        "name": "舊正分部",
        "shortcut": "霧峰舊正"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544653",
        "name": "吉峰路分部",
        "shortcut": "霧峰吉峰路"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544701",
        "name": "外埔區農會",
        "shortcut": "外埔本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544712",
        "name": "土城辦事處",
        "shortcut": "外埔土城辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544723",
        "name": "大東分部",
        "shortcut": "外埔大東"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544804",
        "name": "烏日區農會",
        "shortcut": "烏日本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544815",
        "name": "溪埧分部",
        "shortcut": "烏日溪埧"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544826",
        "name": "九德分部",
        "shortcut": "烏日九德"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544837",
        "name": "成功分部",
        "shortcut": "烏日成功"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544907",
        "name": "潭子區農會",
        "shortcut": "潭子本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544918",
        "name": "頭家分部",
        "shortcut": "潭子頭家"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544929",
        "name": "東寶分部",
        "shortcut": "潭子東寶"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544930",
        "name": "栗林辦事處",
        "shortcut": "潭子栗林"
    },
    {
        "parentCode": "954(農會)",
        "code": "9544941",
        "name": "甘蔗辦事處",
        "shortcut": "潭子甘蔗辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545007",
        "name": "伸港鄉農會",
        "shortcut": "伸港本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545100",
        "name": "和美鎮農會",
        "shortcut": "和美本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545122",
        "name": "塗厝分部",
        "shortcut": "和美塗厝"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545133",
        "name": "新庄分部",
        "shortcut": "和美新庄"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545144",
        "name": "鎮平分部",
        "shortcut": "和美鎮平"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545203",
        "name": "花壇鄉農會",
        "shortcut": "花壇本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545214",
        "name": "白沙分部",
        "shortcut": "花壇白沙"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545225",
        "name": "灣雅分部",
        "shortcut": "花壇灣雅"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545236",
        "name": "口庄分部",
        "shortcut": "花壇口庄"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545258",
        "name": "長沙分部",
        "shortcut": "花壇長沙"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545306",
        "name": "福興鄉農會",
        "shortcut": "福興本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545317",
        "name": "管嶼分部",
        "shortcut": "福興農管嶼"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545328",
        "name": "外中分部",
        "shortcut": "福興農外中"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545409",
        "name": "大村鄉農會",
        "shortcut": "大村本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545410",
        "name": "大村分部",
        "shortcut": "大村大村"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545421",
        "name": "美港分部",
        "shortcut": "大村美港"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545432",
        "name": "大西辦事處",
        "shortcut": "大村大西辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545443",
        "name": "村東辦事處",
        "shortcut": "大村村東辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545502",
        "name": "溪湖鎮農會",
        "shortcut": "溪湖本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545605",
        "name": "社頭鄉農會",
        "shortcut": "社頭本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545616",
        "name": "湳雅分部",
        "shortcut": "社頭湳雅"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545627",
        "name": "橋頭分部",
        "shortcut": "社頭橋頭"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545638",
        "name": "清水分部",
        "shortcut": "社頭清水"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545708",
        "name": "大城鄉農會",
        "shortcut": "大城本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545719",
        "name": "西港分部",
        "shortcut": "大城西港"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545801",
        "name": "溪州鄉農會",
        "shortcut": "溪州本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545812",
        "name": "西畔分部",
        "shortcut": "溪州西畔"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545823",
        "name": "大庄分部",
        "shortcut": "溪州大庄"
    },
    {
        "parentCode": "954(農會)",
        "code": "9545904",
        "name": "田中鎮農會",
        "shortcut": "田中本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546004",
        "name": "南投縣竹山鎮農會",
        "shortcut": "竹山鎮農會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546015",
        "name": "社寮分部",
        "shortcut": "竹山農社寮"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546026",
        "name": "瑞竹分部",
        "shortcut": "竹山農瑞竹"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546037",
        "name": "延平分部",
        "shortcut": "竹山農延平"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546048",
        "name": "中央分部",
        "shortcut": "竹山農中央"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546107",
        "name": "麥寮鄉農會",
        "shortcut": "麥寮本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546118",
        "name": "橋頭分部",
        "shortcut": "麥寮橋頭"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546200",
        "name": "雲林縣東勢鄉農會",
        "shortcut": "勢鄉農本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546211",
        "name": "新坤辦事處",
        "shortcut": "勢鄉農新坤"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546303",
        "name": "土庫鎮農會",
        "shortcut": "土庫本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546314",
        "name": "馬光分部",
        "shortcut": "土庫馬光"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546325",
        "name": "新庄辦事處",
        "shortcut": "土庫新庄辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546336",
        "name": "忠正辦事處",
        "shortcut": "土庫忠正辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546358",
        "name": "後埔分部",
        "shortcut": "土庫後埔"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546406",
        "name": "元長鄉農會",
        "shortcut": "元長本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546417",
        "name": "鹿寮分部",
        "shortcut": "元長鹿寮"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546428",
        "name": "客庴分部",
        "shortcut": "元長客庴"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546509",
        "name": "北港鎮農會",
        "shortcut": "北港本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546510",
        "name": "好收分部",
        "shortcut": "北港好收"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546602",
        "name": "水林鄉農會",
        "shortcut": "水林本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546613",
        "name": "蔦松辦事處",
        "shortcut": "水林蔦松"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546624",
        "name": "西井辦事處",
        "shortcut": "水林西井"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546635",
        "name": "溪墘辦事處",
        "shortcut": "水林溪墘"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546646",
        "name": "宏仁分部",
        "shortcut": "水林宏仁"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546705",
        "name": "中埔鄉農會",
        "shortcut": "中埔本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546716",
        "name": "三層分部",
        "shortcut": "中埔三層"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546727",
        "name": "同仁分部",
        "shortcut": "中埔同仁"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546738",
        "name": "金蘭分部",
        "shortcut": "中埔金蘭"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546749",
        "name": "隆興分部",
        "shortcut": "中埔隆興"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546750",
        "name": "和睦分部",
        "shortcut": "中埔和睦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546761",
        "name": "沄水分部",
        "shortcut": "中埔沄水"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546772",
        "name": "和興分部",
        "shortcut": "中埔和興"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546783",
        "name": "和美分部",
        "shortcut": "中埔和美"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546808",
        "name": "鹿港鎮農會",
        "shortcut": "鹿港本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546819",
        "name": "草港分部",
        "shortcut": "鹿港草港"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546820",
        "name": "頂番分部",
        "shortcut": "鹿港頂番"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546831",
        "name": "海埔分部",
        "shortcut": "鹿港海埔"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546842",
        "name": "東崎分部",
        "shortcut": "鹿港東崎"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546901",
        "name": "瑞穗鄉本會",
        "shortcut": "瑞穗本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9546912",
        "name": "富源分部",
        "shortcut": "瑞穗富源"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547001",
        "name": "官田區農會",
        "shortcut": "官田本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547012",
        "name": "西庄辦事處",
        "shortcut": "官田西庄辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547023",
        "name": "官田辦事處",
        "shortcut": "官田官田辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547034",
        "name": "二鎮辦事處",
        "shortcut": "官田二鎮辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547045",
        "name": "渡頭辦事處",
        "shortcut": "官田渡頭辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547056",
        "name": "湖山辦事處",
        "shortcut": "官田湖山辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547104",
        "name": "大內區農會",
        "shortcut": "大內本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547115",
        "name": "二溪辦事處",
        "shortcut": "大內二溪辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547126",
        "name": "頭社分部",
        "shortcut": "大內頭社"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547137",
        "name": "環湖分部",
        "shortcut": "大內環湖"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547207",
        "name": "新市區農會",
        "shortcut": "新市本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547218",
        "name": "大社分部",
        "shortcut": "新市大社"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547300",
        "name": "左鎮區農會",
        "shortcut": "左鎮本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547403",
        "name": "山上區農會",
        "shortcut": "山上本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547506",
        "name": "柳營區農會",
        "shortcut": "柳營本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547517",
        "name": "太康辦事處",
        "shortcut": "柳營太康辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547528",
        "name": "重溪辦事處",
        "shortcut": "柳營重溪辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547539",
        "name": "旭山辦事處",
        "shortcut": "柳營旭山辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547540",
        "name": "果毅辦事處",
        "shortcut": "柳營果毅辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547609",
        "name": "七股區農會",
        "shortcut": "七股本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547702",
        "name": "玉溪地區農會",
        "shortcut": "玉溪本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547724",
        "name": "松浦分部",
        "shortcut": "玉溪松浦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547805",
        "name": "學甲區農會",
        "shortcut": "學甲本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547816",
        "name": "中洲分部",
        "shortcut": "學甲中洲"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547827",
        "name": "宅港分部",
        "shortcut": "學甲宅港"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547838",
        "name": "頂洲分部",
        "shortcut": "學甲頂洲"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547849",
        "name": "草圣分部",
        "shortcut": "學甲草圣"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547850",
        "name": "大灣分部",
        "shortcut": "學甲大灣"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547908",
        "name": "下營區農會",
        "shortcut": "下營本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547919",
        "name": "中興分部",
        "shortcut": "下營中興"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547920",
        "name": "中營分部",
        "shortcut": "下營中營"
    },
    {
        "parentCode": "954(農會)",
        "code": "9547931",
        "name": "甲中分部",
        "shortcut": "下營甲中"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548053",
        "name": "埔鹽鄉農會",
        "shortcut": "埔鹽本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548064",
        "name": "新水分部",
        "shortcut": "埔鹽新水"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548101",
        "name": "麻豆區農會",
        "shortcut": "麻豆本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548112",
        "name": "光復分部",
        "shortcut": "麻豆光復"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548123",
        "name": "新生分部",
        "shortcut": "麻豆新生"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548134",
        "name": "復光分部",
        "shortcut": "麻豆復光"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548145",
        "name": "安業分部",
        "shortcut": "麻豆安業"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548204",
        "name": "仁德區農會",
        "shortcut": "仁德本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548215",
        "name": "太子分部",
        "shortcut": "仁德太子"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548226",
        "name": "後壁分部",
        "shortcut": "仁德後壁"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548237",
        "name": "保安分部",
        "shortcut": "仁德保安"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548248",
        "name": "中洲分部",
        "shortcut": "仁德中洲"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548259",
        "name": "二行分部",
        "shortcut": "仁德二行"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548307",
        "name": "關廟區農會",
        "shortcut": "關廟本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548318",
        "name": "龜洞辦事處",
        "shortcut": "關廟龜洞辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548329",
        "name": "埤頭辦事處",
        "shortcut": "關廟埤頭辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548400",
        "name": "龍崎區農會",
        "shortcut": "龍崎區本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548411",
        "name": "龍船分部",
        "shortcut": "龍崎龍船"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548503",
        "name": "安定區農會",
        "shortcut": "安定本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548514",
        "name": "蘇厝分部",
        "shortcut": "安定蘇厝"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548525",
        "name": "港口辦事處",
        "shortcut": "安定港口辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548536",
        "name": "中沙分部",
        "shortcut": "安定中沙"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548547",
        "name": "海寮分部",
        "shortcut": "安定海寮"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548606",
        "name": "白河區農會",
        "shortcut": "白河本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548617",
        "name": "市場分部",
        "shortcut": "白河市場"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548628",
        "name": "關嶺分部",
        "shortcut": "白河關嶺"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548639",
        "name": "竹門分部",
        "shortcut": "白河竹門"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548640",
        "name": "玉豐分部",
        "shortcut": "白河玉豐"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548651",
        "name": "內角分部",
        "shortcut": "白河內角"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548709",
        "name": "東山區農會",
        "shortcut": "東山本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548710",
        "name": "東原辦事處",
        "shortcut": "東山東原辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548721",
        "name": "青山辦事處",
        "shortcut": "東山青山辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548732",
        "name": "南溪辦事處",
        "shortcut": "東山南溪辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548743",
        "name": "聖賢辦事處",
        "shortcut": "東山聖賢辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548802",
        "name": "後壁區農會",
        "shortcut": "後壁本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548813",
        "name": "菁寮辦事處",
        "shortcut": "後壁菁寮辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548824",
        "name": "安溪辦事處",
        "shortcut": "後壁安溪辦"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548835",
        "name": "上茄苳分部",
        "shortcut": "後壁上茄苳"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548846",
        "name": "新東分部",
        "shortcut": "後壁新東"
    },
    {
        "parentCode": "954(農會)",
        "code": "9548857",
        "name": "竹新分部",
        "shortcut": "後壁竹新"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549005",
        "name": "內埔地區農會",
        "shortcut": "內埔本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549016",
        "name": "豐田分部",
        "shortcut": "內埔豐田"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549027",
        "name": "東勢分部",
        "shortcut": "內埔東勢"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549038",
        "name": "龍泉分部",
        "shortcut": "內埔龍泉"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549049",
        "name": "水門分部",
        "shortcut": "內埔水門"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549050",
        "name": "老埤分部",
        "shortcut": "內埔老埤"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549108",
        "name": "鳳榮地區農會",
        "shortcut": "鳳榮本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549119",
        "name": "林榮分部",
        "shortcut": "鳳榮農林榮"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549120",
        "name": "萬榮分部",
        "shortcut": "鳳榮農萬榮"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549201",
        "name": "光豐地區農會",
        "shortcut": "光豐本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549212",
        "name": "大富分部",
        "shortcut": "光豐農大富"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549223",
        "name": "豐濱分部",
        "shortcut": "光豐豐濱"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549304",
        "name": "和平區農會",
        "shortcut": "和平本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549315",
        "name": "梨山辦事處",
        "shortcut": "和平梨山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549326",
        "name": "雙崎辦事處",
        "shortcut": "和平雙崎"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549407",
        "name": "大雅區農會",
        "shortcut": "大雅本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549418",
        "name": "上楓分部",
        "shortcut": "大雅上楓"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549429",
        "name": "馬岡分部",
        "shortcut": "大雅馬岡"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549430",
        "name": "忠義分部",
        "shortcut": "大雅忠義"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549452",
        "name": "橫山分部",
        "shortcut": "大雅橫山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549603",
        "name": "台中市東勢區農會",
        "shortcut": "東勢區農會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549706",
        "name": "台中市沙鹿區農會",
        "shortcut": "沙鹿區農會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549717",
        "name": "北勢分部",
        "shortcut": "沙鹿北勢"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549728",
        "name": "鹿峰分部",
        "shortcut": "沙鹿鹿峰"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549739",
        "name": "西明辦事處",
        "shortcut": "沙鹿西明"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549740",
        "name": "公館辦事處",
        "shortcut": "沙鹿公館"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549809",
        "name": "新化區農會",
        "shortcut": "新農本會"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549810",
        "name": "那拔林分部",
        "shortcut": "新農那拔林"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549821",
        "name": "中山分部",
        "shortcut": "新農中山"
    },
    {
        "parentCode": "954(農會)",
        "code": "9549902",
        "name": "南化區農會",
        "shortcut": "南化本會"
    }
]